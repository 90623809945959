import { get, join, map, flatMap, compact, isNil, toString } from 'lodash'

export const formatDate = (dateTimeStamp) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' }
  return new Date(dateTimeStamp).getTime() > 0
    ? new Date(dateTimeStamp).toLocaleDateString('en-US', options)
    : '-'
}

const formatCsvDate = (dateTimeStamp) => {
  return new Date(dateTimeStamp).getTime() > 0
    ? new Date(dateTimeStamp).toLocaleDateString('en-US')
    : '-'
}

export const toSafeData = (data, path, defaultValue) => {
  let a = get(data, path, defaultValue)
  return isNil(a) ? defaultValue : a
}

export const prefixSampleId = (value) => {
  return new RegExp(/^[0-9]*$/).test(value)
    ? toString(value).length === 7
      ? 'WH' + value
      : 'PG' + value
    : value
}

export const formatCSVData = (list, format = '', tableName = '') => {
  return list.map((itemData) => {
    let item = tableName === 'tcinInfo' ? itemData.info : itemData
    let result = {
      filename: toSafeData(item, 'file_name', '-'),
      status: toSafeData(item, 'status', '-'),
      tcin: toSafeData(item, 'file_name', '-')
        .replace('.tif', '')
        .split('_')
        .shift(),
      position: isNaN(toSafeData(item, 'alt_position', '-'))
        ? toSafeData(item, 'alt_position', '-')
        : Number(item.alt_position),
      ingest_datetime: formatCsvDate(toSafeData(item, 'date_ingested', '-')),
      version: toSafeData(item, 'version', '-'),
      pid: toSafeData(item, 'pid', '-'),
      studio: toSafeData(item, 'studio', '-'),
      tbay: toSafeData(item, 'tbay', '-'),
      shot_date: formatCsvDate(toSafeData(item, 'image_created_date', '-')),
      photographer: toSafeData(item, 'artist', '-'),
      stylist: toSafeData(item, 'stylist', '-'),
      file_format:
        toSafeData(item, 'file_name', '-') !== '-'
          ? toSafeData(item, 'file_name', '-').split('.').pop()
          : '-',
      drm_id: toSafeData(item, 'drm_id', '-'),
      bit_depth: toSafeData(item, 'bit_depth', '-'),
      lzw_compression: toSafeData(item, 'compression', '-'),
      file_size: toSafeData(item, 'file_size', '-'),
    }
    return result
  })
}
