import {
  TOGGLE_DOWNLOAD_MANAGER,
  SET_INPROGRESS_DOWNLOADS,
  SET_SUCCESS_DOWNLOADS,
  SET_FILES_FOR_DOWNLOADS,
  SET_FAILED_DOWNLOADS,
  CLEAR_FAILED_DOWNLOADS,
  CLEAR_FILE_FOR_DOWNLOADS,
  SET_DOWNLOAD_FLAG,
  RETRY_FAILED_DOWNLOADS,
} from './actionType'

export function toggleDownloadManager() {
  return {
    type: TOGGLE_DOWNLOAD_MANAGER,
  }
}

export function setInprogressDownloads(filesList) {
  return {
    type: SET_INPROGRESS_DOWNLOADS,
    payload: filesList,
  }
}

export function setFilesForDownloads(filesList) {
  return {
    type: SET_FILES_FOR_DOWNLOADS,
    payload: filesList,
  }
}

export function setSuccessDownloads(filesList) {
  return {
    type: SET_SUCCESS_DOWNLOADS,
    payload: filesList,
  }
}

export function setErrorDownloads(filesList) {
  return {
    type: SET_FAILED_DOWNLOADS,
    payload: filesList,
  }
}

export function clearFileToBeDownload(id) {
  return {
    type: CLEAR_FILE_FOR_DOWNLOADS,
    payload: id,
  }
}

export function setDownloadFlag() {
  return {
    type: SET_DOWNLOAD_FLAG,
  }
}

export function clearErrorDownloads(ids) {
  return {
    type: CLEAR_FAILED_DOWNLOADS,
    payload: ids,
  }
}

export function retryErrorDownloads(ids) {
  return {
    type: RETRY_FAILED_DOWNLOADS,
    payload: ids,
  }
}
