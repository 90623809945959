import Moment from 'moment-timezone'

export const formatDate = (dateTimeStamp) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' }
  return new Date(dateTimeStamp).getTime() > 0
    ? new Date(dateTimeStamp).toLocaleDateString('en-US', options)
    : '-'
}

export const formatDateTime = (date = '', format = 'MMM D, YYYY h:mm a') =>
  date && Moment(date).isValid() ? Moment(date).format(format) : ''

export const convertShortDate = ({
  date = new Date(),
  format = 'YYYY-MM-DD',
} = {}) => Moment.utc(date).format(format)

export const convertUTCtoLocal = (dateString = '', format = 'lll') => {
  return dateString
    ? Moment(new Date(dateString)).utc('Z').local().format(format)
    : ''
}

export const convertToCST = (date = new Date()) =>
  new Date(Moment.tz(convertShortDate(date), 'America/chicago').format())

export const formatCSTDate = (dateTime = new Date(), format = 'lll') => {
  if (dateTime === '') {
    return dateTime
  }
  return Moment(dateTime).utc('Z').format(format)
}

export const formatAnnotationDate = (dateTime = new Date()) =>
  Moment(dateTime).utc('Z').format('LLL')

export const isBefore = (dateTime = new Date()) => Moment().isBefore(dateTime)

export const momentHelper = Moment

export const getISODateWithoutTime = (date = new Date()) => {
  return new Date(convertShortDate(date)).toISOString()
}
