import { createMuiTheme } from '@material-ui/core/styles'

export const praxis = {
  light: '#f05545',
  // main: '#930000',
  main: '#cc0000',
  dark: '#1a6ec5',
  contrastText: '#FFF',
  lightGrey: '#8d8d8d',
  lighterGrey: '#BDBDBD',
  lightestGrey: '#EFEFEF',
  mediumGrey: '#666',
  midGrey: '#484848',
  darkGrey: '#333',
  lightBlue: '#1a6ec5',
  lighterBlue: '#5471d2',
  darkBlue: '#0d469f',
  darkerBlue: '#002171',
  sideNavHover: 'rgba(158,158,158,.2)',
  lighterYellow: '#FFFFB0',
  lightYellow: '#FFE57F',
  darkYellow: '#CAB350',
  bostonRed: '#CC0000',
  paleYellow: '#FFFFDA',
  graphite: '#212121',
  primaryBlue: '#0D46A0',
  primaryDarkBlue: '#002071',
  primaryLightBlue: '#5471d2',
  primaryGreen: '#087F23',
  dodgerBlue: '#2196f3',
  whiteSmoke: '#F7F7F7',
}
const praxisTheme = createMuiTheme({
  palette: {
    primary: praxis,
    secondary: praxis,
  },
  overrides: {
    // MuiTableCell: {
    //   root: {
    //     padding: '4px 56px 4px 0',
    //   }
    // },
    MuiAppBar: {
      colorPrimary: { color: '#000000', backgroundColor: '#ffffff' },
      root: {
        '&.dialogTitleBar': {
          position: 'relative',
          color: '#000000',
          boxShadow: 'none',
          '& > .dialogTitleHeight': {
            minHeight: 65,
            '& > .dialogTitle': {
              fontSize: 22,
              flex: 1,
            },
            '& > .dialogClose': {
              color: '#000000',
              marginRight: -12,
            },
          },
        },
      },
    },
    MuiToolbar: {
      root: {
        '&.UserBar-root-306': {
          color: praxis.mediumGrey,
          backgroundColor: praxis.darkGrey,
        },
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: praxis.lightestGrey,
      },
      barColorPrimary: {
        backgroundColor: praxis.darkerBlue,
      },
    },
    MuiPaper: {
      elevation1: {
        '&.pContainer:hover': {
          boxShadow:
            '0px 0px 0px 1px #8ba5d2, 0px 1px 1px 0px #8ba5d2, 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
        },
        '&:hover .pHeader, &:hover .pFooter': {
          backgroundColor: '#e6ecf5 !important',
        },
      },
    },
    MuiChip: {
      avatar: {
        color: praxis.contrastText,
        backgroundColor: praxis.lightYellow,
        width: '26px !important',
        height: '26px !important',
        fontSize: '0.8rem !important',
        marginLeft: 3,
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: praxis.sideNavHover,
        },
      },
    },
    MuiDialogContent: {
      root: {
        color: praxis.lightGrey,
      },
    },
    MuiInputLabel: {
      root: {
        color: praxis.lightGrey,
        '&$shrink': {
          color: praxis.lightBlue,
        },
      },
    },
    MuiInput: {
      underline: {
        '& :after, & :before': {
          borderBottom: `2px solid ${praxis.lightBlue} !important`,
        },
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: '#2196f3',
      },
    },
    MuiCardHeader: {
      root: {
        backgroundColor: '#efefef',
        padding: '10px 23px !important',
      },
      title: {
        fontSize: 20,
        color: '#484848',
        fontWeight: 'initial',
      },
      subheader: {
        fontSize: 15,
        fontWeight: 'initial',
      },
      action: {
        marginTop: 0,
        height: 24,
        color: '#484848',
      },
      avatar: {
        height: 48,
        width: 48,
        '& > div': {
          backgroundColor: '#FFFFFF',
          '& > svg': {
            color: '#484848',
          },
        },
      },
    },
    MuiButton: {
      root: {
        fontSize: '12px',
        fontWeight: '400',
      },
      textPrimary: {
        color: '#0d469f',
        '&:hover': 'rgba(29, 63, 142, 0.12)',
      },
      containedPrimary: {
        backgroundColor: praxis.darkBlue,
        '&:hover': {
          backgroundColor: praxis.darkerBlue,
        },
      },
      raisedSecondary: {
        backgroundColor: praxis.darkBlue,
        '&:hover': {
          backgroundColor: praxis.darkerBlue,
        },
      },
    },
    MuiStepIcon: {
      completed: {
        color: 'green !important',
      },
      root: {
        color: '#0d469f !important',
      },
    },
  },
})
export default praxisTheme
