import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Tabs, Typography, Box } from '@mui/material'
import ImageCard from '../../components/ImageCard'
import PreviewHeader from './PreviewHeader'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 64,
  },
  outlinedButton: {
    color: '#ffffff !important',
    border: '1px solid #ffffff !important',
    background: 'transparent !important',
  },
  button: {
    height: '32px',
    minWidth: '90px',
    fontFamily: 'Roboto !important',
    fontSize: '14px !important',
    fontStyle: 'normal !important',
    fontWeight: '500 !important',
  },
  containedButton: {
    background: '#ffffff !important',
    border: ' 1px solid #ffffff !important',
    color: '#CC0000 !important',
    '&:hover': {
      backgroundColor: '#ffffff !important',
      color: '#CC0000 !important',
      border: ' 1px solid #ffffff !important',
    },
    '&:focus': {
      backgroundColor: '#ffffff !important',
      color: '#CC0000 !important',
      border: ' 1px solid #ffffff !important',
    },
  },
}))

export default function AdvancedPreview() {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [activeImage, setActiveImage] = useState({})

  const channel = new BroadcastChannel('selectionChannel')

  useEffect(() => {
    const cleanup = () => {
      localStorage.removeItem('selectionState')
      channel.close()
    }
    window.addEventListener('beforeunload', cleanup)
    if (localStorage.getItem('selectionState')) {
      setData(JSON.parse(localStorage.getItem('selectionState')))
      setActiveImage(JSON.parse(localStorage.getItem('selectionState'))[0])
    } else {
      navigate('/workflow')
    }
    channel.onmessage = (event) => {
      const { data: { selection = [] } = {} } = event
      setData(selection)
    }
    return () => {
      window.removeEventListener('beforeunload', cleanup)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = useStyles()
  const [pageSize, setPageSize] = useState(4)
  return (
    <div>
      <PreviewHeader
        classes={classes}
        pageSize={pageSize}
        onButtonClick={setPageSize}
      />

      <div style={{ marginTop: '90px' }}>
        <Typography
          style={{
            marginLeft: '40px',
            color: '#000',
            fontFamily: 'Roboto',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
          }}
        >
          {' '}
          Showing {data.length} images
        </Typography>
        {pageSize === 12 ? (
          <>
            <Box display="flex" justifyContent="center" width="100%">
              <Tabs
                style={{ marginTop: '10px' }}
                centered
                variant="scrollable"
                value={false}
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {data?.map((dat, i) => {
                  return (
                    <div>
                      <img
                        alt={dat.id}
                        style={{
                          height: '90px',
                          cursor: 'pointer',
                          width: '90px',
                          objectFit: 'contain',
                          marginRight: '12px',
                          border: `1px solid ${
                            activeImage.id === dat.id ? '#CC0000' : '#000'
                          }`,
                        }}
                        src={dat.previewImage}
                        onClick={() => {
                          setActiveImage(dat)
                        }}
                      />
                    </div>
                  )
                })}
              </Tabs>
            </Box>
            <Grid
              item
              lg={12}
              sm={12}
              md={12}
              style={{
                height: '900px',
                marginTop: '24px',
                paddingTop: '10px',
                borderTop: '1px solid #000',
              }}
            >
              <div style={{ width: '80%', height: '100%', margin: '0 auto' }}>
                <ImageCard
                  title={`${activeImage.id}, ${activeImage.version}`}
                  imageSrc={activeImage.zoomImage}
                  zoomSrc={activeImage.zoomImage}
                />
              </div>
            </Grid>
          </>
        ) : (
          <Grid
            wrap
            style={{ width: '100%', padding: '20px 40px' }}
            spacing={2}
            container
            className={classes.root}
          >
            {data?.map((dat, i) => {
              return (
                <Grid key={dat.id} item lg={pageSize}>
                  <ImageCard
                    title={`${dat.id}, ${dat.version}`}
                    imageSrc={dat.zoomImage}
                    zoomSrc={dat.zoomImage}
                  />
                </Grid>
              )
            })}
          </Grid>
        )}
      </div>
    </div>
  )
}
