import React from 'react'
import defaultThumbnail from '../../images/defaultThumbnail.jpg'

export default function InlineImageCard({
  onClick = () => {},
  imageList = [],
  srcKey = '',
}) {
  return (
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      {imageList.map((dat, i) => (
        <img
          key={i}
          style={{
            height: 72,
            marginRight: '5px',
            border: dat.highlighted ? '2px solid #000000' : 'none',
          }}
          src={dat[srcKey] || defaultThumbnail}
          alt="-"
          onError={(e) => {
            e.target.src = defaultThumbnail
          }}
        />
      ))}
    </div>
  )
}
