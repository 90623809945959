import * as React from 'react'
import {
  IconButton,
  Chip,
  ClickAwayListener,
  Typography,
  Grid,
  Button,
} from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '150px',
  },
  drawerHeader: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '23px',
    flex: '1',
    color: '#333333',
    margin: '0',
  },
  closeIcon: {
    color: '#ffffff',
    padding: '0 !important',
    marginRight: '-9px !important',
  },
}))

export const MultiTcinReusedFlyout = ({
  originalImage = '',
  subjectCodes = '',
  styles = {},
  tcin = '',
}) => {
  const [open, setOpen] = React.useState(false)

  const classes = useStyles()

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipToggle = () => {
    setOpen(!open)
  }

  const [propsImages, lifestyleTcin] = subjectCodes
    ? subjectCodes.split(/(?:,|;)+/).reduce(
        (acc, val, i) => {
          if (val.length && val.toLowerCase().includes('_p')) {
            acc[0].push(val)
          } else {
            acc[1].push(val)
          }
          return acc
        },
        [[], []]
      )
    : [[], []]

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        onClose={handleTooltipClose}
        open={open}
        placement="right-start"
        disableFocusListener
        disableHoverListener
        disableTouchListener
        PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              background: '#FFFFFF',
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
              borderRadius: '4px',
              color: '#333333',
              maxWidth: '560px !important',
              padding: '15px 24px',
            },
          },
        }}
        title={
          <div className={classes.tooltipContainer}>
            <Grid
              wrap={'nowrap'}
              container
              justify={'space-between'}
              alignItems={'center'}
              style={{ marginBottom: '15px' }}
            >
              <h4
                className={classes.drawerHeader}
              >{`Reused Image - ${tcin}`}</h4>
              <IconButton
                aria-label="close"
                onClick={handleTooltipClose}
                className={classes.closeIcon}
              >
                <CloseIcon style={{ fontSize: 24 }} />
              </IconButton>{' '}
            </Grid>
            <div
              style={{
                flexGrow: 1,
                padding: '5px 0px',
                overflowY: 'auto',
              }}
            >
              <Typography
                style={{ fontFamily: 'Roboto', fontWeight: 400, fontSize: 14 }}
                variant="body2"
              >
                <b>Original Image:</b>
                {originalImage}
              </Typography>
              <br />
              {lifestyleTcin.length ? (
                <>
                  {' '}
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontWeight: 400,
                      fontSize: 14,
                    }}
                    variant="body2"
                  >
                    <b>Featured TCIN Images</b>(Lifestyle Image Reused):{' '}
                    {lifestyleTcin.join(', ')}
                  </Typography>
                  <br />
                </>
              ) : (
                <></>
              )}

              {propsImages.length ? (
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 400,
                    fontSize: 14,
                  }}
                  variant="body2"
                >
                  <b>Prop TCIN Images</b> (Item displayed in image, lifestyle
                  image not reused): {propsImages.join(', ')}
                </Typography>
              ) : (
                <></>
              )}
            </div>
            <div>
              <CopyToClipboard text={subjectCodes}>
                <Button
                  style={{
                    padding: '6px 3px',
                    fontFamily: 'Roboto',
                    fontWeight: '400',
                    fontSize: '14px',
                    color: '#366CD9',
                  }}
                  startIcon={<FileCopyOutlinedIcon />}
                >
                  Copy To Clipboard
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        }
        style={{
          background: '#FFFFFF',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
          borderRadius: '4px',
        }}
      >
        {/* <Chip
          variant="outlined"
          color="primary"
          label={`Multi TCINs (${subjectCodesArray.length})`}
          onClick={handleTooltipToggle}
          style={{ marginRight: 7, marginBottom: 7 }}
        /> */}
        <Button
          onClick={handleTooltipToggle}
          style={{ ...styles, fontSize: '14px', textTransform: 'none' }}
          variant="outlined"
        >
          {originalImage === tcin ? 'MultiTCIN' : 'REUSED'}
        </Button>
      </Tooltip>
    </ClickAwayListener>
  )
}
