import React from 'react'
import {
  Accordion,
  AccordionSummary,
  TableCell,
  TableRow,
  AccordionDetails,
  Table,
  TableHead,
  TableBody,
  Checkbox,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import { formatDateTime } from '../../helpers/dateHelper'
import { findIndex } from 'lodash'

export default function ErrorDownloadstable({
  errorFiles = {},
  isExpanded = false,
  setExpanded = false,
  classes = {},
  onSelectAll = () => {},
  filesSelected = [],
  onSelect = () => {},
}) {
  const isAllSelected =
    Object.keys(errorFiles).length > 0 &&
    Object.keys(errorFiles).every(
      (elem) =>
        findIndex(filesSelected, (dat) => {
          return dat === elem
        }) !== -1
    )

  return (
    <Accordion
      elevation={0}
      square
      expanded={isExpanded}
      onChange={setExpanded}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        classes={{
          root: classes.accordianSummaryRoot,
          content: classes.accordianSummaryContent,
        }}
        id="panel1a-header"
      >
        <Typography className={classes.heading}>Error</Typography>

        <ExpandMoreIcon
          className={`${classes.nonExpandedIcon} ${
            isExpanded ? classes.expandedIcon : ''
          }`}
          style={{ marginLeft: '5px' }}
        />
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  disabled={!Object.keys(errorFiles).length}
                  indeterminate={filesSelected.length > 0 && !isAllSelected}
                  checked={isAllSelected}
                  onChange={onSelectAll}
                  inputProps={{ 'aria-label': 'Select all failed downloads' }}
                />
              </TableCell>
              <TableCell>Image Name</TableCell>
              <TableCell>Date - Time</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(errorFiles).map((key, i) => {
              const isChecked = filesSelected.includes(key)
              return (
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={isChecked}
                      onClick={(event) => onSelect(!isChecked, key)}
                      inputProps={{
                        'aria-label': 'Select failed downloads',
                      }}
                    />
                  </TableCell>
                  <TableCell>{key}</TableCell>
                  <TableCell>
                    {formatDateTime(
                      errorFiles[key].timeStamp,
                      'MMM D, YYYY - h:mm'
                    )}
                  </TableCell>
                  <TableCell>
                    <span className={classes.iconWithDesc}>
                      <ReportProblemOutlinedIcon
                        style={{
                          color: '#BD6839',
                          marginRight: '5px',
                        }}
                      />
                      Error
                    </span>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}
