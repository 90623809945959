import * as React from 'react'
import Box from '@mui/material/Box'
import {
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Button,
  Stack,
  Checkbox,
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import { workflowColumns, columnCategories } from './constants'
import { columnMap } from '../TcinInfo/constants'

const generateColumnChecks = (
  grouping,
  columnSelectionModel,
  handleCustomizeColumnChange,
  columnView,
  tabName
) => {
  const checkboxList = workflowColumns.reduce(
    (filteredColumnToggles, column) => {
      if (column.category === grouping) {
        filteredColumnToggles.push(
          <ListItem
            disableGutters
            key={`${column.category}-${column.id}`}
            style={{ padding: 0, margin: '12px 0' }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  style={{ padding: 0 }}
                  checked={
                    columnSelectionModel[column.id]?.selected ||
                    column.alwaysInclude
                  }
                  onChange={handleCustomizeColumnChange}
                  name={column.id}
                  disabled={
                    columnView === 'default' ||
                    column.alwaysInclude ||
                    (column.id === 'qaAssignee' && tabName === 'RETOUCH')
                  }
                />
              }
              label={
                <span style={{ fontSize: '14px' }}>{column.headerName}</span>
              }
            />
          </ListItem>
        )
      }
      return filteredColumnToggles
    },
    []
  )
  return checkboxList
}

const generateColumnGroups = (
  columnSelectionModel,
  handleCustomizeColumnChange,
  columnView,
  tabName
) => {
  const checkBoxGroups = []

  for (const [key, value] of Object.entries(columnCategories)) {
    checkBoxGroups.push(
      <Grid
        item
        md={3}
        lg={3}
        key={value}
        style={{ padding: 0, margin: '25px' }}
      >
        <Typography
          variant="h6"
          style={{
            fontSize: '18px',
            fontWeight: '400',
            lineHeight: '22px',
            color: '#333333',
            fontFamily: 'Roboto',
          }}
        >
          {value}
        </Typography>
        <List dense style={{ padding: '0', margin: '8px 0 0px 8px' }}>
          {generateColumnChecks(
            value,
            columnSelectionModel,
            handleCustomizeColumnChange,
            columnView,
            tabName
          )}
        </List>
      </Grid>
    )
  }
  return checkBoxGroups
}

const CustomizeColumnsPanel = (props) => {
  const {
    columnSelectionModel,
    handleCustomizeColumnChange,
    columnView,
    handleColumnViewChange,
    saveCustomColumns,
    tabName,
  } = props

  return (
    <Box sx={{ width: 1000, m: 3, height: '100%' }} role="presentation">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <Typography
          variant="h5"
          component="span"
          sx={{
            verticalAlign: 'middle',
            mr: 4,
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '24px',
            fontFamily: 'Roboto',
          }}
        >
          Customize Columns
        </Typography>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="deafault-custom-column-selector"
            name="default-custom-selector"
            value={columnView}
            onChange={handleColumnViewChange}
          >
            <FormControlLabel
              value="default"
              control={<Radio />}
              label={<span style={{ fontSize: '14px' }}>Default View</span>}
            />
            <FormControlLabel
              value="custom"
              control={<Radio />}
              label={<span style={{ fontSize: '14px' }}>Custom View</span>}
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <FormControl sx={{ width: '100%', maxHeight: '600px' }}>
        <Grid container spacing={3}>
          {generateColumnGroups(
            columnSelectionModel,
            handleCustomizeColumnChange,
            columnView,
            tabName
          )}
        </Grid>
      </FormControl>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginTop: '24px',
        }}
      >
        <Stack direction="row" spacing={2}>
          <Button variant="contained" size="large" onClick={saveCustomColumns}>
            Apply
          </Button>
        </Stack>
      </Box>
    </Box>
  )
}

export default CustomizeColumnsPanel
