import { getWorkflowRows } from '../../containers/Workflow/utils'
import {
  GET_WORKFLOW_DATA_PENDING,
  GET_WORKFLOW_DATA_FULFILLED,
  GET_WORKFLOW_DATA_REJECTED,
  GET_CUSTOM_COLUMNS_PENDING,
  GET_CUSTOM_COLUMNS_FULFILLED,
  GET_CUSTOM_COLUMNS_REJECTED,
  SET_CUSTOM_COLUMNS_CHANGED,
  GET_WORKFLOW_ASIGNEE_LIST_PENDING,
  GET_WORKFLOW_ASIGNEE_LIST_FULFILLED,
  GET_WORKFLOW_ASIGNEE_LIST_REJECTED,
  GET_WORKFLOW_QAASIGNEE_LIST_PENDING,
  GET_WORKFLOW_QAASIGNEE_LIST_FULFILLED,
  GET_WORKFLOW_QAASIGNEE_LIST_REJECTED,
  CHANGE_IMAGE__WORKFLOW_PENDING,
  CHANGE_IMAGE__WORKFLOW_FULFILLED,
  CHANGE_IMAGE__WORKFLOW_REJECTED,
  ADD_QA_NOTES_PENDING,
  ADD_QA_NOTES_FULFILLED,
  ADD_QA_NOTES_REJECTED,
  ADD_NOTES_PENDING,
  ADD_NOTES_FULFILLED,
  ADD_NOTES_REJECTED,
  ADD_TAGS_PENDING,
  ADD_TAGS_FULFILLED,
  ADD_TAGS_REJECTED,
  GET_TAGS_LIST_PENDING,
  GET_TAGS_LIST_FULFILLED,
  GET_TAGS_LIST_REJECTED,
  EDIT_TAGS_PENDING,
  EDIT_TAGS_FULFILLED,
  EDIT_TAGS_REJECTED,
  DELETE_TAGS_PENDING,
  DELETE_TAGS_FULFILLED,
  DELETE_TAGS_REJECTED,
  SEND_TO_PIPELINE_PENDING,
  SEND_TO_PIPELINE_FULFILLED,
  SEND_TO_PIPELINE_REJECTED,
  SET_IMAGE_TO_ARCHIVE_PENDING,
  SET_IMAGE_TO_ARCHIVE_FULFILLED,
  SET_IMAGE_TO_ARCHIVE_REJECTED,
  CHECK_WITH_SCRIPT_TAGS_FULFILLED,
  CHECK_WITH_SCRIPT_TAGS_PENDING,
  CHECK_WITH_SCRIPT_TAGS_REJECTED,
  APPLY_IMAGE_TAGS_PENDING,
  APPLY_IMAGE_TAGS_FULFILLED,
  APPLY_IMAGE_TAGS_REJECTED,
  UPDATE_WORKFLOW_TABLE_IMAGE_THUMBNAIL,
  SET_TOP_LEVEL_STUDIO_FILTER,
} from './actionTypes'
import cloneDeep from 'lodash/cloneDeep'
import { generateDefaultColumnVisibilityModel } from '../../containers/Workflow/constants'
import { createWorkFlowFilterData } from '../../helpers/filter'
export const initialState = {
  page: 0,
  rows: [],
  subTabCount: {},
  statusCounts: {},
  filters: {},
  totalRowCount: 0,
  customColumnsList: [],
  tagsList: [],
  activeTab: 'NEW',
  topStudioList: localStorage.getItem('top_studios')
    ? JSON.parse(localStorage.getItem('top_studios'))
    : [],
  columnVisibilityModel: {
    NEW: generateDefaultColumnVisibilityModel('NEW'),
  },
  columnSelectionModel: {
    NEW: generateDefaultColumnVisibilityModel('NEW'),
  },
  columnView: 'Default',
  tagsDeleting: [],
  isLoading: false,
  isColumnsLoading: false,
  isLoadingAsignee: false,
  isLoadingQaAsignee: false,
  isLoadingPipeline: false,
  isLoadingNotes: false,
  isLoadingTags: false,
  isApplyingTags: false,
  isAddingTags: false,
  isEditingTags: {},
  isDeletingTags: false,
  rawData: {},
}
const reducer = (state = initialState, { type, payload = {} } = {}) => {
  const {
    workflowTab = '',
    total_count = '',
    column_list = [],
    tableTab = 'NEW',
    status_counts = {},
    aggregated_status_counts = {},
    image_counts = {},
    columnVisibilityModel = {},
    columnSelectionModel = {},
  } = payload
  switch (type) {
    case GET_WORKFLOW_DATA_PENDING:
      return {
        ...state,
        activeTab: workflowTab,
        page: 0,
        rows: [],
        subTabCount: {},
        totalRowCount: 0,
        isLoading: true,
      }
    case GET_WORKFLOW_DATA_FULFILLED:
      return {
        ...state,
        page: payload.page,
        filters: createWorkFlowFilterData(payload.filters),
        rows: getWorkflowRows(payload),
        rawData: payload,
        subTabCount: { ...status_counts, ...image_counts },
        statusCounts: aggregated_status_counts,
        totalRowCount: total_count,
        isLoading: false,
      }
    case GET_WORKFLOW_DATA_REJECTED:
      return {
        ...state,
        isLoading: false,
      }
    case CHANGE_IMAGE__WORKFLOW_PENDING:
      return {
        ...state,
        isLoading: true,
      }
    case CHANGE_IMAGE__WORKFLOW_REJECTED:
      return {
        ...state,
        isLoading: false,
      }
    case SEND_TO_PIPELINE_PENDING:
      return {
        ...state,
        isLoading: true,
      }
    case SEND_TO_PIPELINE_REJECTED:
      return {
        ...state,
        isLoading: false,
      }
    case SET_IMAGE_TO_ARCHIVE_PENDING:
      return {
        ...state,
        isLoading: true,
      }
    case SET_IMAGE_TO_ARCHIVE_REJECTED:
      return {
        ...state,
        isLoading: false,
      }
    case APPLY_IMAGE_TAGS_PENDING:
      return {
        ...state,
        isApplyingTags: true,
      }

    case SET_TOP_LEVEL_STUDIO_FILTER: {
      localStorage.setItem('top_studios', JSON.stringify(payload))
      return {
        ...state,
        topStudioList: payload,
      }
    }

    case APPLY_IMAGE_TAGS_FULFILLED: {
      let rawData = cloneDeep(state.rawData)
      payload.forEach((data, i) => {
        let { file_name = '', tags = {} } = data
        for (const obj of rawData.search_response) {
          if (obj?.workflow_info?.file_name === file_name) {
            obj.workflow_info.tags = tags
            break
          }
        }
      })

      return {
        ...state,
        isApplyingTags: false,
        rawData: rawData,
        rows: getWorkflowRows(rawData),
      }
    }
    case APPLY_IMAGE_TAGS_REJECTED:
      return {
        ...state,
        isApplyingTags: false,
      }
    case ADD_QA_NOTES_PENDING:
      return {
        ...state,
        isLoadingNotes: true,
      }
    case ADD_QA_NOTES_FULFILLED: {
      let rawData = cloneDeep(state.rawData)
      payload.forEach((data, i) => {
        let { file_name = '', qa_notes = '' } = data
        for (const obj of rawData.search_response) {
          if (obj?.workflow_info?.file_name === file_name) {
            obj.workflow_info.qa_notes = qa_notes
            break
          }
        }
      })
      return {
        ...state,
        isLoadingNotes: false,
        rawData: rawData,
        rows: getWorkflowRows(rawData),
      }
    }
    case UPDATE_WORKFLOW_TABLE_IMAGE_THUMBNAIL: {
      let rawData = cloneDeep(state.rawData)

      let {
        file_name = '',
        image_url = '',
        thumbnail_url = '',
        uploaded_version_number: uploaded_version = '',
        zoom_url = '',
        file_size = '',
      } = payload
      for (const obj of rawData.search_response) {
        if (obj?.workflow_info?.file_name === file_name) {
          obj.workflow_info.image_url = image_url
          obj.workflow_info.preview_image_url = thumbnail_url
          obj.workflow_info.zoom_url = zoom_url
          obj.workflow_info.uploaded_version = uploaded_version
          obj.workflow_info.file_size = file_size
          break
        }
      }

      return {
        ...state,
        isLoadingNotes: false,
        rawData: rawData,
        rows: getWorkflowRows(rawData),
      }
    }
    case ADD_QA_NOTES_REJECTED:
      return {
        ...state,
        isLoadingNotes: false,
      }
    case ADD_NOTES_PENDING:
      return {
        ...state,
        isLoadingNotes: true,
      }
    case ADD_TAGS_PENDING:
      return {
        ...state,
        isAddingTags: true,
      }
    case ADD_TAGS_REJECTED:
      return {
        ...state,
        isAddingTags: false,
      }
    case ADD_TAGS_FULFILLED:
      return {
        ...state,
        isAddingTags: false,
        tagsList: [...state.tagsList, payload],
      }
    case DELETE_TAGS_PENDING:
      return {
        ...state,
        isDeletingTags: true,
        tagsDeleting: [...state.tagsDeleting, ...payload],
      }
    case DELETE_TAGS_REJECTED:
      return {
        ...state,
        isDeletingTags: false,
      }
    case DELETE_TAGS_FULFILLED: {
      const { ids = [], data = [] } = payload
      let tags = cloneDeep(state.tagsList)
      let deleteTags = cloneDeep(state.tagsDeleting)
      let rawData = cloneDeep(state.rawData)
      data.forEach((dat, i) => {
        let { file_name = '', tags = '' } = dat
        for (const obj of rawData.search_response) {
          if (obj?.workflow_info?.file_name === file_name) {
            obj.workflow_info.tags = tags
            break
          }
        }
      })
      let remaingDeleted = deleteTags.filter((el) => ids.includes(el.tag_id))
      let remainingTags = tags.filter((el) => !ids.includes(el.tag_id))
      return {
        ...state,
        isDeletingTags: false,
        tagsList: remainingTags,
        tagsDeleting: remaingDeleted,
        rawData: rawData,
        rows: getWorkflowRows(rawData),
      }
    }
    case GET_TAGS_LIST_PENDING:
      return {
        ...state,
        isLoadingTags: true,
      }
    case GET_TAGS_LIST_REJECTED:
      return {
        ...state,
        isLoadingTags: false,
      }
    case GET_TAGS_LIST_FULFILLED:
      return {
        ...state,
        isLoadingTags: false,
        tagsList: payload,
      }
    case ADD_NOTES_FULFILLED: {
      let rawData = cloneDeep(state.rawData)
      payload.forEach((data, i) => {
        let { file_name = '', notes = '' } = data
        for (const obj of rawData.search_response) {
          if (obj?.workflow_info?.file_name === file_name) {
            obj.workflow_info.notes = notes
            break
          }
        }
      })
      return {
        ...state,
        isLoadingNotes: false,
        rawData: rawData,
        rows: getWorkflowRows(rawData),
      }
    }
    case ADD_NOTES_REJECTED:
      return {
        ...state,
        isLoadingNotes: false,
      }
    case EDIT_TAGS_PENDING:
      return {
        ...state,
        isEditingTags: { ...state.isEditingTags, [payload]: true },
      }
    case EDIT_TAGS_FULFILLED: {
      let rawData = cloneDeep(state.tagsList)
      rawData[rawData.findIndex((el) => el.tag_id === payload.tag_id)] = payload
      let updatedEdit = { ...state.isEditingTags }
      delete updatedEdit[payload.tag_id]
      return {
        ...state,
        isEditingTags: updatedEdit,
        tagsList: rawData,
      }
    }
    case EDIT_TAGS_REJECTED: {
      let updatedEdit = { ...state.isEditingTags }
      delete updatedEdit[payload.tagId]
      return {
        ...state,
        isEditingTags: updatedEdit,
      }
    }
    case CHECK_WITH_SCRIPT_TAGS_PENDING:
      return {
        ...state,
        isEditingTags: { ...state.isEditingTags, [payload]: true },
      }
    case CHECK_WITH_SCRIPT_TAGS_FULFILLED: {
      let rawData = cloneDeep(state.tagsList)
      rawData[rawData.findIndex((el) => el.tag_id === payload.tag_id)] = payload
      let updatedEdit = { ...state.isEditingTags }
      delete updatedEdit[payload.tag_id]
      return {
        ...state,
        isEditingTags: updatedEdit,
        tagsList: rawData,
      }
    }
    case CHECK_WITH_SCRIPT_TAGS_REJECTED: {
      let updatedEdit = { ...state.isEditingTags }
      delete updatedEdit[payload.tagId]
      return {
        ...state,
        isEditingTags: updatedEdit,
      }
    }
    case GET_CUSTOM_COLUMNS_PENDING:
      return {
        ...state,
        isColumnsLoading: true,
      }
    case GET_CUSTOM_COLUMNS_FULFILLED:
      return {
        ...state,
        customColumnsList: column_list,
        columnVisibilityModel: {
          ...state.columnVisibilityModel,
          [tableTab]: columnVisibilityModel,
        },
        columnSelectionModel: {
          ...state.columnVisibilityModel,
          [tableTab]: columnVisibilityModel,
        },
        isColumnsLoading: false,
      }
    case GET_CUSTOM_COLUMNS_REJECTED:
      return {
        ...state,
        isColumnsLoading: false,
      }
    case SET_CUSTOM_COLUMNS_CHANGED:
      return {
        ...state,
        columnSelectionModel: {
          ...state.columnSelectionModel,
          [tableTab]: columnSelectionModel,
        },
      }
    case GET_WORKFLOW_QAASIGNEE_LIST_PENDING:
      return {
        ...state,
        isLoadingQaAsignee: true,
      }
    case GET_WORKFLOW_QAASIGNEE_LIST_FULFILLED:
      return {
        ...state,
        qaAsigneeList: payload,
        isLoadingQaAsignee: false,
      }
    case GET_WORKFLOW_QAASIGNEE_LIST_REJECTED:
      return {
        ...state,
        isLoadingQaAsignee: false,
      }
    case GET_WORKFLOW_ASIGNEE_LIST_PENDING:
      return {
        ...state,
        isLoadingAsignee: true,
      }
    case GET_WORKFLOW_ASIGNEE_LIST_FULFILLED:
      return {
        ...state,
        asigneeList: payload,
        isLoadingAsignee: false,
      }
    case GET_WORKFLOW_ASIGNEE_LIST_REJECTED:
      return {
        ...state,
        isLoadingAsignee: false,
      }
    default:
      return state
  }
}

export default reducer
