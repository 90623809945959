import React from 'react'
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { columnMap, altImageData } from './constants'
import { useSelector, useDispatch } from 'react-redux'
import {
  groupBy,
  orderBy,
  chain,
  isEmpty,
  isEqual,
  flattenDeep,
  uniq,
  compact,
} from 'lodash'
import { Button, TablePagination } from '@material-ui/core'
import Popover from '@material-ui/core/Popover'
import PaginationActions from './PaginationActions'
import GetAppIcon from '@material-ui/icons/GetApp'
import SettingsIcon from '@material-ui/icons/Settings'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import CustomColumn from './CustomColumns'
import { CSVLink } from 'react-csv'
import { formatCSVData, formatDate, toSafeData, prefixSampleId } from './mapper'
import CircularProgress from '@material-ui/core/CircularProgress'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import axios from 'axios'
import {
  openZoomedImage,
  setSortedTcinData,
  closeZoomedImage,
  setSelected,
  setTreeList,
} from '../../store/TcinInfo/actionCreator'
import Dialog from '@material-ui/core/Dialog'
import defaultThumbnail from '../../images/defaultThumbnail.jpg'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import CancelIcon from '@material-ui/icons/Cancel'
import apiConfig from '../../apiConfig'
import {
  generatePDFAPI,
  generateContactSheetPDFAPI,
  generateContactSheetFinalPDFAPI,
  generateContactSheetStoresPDFAPI,
  generateCompactShotSheetPDFAPI,
} from '../../store/TcinInfo/actionCreator'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import DataTableBody from './DataTableBody'
import { withAuth } from '@praxis/component-auth'
import { isSafari } from '../../helpers/filter'

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: 'auto',
    boxShadow: '0 0 black',
    height: isSafari() ? 'calc(100vh - 315px)' : 'calc(100vh - 290px)',
  },
  tableHead: {
    position: 'sticky',
    zIndex: 2,
    backgroundColor: '#fafafa',
    lineHeight: '1.37',
    padding: '8px 16px',
    verticalAlign: 'top',
    textAlign: 'left',
  },
  checkboxLeftFix: {
    zIndex: 3,
    left: 75,
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  tableHeadLeftFix: {
    zIndex: 3,
    left: 74,
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  tableHead2LeftFix: {
    zIndex: 3,
    left: 207,
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  tableHeadRightFix: {
    zIndex: 3,
    right: 0,
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
  tableToolbar: {
    display: 'flex',
    flexDirection: 'column',
    height: 100,
    width: 'calc(100vw - 65px)',
    position: 'sticky',
    top: 0,
    zIndex: 4,
    left: 1,
    marginBottom: 2,
  },
  tableToolbarAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 5,
    '& > :not(:last-child)': {
      margin: '0 15px',
    },
  },
  paginationToolbar: {
    borderBottom: 'none',
    flexGrow: 1,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  sortIconRoot: {
    '&:focus': {
      color: 'unset',
    },
  },
  sortIcon: {
    opacity: 1,
  },
  popover: {
    width: 896,
    padding: '20px 30px',
  },
  filterPopover: {
    width: '16%',
    padding: '20px 30px',
  },
}))

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

export const modalStyles = makeStyles((theme) => ({
  root: {
    maxheight: 300,
    // flexGrow: 1,
    // minWidth: 300,
    transform: 'translateZ(0)',
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    '@media all and (-ms-high-contrast: none)': {
      display: 'none',
    },
    margin: 0,
    padding: theme.spacing(2),
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  dailogContainer: {
    position: 'relative',
  },
  navContainer: {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    zIndex: '10',
    display: 'flex',
  },
  navButtons: {
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  prevButton: {
    width: '20%',
    left: 0,
    float: 'left',
    filter: 'alpha(Opacity=0)',
    opacity: 0,
    transition: 'opacity .6s',
    justifyContent: 'flex-start',
    '&:hover': {
      opacity: '1',
    },
  },
  centerButton: {
    width: '60%',
    cursor: 'zoom-in !important',
  },
  nextButton: {
    width: '20%',
    right: 0,
    float: 'right',
    filter: 'alpha(Opacity=0)',
    opacity: 0,
    transition: 'opacity .6s',
    justifyContent: 'flex-end',
    '&:hover': {
      opacity: '1',
    },
  },
  imgStyle: { width: '400px', maxHeight: '100%' },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

const { getUserCustomColumns, getTcinInfo } = apiConfig

const ImageDialog = ({
  open,
  src,
  handleClose,
  title = '',
  dataFiltered = [],
  page = 0,
  rowsPerPage = 50,
  activeImageIndex = 0,
  openImage = () => {},
  setImageIndex = () => {},
}) => {
  const classes = modalStyles()
  const rootRef = React.useRef(null)
  // commenting this line for now to test the resolution in prod
  // const isRetinaDisplay = useMediaQuery('(-webkit-min-device-pixel-ratio: 2)')
  src += '?scl=1'
  const filtered =
    rowsPerPage > 0
      ? dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : dataFiltered
  const DialogTitle = withStyles(classes)((props) => {
    const { children, onClose } = props
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  })

  const isFinalMainImage = title.toLowerCase().includes('final')

  return (
    <div className={classes.root} ref={rootRef}>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={handleClose}
        className={classes.modal}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          TCIN {title}
        </DialogTitle>
        <DialogContent dividers className={classes.dailogContainer}>
          <img
            src={src}
            className={classes.imgStyle}
            alt="full"
            style={{
              height: 564,
              width: 564,
              ...(!isFinalMainImage && { cursor: 'zoom-in' }),
            }}
            onClick={() => {
              !isFinalMainImage && window.open(src)
            }}
            onError={(e) => {
              e.target.src = defaultThumbnail
            }}
          />
          {isFinalMainImage ? (
            <div className={classes.navContainer}>
              <div
                className={`${classes.prevButton} ${classes.navButtons}`}
                role="button"
                style={{ visibility: activeImageIndex === 0 && 'hidden' }}
                onClick={() => {
                  setImageIndex(activeImageIndex - 1)
                  openImage(
                    `${filtered[activeImageIndex - 1].node.tcin} - Final Main`,
                    filtered[activeImageIndex - 1].node.info.item_info.images
                      .main
                  )
                }}
              >
                {' '}
                <ArrowBackIosIcon style={{ fontSize: '50px' }} />
              </div>
              <div
                className={`${classes.centerButton} ${classes.navButtons}`}
                onClick={() => window.open(src)}
                role="button"
              ></div>
              <div
                style={{
                  visibility:
                    activeImageIndex === filtered.length - 1 && 'hidden',
                }}
                className={`${classes.nextButton} ${classes.navButtons}`}
                onClick={() => {
                  setImageIndex(activeImageIndex + 1)
                  openImage(
                    `${filtered[activeImageIndex + 1].node.tcin} - Final Main`,
                    filtered[activeImageIndex + 1].node.info.item_info.images
                      .main
                  )
                }}
                role="button"
              >
                {' '}
                <ArrowForwardIosIcon style={{ fontSize: '50px' }} />
              </div>
            </div>
          ) : (
            <></>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

const DataTable = (props) => {
  const { accessToken = '', lanId = '' } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const [activeImageIndex, setActiveImageIndex] = React.useState(0)
  const [colMap, setColMap] = React.useState(columnMap)
  const [tempColMap, setTempColMap] = React.useState(columnMap)
  const [tempLayout, setTempLayout] = React.useState('flat')
  const [customizeView, setCustomizeView] = React.useState(null)
  const [userColMap, setUserColMap] = React.useState([])
  const [isSaveDisabled, setIsSaveDisabled] = React.useState(true)
  const [isApplyDisabled, setIsApplyDisabled] = React.useState(true)
  const [defaultColMap, setDefaultColMap] = React.useState([])
  const [appliedView, setAppliedView] = React.useState(null)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(50)
  const [order, setOrder] = React.useState('asc')
  const [orderWith, setOrderWith] = React.useState('')
  const [customColumnAnchor, setCustomColumnAnchor] = React.useState(null)
  const [layout, setLayout] = React.useState('flat')
  const [downloadAnchorEl, setDownloadAnchorEl] = React.useState(null)
  const [downloadSheetAnchorEl, setDownloadSheetAnchorEl] = React.useState(null)
  const [csvData, setCsvData] = React.useState({
    headers: [],
    data: false,
  })
  const csvBtn = React.useRef()
  const dataFiltered = useSelector(
    (state) => state.tcinPhotoInfoReducer.dataFiltered
  )
  const selected = useSelector((state) => state.tcinPhotoInfoReducer.selected)

  const enlargeImage = useSelector(
    (state) => state.tcinPhotoInfoReducer.enlargeImage
  )
  const { isPol = false } = apiConfig
  const [progressSheet, setProgressSheet] = React.useState(0)
  const [progressExpand, setProgressExpand] = React.useState(false)

  React.useEffect(() => {
    let altCols = []
    for (var i = 0; i < props.altImageCount; i++) {
      altCols = [...altCols, ...altImageData(i)]
    }
    let sortedColMap = orderBy([...colMap, ...altCols], ['colOrder'], ['asc'])
    setColMap(sortedColMap)
    setDefaultColMap(sortedColMap)
    setTempColMap(sortedColMap)
    getUserViewData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // React.useEffect(() => {
  //   let sortedData = orderBy(props.tcinsData, [orderWith], [order])
  //   dispatch(setSortedTcinData(sortedData))
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [order, orderWith])

  React.useEffect(() => {
    if (
      !isEmpty(csvData.data) &&
      csvBtn.current !== null &&
      csvBtn.current.link !== null
    ) {
      setTimeout(() => {
        csvBtn.current !== null && csvBtn.current.link.click()
        setCsvData({ ...csvData, data: false })
      })
    }
  }, [csvData])

  const getUserViewData = () => {
    axios
      .get(`${getUserCustomColumns}?user_id=${lanId.toUpperCase()}&view=tcin`, {
        params: {
          key: apiConfig.apiKey,
        },
      })
      .then((res) => {
        res.data && res.data.column_list !== null
          ? applyUserView(res.data.column_list)
          : handleNoUserView()
      })
      .catch((error) => {
        console.error(
          'There is an error while fetching the custom columns list',
          error
        )
      })
  }

  const handleNoUserView = () => {
    setCustomizeView('Userbased')
    setAppliedView('Userbased')
    setIsSaveDisabled(false)
  }

  const applyUserView = (data) => {
    let altCols = []
    for (var i = 0; i < props.altImageCount; i++) {
      altCols = [...altCols, ...altImageData(i)]
    }
    let sortedColMap = orderBy([...colMap, ...altCols], ['colOrder'], ['asc'])
    let indices = data.reduce((s, t, u) => {
      return s.concat(
        sortedColMap.reduce((r, v, i) => {
          return r.concat(v.groupId === t ? i : [])
        }, [])
      )
    }, [])
    let updatedColMap = sortedColMap.map((u) =>
      u.groupId !== 'tcin' || u.groupId !== 'relationship_type'
        ? { ...u, selected: false }
        : u
    )
    indices.forEach((index) => {
      if (updatedColMap[index].isParent) {
        let indexMap = updatedColMap.reduce((r, v, i) => {
          return r.concat(v.parentId === updatedColMap[index].groupId ? i : [])
        }, [])
        indexMap.forEach((o) => {
          let sibling = updatedColMap.find(
            (i) =>
              i.groupId === updatedColMap[o].groupId &&
              i.custColType === 'checkbox'
          )
          updatedColMap[o] = {
            ...updatedColMap[o],
            selected: sibling.selected,
          }
        })
        updatedColMap[index] = {
          ...updatedColMap[index],
          selected: true,
        }
      } else if (updatedColMap[index].parentId !== undefined) {
        let parent = updatedColMap.find(
          (i) => i.groupId === updatedColMap[index].parentId
        )
        updatedColMap[index] = {
          ...updatedColMap[index],
          selected: parent.selected,
        }
      } else {
        if (updatedColMap[index].groupId === 'layout') {
          setLayout(updatedColMap[index].selected ? 'flat' : 'nested')
        }
        updatedColMap[index] = {
          ...updatedColMap[index],
          selected: true,
        }
      }
    })
    setTempColMap(updatedColMap)
    setColMap(updatedColMap)
    setUserColMap(updatedColMap)
    setCustomizeView('Userbased')
    setAppliedView('Userbased')
    setIsApplyDisabled(true)
  }

  const handleColumnSelection = (event) => {
    const indices = tempColMap.reduce((r, v, i) => {
      return r.concat(v.groupId === event.target.name ? i : [])
    }, [])
    let updatedColMap = [...tempColMap]
    indices.forEach((index) => {
      if (updatedColMap[index].isParent) {
        let indexMap = updatedColMap.reduce((r, v, i) => {
          return r.concat(v.parentId === updatedColMap[index].groupId ? i : [])
        }, [])
        indexMap.forEach((o) => {
          let sibling = tempColMap.find(
            (i) =>
              i.groupId === updatedColMap[o].groupId &&
              i.custColType === 'checkbox'
          )
          updatedColMap[o] = {
            ...updatedColMap[o],
            selected: event.target.checked && sibling.selected,
          }
        })
        updatedColMap[index] = {
          ...updatedColMap[index],
          selected: event.target.checked,
        }
      } else if (updatedColMap[index].parentId !== undefined) {
        let parent = colMap.find(
          (i) => i.groupId === updatedColMap[index].parentId
        )
        updatedColMap[index] = {
          ...updatedColMap[index],
          selected: event.target.checked && parent.selected,
        }
      } else {
        if (updatedColMap[index].groupId === 'layout') {
          setTempLayout(updatedColMap[index].selected ? 'nested' : 'flat')
        }
        updatedColMap[index] = {
          ...updatedColMap[index],
          selected: event.target.checked,
        }
      }
    })
    setTempColMap(updatedColMap)
    setTempLayout(
      updatedColMap.filter((c) => c.groupId === 'layout').length === 1 &&
        updatedColMap.find((c) => c.groupId === 'layout').selected
        ? 'nested'
        : 'flat'
    )
    setIsSaveDisabled(isEqual(userColMap, updatedColMap))
    setIsApplyDisabled(isEqual(colMap, updatedColMap))
  }

  const onSaveAndApplyUserView = () => {
    let column_list = []
    tempColMap
      .filter((c) => c.selected === true)
      .forEach((element) => {
        column_list.push(element.groupId)
      })
    const request = {
      user_id: lanId.toUpperCase(),
      column_list: [...new Set(column_list)],
    }
    axios
      .post(`${getUserCustomColumns}?view=tcin`, request, {
        params: {
          key: apiConfig.apiKey,
        },
        headers: { Authorization: accessToken },
      })
      .then((res) => {
        setUserColMap(tempColMap)
        setLayout(tempLayout)
        setColMap(tempColMap)
        setIsSaveDisabled(true)
        setIsApplyDisabled(true)
      })
      .catch((error) => {
        console.error(
          'There is an error while saving the custom columns list',
          error
        )
      })
  }

  const onRestoreDefaultMap = () => {
    setCustomizeView('Default')
    setIsApplyDisabled(false)
  }

  const onUpdateView = () => {
    setLayout(tempLayout)
    setColMap(customizeView === 'Userbased' ? tempColMap : defaultColMap)
    setAppliedView(customizeView)
    setIsApplyDisabled(true)
  }

  const onSwitchToUserView = () => {
    setCustomizeView('Userbased')
    setIsSaveDisabled(isEqual(userColMap, tempColMap))
    setIsApplyDisabled(isEqual(colMap, tempColMap))
  }

  const onCloseCustomizeWindow = () => {
    appliedView === 'Userbased' && setTempColMap(colMap)
    setCustomizeView(appliedView)
    setIsApplyDisabled(true)
    setIsSaveDisabled(isEqual(userColMap, colMap))
    setCustomColumnAnchor(null)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderWith === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderWith(property)
    let sortedData = orderBy(dataFiltered, [`node.info.${orderWith}`], [order])
    dispatch(setTreeList(sortedData))
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const onSelectAllClick = (event) => {
    if (event.target.checked) {
      let data = dataFiltered.map((o) => o.node)
      dispatch(setSelected(data))
      return
    }
    dispatch(setSelected([]))
  }

  const openDownload = (event) => {
    setDownloadAnchorEl(event.currentTarget)
  }

  const closeDownload = () => {
    setDownloadAnchorEl(null)
  }

  const onClickDownload = (event, value) => {
    closeDownload()
    let csvHeaders = []
    if (event.currentTarget.dataset.item === 'all') {
      csvHeaders = colMap
        .filter((o) => {
          return (
            o.colId !== undefined &&
            o.dataType !== undefined &&
            o.dataType !== 'image' &&
            o.dataType !== 'image_array'
          )
        })
        .map((obj) => {
          return {
            key: obj.colId,
            label: obj.colName,
          }
        })
    } else {
      csvHeaders = colMap
        .filter((o) => {
          return (
            o.selected &&
            o.colId !== undefined &&
            o.dataType !== undefined &&
            o.dataType !== 'image' &&
            o.dataType !== 'image_array'
          )
        })
        .map((obj) => {
          return {
            key: obj.colId,
            label: obj.colName,
          }
        })
    }
    setCsvData({
      headers: csvHeaders,
      data: formatCSVData(selected, props.altImageCount, 'csv', 'tcinInfo'),
    })
  }

  const downloadCallBack = (downlistPercentages = '0') => {
    if (downlistPercentages === 100) {
      setProgressSheet(0)
    } else {
      setProgressSheet(downlistPercentages)
    }
  }

  const handleDownloadShotSheets = () => {
    closeDownloadSheet()
    setProgressSheet(1)
    dispatch(
      generatePDFAPI(
        selected.map((r) => r?.tcin),
        accessToken,
        downloadCallBack
      )
    )
  }

  const handleDownloadContactSheets = () => {
    closeDownloadSheet()
    setProgressSheet(1)
    dispatch(
      generateContactSheetPDFAPI(
        selected.map((r) => r?.tcin),
        accessToken,
        downloadCallBack
      )
    )
  }

  const handleDownloadContactSheetsDpci = () => {
    closeDownloadSheet()
    setProgressSheet(1)
    dispatch(
      generateContactSheetFinalPDFAPI(
        selected.map((r) => r?.tcin),
        accessToken,
        downloadCallBack,
        true
      )
    )
  }

  const handleDownloadContactSheetsStores = () => {
    closeDownloadSheet()
    setProgressSheet(1)
    dispatch(
      generateContactSheetStoresPDFAPI(
        selected.map((r) => r?.info?.dpci),
        accessToken,
        downloadCallBack,
        true
      )
    )
  }

  const handleDownloadContactSheetsFinal = () => {
    closeDownloadSheet()
    setProgressSheet(1)
    dispatch(
      generateContactSheetFinalPDFAPI(
        selected.map((r) => r?.tcin),
        accessToken,
        downloadCallBack,
        false
      )
    )
  }

  const handleDownloadCompactShotSheets = () => {
    closeDownloadSheet()
    setProgressSheet(1)
    dispatch(
      generateCompactShotSheetPDFAPI(
        selected.map((r) => r?.tcin),
        accessToken,
        downloadCallBack
      )
    )
  }

  const closeDownloadSheet = () => {
    setDownloadSheetAnchorEl(null)
  }

  const openDownloadSheet = (event) => {
    setDownloadSheetAnchorEl(event.currentTarget)
  }

  const expandRelationhips = () => {
    setProgressExpand(true)
    let tcinsAddedDueToExpansion = []
    selected.forEach((s) =>
      tcinsAddedDueToExpansion.push(
        s.info.item_info.parent_tcins !== '' &&
          s.info.item_info.parent_tcins.split(',')
      )
    )
    selected.forEach((s) =>
      tcinsAddedDueToExpansion.push(s.info.item_info.child_details)
    )
    let request = {
      id_list: compact(uniq(flattenDeep(tcinsAddedDueToExpansion))),
    }
    axios
      .post(getTcinInfo, request, {
        params: {
          key: apiConfig.apiKey,
        },
        headers: { Authorization: accessToken },
      })
      .then((res) => {
        dispatch(setSortedTcinData(res.data.tcin_info, true))
        // dispatch(setSelected([]))
      })
      .catch((e) => {
        console.log(e)
      })
    // setIsExpandDisabled(true)
  }

  return (
    <React.Fragment>
      {enlargeImage.open && (
        <ImageDialog
          activeImageIndex={activeImageIndex}
          setImageIndex={(index) => {
            setActiveImageIndex(index)
          }}
          open={enlargeImage.open}
          src={enlargeImage.src}
          thumbnailSrc={enlargeImage.src}
          handleClose={() => {
            setActiveImageIndex(0)
            dispatch(closeZoomedImage())
          }}
          openImage={(title, src) => dispatch(openZoomedImage(title, src))}
          title={enlargeImage.title}
          dataFiltered={dataFiltered}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      )}
      {customizeView && (
        <React.Fragment>
          <Paper elevation={3} square className={classes.tableToolbar}>
            <div className={classes.tableToolbarAction}>
              <Typography style={{ margin: '0 15px' }}>
                Showing {dataFiltered.length} TCINs
              </Typography>
            </div>
            <div className={classes.tableToolbarAction}>
              <Typography>{selected.length} selected</Typography>
              <Button
                variant="outlined"
                startIcon={<GetAppIcon />}
                onClick={openDownload}
                disabled={selected.length === 0}
              >
                Data
              </Button>
              {csvData.data ? (
                <CSVLink
                  ref={csvBtn}
                  asyncOnClick
                  filename={'tcinImageInfo.csv'}
                  data={csvData.data}
                  headers={csvData.headers}
                />
              ) : undefined}
              <Menu
                id="simple-menu"
                anchorEl={downloadAnchorEl}
                keepMounted
                open={Boolean(downloadAnchorEl)}
                onClose={closeDownload}
              >
                <MenuItem data-item="all" onClick={onClickDownload}>
                  Download All Columns
                </MenuItem>
                <MenuItem data-item="visible" onClick={onClickDownload}>
                  Download Visible Columns
                </MenuItem>
                {/* <MenuItem onClick={handleDownloadShotSheets}>
                  Download Shot Sheets
                  <CircularProgress
                    style={{
                      marginLeft: 8,
                    }}
                    thickness={6}
                    size={20}
                    variant="determinate"
                    value={progressShotSheet}
                  />
                </MenuItem> */}
              </Menu>
              <Button
                variant="outlined"
                startIcon={<GetAppIcon />}
                onClick={openDownloadSheet}
                disabled={selected.length === 0 || progressSheet !== 0}
              >
                Sheets
                {progressSheet !== 0 && (
                  <CircularProgress
                    style={{
                      marginLeft: 8,
                    }}
                    thickness={6}
                    size={20}
                    variant="determinate"
                    value={progressSheet}
                  />
                )}
              </Button>
              {csvData.data ? (
                <CSVLink
                  ref={csvBtn}
                  asyncOnClick
                  filename={'tcinImageInfo.csv'}
                  data={csvData.data}
                  headers={csvData.headers}
                />
              ) : undefined}
              <Menu
                id="simple-menu"
                anchorEl={downloadSheetAnchorEl}
                keepMounted
                open={Boolean(downloadSheetAnchorEl)}
                onClose={closeDownloadSheet}
              >
                <MenuItem
                  data-item="shot-sheet"
                  onClick={handleDownloadShotSheets}
                >
                  Shot Sheets
                </MenuItem>
                <MenuItem
                  data-item="contact-sheet"
                  onClick={handleDownloadCompactShotSheets}
                >
                  Compact Shot Sheets
                </MenuItem>
                <MenuItem
                  data-item="contact-sheet"
                  onClick={handleDownloadContactSheets}
                >
                  Contact Sheets Source
                </MenuItem>
                <MenuItem
                  data-item="contact-sheet"
                  onClick={handleDownloadContactSheetsFinal}
                >
                  Contact Sheets Final
                </MenuItem>
                <MenuItem
                  data-item="contact-sheet"
                  onClick={handleDownloadContactSheetsDpci}
                >
                  Contact Sheets DPCI
                </MenuItem>
                <MenuItem
                  data-item="contact-sheet"
                  onClick={handleDownloadContactSheetsStores}
                >
                  Contact Sheets Stores
                </MenuItem>
              </Menu>
              <Button
                variant="outlined"
                startIcon={<AccountTreeIcon />}
                onClick={expandRelationhips}
                disabled={selected.length === 0 || progressExpand}
              >
                Expand
                {progressExpand && (
                  <CircularProgress
                    style={{
                      marginLeft: 8,
                    }}
                    thickness={6}
                    size={20}
                    variant="indeterminate"
                  />
                )}
              </Button>

              <TablePagination
                classes={{
                  root: classes.paginationToolbar,
                }}
                component="div"
                rowsPerPageOptions={[
                  50,
                  100,
                  200,
                  { label: 'All', value: dataFiltered.length },
                ]}
                colSpan={3}
                count={dataFiltered.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={PaginationActions}
              />
              <div>
                <IconButton
                  variant="outlined"
                  onClick={(e) => setCustomColumnAnchor(e.currentTarget)}
                >
                  <SettingsIcon />
                </IconButton>
                <Popover
                  open={Boolean(customColumnAnchor)}
                  anchorEl={customColumnAnchor}
                  onClose={onCloseCustomizeWindow}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  classes={{
                    paper: classes.popover,
                  }}
                >
                  <CustomColumn
                    columnMap={
                      customizeView === 'Userbased' ? tempColMap : defaultColMap
                    }
                    handleColumnSelection={handleColumnSelection}
                    onRestoreDefaultMap={onRestoreDefaultMap}
                    onPopupClose={setCustomColumnAnchor}
                    onSwitchToUserView={onSwitchToUserView}
                    onSaveAndApplyUserView={onSaveAndApplyUserView}
                    view={customizeView}
                    isSaveDisabled={isSaveDisabled}
                    isApplyDisabled={isApplyDisabled}
                    onUpdateView={onUpdateView}
                    onDiscardChanges={onCloseCustomizeWindow}
                  />
                </Popover>
              </div>
            </div>
          </Paper>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table stickyHeader aria-label="customized table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell
                    // padding="checkbox"
                    style={{ left: 0, paddingTop: 0 }}
                    className={clsx(classes.tableHead, classes.checkboxLeftFix)}
                  >
                    <Checkbox
                      indeterminate={
                        selected.length > 0 &&
                        selected.length < dataFiltered.length
                      }
                      checked={
                        dataFiltered.length > 0 &&
                        selected.length === dataFiltered.length
                      }
                      onChange={onSelectAllClick}
                      inputProps={{ 'aria-label': 'select all tcins' }}
                    />
                  </TableCell>
                  {colMap
                    .filter((o) => {
                      return (
                        o.colId !== undefined &&
                        (layout === 'nested' ? o.parentId === undefined : true)
                      )
                    })
                    .map(
                      (x, i) =>
                        x.selected && (
                          <TableCell
                            key={i}
                            sortDirection={orderWith === x.data ? order : false}
                            className={clsx(
                              classes.tableHead,
                              i === 0 && classes.tableHeadLeftFix
                              // i === 1 && classes.tableHead2LeftFix
                            )}
                          >
                            {x.sortable ? (
                              <TableSortLabel
                                active={orderWith === x.data}
                                direction={orderWith === x.data ? order : 'asc'}
                                onClick={(e) => handleRequestSort(e, x.data)}
                                classes={{
                                  root: classes.sortIconRoot,
                                  icon: classes.sortIcon,
                                }}
                                IconComponent={
                                  orderWith !== x.data
                                    ? UnfoldMoreIcon
                                    : ArrowDownwardIcon
                                }
                              >
                                {x.colName}
                                {orderWith === x.data ? (
                                  <span className={classes.visuallyHidden}>
                                    {order === 'desc'
                                      ? 'sorted descending'
                                      : 'sorted ascending'}
                                  </span>
                                ) : null}
                              </TableSortLabel>
                            ) : (
                              x.colName
                            )}
                          </TableCell>
                        )
                    )}
                  <TableCell
                    className={clsx(
                      classes.tableHead,
                      classes.tableHeadRightFix
                    )}
                  >
                    More Info
                  </TableCell>
                </TableRow>
              </TableHead>
              <DataTableBody
                setImageIndex={(index) => setActiveImageIndex(index)}
                setProgressExpand={setProgressExpand}
                handleChangePage={setPage}
                rowsPerPage={rowsPerPage}
                colMap={colMap}
                page={page}
                layout={layout}
                handleDialogOpen={props.handleDialogOpen}
              />
            </Table>
          </TableContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const mapAuthToProps = (auth) => {
  return {
    accessToken: auth.session?.userInfo?.accessToken,
    lanId: auth.session?.userInfo?.lanId,
  }
}

export default withAuth(mapAuthToProps)(DataTable)
