// import apiConfig from '../../src/apiConfig'

export const photographerADGroup = 'APP-PTB-Photographer'
export const generalUserADGroup = 'APP-PTB-GeneralUser'
export const producerADGroup = 'APP-PTB-Producer'
export const retoucherADGroup = 'APP-PTB-ReToucher'
export const retouchingLeadADGroup = 'APP-PTB-ReTouching-Lead'
export const adminADGroup = 'APP-PTB-ADMIN'
export const retouchingQAADGroup = 'APP-PTB-ReTouching-QA'
export const artDirectorADGroup = 'APP-PTB-ArtDirectors'

export const allowedGroupsToUpload = [
  'APP-PTB-Photographer',
  'APP-PTB-Photographer-QC',
]
export const imageDownloadHost = 'https://ttc-01.sg02.target.com:11446'
export const allowedGroupsToReject = ['APP-PTB-Photographer-QC']
export const allowedGroups = [
  'APP-PTB-Producer',
  'APP-PTB-Prep-Stylist',
  'APP-PTB-Photographer',
  'APP-PTB-Photographer-QC',
]
export const tabsList = {
  TOTAL: 'All',
  UNASSIGNED: 'Unassigned',
  ASSIGNED: 'Assigned',
  APPROVE: 'Approved',
  ONHOLD: 'OnHold',
}
export const fileValidationTexts = {
  valid: 'TCIN image exists, upload edited image',
  new: 'TCIN image not found, ingest new image',
  reusedImage:
    'TCIN Image is a “Reused” image and cannot be uploaded. Refer MultiTCIN help for more info',
  validating: 'Validating',
  error: 'Some error occured',
}
export const rolewiseImageStatusList = {
  aap_ptb_retoucher: ['Ready for Retouching', 'Retouching Complete'],
  app_ptb_reTouching_qa: [
    'Ready for Retouching',
    'Retouching Complete',
    'QA Complete',
  ],
  app_ptb_artDirectors: [
    'Approved',
    'Ready for Review',
    'Sent to Pipeline',
    'Ready for Retouching',
    'Color Correction',
  ],
  others: [
    'Photo Shot & Ingested',
    'Reshoot',
    'Ready for Retouching',
    'Retouching Complete',
    'QA Complete',
    'Color Correction',
    'Hold',
    'Ready for Review',
    'Approved',
    'Sent to Pipeline',
  ],
}

export const uploadManagerAccess = [
  'APP-PTB-ADMIN',
  'APP-PTB-ReTouching-Lead',
  'APP-PTB-Producer',
  'APP-PTB-ReTouching-QA',
  'APP-PTB-ReToucher',
  'APP-PTB-ArtDirectors',
]
export const ingestionUploadManagerAccess = [
  'APP-PTB-ADMIN',
  'APP-PTB-ReTouching-Lead',
  'APP-PTB-Photographer',
]
export const workflowToolbarContents = [
  {
    label: 'Tags',
    access: ['APP-PTB-ADMIN', 'APP-PTB-ReTouching-Lead', 'APP-PTB-Producer'],
  },
  {
    label: 'Notes',
    access: [
      'APP-PTB-ADMIN',
      'APP-PTB-ReTouching-Lead',
      'APP-PTB-Producer',
      'APP-PTB-ArtDirectors',
    ],
  },
  {
    label: 'QA Notes',
    access: [
      'APP-PTB-ADMIN',
      'APP-PTB-ReTouching-Lead',
      'APP-PTB-ReTouching-QA',
      'APP-PTB-Producer',
    ],
  },
  {
    label: 'Workflow',
    access: [
      'APP-PTB-ADMIN',
      'APP-PTB-ReTouching-Lead',
      'APP-PTB-ArtDirectors',
      'APP-PTB-ReTouching-QA',
      'APP-PTB-ReToucher',
      'APP-PTB-Producer',
    ],
  },
]
export const approvedStatus = ['APPROVE', 'COMPLETED', 'READY']
export const IMAGE_STATUS_LIST = ['REVIEW', 'APPROVE', 'READY', 'COMPLETED']
export const RoutineTCINHeadCells = [
  {
    id: 'tcin',
    numeric: true,
    disablePadding: false,
    label: 'TCIN',
    width: '75',
    pagesList: ['MyTasks', 'RoutineTcin'],
  },
  {
    id: 'sample_id',
    numeric: true,
    disablePadding: false,
    label: 'Sample ID',
    width: '90',
    pagesList: ['MyTasks', 'RoutineTcin'],
  },
  {
    id: 'dpci',
    numeric: true,
    disablePadding: false,
    label: 'DPCI',
    width: '90',
    pagesList: ['MyTasks', 'RoutineTcin'],
  },
  {
    id: 'project_name',
    numeric: false,
    disablePadding: false,
    label: 'Project',
    width: '100',
    pagesList: ['MyTasks', 'RoutineTcin'],
  },
  {
    id: 'phase_name',
    numeric: false,
    disablePadding: true,
    label: 'Phase',
    width: '100',
    pagesList: ['MyTasks', 'RoutineTcin'],
  },
  {
    id: 'status_name',
    numeric: false,
    disablePadding: true,
    label: 'Status',
    width: '100',
    pagesList: ['MyTasks', 'RoutineTcin'],
  },
  {
    id: 'shot_count',
    numeric: true,
    disablePadding: false,
    label: 'Shots(#)',
    width: '75',
    pagesList: [],
  },
  {
    id: 'percentage_completed',
    numeric: true,
    disablePadding: false,
    label: 'POC(%)',
    width: '75',
    pagesList: [],
  },
  {
    id: 'artist',
    numeric: false,
    disablePadding: false,
    label: 'Assigned To',
    width: '110',
    pagesList: ['RoutineTcin'],
  },
  {
    id: 'received_date',
    numeric: true,
    disablePadding: false,
    label: 'Received Date',
    width: '118',
    pagesList: ['MyTasks', 'RoutineTcin'],
  },
  {
    id: 'tcin_launch_date',
    numeric: true,
    disablePadding: false,
    label: 'Launch Date',
    width: '108',
    pagesList: ['MyTasks', 'RoutineTcin'],
  },
  {
    id: 'brand_name',
    numeric: true,
    disablePadding: false,
    label: 'Brand',
    width: '100',
    pagesList: ['MyTasks', 'RoutineTcin'],
  },
  {
    id: 'studio_notes',
    numeric: true,
    disablePadding: false,
    label: 'Studio Notes',
    width: '112',
    pagesList: ['MyTasks', 'RoutineTcin'],
  },
  {
    id: 'description',
    numeric: true,
    disablePadding: false,
    label: 'Description',
    width: '112',
    pagesList: ['MyTasks', 'RoutineTcin'],
  },
]

export const ReportsHeadCells = [
  {
    id: 'department_name',
    numeric: false,
    disablePadding: false,
    label: 'Department',
    width: '90',
    pagesList: ['Reports'],
  },
  {
    id: 'class_name',
    numeric: false,
    disablePadding: false,
    label: 'Class',
    width: '90',
    pagesList: ['Reports'],
  },
  {
    id: 'tcin',
    numeric: false,
    disablePadding: false,
    label: 'TCIN',
    width: '75',
    pagesList: ['Reports'],
  },
  {
    id: 'uploaded_count',
    numeric: true,
    disablePadding: true,
    label: 'Uploaded Count',
    width: '90',
    pagesList: ['Reports'],
  },
  {
    id: 'approved_count',
    numeric: true,
    disablePadding: true,
    label: 'Approved Count',
    width: '90',
    pagesList: ['Reports'],
  },
  {
    id: 'retouched_count',
    numeric: true,
    disablePadding: true,
    label: 'Retouched Count',
    width: '90',
    pagesList: ['Reports'],
  },
]

export const StyleGuidesHeadCells = [
  {
    id: 'file_name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    width: '120',
    pagesList: ['StyleGuides'],
  },
  {
    id: 'uploaded_date',
    numeric: false,
    disablePadding: false,
    label: 'Date Modified',
    width: '90',
    pagesList: ['StyleGuides'],
  },
]

export const DashboardHeadCells = [
  {
    id: 'tcin',
    numeric: false,
    disablePadding: false,
    label: 'TCIN',
    width: '60',
    pagesList: ['dashboard'],
  },
  {
    id: 'dpci',
    numeric: false,
    disablePadding: false,
    label: 'DPCI',
    width: '70',
    pagesList: ['dashboard'],
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    width: '180',
    pagesList: ['dashboard'],
  },
  {
    id: 'Main',
    numeric: false,
    disablePadding: false,
    label: 'Main',
    width: '50',
    pagesList: ['dashboard'],
  },
  {
    id: '1',
    numeric: false,
    disablePadding: false,
    label: '01',
    width: '50',
    pagesList: ['dashboard'],
  },
  {
    id: '2',
    numeric: false,
    disablePadding: false,
    label: '02',
    width: '50',
    pagesList: ['dashboard'],
  },
  {
    id: '3',
    numeric: false,
    disablePadding: false,
    label: '03',
    width: '50',
    pagesList: ['dashboard'],
  },
  {
    id: '4',
    numeric: false,
    disablePadding: false,
    label: '04',
    width: '50',
    pagesList: ['dashboard'],
  },
  {
    id: '5',
    numeric: false,
    disablePadding: false,
    label: '05',
    width: '50',
    pagesList: ['dashboard'],
  },
  {
    id: '6',
    numeric: false,
    disablePadding: false,
    label: '06',
    width: '50',
    pagesList: ['dashboard'],
  },
  {
    id: '7',
    numeric: false,
    disablePadding: false,
    label: '07',
    width: '50',
    pagesList: ['dashboard'],
  },
  {
    id: '8',
    numeric: false,
    disablePadding: false,
    label: '08',
    width: '50',
    pagesList: ['dashboard'],
  },
]

export const searchByOptions = {
  all: 'All',
  tcin: 'TCIN',
}
export const supportIssueTypeList = [
  { value: 'Issue', label: 'Issue' },
  { value: 'Feature Request', label: 'Feature Request' },
  { value: 'UI Request', label: 'UI Request' },
  { value: 'Other', label: 'Other' },
]
export const supportpriorityList = [
  { value: "Don't Know", label: "Don't Know" },
  { value: 'Low', label: 'Low' },
  { value: 'Medium', label: 'Medium' },
  { value: 'High', label: 'High' },
]
export const supportSuccessMessage =
  'Your request has been raised & successfully submitted, Our experts will reach back to you shortly!'
export const supportErrorMessage =
  'Error in creating a Jira Request. Please try again.'
export const TCIN_LIST_PAGE = 0
export const TCIN_LIST_ROWS_PER_PAGE = 10
export const ROWS_PER_PAGE_LIST = [1, 5, 10, 25, 50, 100]

export const FACETS_GRID_COL_SPAN = 2
const BRAND_DISPLAY_NAME = 'Brand'
const STATUS_DISPLAY_NAME = 'Status'
const PHASE_DISPLAY_NAME = 'Phase'
const PROJECT_DISPLAY_NAME = 'Project Name'
export const FACETS_DISPLAY_NAMES = {
  brand_name: BRAND_DISPLAY_NAME,
  status_name: STATUS_DISPLAY_NAME,
  phase_name: PHASE_DISPLAY_NAME,
  project_name: PROJECT_DISPLAY_NAME,
}
export const getFacetsCount = (facets = {}) => {
  return Object.keys(facets).filter((obj) => (facets[obj] || []).length).length
}
