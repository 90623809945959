import React from 'react'
import Support from '../containers/Support'
import Dashboard from '../containers/Dashboard'
import TcinInfo from '../containers/TcinInfo'
import UnAuthorized from '../containers/UnAuthorized'
import apiConfig from '../apiConfig'
import Ingestion from '../containers/Ingestion'
import Workflow from '../containers/Workflow'
import {
  photographerADGroup,
  generalUserADGroup,
  producerADGroup,
  retoucherADGroup,
  retouchingLeadADGroup,
  adminADGroup,
  retouchingQAADGroup,
  artDirectorADGroup,
} from '../constants/common'
import {
  Search as TcinInfoIcon,
  Assignment as ProjectsIcon,
  Brush as WorkflowIcon,
  DriveFileMove as IngestionIcon,
  FileCopy as FileReuseIcon,
  HelpCenter as SupportIcon,
} from '@mui/icons-material'
import AdvancedPreview from '../containers/AdvancedPreview'

const navRoutes = [
  {
    path: '/',
    name: 'Home',
    tooltip: 'Tcin Info Page',
    displayName: 'TCIN Image Info',
    icon: TcinInfoIcon,
    component: TcinInfo,
    adGroups: apiConfig.adGroups,
    exact: true,
    sideNav: true,
    ShouldInclude: true,
    allowedAdGroups: [
      producerADGroup,
      photographerADGroup,
      generalUserADGroup,
      retoucherADGroup,
      retouchingLeadADGroup,
      adminADGroup,
      retouchingQAADGroup,
      artDirectorADGroup,
    ],
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    tooltip: 'Dashboard',
    displayName: 'Projects',
    icon: ProjectsIcon,
    component: Dashboard,
    adGroups: apiConfig.adGroups,
    exact: false,
    sideNav: true,
    ShouldInclude: true,
    allowedAdGroups: [
      producerADGroup,
      photographerADGroup,
      generalUserADGroup,
      retoucherADGroup,
      retouchingLeadADGroup,
      adminADGroup,
      retouchingQAADGroup,
      artDirectorADGroup,
    ],
  },
  {
    path: '/workflow',
    name: 'Workflow',
    tooltip: 'Workflow',
    displayName: 'Workflow',
    icon: WorkflowIcon,
    component: !apiConfig.isPol ? Workflow : UnAuthorized,
    adGroups: apiConfig.adGroups.filter((g) => g !== generalUserADGroup),
    exact: true,
    sideNav: !apiConfig.isPol,
    ShouldInclude: !apiConfig.isPol,
    allowedAdGroups: [
      producerADGroup,
      photographerADGroup,
      retoucherADGroup,
      retouchingLeadADGroup,
      adminADGroup,
      retouchingQAADGroup,
      artDirectorADGroup,
    ],
  },
  {
    path: '/ingestion',
    name: 'Ingestion',
    tooltip: 'Ingestion',
    displayName: 'Image File Ingestion',
    icon: IngestionIcon,
    component: Ingestion,
    adGroups: apiConfig.adGroups.filter((g) => g !== artDirectorADGroup),
    exact: true,
    sideNav: true,
    ShouldInclude: true,
    allowedAdGroups: [
      producerADGroup,
      photographerADGroup,
      generalUserADGroup,
      retoucherADGroup,
      retouchingLeadADGroup,
      adminADGroup,
      retouchingQAADGroup,
    ],
  },
  {
    path: '/filereuse',
    name: 'FileReuse',
    tooltip: 'File Reuse',
    displayName: 'File Reuse',
    icon: FileReuseIcon,
    component: UnAuthorized,
    adGroups: [],
    exact: true,
    sideNav: false,
    ShouldInclude: false,
  },
  {
    path: '/advancedPreview',
    name: 'Advanced Preview',
    tooltip: 'Advanced Preview',
    displayName: 'Advanced Preview',
    icon: FileReuseIcon,
    component: AdvancedPreview,
    adGroups: apiConfig.adGroups.filter((g) => g !== generalUserADGroup),
    exact: true,
    sideNav: false,
    ShouldInclude: true,
  },
  {
    path: '/support',
    name: 'Support',
    tooltip: 'support',
    displayName: 'Help',
    adGroups: apiConfig.adGroups,
    icon: SupportIcon,
    component: Support,
    exact: true,
    sideNav: true,
    ShouldInclude: true,
    allowedAdGroups: [
      producerADGroup,
      photographerADGroup,
      generalUserADGroup,
      retoucherADGroup,
      retouchingLeadADGroup,
      adminADGroup,
      retouchingQAADGroup,
      artDirectorADGroup,
    ],
  },
  {
    path: '/unauthorized',
    name: 'UnAuthorized',
    tooltip: 'UnAuthorized',
    displayName: '',
    component: UnAuthorized,
    exact: true,
    sideNav: false,
    ShouldInclude: true,
  },
  { redirect: true, path: '/', to: '/home', navbarName: 'Redirect' },
]

export default navRoutes
