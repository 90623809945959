import React from 'react'
import { TableCell, TableRow } from '@mui/material'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  padding_left_right_null: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
  iconWithDesc: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function UploadFailedTable({ failedFiles }) {
  const classes = useStyles()

  return (
    <>
      {Object.keys(failedFiles).map((key, i) => (
        <TableRow key={failedFiles[key].name + i}>
          <TableCell
            className={classes.padding_left_right_null}
            align="left"
            padding="none"
            component="th"
            scope="row"
          >
            {failedFiles[key].name}
          </TableCell>
          <TableCell className={classes.padding_left_right_null} align="left">
            <span className={classes.iconWithDesc}>
              <ReportProblemOutlinedIcon
                style={{ color: '#BD6839', marginRight: '5px' }}
              />
              Error - Upload Failed
            </span>
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}
