import axios from 'axios'
import {
  GET_ALL_PROJECTS,
  SET_LOADING,
  IS_INCLUDE_ARCHIVED,
  IS_MY_PROJECTS,
  SET_PROJECT_DATA,
  SET_TAB_CONTENT,
  SET_PROJECT_SEARCH_TEXT,
} from './actionTypes'
import apiConfig, { appEnv } from '../../apiConfig'

const { projectsApi, isPol = false } = apiConfig

function getAllProjects(data) {
  return {
    type: GET_ALL_PROJECTS,
    payload: data,
  }
}

export const getAllProjectsAPI =
  (accessToken = '', lanId = '', company = '') =>
  async (dispatch, getState) => {
    dispatch(setLoading(true))

    const {
      dashboardReducer: {
        isIncludedArchived = false,
        isMyProjects = false,
      } = {},
      authReducer: { lanId: userId = '' } = {},
    } = getState()

    const url = `${projectsApi}`
    axios
      .get(url, {
        params: {
          key: apiConfig.apiKey,
          archived: isIncludedArchived,
          is_user_projects: isMyProjects,
          company: isPol
            ? company.indexOf(',') > -1
              ? company.substring(0, company.indexOf(','))
              : company
            : null,
        },
        headers: {
          Authorization: accessToken,
          ...(appEnv === 'dev' && { 'x-tgt-lanId': userId || lanId }),
        },
      })
      .then((response) => {
        let result = response.data.map((o, i) => {
          o[
            'user_name'
          ] = `${o.created_by?.first_name} ${o.created_by?.last_name}`
          return o
        })
        dispatch(getAllProjects(result))
        dispatch(setLoading(false))
      })
      .catch((error) => {
        //console.log(error)
      })
  }

export const setProjectsToArchive = (ids) => async (dispatch, getState) => {
  const url = `${projectsApi}/archive`
  const { authReducer: { accessToken = '' } = {} } = getState()
  const body = {
    project_ids: ids,
  }
  axios
    .post(url, body, {
      params: {
        key: apiConfig.apiKey,
      },
      headers: { Authorization: accessToken },
    })
    .then((response) => {
      dispatch(getAllProjectsAPI())
    })
    .catch((error) => {
      //console.log(error)
    })
}

export const setIsMyProjects = (isMyProj) => {
  return {
    type: IS_MY_PROJECTS,
    payload: isMyProj,
  }
}

export const setIsIncludeArchived = (isIncArch) => {
  return {
    type: IS_INCLUDE_ARCHIVED,
    payload: isIncArch,
  }
}

const setLoading = (loading) => {
  return {
    type: SET_LOADING,
    payload: loading,
  }
}

export const setProjectInfo = (data) => {
  return {
    type: SET_PROJECT_DATA,
    payload: data,
  }
}

export const setProjectSearchText = (query) => {
  return {
    type: SET_PROJECT_SEARCH_TEXT,
    payload: query,
  }
}

export const setTabContentInfo = (data) => {
  return {
    type: SET_TAB_CONTENT,
    payload: data,
  }
}
