import {
  GET_ALL_PROJECTS,
  SET_LOADING,
  IS_INCLUDE_ARCHIVED,
  IS_MY_PROJECTS,
  SET_PROJECT_DATA,
  SET_TAB_CONTENT,
  SET_PROJECT_SEARCH_TEXT,
} from './actionTypes'

const dashboardResultState = {
  projects: [],
  loading: false,
  isMyProjects: false,
  searchQuery: '',
  isIncludedArchived: false,
  projectDataReducer: {},
  projectTcinInfoReducer: [],
}
export default function dashboardReducer(state = dashboardResultState, action) {
  switch (action.type) {
    case SET_TAB_CONTENT:
      return { ...state, projectTcinInfoReducer: action.payload }
    case SET_PROJECT_DATA:
      return {
        ...state,
        projectDataReducer: action.payload,
      }
    case GET_ALL_PROJECTS:
      return {
        ...state,
        projects: [...action.payload],
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case SET_PROJECT_SEARCH_TEXT:
      return {
        ...state,
        searchQuery: action.payload,
      }
    case IS_MY_PROJECTS:
      return {
        ...state,
        isMyProjects: action.payload,
      }
    case IS_INCLUDE_ARCHIVED:
      return {
        ...state,
        isIncludedArchived: action.payload,
      }
    default:
      return state
  }
}
