export const GET_WORKFLOW_DATA_PENDING = 'GET_WORKFLOW_DATA_PENDING'
export const GET_WORKFLOW_DATA_FULFILLED = 'GET_WORKFLOW_DATA_FULFILLED'
export const GET_WORKFLOW_DATA_REJECTED = 'GET_WORKFLOW_DATA_REJECTED'
export const GET_CUSTOM_COLUMNS_PENDING = 'GET_CUSTOM_COLUMNS_PENDING'
export const GET_CUSTOM_COLUMNS_FULFILLED = 'GET_CUSTOM_COLUMNS_FULFILLED'
export const GET_CUSTOM_COLUMNS_REJECTED = 'GET_CUSTOM_COLUMNS_REJECTED'
export const SET_CUSTOM_COLUMNS_CHANGED = 'SET_CUSTOM_COLUMNS_CHANGED'
export const SET_CUSTOM_COLUMNS_PENDING = 'SET_CUSTOM_COLUMNS_PENDING'
export const SET_CUSTOM_COLUMNS_FULFILLED = 'SET_CUSTOM_COLUMNS_FULFILLED'
export const SET_CUSTOM_COLUMNS_REJECTED = 'SET_CUSTOM_COLUMNS_REJECTED'
export const CHANGE_IMAGE__WORKFLOW_PENDING = 'CHANGE_IMAGE__WORKFLOW_PENDING'
export const CHANGE_IMAGE__WORKFLOW_FULFILLED =
  'CHANGE_IMAGE__WORKFLOW_FULFILLED'
export const CHANGE_IMAGE__WORKFLOW_REJECTED = 'CHANGE_IMAGE__WORKFLOW_REJECTED'
export const ADD_QA_NOTES_PENDING = 'ADD_QA_NOTES_PENDING'
export const ADD_QA_NOTES_FULFILLED = 'ADD_QA_NOTES_FULFILLED'
export const ADD_QA_NOTES_REJECTED = 'ADD_QA_NOTES_REJECTED'
export const ADD_NOTES_PENDING = 'ADD_NOTES_PENDING'
export const ADD_NOTES_FULFILLED = 'ADD_NOTES_FULFILLED'
export const ADD_NOTES_REJECTED = 'ADD_NOTES_REJECTED'
export const ADD_TAGS_PENDING = 'ADD_TAGS_PENDING'
export const ADD_TAGS_FULFILLED = 'ADD_TAGS_FULFILLED'
export const ADD_TAGS_REJECTED = 'ADD_TAGS_REJECTED'
export const EDIT_TAGS_PENDING = 'EDIT_TAGS_PENDING'
export const EDIT_TAGS_FULFILLED = 'EDIT_TAGS_FULFILLED'
export const EDIT_TAGS_REJECTED = 'EDIT_TAGS_REJECTED'
export const DELETE_TAGS_PENDING = 'DELETE_TAGS_PENDING'
export const DELETE_TAGS_FULFILLED = 'DELETE_TAGS_FULFILLED'
export const DELETE_TAGS_REJECTED = 'DELETE_TAGS_REJECTED'
export const CHECK_WITH_SCRIPT_TAGS_PENDING = 'CHECK_WITH_SCRIPT_TAGS_PENDING'
export const CHECK_WITH_SCRIPT_TAGS_FULFILLED =
  'CHECK_WITH_SCRIPT_TAGS_FULFILLED'
export const CHECK_WITH_SCRIPT_TAGS_REJECTED = 'CHECK_WITH_SCRIPT_TAGS_REJECTED'
export const GET_TAGS_LIST_PENDING = 'GET_TAGS_LIST_PENDING'
export const GET_TAGS_LIST_FULFILLED = 'GET_TAGS_LIST_FULFILLED'
export const GET_TAGS_LIST_REJECTED = 'GET_TAGS_LIST_REJECTED'
export const APPLY_IMAGE_TAGS_PENDING = 'APPLY_IMAGE_TAGS_PENDING'
export const APPLY_IMAGE_TAGS_FULFILLED = 'APPLY_IMAGE_TAGS_FULFILLED'
export const APPLY_IMAGE_TAGS_REJECTED = 'APPLY_IMAGE_TAGS_REJECTED'
export const SEND_TO_PIPELINE_PENDING = 'SEND_TO_PIPELINE_PENDING'
export const SEND_TO_PIPELINE_FULFILLED = 'SEND_TO_PIPELINE_FULFILLED'
export const SEND_TO_PIPELINE_REJECTED = 'SEND_TO_PIPELINE_REJECTED'
export const SET_IMAGE_TO_ARCHIVE_PENDING = 'SET_IMAGE_TO_ARCHIVE_PENDING'
export const SET_IMAGE_TO_ARCHIVE_FULFILLED = 'SET_IMAGE_TO_ARCHIVE_FULFILLED'
export const SET_IMAGE_TO_ARCHIVE_REJECTED = 'SET_IMAGE_TO_ARCHIVE_REJECTED'
export const GET_WORKFLOW_ASIGNEE_LIST_PENDING =
  'GET_WORKFLOW_ASIGNEE_LIST_PENDING'
export const GET_WORKFLOW_ASIGNEE_LIST_FULFILLED =
  'GET_WORKFLOW_ASIGNEE_LIST_FULFILLED'
export const GET_WORKFLOW_ASIGNEE_LIST_REJECTED =
  'GET_WORKFLOW_ASIGNEE_LIST_REJECTED'
export const GET_WORKFLOW_QAASIGNEE_LIST_PENDING =
  'GET_WORKFLOW_QAASIGNEE_LIST_PENDING'
export const GET_WORKFLOW_QAASIGNEE_LIST_FULFILLED =
  'GET_WORKFLOW_QAASIGNEE_LIST_FULFILLED'
export const GET_WORKFLOW_QAASIGNEE_LIST_REJECTED =
  'GET_WORKFLOW_QAASIGNEE_LIST_REJECTED'
export const DOWNLOAD_IMAGES = 'DOWNLOAD_IMAGES'
export const UPDATE_WORKFLOW_TABLE_IMAGE_THUMBNAIL =
  'UPDATE_WORKFLOW_TABLE_IMAGE_THUMBNAIL'
export const SET_TOP_LEVEL_STUDIO_FILTER = 'SET_TOP_LEVEL_STUDIO_FILTER'
