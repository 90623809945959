import NewImageFilesTable from './NewImageFilesTable'
import UploadFailedTable from './UploadFailedTable'
import UploadProgressTable from './UploadProgressTable'
import UploadSuccessTable from './UploadSuccessTable'
import UploadManger from './UploadManager'
import DroppedFilesTableIngestion from './DroppedFilesTableIngestion'
import DroppedFilesTableWorkflow from './DroppedFilesTableWorkflow'

UploadManger.NewImageFilesTable = NewImageFilesTable
UploadManger.UploadFailedTable = UploadFailedTable
UploadManger.UploadProgressTable = UploadProgressTable
UploadManger.UploadSuccessTable = UploadSuccessTable
UploadManger.DroppedFilesTableIngestion = DroppedFilesTableIngestion
UploadManger.DroppedFilesTableWorkflow = DroppedFilesTableWorkflow

export default UploadManger
