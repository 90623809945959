import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import navRoutes from '../../routes'
import { Link, Avatar } from '@material-ui/core'
import { Link as ReactLink } from 'react-router-dom'
import appLogo from '../../images/PhotoBridge_Logo_white-on-red.svg'
import Box from '@material-ui/core/Box'
import Stack from '@mui/material/Stack'
import ListItemButton from '@mui/material/ListItemButton'
import ListSubheader from '@mui/material/ListSubheader'

const useStyles = makeStyles({
  drawerPaper: { marginLeft: 64 },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  sideNavLinks: {
    color: '#666 !important',
  },
  sideNavLinkDisabled: {
    textDecoration: 'none',
    backgroundColor: 'rgba(158,158,158,.2)',
  },
  sideNavAppbar: {
    display: 'flex',
    // backgroundColor: '#cc0000',
    alignItems: 'center',
    paddingLeft: 10,
  },
  ChevronRightIcon: {
    color: '#FFF',
  },
  logoAvatar: {
    width: '40px',
    maxHeight: '40px',
    padding: '0px 4px',
  },
  appTitle: {
    flexGrow: 1,
    padding: '0px',
    paddingLeft: 11,
  },
  logoSize: {
    height: '50%',
  },
  navLabels: {
    '&:hover': {
      color: '#6376e0',
    },
  },
})

export default function TemporaryDrawer({
  sideNav = false,
  toggleSideNav = () => {},
}) {
  const classes = useStyles()
  const currentAdGroup = useSelector(
    ({ authReducer = {} }) => authReducer.currentAdGroup
  )
  const isLinkDisable = (linkName) => {
    return linkName === 'MyTasks' && !currentAdGroup
  }

  const sideList = () => (
    <React.Fragment>
      <Stack direction="column" spacing={2} style={{ paddingTop: 12 }}>
        <ListItem>
          <ListItemText disableTypography primary="Photobridge" />
        </ListItem>
        {navRoutes
          .filter((r) => r.sideNav)
          .map((prop, key) => (
            <Link
              key={key}
              color="secondary"
              component={ReactLink}
              underline={'none'}
              to={prop.path}
              onClick={(e) => {
                if (isLinkDisable(prop.name)) {
                  e.preventDefault()
                  e.stopPropagation()
                }
                toggleSideNav()
              }}
              classes={{
                root: classes.sideNavLinks,
              }}
            >
              <ListItemButton>
                <ListItemText disableTypography primary={prop.displayName} />
              </ListItemButton>
            </Link>
          ))}
      </Stack>
    </React.Fragment>
  )

  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      open={sideNav}
      anchor="left"
      onClose={toggleSideNav}
    >
      {sideList()}
    </Drawer>
  )
}
