import {
  GET_COMMENTS_SUCCESS,
  GET_SUMMARY_SUCCESS,
  GET_TCIN_STYLEGUIDES_SUCCESS,
  GET_ALL_STYLEGUIDES_SUCCESS,
  ADD_STYLEGUIDE_SUCCESS,
  GET_TCIN_IMAGES_SUCCESS,
  GET_TCIN_HISTORY_FULFILLED,
  GET_TCIN_HISTORY_PENDING,
  GET_TCIN_HISTORY_REJECTED,
  PREVIEW_API_CALL_FULFILLED,
  PREVIEW_API_CALL_REJECTED,
  PREVIEW_API_CALL_PENDING,
  UPDATE_IMAGE_DETAILS_FULFILLED,
  UPDATE_IMAGE_DETAILS_PENDING,
  UPDATE_IMAGE_DETAILS_REJECTED,
} from './ActionType'

const initialState = {
  comments: [],
  summary: {
    tcin: '',
  },
  tcinStyleGuides: [],
  tcinImages: [],
  allStyleGuides: [],
  tcinHistory: [],
  previewLoadingList: {},
}
export default function TcinDetailReducer(state = initialState, action) {
  const { payload = {} } = action
  const { data = [], config: { data: configData = '' } = {} } = payload
  let newConfigData = configData ? JSON.parse(configData) : {}
  const { tcinImages = [] } = state
  const { data: currrentThumbnail = '' } = payload
  const { file_url: currentFileUrl = '' } = newConfigData
  let tcinImagesWithOut = []
  let tcinImagesWithObj = {}
  const thumbnailObj = { thumbnail_url: currrentThumbnail }
  let newobj = {}
  switch (action.type) {
    case GET_COMMENTS_SUCCESS:
      return { ...state, comments: [...action.data] }

    case GET_SUMMARY_SUCCESS:
      return { ...state, summary: { ...action.data } }
    case GET_TCIN_STYLEGUIDES_SUCCESS:
      return { ...state, tcinStyleGuides: [...action.data] }
    case GET_ALL_STYLEGUIDES_SUCCESS:
      return { ...state, allStyleGuides: [...action.data] }
    case ADD_STYLEGUIDE_SUCCESS:
      return {
        ...state,
        tcinStyleGuides: [...state.tcinStyleGuides, ...action.data],
      }
    case GET_TCIN_IMAGES_SUCCESS:
      return { ...state, tcinImages: [...action.data] }
    case GET_TCIN_HISTORY_FULFILLED:
      return {
        ...state,
        tcinHistory: [...data],
      }
    case GET_TCIN_HISTORY_REJECTED:
      return {
        ...state,
      }
    case GET_TCIN_HISTORY_PENDING:
      return {
        ...state,
      }
    case UPDATE_IMAGE_DETAILS_FULFILLED: {
      const {
        payload: { data: payloadData },
      } = action
      const newDataObj = (tcinImages || []).map((obj) => {
        const findObj = payloadData.find(
          (fobj) => fobj.shot_type_id === obj.shot_type_id
        )
        const finalObj =
          findObj && Object.entries(findObj).length > 0
            ? Object.assign({}, obj, { status: findObj.status_id || '' })
            : obj
        return finalObj
      })
      return {
        ...state,
        tcinImages: newDataObj,
      }
    }
    case UPDATE_IMAGE_DETAILS_PENDING:
      return {
        ...state,
      }
    case UPDATE_IMAGE_DETAILS_REJECTED:
      return {
        ...state,
      }
    case PREVIEW_API_CALL_FULFILLED:
      tcinImagesWithOut = tcinImages.filter(
        (obj) => obj.asset_url !== currentFileUrl
      )
      tcinImagesWithObj = tcinImages.find(
        (obj) => obj.asset_url === currentFileUrl
      )
      newobj = Object.assign({}, tcinImagesWithObj, thumbnailObj)
      return { ...state, tcinImages: [...tcinImagesWithOut, newobj] }
    case PREVIEW_API_CALL_PENDING:
      return {
        ...state,
      }
    case PREVIEW_API_CALL_REJECTED:
      return {
        ...state,
      }
    default:
      return state
  }
}
