import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Button, Typography } from '@material-ui/core'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from '@material-ui/core'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import cloneDeep from 'lodash/cloneDeep'
import mapValues from 'lodash/mapValues'
import { generateDefaultColumns } from './constants'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: 10,
  },
  formGroup: {
    marginBottom: 15,
    marginTop: 7,
  },
  labelRoot: {
    height: 30,
  },
  label: {
    fontSize: 14,
    color: 'black !important',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'end',
    gap: 16,
  },
}))

export default function CustomColumn(props) {
  const {
    userColumns,
    customColumns,
    onPopupClose,
    setCustomColumns,
    onDiscardChanges,
    viewType,
    setViewType,
    isApplyDisabled,
    updateUserView,
  } = props
  const classes = useStyles()

  const [localColumns, setLocalColumns] = React.useState({})
  const [localViewType, setLocalViewType] = React.useState(viewType)

  const disableDefaultColumnSelection = (column) => {
    const mappedColumn = { ...column }
    mappedColumn.disabled = true
    return mappedColumn
  }

  const defaultColumns = mapValues(
    generateDefaultColumns(),
    disableDefaultColumnSelection
  )

  const handleViewTypeChange = (event) => {
    setLocalViewType(event.target.value)
  }

  React.useEffect(() => {
    localViewType === 'Userbased'
      ? setLocalColumns(userColumns)
      : setLocalColumns(defaultColumns)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localViewType])

  const handleColumnSelection = (e, col) => {
    let newCol = cloneDeep(localColumns)
    newCol[col].selected = e.target.checked

    setLocalColumns(newCol)
  }

  const handleApply = () => {
    if (localViewType === 'Userbased') {
      updateUserView(localColumns)
    }
    setViewType(localViewType)
    onPopupClose(null)
  }

  const onCloseCustomization = () => {
    setLocalColumns(customColumns)
    onDiscardChanges()
  }

  return (
    <React.Fragment>
      <div className={classes.header}>
        <Typography variant="h6">Customize Columns</Typography>
        <IconButton
          onClick={onCloseCustomization}
          style={{
            paddingRight: 10,
            position: 'relative',
            alignSelf: 'flex-end',
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Grid container spacing={3}>
        <Grid item>
          <FormControl component="fieldset">
            <FormLabel component="legend">Item</FormLabel>
            <FormGroup classes={{ root: classes.formGroup }}>
              {Object.keys(localColumns).map((col, i) => {
                return (
                  localColumns[col].category === 'item' && (
                    <FormControlLabel
                      key={i}
                      classes={{
                        root: classes.labelRoot,
                        label: classes.label,
                        disabled: classes.labelDisabled,
                      }}
                      disabled={localColumns[col].disabled}
                      control={
                        <Checkbox
                          color="primary"
                          checked={localColumns[col].selected}
                          onChange={(e) => handleColumnSelection(e, col)}
                          name={col.groupId}
                        />
                      }
                      label={localColumns[col].name}
                    />
                  )
                )
              })}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Sample</FormLabel>
            <FormGroup classes={{ root: classes.formGroup }}>
              {Object.keys(localColumns).map((col, i) => {
                return (
                  localColumns[col].category === 'sample' && (
                    <FormControlLabel
                      key={i}
                      classes={{
                        root: classes.labelRoot,
                        label: classes.label,
                        disabled: classes.labelDisabled,
                      }}
                      disabled={localColumns[col].disabled}
                      control={
                        <Checkbox
                          color="primary"
                          checked={localColumns[col].selected}
                          onChange={(e) => handleColumnSelection(e, col)}
                          name={col.groupId}
                        />
                      }
                      label={localColumns[col].name}
                    />
                  )
                )
              })}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Photo</FormLabel>
            <FormGroup classes={{ root: classes.formGroup }}>
              <Typography
                variant="body2"
                style={{ color: 'rgba(0, 0, 0, 0.54)', marginTop: 2 }}
              >
                Photo info
              </Typography>
              {Object.keys(localColumns).map((col, i) => {
                return (
                  localColumns[col].category === 'photo' && (
                    <FormControlLabel
                      key={i}
                      classes={{
                        root: classes.labelRoot,
                        label: classes.label,
                        disabled: classes.labelDisabled,
                      }}
                      disabled={localColumns[col].disabled}
                      control={
                        <Checkbox
                          color="primary"
                          checked={localColumns[col].selected}
                          onChange={(e) => handleColumnSelection(e, col)}
                          name={col.groupId}
                        />
                      }
                      label={localColumns[col].name}
                    />
                  )
                )
              })}
              <Typography
                variant="body2"
                style={{ color: 'rgba(0, 0, 0, 0.54)', marginTop: 2 }}
              >
                Photo info for each Image
              </Typography>
              {Object.keys(localColumns).map((col, i) => {
                return (
                  localColumns[col].category === 'photo_each' && (
                    <FormControlLabel
                      key={i}
                      classes={{
                        root: classes.labelRoot,
                        label: classes.label,
                        disabled: classes.labelDisabled,
                      }}
                      disabled={localColumns[col].disabled}
                      control={
                        <Checkbox
                          color="primary"
                          checked={localColumns[col].selected}
                          onChange={(e) => handleColumnSelection(e, col)}
                          name={col.groupId}
                        />
                      }
                      label={localColumns[col].name}
                    />
                  )
                )
              })}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl component="fieldset">
            <FormLabel component="legend">&nbsp;</FormLabel>
            <FormGroup classes={{ root: classes.formGroup }}>
              <Typography
                variant="body2"
                style={{ color: 'rgba(0, 0, 0, 0.54)', marginTop: 2 }}
              >
                Options
              </Typography>
              {Object.keys(localColumns).map((col, i) => {
                return (
                  localColumns[col].category === undefined && (
                    <FormControlLabel
                      key={i}
                      classes={{
                        root: classes.labelRoot,
                        label: classes.label,
                        disabled: classes.labelDisabled,
                      }}
                      disabled={localColumns[col].disabled}
                      control={
                        <Checkbox
                          color="primary"
                          checked={localColumns[col].selected}
                          onChange={(e) => handleColumnSelection(e, col)}
                          name={col.groupId}
                        />
                      }
                      label={localColumns[col].name}
                    />
                  )
                )
              })}
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container className={classes.buttonGroup}>
        <Grid item>
          <FormControl component="fieldset">
            <RadioGroup
              row
              name="ViewType"
              value={localViewType}
              onChange={handleViewTypeChange}
            >
              <FormControlLabel
                value="Default"
                control={<Radio />}
                label="Default View"
                // onClick={onRestoreDefaultMap}
              />
              <FormControlLabel
                value="Userbased"
                control={<Radio />}
                label="My Custom View"
                // onClick={onSwitchToUserView}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleApply}
                size="small"
                // disabled={isSaveDisabled}
              >
                Apply
              </Button>
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
