import { Card, Typography } from '@mui/material'

export default function ImageCard(props) {
  const { title = '', imageSrc = '', zoomSrc = '' } = props
  return (
    <>
      <Typography
        style={{
          color: '#333',
          fontFamily: 'Roboto',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '500',
        }}
      >
        {title}
      </Typography>
      <Card
        style={{
          height: 'calc(100% - 24px)',
          width: '100%',
          border: '1px solid #000000',
        }}
        className={props.rootClass}
      >
        <img
          alt={title}
          onClick={() => {
            window.open(zoomSrc, '_blank')
          }}
          className={props.mediaClass}
          style={{
            height: '100%',
            cursor: 'zoom-in',
            width: '100%',
            objectFit: 'contain',
          }}
          src={imageSrc}
        />
      </Card>
    </>
  )
}
