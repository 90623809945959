import TreeNode from '../../containers/TcinInfo/TreeNode'
import {
  GET_TCIN_INFO_DATA,
  SORTED_TCIN_INFO_DATA,
  ENLARGE_IMAGE,
  SET_FILTER_DATA,
  GROUPING_TREE_DATA,
  SELECTED_TCIN_DATA,
  SELECTED_FILTER_TCINS,
  DATA_FILTERED,
  TREE_LIST_DATA,
  USER_FILTERS,
} from './actionTypes'

const initialState = {
  filters: {},
  filteredTCINS: [],
  dataFiltered: [],
  userFilters: {},
  selected: [],
  treeList: [],
  tree: {
    treeNode: new TreeNode('root'),
    treeMap: new Map(),
  },
  enlargeImage: {
    open: false,
    title: '',
    src: '',
    fileName: '',
  },
}

export default function tcinPhotoInfo(state = initialState, action) {
  switch (action.type) {
    case GET_TCIN_INFO_DATA:
      return {
        ...state,
        tcinPhotoData: action.payload,
      }

    case SORTED_TCIN_INFO_DATA:
      return {
        ...state,
        sortedTcinPhotoData: action.payload,
      }

    case ENLARGE_IMAGE:
      return {
        ...state,
        enlargeImage: { ...action.payload },
      }

    case SET_FILTER_DATA:
      return {
        ...state,
        filters: action.payload,
      }

    case GROUPING_TREE_DATA:
      return {
        ...state,
        tree: action.payload,
      }

    case TREE_LIST_DATA:
      return {
        ...state,
        treeList: action.payload,
      }

    case SELECTED_TCIN_DATA:
      return {
        ...state,
        selected: action.payload,
      }

    case SELECTED_FILTER_TCINS:
      return {
        ...state,
        filteredTCINS: action.payload,
      }

    case DATA_FILTERED:
      return {
        ...state,
        dataFiltered: action.payload,
      }
    case USER_FILTERS:
      return {
        ...state,
        userFilters: action.payload,
      }
    default:
      return state
  }
}
