import {
  Paper,
  Typography,
  IconButton,
  TablePagination,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { Settings as SettingsIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import CustomPaginationActions from '../../components/CustomTable/CustomPaginationActions'
import { withAuth } from '@praxis/component-auth'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    width: '100%',
  },
  actionMenu: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  topMenu: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginTop: 8,
  },
  paginationToolbar: {
    marginLeft: 'auto',
  },
  inputProps: {
    height: 40,
    padding: '0 8px',
  },
  inputWidth: {
    width: 423,
  },
  inputLabel: {
    transform: 'translate(14px, 13px) scale(1)',
  },
  popover: {
    width: 896,
    padding: '20px 30px',
  },
}))

const TableToolbar = (props) => {
  const {
    rows,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    setWorkflowState,
    totalCount,
    setCustomizeColumnsIsOpen,
    tableTab,
    rowsPerPage,
  } = props

  const classes = useStyles()
  const onPageChangeClick = (event, page = Number(0)) => {
    setWorkflowState(page, rowsPerPage)
    handleChangePage(event, page)
  }

  const onRowsPerPageChangeClick = (event) => {
    setWorkflowState(0, event.target.value)
    handleChangeRowsPerPage(event, event.target.value)
  }
  return (
    <Paper className={classes.root} elevation={0}>
      <div className={classes.topMenu}>
        <Typography variant="h6" style={{ fontWeight: 400 }}>
          Showing {rows.length} Images of {totalCount}
        </Typography>
        <TablePagination
          classes={{
            root: classes.paginationToolbar,
          }}
          component="div"
          rowsPerPageOptions={[
            25,
            50,
            100,
            200,
            500,
            { label: 'All', value: totalCount },
          ]}
          colSpan={3}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onPageChangeClick}
          onChangeRowsPerPage={onRowsPerPageChangeClick}
          ActionsComponent={CustomPaginationActions}
        />
        <IconButton
          variant="outlined"
          onClick={(e) => setCustomizeColumnsIsOpen(e.currentTarget)}
        >
          <SettingsIcon />
        </IconButton>
      </div>
    </Paper>
  )
}

const mapAuthToProps = (auth) => {
  return {
    accessToken: auth.session?.userInfo?.accessToken,
  }
}

export default withAuth(mapAuthToProps)(TableToolbar)
