import { Toolbar, Typography } from '@material-ui/core'
import React from 'react'
import { CustomTableContext } from './index'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: '1px solid #dddddd',
    minHeight: 'max-content',
  },
}))

export default function CustomTableToolbar(props) {
  const { tState, tMethods } = React.useContext(CustomTableContext)
  const classes = useStyles()

  const handleChangePage = (event, newPage) => {
    tMethods.pageHandler(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    tMethods.rowsPerPageHandler(parseInt(event.target.value, 10))
    tMethods.pageHandler(0)
  }

  const toolbarProps = {
    ...props,
    setColumns: tMethods.colMapHandler,
    setCustomizeColumns: tMethods.customizeColHandler,
    selected: tState.selected,
    rows: tState.data,
    setRows: tMethods.dataHandler,
    originalRows: tState.originalData,
    customizeColumns: tState.customizeCol,
    handleChangePage: handleChangePage,
    handleChangeRowsPerPage: handleChangeRowsPerPage,
    page: tState.page,
    rowsPerPage: tState.rowsPerPage,
    columns: tState.colMap,
  }

  return (
    <Toolbar className={classes.root}>
      {React.cloneElement(props.Toolbar, toolbarProps)}
      {/* <props.Toolbar
        {...props}
        setColumns={tMethods.colMapHandler}
        setCustomizeColumns={tMethods.customizeColHandler}
        selected={tState.selected}
        rows={tState.data}
        setRows={tMethods.dataHandler}
        originalRows={tState.originalData}
        customizeColumns={tState.customizeCol}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={tState.page}
        rowsPerPage={tState.rowsPerPage}
        columns={tState.colMap}
      /> */}
    </Toolbar>
  )
}
