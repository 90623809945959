import React from 'react'
import { TableCell, TableRow } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import LinearDeterminate from '../../components/ProgressBar'

const useStyles = makeStyles(() => ({
  padding_left_right_null: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
  iconWithDesc: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function UploadProgressTable({ uploadsInProgress }) {
  const classes = useStyles()
  return (
    <>
      {Object.keys(uploadsInProgress).map((key, i) => {
        return (
          <TableRow key={uploadsInProgress[key].name + i}>
            <TableCell
              className={classes.padding_left_right_null}
              align="left"
              padding="none"
              component="th"
              scope="row"
            >
              {uploadsInProgress[key].name}
            </TableCell>
            <TableCell className={classes.padding_left_right_null} align="left">
              <LinearDeterminate
                progress={
                  uploadsInProgress[key].completedChunks > 0
                    ? (uploadsInProgress[key].completedChunks /
                        (uploadsInProgress[key].totalNumberOfChunks + 1)) *
                      100
                    : 0
                }
              />
            </TableCell>
          </TableRow>
        )
      })}
    </>
  )
}
