import { get } from 'lodash'
import ItemData from './ItemData'
import PhotoData from './PhotoData'
import SampleData from './SampleData'
import {
  formatDate,
  toSafeData,
  prefixSampleId,
} from '../../containers/TcinInfo/mapper'
import defaultThumbnail from '../../images/defaultThumbnail.jpg'
import { keys } from '@material-ui/core/styles/createBreakpoints'
import mapValues from 'lodash/mapValues'
import cloneDeep from 'lodash/cloneDeep'

export const itemType = {
  'Variation Parent w/in Collection': 'VPC',
  'Variation Parent': 'VP',
  'Variation Child': 'VC',
  'Collection Parent': 'COP',
  'Collection Child': 'CC',
  'Stand Alone': 'SA',
  '-': '-',
}

export const tabLabels = [
  { label: 'photo', component: PhotoData },
  { label: 'item', component: ItemData },
  { label: 'sample', component: SampleData },
]
export const projectHeaders = [
  {
    id: 'project_id',
    label: 'Project ID',
    data: 'project_id',
  },
  {
    id: 'project_name',
    label: 'Project Name',
    data: 'project_name',
  },
  {
    id: 'created_by',
    label: 'Created By',
    data: 'user_name',
  },
  {
    id: 'created_date',
    label: 'Created Date',
    data: 'created_date',
    type: 'date',
  },
  {
    id: 'due_date',
    label: 'Due Date',
    data: 'due_date',
    type: 'date',
  },
  {
    id: 'no_of_tcins',
    label: '#TCINs',
    data: 'project_stats.tcin_count',
  },
  {
    id: 'estimated_images',
    label: 'Estimated #Images',
    data: 'project_stats.estimated_images',
  },
  {
    id: 'shoot_complete',
    label: 'Shoot Complete',
    data: 'project_stats.shoot_complete',
  },
  {
    id: 'im_imaging',
    label: 'In Imaging',
    data: 'project_stats.imaging',
  },
  {
    id: 'sent_to_pipeline',
    label: 'Sent To Pipeline',
    data: 'project_stats.pipeline',
  },
]

export const tcinHeaders = (alt_count) => {
  let colHead = [
    {
      id: 'tcin',
      headerName: 'TCIN',
      field: 'tcin',
      pinned: 1,
    },
    {
      id: 'added_date',
      headerName: 'Added Date',
      field: 'added_date',
      renderCell: (row) => {
        return `${formatDate(toSafeData(row, 'added_date', '-'))}`
      },
      dataType: 'date',
      pinned: 1,
    },
    {
      id: 'relationship_type',
      headerName: 'Relationship type',
      renderCell: (row) => {
        return `${itemType[toSafeData(row, 'item_info.relationship', '-')]}`
      },
      field: 'item_info.relationship',
    },
    {
      id: 'dpci',
      headerName: 'DPCI',
      field: 'dpci',
    },
    {
      id: 'item_description',
      headerName: 'Description',
      field: 'item_info.product_description',
    },
    {
      id: 'launch_date_time',
      headerName: 'Launch Date',
      renderCell: (row) => {
        return `${formatDate(
          toSafeData(row, 'item_info.launch_date_time', '-')
        )}`
      },
      dataType: 'date',
      field: 'item_info.launch_date_time',
    },
    {
      id: 'parent_tcins',
      headerName: 'Parent TCIN',
      field: 'item_info.parent_tcins',
    },
    {
      id: 'vendor_id',
      headerName: 'Vendor ID',
      field: 'item_info.vendor_id',
    },
    {
      id: 'manufacturer_style',
      headerName: 'Manufacturer Style',
      field: 'item_info.manufacturer_style',
    },
    {
      id: 'brands',
      headerName: 'Brands',
      field: 'item_info.brands',
    },
    {
      id: 'class_data',
      headerName: 'Class ID/Name',
      renderCell: (row) => {
        return `${toSafeData(row, 'item_info.class_id', ' ')} - 
        ${toSafeData(row, 'item_info.class_name', ' ')}`
      },
      field: 'item_info.class_name',
    },
    {
      id: 'department_data',
      headerName: 'Department ID/Name',
      renderCell: (row) => {
        return `${toSafeData(row, 'item_info.department_id', ' ')} - 
        ${toSafeData(row, 'item_info.department_name', ' ')}`
      },
      field: 'item_info.department_id',
    },
    {
      id: 'barcode',
      headerName: 'Barcode',
      field: 'item_info.barcode_number',
    },
    {
      id: 'size',
      headerName: 'Size',
      field: 'item_info.size',
    },
    {
      id: 'color',
      headerName: 'Color',
      field: 'item_info.color',
    },
    {
      id: 'item_state',
      headerName: 'Lifecycle State',
      field: 'item_info.item_state',
    },

    {
      id: 'sample_ids',
      headerName: 'Sample ID',
      renderCell: (row) => {
        return `${prefixSampleId(
          toSafeData(row, 'sample_info[0].pegasus_id', '-')
        )}`
      },
      field: 'sample_info[0].pegasus_id',
    },
    {
      id: 'sample_status',
      headerName: 'Sample Status',
      field: 'sample_info[0].sample_status',
    },
    {
      id: 'sample_ship_location',
      headerName: 'Sample Ship To Location',
      field: 'sample_info[0].ship_to_loc',
    },
    {
      id: 'sample_received_date',
      headerName: 'Sample Received Date',
      renderCell: (row) => {
        return `${formatDate(
          toSafeData(row, 'sample_info[0].received_date', '-')
        )}`
      },
      field: 'sample_info[0].received_date',
    },
    {
      id: 'sample_current_location',
      headerName: 'Sample Current Location',
      field: 'sample_info[0].location',
    },
    {
      id: 'sample_projects',
      headerName: 'Sample Projects',
      field: 'sample_info[0].projects',
    },
    {
      id: 'scene7_main_img',
      headerName: 'Final Main',
      isImage: true,
      field: ['item_info.images.main'],
      csvDownload: false,
      sort_disabled: true,
    },
    {
      id: 'scene7_alt_img',
      headerName: 'Final Alt',
      isImage: true,
      sort_disabled: true,
      renderCell: (row) => {
        var list = toSafeData(row, 'item_info.images.alt', [])
        return list
        // return (
        //   <div style={{ display: 'inline-flex' }}>
        //     {list.map((url, i) => (
        //       <img
        //         key={i}
        //         style={{ height: 72, cursor: 'pointer' }}
        //         src={url}
        //         alt="-"
        //         onError={(e) => {
        //           e.target.src = defaultThumbnail
        //         }}
        //       />
        //     ))}
        //   </div>
        // )
      },
      csvDownload: false,
    },
    {
      id: 'source_image',
      headerName: 'Source - Main Image',
      isImage: true,
      field: [
        'image_information.main.metadata.preview_image_url',
        'image_information.main.metadata.zoom_image_url',
        // 'image_information.main.metadata.image_url',
      ],
      metaDataInfo: [
        `image_information.main.metadata.multitcin`,
        `image_information.main.metadata.original_image`,
        `image_information.main.metadata.subject_codes`,
        `image_information.main.file_name`,
      ],
      group_id: 'source_image',
      csvDownload: false,
      sort_disabled: true,
    },
    {
      id: 'file_name',
      headerName: 'File Name',
      field: 'image_information.main.file_name',
      group_id: 'file_name',
    },
    {
      id: 'photo_studio',
      headerName: 'Photo Studio',
      field: 'image_information.main.metadata.photo_studio',
      group_id: 'photo_studio',
    },
    {
      id: 'photo_shot',
      headerName: 'Photo Shot Date',
      field: 'image_information.main.metadata.image_created_dt',
      renderCell: (row) => {
        return `${formatDate(
          toSafeData(
            row,
            'image_information.main.metadata.image_created_dt',
            '-'
          )
        )}`
      },
      group_id: 'photo_shot',
    },
    {
      id: 'photo_tbay',
      headerName: 'Tbay',
      field: 'image_information.main.metadata.tbay',
      group_id: 'photo_tbay',
    },
    {
      id: 'photo_source',
      headerName: 'Sent to Photobridge',
      renderCell: (row) => {
        return `${formatDate(
          toSafeData(row, 'image_information.main.metadata.date_ingested')
        )}`
      },
      group_id: 'photo_source',
      field: 'image_information.main.metadata.date_ingested',
    },
    {
      id: 'photographer',
      headerName: 'Photographer',
      // field: 'image_information.main.metadata.photographer',
      renderCell: (row) => {
        return `${toSafeData(
          row,
          'image_information.main.metadata.photographer',
          '-'
        )}`
      },
      group_id: 'photographer',
      field: 'image_information.main.metadata.photographer',
    },
    {
      id: 'photo_retouch_status',
      headerName: 'Image Status',
      field: 'image_information.main.retouch.retouch_status',
      group_id: 'photo_retouch_status',
    },
    {
      id: 'photo_pipeline',
      headerName: 'Sent To Pipeline',
      renderCell: (row) => {
        return `${formatDate(
          toSafeData(row, 'image_information.main.retouch.sent_to_pipeline')
        )}`
      },
      group_id: 'photo_pipeline',
      field: 'image_information.main.retouch.sent_to_pipeline',
    },
  ]

  let alt_image_col = (index) => [
    {
      id: 'alt_image_' + index,
      headerName: 'Source Alt Image ' + (index + 1),
      isImage: true,
      field: [
        `image_information.alt[${index}].metadata.preview_image_url`,
        `image_information.alt[${index}].metadata.zoom_image_url`,
        // `image_information.alt[${index}].metadata.image_url`,
      ],
      metaDataInfo: [
        `image_information.alt[${index}].metadata.multitcin`,
        `image_information.alt[${index}].metadata.original_image`,
        `image_information.alt[${index}].metadata.subject_codes`,
        `image_information.alt[${index}].file_name`,
      ],
      sort_disabled: true,
      is_nested_field: index,
      group_id: 'source_image',
      csvDownload: false,
      showNestedRow: (cust) => {
        return cust.include_alt_image.selected && cust.source_image.selected
      },
      showColumn: (cust) => {
        return (
          !cust.nested_layout.selected &&
          cust.include_alt_image.selected &&
          cust.source_image.selected
        )
      },
    },
    {
      id: 'file_name_' + index,
      headerName: 'File Name ' + (index + 1),
      field: `image_information.alt[${index}].file_name`,
      is_nested_field: index,
      group_id: 'file_name',
      showNestedRow: (cust) => {
        return cust.include_alt_image.selected && cust.file_name.selected
      },
      showColumn: (cust) => {
        return (
          !cust.nested_layout.selected &&
          cust.include_alt_image.selected &&
          cust.file_name.selected
        )
      },
      sort_disabled: true,
    },
    {
      id: 'photo_studio_' + index,
      headerName: 'Photo Studio ' + (index + 1),
      field: `image_information.alt[${index}].metadata.photo_studio`,
      is_nested_field: index,
      group_id: 'photo_studio',
      showNestedRow: (cust) => {
        return cust.include_alt_image.selected && cust.photo_studio.selected
      },
      showColumn: (cust) => {
        return (
          !cust.nested_layout.selected &&
          cust.include_alt_image.selected &&
          cust.photo_studio.selected
        )
      },
    },
    {
      id: 'photo_shot_' + index,
      headerName: 'Photo Shot ' + (index + 1),
      field: `image_information.alt[${index}].metadata.image_created_dt`,
      renderCell: (row) => {
        return `${formatDate(
          toSafeData(
            row,
            `image_information.alt[${index}].metadata.image_created_dt`
          )
        )}`
      },
      is_nested_field: index,
      group_id: 'photo_shot',
      showNestedRow: (cust) => {
        return cust.include_alt_image.selected && cust.photo_shot.selected
      },
      showColumn: (cust) => {
        return (
          !cust.nested_layout.selected &&
          cust.include_alt_image.selected &&
          cust.photo_shot.selected
        )
      },
    },
    {
      id: 'photo_tbay_' + index,
      headerName: 'Photo TBAY ' + (index + 1),
      field: `image_information.alt[${index}].metadata.tbay`,
      is_nested_field: index,
      group_id: 'photo_tbay',
      showNestedRow: (cust) => {
        return cust.include_alt_image.selected && cust.photo_tbay.selected
      },
      showColumn: (cust) => {
        return (
          !cust.nested_layout.selected &&
          cust.include_alt_image.selected &&
          cust.photo_tbay.selected
        )
      },
    },
    {
      id: 'photo_source_' + index,
      headerName: 'Sent to Photobridge ' + (index + 1),
      field: `image_information.alt[${index}].metadata.date_ingested`,
      renderCell: (row) => {
        return `${formatDate(
          toSafeData(
            row,
            `image_information.alt[${index}].metadata.date_ingested`
          )
        )}`
      },
      is_nested_field: index,
      group_id: 'photo_source',
      showNestedRow: (cust) => {
        return cust.include_alt_image.selected && cust.photo_source.selected
      },
      showColumn: (cust) => {
        return (
          !cust.nested_layout.selected &&
          cust.include_alt_image.selected &&
          cust.photo_source.selected
        )
      },
    },
    {
      id: 'photographer_' + index,
      headerName: 'Photographer ' + (index + 1),
      field: `image_information.alt[${index}].metadata.photographer`,
      is_nested_field: index,
      group_id: 'photographer',
      showNestedRow: (cust) => {
        return cust.include_alt_image.selected && cust.photographer.selected
      },
      showColumn: (cust) => {
        return (
          !cust.nested_layout.selected &&
          cust.include_alt_image.selected &&
          cust.photographer.selected
        )
      },
    },
    {
      id: 'photo_retouch_status_' + index,
      headerName: 'Image Status ' + (index + 1),
      field: `image_information.alt[${index}].retouch.retouch_status`,
      is_nested_field: index,
      group_id: 'photo_retouch_status',
      showNestedRow: (cust) => {
        return (
          cust.include_alt_image.selected && cust.photo_retouch_status.selected
        )
      },
      showColumn: (cust) => {
        return (
          !cust.nested_layout.selected &&
          cust.include_alt_image.selected &&
          cust.photo_retouch_status.selected
        )
      },
    },
    {
      id: 'photo_pipeline_' + index,
      headerName: 'Sent To Pipeline ' + (index + 1),
      field: `image_information.alt[${index}].retouch.sent_to_pipeline`,
      renderCell: (row) => {
        return `${formatDate(
          toSafeData(
            row,
            `image_information.alt[${index}].retouch.sent_to_pipeline`
          )
        )}`
      },
      is_nested_field: index,
      group_id: 'photo_pipeline',
      showNestedRow: (cust) => {
        return cust.include_alt_image.selected && cust.photo_pipeline.selected
      },
      showColumn: (cust) => {
        return (
          !cust.nested_layout.selected &&
          cust.include_alt_image.selected &&
          cust.photo_pipeline.selected
        )
      },
    },
  ]

  let res = [...colHead]

  for (var i = 0; i < alt_count; i++) {
    res = [...res, ...alt_image_col(i)]
  }

  return res
}

export const customizeColumns = {
  tcin: {
    name: 'TCIN',
    selected: true,
    category: 'item',
    disabled: true,
    default: true,
  },
  added_date: {
    name: 'Added Date',
    category: 'excluded',
    selected: true,
    disabled: true,
    default: true,
  },
  relationship_type: {
    name: 'Relationship Type',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  dpci: {
    name: 'DPCI',
    selected: false,
    category: 'item',
    disabled: false,
    default: true,
  },
  item_description: {
    name: 'Description',
    selected: false,
    category: 'item',
    disabled: false,
    default: true,
  },
  launch_date_time: {
    name: 'Launch Date',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  parent_tcins: {
    name: 'Parent TCIN',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  barcode: {
    name: 'Barcode',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  vendor_id: {
    name: 'Vendor ID',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  manufacturer_style: {
    name: 'Manufacturer Style',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  size: {
    name: 'Size',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  color: {
    name: 'Color',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  item_state: {
    name: 'Lifecycle State',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  brands: {
    name: 'Brands',
    selected: false,
    category: 'item',
    disabled: false,
    default: true,
  },
  include_alt_image: {
    name: 'Include Alt Images',
    selected: false,
    disabled: false,
    default: true,
  },
  class_data: {
    name: 'Class',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  department_data: {
    name: 'Department',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  sample_ids: {
    name: 'Sample ID',
    selected: false,
    category: 'sample',
    disabled: false,
    default: false,
  },
  sample_status: {
    name: 'Sample Status',
    selected: false,
    category: 'sample',
    disabled: false,
    default: false,
  },
  sample_ship_location: {
    name: 'Sample Ship To Location',
    selected: false,
    category: 'sample',
    disabled: false,
    default: false,
  },
  sample_received_date: {
    name: 'Sample Received Date',
    selected: false,
    category: 'sample',
    disabled: false,
    default: false,
  },
  sample_current_location: {
    name: 'Sample Current Location',
    selected: false,
    category: 'sample',
    disabled: false,
    default: false,
  },
  sample_projects: {
    name: 'Sample Project(s)',
    selected: false,
    category: 'sample',
    disabled: false,
    default: false,
  },
  source_image: {
    name: 'Source Image',
    selected: false,
    category: 'photo_each',
    disabled: false,
    default: true,
  },
  scene7_main_img: {
    selected: false,
    name: 'Final Main',
    category: 'photo',
    disabled: false,
    default: false,
  },
  scene7_alt_img: {
    selected: false,
    name: 'Final Alt',
    category: 'photo',
    disabled: false,
    default: false,
  },
  file_name: {
    name: 'File Name',
    selected: false,
    category: 'photo_each',
    disabled: false,
    default: false,
  },
  photo_studio: {
    name: 'Photo Studio',
    selected: false,
    category: 'photo_each',
    disabled: false,
    default: false,
  },
  photo_shot: {
    name: 'Photo Shot Date',
    selected: false,
    category: 'photo_each',
    disabled: false,
    default: true,
  },
  photo_tbay: {
    name: 'Tbay',
    selected: false,
    category: 'photo_each',
    disabled: false,
    default: false,
  },
  photo_source: {
    name: 'Sent to Photobridge',
    selected: false,
    category: 'photo_each',
    disabled: false,
    default: false,
  },
  photographer: {
    name: 'Photographer',
    selected: false,
    category: 'photo_each',
    disabled: false,
    default: true,
  },
  photo_retouch_status: {
    name: 'Image Status',
    selected: false,
    category: 'photo_each',
    disabled: false,
    default: false,
  },
  photo_pipeline: {
    name: 'Sent To Pipeline',
    selected: false,
    category: 'photo_each',
    disabled: false,
    default: false,
  },
  nested_layout: {
    name: 'Nested Layout',
    selected: false,
    disabled: false,
    default: false,
  },
}

export const generateDefaultColumns = () => {
  return mapValues(customizeColumns, selectDefaultColumns)
}

const selectDefaultColumns = (column) => {
  const clonedColumn = cloneDeep(column)
  if (clonedColumn.default) {
    clonedColumn.selected = true
  }
  return clonedColumn
}
