export const GET_TCIN_INFO_DATA = 'GET_TCIN_INFO_DATA'
export const SORTED_TCIN_INFO_DATA = 'SORTED_TCIN_INFO_DATA'
export const ENLARGE_IMAGE = 'ENLARGE_IMAGE'
export const SET_FILTER_DATA = 'SET_FILTER_DATA'
export const GROUPING_TREE_DATA = 'GROUPING_TREE_DATA'
export const TREE_LIST_DATA = 'TREE_LIST_DATA'
export const SELECTED_TCIN_DATA = 'SELECTED_TCIN_DATA'
export const SELECTED_FILTER_TCINS = 'SELECTED_FILTER_TCINS'
export const DATA_FILTERED = 'DATA_FILTERED'
export const USER_FILTERS = 'USER_FILTERS'
