/*

👋 “Hey” from the Praxis team!

'apiConfig' is one pattern that can be used to set environment-based values at build time.
This happens by creating an environment variable REACT_APP_ENV, and then matching corresponding
keys to each environment in the 'envConfigs' object below. To learn more about how to set
REACT_APP_ENV, view the README generated with this app.


For an alternative pattern - using TAP configuration to set environment-based values at cluster initialization - see:
@praxis/component-runtime-env - https://praxis.prod.target.com/components/runtime-env

*/

import merge from 'lodash/merge'
import {
  photographerADGroup,
  generalUserADGroup,
  producerADGroup,
  retoucherADGroup,
  adminADGroup,
  retouchingLeadADGroup,
  retouchingQAADGroup,
  artDirectorADGroup,
} from '../src/constants/common'

export const appEnv = process.env.REACT_APP_ENV
// configurations shared between most or all environments can be stored in commonConfig
const commonConfig = {
  auth: {
    authorizationUrl:
      'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
    logoutUrl:
      'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
    logoutRedirect: `${window.location.origin}/`,
    loginRedirect: `${window.location.origin}/`,
    storageType: 'localStorage',
    scope: ['openid profile'],
    responseType: 'token id_token',
    tokenType: 'Bearer',
    extraUserInfoFields: ['company'],
  },
}

// Firefly environment based urls
const fireFlyEnvUrls = {
  dev: 'https://stgapi-internal.target.com/internal_app_analytics/v3',
  stage: 'https://stgapi-internal.target.com/internal_app_analytics/v3',
  prod: 'https://api-internal.target.com/internal_app_analytics/v3',
}

// Firefly Configurations
const fireFlyConfig = {
  dev: {
    url: fireFlyEnvUrls[appEnv],
    apiKey: 'e8791ab338a5569cb12e1de7511ca44202380cd2',
    eventManagerKey: 'ptbeventmanagerdev',
    persistMethod: 'localStorage',
    retries: '1',
  },
  stage: {
    url: fireFlyEnvUrls[appEnv],
    apiKey: 'e8791ab338a5569cb12e1de7511ca44202380cd2',
    eventManagerKey: 'ptbeventmanagerstage',
    persistMethod: 'localStorage',
    retries: '1',
  },
  prod: {
    url: fireFlyEnvUrls[appEnv],
    apiKey: 'e8791ab338a5569cb12e1de7511ca44202380cd2',
    eventManagerKey: 'ptbeventmanagerprod',
    persistMethod: 'localStorage',
    retries: '1',
  },
}

const baseURLQA = 'https://photobridgeapi-dev.dev.target.com/v1/'
const searchBaseURLQA = 'https://photobridgesearchapi-dev.dev.target.com/v1/'
const messageApiURLQA =
  'https://photobridgemessageapi-dev.dev.target.com/photobridge_operations/v1/'

const baseURLSTG = 'https://photobridgeapi-stg.dev.target.com/v1/'
const searchBaseURLSTG = 'https://photobridgesearchapi-stg.dev.target.com/v1/'
const messageApiURLSTG =
  'https://stgapi-internal.target.com/photobridge_operations/v1/'
const messageApiURLSTGExternal =
  'https://stage-api.target.com/photobridge_operations/v1/'

const baseURLPROD = 'https://photobridgeapi.prod.target.com/v1/'
const searchBaseURLPROD = 'https://photobridgesearchapi.prod.target.com/v1/'
const messageApiURLPROD =
  'https://api-internal.target.com/photobridge_operations/v1/'
const messageApiURLPRODExternal =
  'https://api.target.com/photobridge_operations/v1/'

const STG_API_KEY = '5c054267bb14cd59fc355be5bb172858a96c4845'
const PRD_API_KEY = '62a3c3e28b616f34a191c89faa8add009ba4c593'

// configurations based on environments can be stored in envConfigs
const envConfigs = {
  // dev is the default development environment set by .env.development and the default pipeline in .drone.yml
  dev: {
    isPol: false,
    auth: {
      clientId: 'photobridge_npe_im',
    },
    uploadKey: '',
    routinueTCINListApi: searchBaseURLQA + 'search_tcins',
    myTasksListApi: searchBaseURLQA + 'search_mytcins',
    downloadImageAPI: baseURLQA + 'photobridge_downloads',
    updateTCINSearchApi: baseURLQA + 'update_tcin_status',
    updateTCINApi: searchBaseURLQA + 'update_tcin_batch',
    updateImageDataApi: baseURLQA + 'update_file_status',
    usersAdGroupBaseUrl: baseURLQA + 'users',
    getCommentsApi: (taskUUID) => baseURLQA + `tcin_comments/${taskUUID}`,
    postComment: baseURLQA + `tcin_comments`,
    getTcinSummaryApi: (tcin, taskUUID) =>
      baseURLQA + `tcin_info/${tcin}/${taskUUID}`,
    getAllStyleGuideApi: baseURLQA + `style_guides`,
    getTcinStyleGuideApi: (tcin, taskUUID) =>
      baseURLQA + `style_guides/${tcin}/${taskUUID}`,
    updateTcinStyleGuideApi: baseURLQA + `update_tcin_styleguide`,
    getTcinImages: (tcin, taskUUID) =>
      baseURLQA + `tcin_photos/${tcin}/${taskUUID}`,
    getHistoryAPI: (tcin, taskUUID) =>
      baseURLQA + `tcin_history/${tcin}/${taskUUID}`,
    uploads: {
      host: 'https://photobridgetossdemo.dev.target.com',
      uploadsUrl: baseURLQA + `photobridge_uploads/`,
      previewAPI: baseURLQA + `photobridge_previews/`,
      getDeleteImageAPI: (urlParams = '') =>
        baseURLQA + `photobridge_toss/${urlParams}`,
      newUploadsUrl: messageApiURLQA + 'images/new_image_chunk_uploads',
      existingUploadsUrl:
        messageApiURLQA + 'images/existing_image_chunk_uploads',
      uploadStatusUrl: messageApiURLQA + 'images/upload_statuses',
      validatingUrl: messageApiURLQA + 'images/file_validations',
      ingestionUploadsUrl:
        messageApiURLQA + 'images/ingestion/new_image_chunk_uploads',
    },
    reportsApi: baseURLQA + 'report',
    createJiraRequestApi: baseURLQA + 'report_issue',
    attachJiraRequestApi: baseURLQA + 'attachment',
    editProjectApi: baseURLQA + 'tcin/validate_tcins',
    deleteProjectsApi: baseURLQA + 'projects/delete_tcins',
    projectpdfreport: baseURLQA + 'pdfreport',
    projectkey: 'TPB',
    fireFlyConfig: fireFlyConfig[appEnv],
    getTcinInfo: messageApiURLQA + 'tcins/tcin_info',
    getUserCustomColumns: messageApiURLQA + 'columns',
    generateShotSheets: messageApiURLQA + 'sheets/shot_sheets',
    generateContactSheets: messageApiURLQA + 'sheets/contact_sheets',
    editMultiTcinInfo: messageApiURLQA + 'tcins/multi_tcin',
    generateSupportContactSheets:
      messageApiURLQA + 'sheets/retouched_contact_sheets',
    generateContactSheetsFinal: messageApiURLQA + 'sheets/contact_sheets_final',
    generateContactSheetsStores:
      messageApiURLQA + 'sheets/contact_sheets_stores',
    generateCompactShotSheets: messageApiURLQA + 'sheets/compact_shot_sheets',
    projectsApi: messageApiURLQA + 'projects',
    projectUsersApi: baseURLQA + 'users',
    getIngestedImagesApi: messageApiURLQA + 'images/image_details',
    getWorkflowItemsApi: messageApiURLQA + 'workflows/image_details',
    getWorkflowOperationsApi: messageApiURLQA + 'workflows',
    archiveImageApi: messageApiURLQA + 'images/image_archives',
    addTagsApi: messageApiURLQA + 'tags',
    getDownloadImageApi: messageApiURLQA + 'images/download_image',
    getSupportImagesApi: messageApiURLQA + 'workflows/support_images',
    getImageVersionsApi: messageApiURLQA + 'workflows/image_versions',
    adGroups: [
      generalUserADGroup,
      producerADGroup,
      photographerADGroup,
      retoucherADGroup,
      adminADGroup,
      retouchingLeadADGroup,
      retouchingQAADGroup,
      artDirectorADGroup,
    ],
    userGuide:
      'https://jira.target.com/secure/attachment/460528/Photobridge%20Training%20Guide.docx',
    supportEmailGroup: 'TargetTech.Photobridge@target.com',
  },
  pol_dev: {
    isPol: true,
    auth: {
      clientId: 'photobridge_npe_im',
      authorizationUrl:
        'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl:
        'https://logonservices.oauth.iam.perf.partnersonline.com/login/responses/logoff.html',
    },
    uploadKey: '',
    routinueTCINListApi: searchBaseURLQA + 'search_tcins',
    myTasksListApi: searchBaseURLQA + 'search_mytcins',
    downloadImageAPI: baseURLQA + 'photobridge_downloads',
    updateTCINSearchApi: baseURLQA + 'update_tcin_status',
    updateTCINApi: searchBaseURLQA + 'update_tcin_batch',
    updateImageDataApi: baseURLQA + 'update_file_status',
    usersAdGroupBaseUrl: baseURLQA + 'users',
    getCommentsApi: (taskUUID) => baseURLQA + `tcin_comments/${taskUUID}`,
    postComment: baseURLQA + `tcin_comments`,
    getTcinSummaryApi: (tcin, taskUUID) =>
      baseURLQA + `tcin_info/${tcin}/${taskUUID}`,
    getAllStyleGuideApi: baseURLQA + `style_guides`,
    getTcinStyleGuideApi: (tcin, taskUUID) =>
      baseURLQA + `style_guides/${tcin}/${taskUUID}`,
    updateTcinStyleGuideApi: baseURLQA + `update_tcin_styleguide`,
    getTcinImages: (tcin, taskUUID) =>
      baseURLQA + `tcin_photos/${tcin}/${taskUUID}`,
    getHistoryAPI: (tcin, taskUUID) =>
      baseURLQA + `tcin_history/${tcin}/${taskUUID}`,
    uploads: {
      host: 'https://photobridgetossdemo.dev.target.com',
      uploadsUrl: baseURLQA + `photobridge_uploads/`,
      previewAPI: baseURLQA + `photobridge_previews/`,
      getDeleteImageAPI: (urlParams = '') =>
        baseURLQA + `photobridge_toss/${urlParams}`,
      newUploadsUrl: messageApiURLQA + 'images/new_image_chunk_uploads',
      existingUploadsUrl:
        messageApiURLQA + 'images/existing_image_chunk_uploads',
      uploadStatusUrl: messageApiURLQA + 'images/upload_statuses',
      validatingUrl: messageApiURLQA + 'images/file_validations',
      ingestionUploadsUrl:
        messageApiURLQA + 'images/ingestion/new_image_chunk_uploads',
    },
    reportsApi: baseURLQA + 'report',
    createJiraRequestApi: baseURLQA + 'report_issue',
    attachJiraRequestApi: baseURLQA + 'attachment',
    editProjectApi: baseURLQA + 'tcin/validate_tcins',
    deleteProjectsApi: baseURLQA + 'projects/delete_tcins',
    projectpdfreport: baseURLQA + 'pdfreport',
    projectkey: 'TPB',
    fireFlyConfig: fireFlyConfig[appEnv],
    getTcinInfo: messageApiURLQA + 'tcins/tcin_info',
    getUserCustomColumns: messageApiURLQA + 'columns',
    generateShotSheets: messageApiURLQA + 'sheets/shot_sheets',
    generateContactSheets: messageApiURLQA + 'sheets/contact_sheets',
    editMultiTcinInfo: messageApiURLQA + 'tcins/multi_tcin',
    generateSupportContactSheets:
      messageApiURLQA + 'sheets/retouched_contact_sheets',
    generateContactSheetsFinal: messageApiURLQA + 'sheets/contact_sheets_final',
    generateContactSheetsStores:
      messageApiURLQA + 'sheets/contact_sheets_stores',
    generateCompactShotSheets: messageApiURLQA + 'sheets/compact_shot_sheets',
    projectsApi: messageApiURLQA + 'projects',
    projectUsersApi: baseURLQA + 'users',
    getIngestedImagesApi: messageApiURLQA + 'images/image_details',
    getWorkflowItemsApi: messageApiURLQA + 'workflows/image_details',
    addTagsApi: messageApiURLQA + 'tags',
    getWorkflowOperationsApi: messageApiURLQA + 'workflows',
    archiveImageApi: messageApiURLQA + 'images/image_archives',
    getSupportImagesApi: messageApiURLQA + 'workflows/support_images',
    getImageVersionsApi: messageApiURLQA + 'workflows/image_versions',
    adGroups: ['APP-PB-VENDOR'],
    userGuide:
      'https://xyc.partnersonline.com/page/library/PB/applications/Application%20Training%20Document/3504',
    supportEmailGroup: 'TargetTech.Photobridge@target.com',
  },
  stage: {
    isPol: false,
    auth: {
      clientId: 'photobridge_npe_im',
    },
    apiKey: STG_API_KEY,
    routinueTCINListApi: searchBaseURLSTG + 'search_tcins',
    myTasksListApi: searchBaseURLSTG + 'search_mytcins',
    downloadImageAPI: baseURLSTG + 'photobridge_downloads',
    updateTCINSearchApi: baseURLSTG + 'update_tcin_status',
    updateTCINApi: searchBaseURLSTG + 'update_tcin_batch',
    updateImageDataApi: baseURLSTG + 'update_file_status',
    usersAdGroupBaseUrl: baseURLSTG + 'users',
    getCommentsApi: (taskUUID) => baseURLSTG + `tcin_comments/${taskUUID}`,
    postComment: baseURLSTG + `tcin_comments`,
    getTcinSummaryApi: (tcin, taskUUID) =>
      baseURLSTG + `tcin_info/${tcin}/${taskUUID}`,
    getAllStyleGuideApi: baseURLSTG + `style_guides`,
    getTcinStyleGuideApi: (tcin, taskUUID) =>
      baseURLSTG + `style_guides/${tcin}/${taskUUID}`,
    updateTcinStyleGuideApi: baseURLSTG + `update_tcin_styleguide`,
    getTcinImages: (tcin, taskUUID) =>
      baseURLSTG + `tcin_photos/${tcin}/${taskUUID}`,
    getHistoryAPI: (tcin, taskUUID) =>
      baseURLSTG + `tcin_history/${tcin}/${taskUUID}`,
    uploadKey: '',
    uploads: {
      host: 'https://photobridgetossdemo.dev.target.com',
      uploadsUrl: baseURLSTG + `photobridge_uploads/`,
      previewAPI: baseURLSTG + `photobridge_previews/`,
      getDeleteImageAPI: (urlParams = '') =>
        baseURLSTG + `photobridge_toss/${urlParams}`,
      newUploadsUrl:
        messageApiURLSTGExternal + 'images/new_image_chunk_uploads',
      existingUploadsUrl:
        messageApiURLSTGExternal + 'images/existing_image_chunk_uploads',
      uploadStatusUrl: messageApiURLSTGExternal + 'images/upload_statuses',
      validatingUrl: messageApiURLSTGExternal + 'images/file_validations',
      ingestionUploadsUrl:
        messageApiURLSTGExternal + 'images/ingestion/new_image_chunk_uploads',
    },
    reportsApi: baseURLSTG + 'report',
    createJiraRequestApi: baseURLSTG + 'report_issue',
    attachJiraRequestApi: baseURLSTG + 'attachment',
    editProjectApi: baseURLSTG + 'tcin/validate_tcins',
    deleteProjectsApi: baseURLSTG + 'projects/delete_tcins',
    projectpdfreport: baseURLSTG + 'pdfreport',
    projectkey: 'TPB',
    fireFlyConfig: fireFlyConfig[appEnv],
    getTcinInfo: messageApiURLSTG + 'tcins/tcin_info',
    getUserCustomColumns: messageApiURLSTG + 'columns',
    generateShotSheets: messageApiURLSTG + 'sheets/shot_sheets',
    generateContactSheets: messageApiURLSTG + 'sheets/contact_sheets',
    editMultiTcinInfo: messageApiURLSTG + 'tcins/multi_tcin',
    generateSupportContactSheets:
      messageApiURLSTG + 'sheets/retouched_contact_sheets',
    generateContactSheetsFinal:
      messageApiURLSTG + 'sheets/contact_sheets_final',
    generateContactSheetsStores:
      messageApiURLSTG + 'sheets/contact_sheets_stores',
    generateCompactShotSheets: messageApiURLSTG + 'sheets/compact_shot_sheets',
    projectsApi: messageApiURLSTG + 'projects',
    projectUsersApi: baseURLSTG + 'users',
    getIngestedImagesApi: messageApiURLSTG + 'images/image_details',
    getWorkflowItemsApi: messageApiURLSTG + 'workflows/image_details',
    getWorkflowOperationsApi: messageApiURLSTG + 'workflows',
    archiveImageApi: messageApiURLSTG + 'images/image_archives',
    addTagsApi: messageApiURLSTG + 'tags',
    getDownloadImageApi: messageApiURLSTG + 'images/download_image',
    getSupportImagesApi: messageApiURLSTG + 'workflows/support_images',
    getImageVersionsApi: messageApiURLSTG + 'workflows/image_versions',
    adGroups: [
      generalUserADGroup,
      producerADGroup,
      photographerADGroup,
      retoucherADGroup,
      adminADGroup,
      retouchingLeadADGroup,
      retouchingQAADGroup,
      artDirectorADGroup,
    ],
    userGuide:
      'https://jira.target.com/secure/attachment/460528/Photobridge%20Training%20Guide.docx',
    supportEmailGroup: 'TargetTech.Photobridge@target.com',
  },
  pol_stage: {
    isPol: true,
    auth: {
      clientId: 'photobridge_npe_im',
      authorizationUrl:
        'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl:
        'https://logonservices.oauth.iam.perf.partnersonline.com/login/responses/logoff.html',
    },
    apiKey: STG_API_KEY,
    routinueTCINListApi: searchBaseURLSTG + 'search_tcins',
    myTasksListApi: searchBaseURLSTG + 'search_mytcins',
    downloadImageAPI: baseURLSTG + 'photobridge_downloads',
    updateTCINSearchApi: baseURLSTG + 'update_tcin_status',
    updateTCINApi: searchBaseURLSTG + 'update_tcin_batch',
    updateImageDataApi: baseURLSTG + 'update_file_status',
    usersAdGroupBaseUrl: baseURLSTG + 'users',
    getCommentsApi: (taskUUID) => baseURLSTG + `tcin_comments/${taskUUID}`,
    postComment: baseURLSTG + `tcin_comments`,
    getTcinSummaryApi: (tcin, taskUUID) =>
      baseURLSTG + `tcin_info/${tcin}/${taskUUID}`,
    getAllStyleGuideApi: baseURLSTG + `style_guides`,
    getTcinStyleGuideApi: (tcin, taskUUID) =>
      baseURLSTG + `style_guides/${tcin}/${taskUUID}`,
    updateTcinStyleGuideApi: baseURLSTG + `update_tcin_styleguide`,
    getTcinImages: (tcin, taskUUID) =>
      baseURLSTG + `tcin_photos/${tcin}/${taskUUID}`,
    getHistoryAPI: (tcin, taskUUID) =>
      baseURLSTG + `tcin_history/${tcin}/${taskUUID}`,
    uploadKey: '',
    uploads: {
      host: 'https://photobridgetossdemo.dev.target.com',
      uploadsUrl: baseURLSTG + `photobridge_uploads/`,
      previewAPI: baseURLSTG + `photobridge_previews/`,
      getDeleteImageAPI: (urlParams = '') =>
        baseURLSTG + `photobridge_toss/${urlParams}`,
      newUploadsUrl:
        messageApiURLSTGExternal + 'images/new_image_chunk_uploads',
      existingUploadsUrl:
        messageApiURLSTGExternal + 'images/existing_image_chunk_uploads',
      uploadStatusUrl: messageApiURLSTGExternal + 'images/upload_statuses',
      validatingUrl: messageApiURLSTGExternal + 'images/file_validations',
      ingestionUploadsUrl:
        messageApiURLSTGExternal + 'images/ingestion/new_image_chunk_uploads',
    },
    reportsApi: baseURLSTG + 'report',
    createJiraRequestApi: baseURLSTG + 'report_issue',
    attachJiraRequestApi: baseURLSTG + 'attachment',
    editProjectApi: baseURLSTG + 'tcin/validate_tcins',
    deleteProjectsApi: baseURLSTG + 'projects/delete_tcins',
    projectpdfreport: baseURLSTG + 'pdfreport',
    projectkey: 'TPB',
    fireFlyConfig: fireFlyConfig[appEnv],
    getTcinInfo: messageApiURLSTGExternal + 'tcins/tcin_info',
    getUserCustomColumns: messageApiURLSTGExternal + 'columns',
    generateShotSheets: messageApiURLSTGExternal + 'sheets/shot_sheets',
    generateContactSheets: messageApiURLSTGExternal + 'sheets/contact_sheets',
    editMultiTcinInfo: messageApiURLSTGExternal + 'tcins/multi_tcin',
    generateSupportContactSheets:
      messageApiURLSTGExternal + 'sheets/retouched_contact_sheets',
    generateContactSheetsFinal:
      messageApiURLSTGExternal + 'sheets/contact_sheets_final',
    generateContactSheetsStores:
      messageApiURLSTGExternal + 'sheets/contact_sheets_stores',
    generateCompactShotSheets:
      messageApiURLSTGExternal + 'sheets/compact_shot_sheets',
    projectsApi: messageApiURLSTGExternal + 'projects',
    projectUsersApi: baseURLSTG + 'users',
    getIngestedImagesApi: messageApiURLSTG + 'images/image_details',
    getWorkflowItemsApi: messageApiURLSTG + 'workflows/image_details',
    getWorkflowOperationsApi: messageApiURLSTG + 'workflows',
    archiveImageApi: messageApiURLSTG + 'images/image_archives',
    addTagsApi: messageApiURLSTG + 'tags',
    getDownloadImageApi: messageApiURLSTG + 'images/download_image',
    getSupportImagesApi: messageApiURLSTGExternal + 'workflows/support_images',
    getImageVersionsApi: messageApiURLSTGExternal + 'workflows/image_versions',
    adGroups: ['APP-PB-VENDOR'],
    userGuide:
      'https://xyc.partnersonline.com/page/library/PB/applications/Application%20Training%20Document/3504',
    supportEmailGroup: 'Michelle.Nordberg@target.com',
  },
  prod: {
    isPol: false,
    auth: {
      clientId: 'photobridge_prod_im',
      authorizationUrl: 'https://oauth.iam.target.com/auth/oauth/v2/authorize',
      logoutUrl:
        'https://logonservices.iam.target.com/login/responses/logoff.html',
    },
    apiKey: PRD_API_KEY,
    routinueTCINListApi: searchBaseURLPROD + 'search_tcins',
    myTasksListApi: searchBaseURLPROD + 'search_mytcins',
    downloadImageAPI: baseURLPROD + 'photobridge_downloads',
    updateTCINSearchApi: baseURLPROD + 'update_tcin_status',
    updateTCINApi: searchBaseURLPROD + 'update_tcin_batch',
    updateImageDataApi: baseURLPROD + 'update_file_status',
    usersAdGroupBaseUrl: baseURLPROD + 'users',
    getCommentsApi: (taskUUID) => baseURLPROD + `tcin_comments/${taskUUID}`,
    postComment: baseURLPROD + `tcin_comments`,
    getTcinSummaryApi: (tcin, taskUUID) =>
      baseURLPROD + `tcin_info/${tcin}/${taskUUID}`,
    getAllStyleGuideApi: baseURLPROD + `style_guides`,
    getTcinStyleGuideApi: (tcin, taskUUID) =>
      baseURLPROD + `style_guides/${tcin}/${taskUUID}`,
    updateTcinStyleGuideApi: baseURLPROD + `update_tcin_styleguide`,
    getTcinImages: (tcin, taskUUID) =>
      baseURLPROD + `tcin_photos/${tcin}/${taskUUID}`,
    getHistoryAPI: (tcin, taskUUID) =>
      baseURLPROD + `tcin_history/${tcin}/${taskUUID}`,
    uploadKey: '',
    uploads: {
      host: 'https://photobridgetossdemo.dev.target.com',
      uploadsUrl: baseURLPROD + `photobridge_uploads/`,
      previewAPI: baseURLPROD + `photobridge_previews/`,
      getDeleteImageAPI: (urlParams = '') =>
        baseURLPROD + `photobridge_toss/${urlParams}`,
      newUploadsUrl:
        messageApiURLPRODExternal + 'images/new_image_chunk_uploads',
      existingUploadsUrl:
        messageApiURLPRODExternal + 'images/existing_image_chunk_uploads',
      uploadStatusUrl: messageApiURLPRODExternal + 'images/upload_statuses',
      validatingUrl: messageApiURLPRODExternal + 'images/file_validations',
      ingestionUploadsUrl:
        messageApiURLPRODExternal + 'images/ingestion/new_image_chunk_uploads',
    },
    reportsApi: baseURLPROD + 'report',
    createJiraRequestApi: baseURLPROD + 'report_issue',
    attachJiraRequestApi: baseURLPROD + 'attachment',
    editProjectApi: baseURLPROD + 'tcin/validate_tcins',
    deleteProjectsApi: baseURLPROD + 'projects/delete_tcins',
    projectpdfreport: baseURLPROD + 'pdfreport',
    projectkey: 'TPB',
    fireFlyConfig: fireFlyConfig[appEnv],
    getTcinInfo: messageApiURLPROD + 'tcins/tcin_info',
    getUserCustomColumns: messageApiURLPROD + 'columns',
    generateShotSheets: messageApiURLPROD + 'sheets/shot_sheets',
    generateContactSheets: messageApiURLPROD + 'sheets/contact_sheets',
    editMultiTcinInfo: messageApiURLPROD + 'tcins/multi_tcin',
    generateSupportContactSheets:
      messageApiURLPROD + 'sheets/retouched_contact_sheets',
    generateContactSheetsFinal:
      messageApiURLPROD + 'sheets/contact_sheets_final',
    generateContactSheetsStores:
      messageApiURLPROD + 'sheets/contact_sheets_stores',
    generateCompactShotSheets: messageApiURLPROD + 'sheets/compact_shot_sheets',
    projectsApi: messageApiURLPROD + 'projects',
    projectUsersApi: baseURLPROD + 'users',
    getIngestedImagesApi: messageApiURLPROD + 'images/image_details',
    getWorkflowItemsApi: messageApiURLPROD + 'workflows/image_details',
    getWorkflowOperationsApi: messageApiURLPROD + 'workflows',
    archiveImageApi: messageApiURLPROD + 'images/image_archives',
    addTagsApi: messageApiURLPROD + 'tags',
    getDownloadImageApi: messageApiURLPROD + 'images/download_image',
    getSupportImagesApi: messageApiURLPROD + 'workflows/support_images',
    getImageVersionsApi: messageApiURLPROD + 'workflows/image_versions',
    adGroups: [
      generalUserADGroup,
      producerADGroup,
      photographerADGroup,
      retoucherADGroup,
      adminADGroup,
      retouchingLeadADGroup,
      retouchingQAADGroup,
      artDirectorADGroup,
    ],
    userGuide:
      'https://jira.target.com/secure/attachment/460528/Photobridge%20Training%20Guide.docx',
    supportEmailGroup: 'TargetTech.Photobridge@target.com',
  },
  pol_prod: {
    isPol: true,
    auth: {
      clientId: 'photobridge_prod_im',
      authorizationUrl:
        'https://oauth.iam.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl:
        'https://logonservices.oauth.iam.partnersonline.com/login/responses/logoff.html',
    },
    apiKey: PRD_API_KEY,
    routinueTCINListApi: searchBaseURLPROD + 'search_tcins',
    myTasksListApi: searchBaseURLPROD + 'search_mytcins',
    downloadImageAPI: baseURLPROD + 'photobridge_downloads',
    updateTCINSearchApi: baseURLPROD + 'update_tcin_status',
    updateTCINApi: searchBaseURLPROD + 'update_tcin_batch',
    updateImageDataApi: baseURLPROD + 'update_file_status',
    usersAdGroupBaseUrl: baseURLPROD + 'users',
    getCommentsApi: (taskUUID) => baseURLPROD + `tcin_comments/${taskUUID}`,
    postComment: baseURLPROD + `tcin_comments`,
    getTcinSummaryApi: (tcin, taskUUID) =>
      baseURLPROD + `tcin_info/${tcin}/${taskUUID}`,
    getAllStyleGuideApi: baseURLPROD + `style_guides`,
    getTcinStyleGuideApi: (tcin, taskUUID) =>
      baseURLPROD + `style_guides/${tcin}/${taskUUID}`,
    updateTcinStyleGuideApi: baseURLPROD + `update_tcin_styleguide`,
    getTcinImages: (tcin, taskUUID) =>
      baseURLPROD + `tcin_photos/${tcin}/${taskUUID}`,
    getHistoryAPI: (tcin, taskUUID) =>
      baseURLPROD + `tcin_history/${tcin}/${taskUUID}`,
    uploadKey: '',
    uploads: {
      host: 'https://photobridgetossdemo.dev.target.com',
      uploadsUrl: baseURLPROD + `photobridge_uploads/`,
      previewAPI: baseURLPROD + `photobridge_previews/`,
      getDeleteImageAPI: (urlParams = '') =>
        baseURLPROD + `photobridge_toss/${urlParams}`,
      newUploadsUrl:
        messageApiURLPRODExternal + 'images/new_image_chunk_uploads',
      existingUploadsUrl:
        messageApiURLPRODExternal + 'images/existing_image_chunk_uploads',
      uploadStatusUrl: messageApiURLPRODExternal + 'images/upload_statuses',
      validatingUrl: messageApiURLPRODExternal + 'images/file_validations',
      ingestionUploadsUrl:
        messageApiURLPRODExternal + 'images/ingestion/new_image_chunk_uploads',
    },
    reportsApi: baseURLPROD + 'report',
    createJiraRequestApi: baseURLPROD + 'report_issue',
    attachJiraRequestApi: baseURLPROD + 'attachment',
    editProjectApi: baseURLPROD + 'tcin/validate_tcins',
    deleteProjectsApi: baseURLPROD + 'projects/delete_tcins',
    projectpdfreport: baseURLPROD + 'pdfreport',
    projectkey: 'TPB',
    fireFlyConfig: fireFlyConfig[appEnv],
    getTcinInfo: messageApiURLPRODExternal + 'tcins/tcin_info',
    getUserCustomColumns: messageApiURLPRODExternal + 'columns',
    generateShotSheets: messageApiURLPRODExternal + 'sheets/shot_sheets',
    generateContactSheets: messageApiURLPRODExternal + 'sheets/contact_sheets',
    editMultiTcinInfo: messageApiURLPRODExternal + 'tcins/multi_tcin',
    generateSupportContactSheets:
      messageApiURLPRODExternal + 'sheets/retouched_contact_sheets',
    generateContactSheetsFinal:
      messageApiURLPRODExternal + 'sheets/contact_sheets_final',
    generateContactSheetsStores:
      messageApiURLPRODExternal + 'sheets/contact_sheets_stores',
    generateCompactShotSheets:
      messageApiURLPRODExternal + 'sheets/compact_shot_sheets',
    projectsApi: messageApiURLPRODExternal + 'projects',
    projectUsersApi: baseURLPROD + 'users',
    getIngestedImagesApi: messageApiURLPRODExternal + 'images/image_details',
    getWorkflowItemsApi: messageApiURLPRODExternal + 'workflows/image_details',
    getWorkflowOperationsApi: messageApiURLPRODExternal + 'workflows',
    archiveImageApi: messageApiURLPRODExternal + 'images/image_archives',
    addTagsApi: messageApiURLPRODExternal + 'tags',
    getDownloadImageApi: messageApiURLPRODExternal + 'images/download_image',
    getSupportImagesApi: messageApiURLPRODExternal + 'workflows/support_images',
    getImageVersionsApi: messageApiURLPRODExternal + 'workflows/image_versions',
    adGroups: ['APP-PB-VENDOR'],
    userGuide:
      'https://www.partnersonline.com/page/library/PB/applications/Application%20Training%20Document/3504',
    supportEmailGroup: 'Michelle.Nordberg@target.com',
  },
}

const config = envConfigs[appEnv]

// commonConfig and the config for the matching REACT_APP_ENV are combined.
// Values in the environment-specific config will overwrite commonConfig keys
// if they share the same name.
const apiConfig = merge(commonConfig, config)

export default apiConfig

// The following code block is purely for demonstration purposes and can be removed.
if (process.env.NODE_ENV !== 'production') {
  //console.log(`${appEnv} ENV apiConfig:`, config)
}
