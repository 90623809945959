import { GET_ROUTINE_TCIN_LIST_API_FULFILLED } from './actionType'
import { GET_ROUTINE_TCIN_LIST_API_PENDING } from './actionType'
import { GET_ROUTINE_TCIN_LIST_API_REJECTED } from './actionType'
import { GET_MY_TASKS_LIST_API_FULFILLED } from './actionType'
import { GET_MY_TASKS_LIST_API_PENDING } from './actionType'
import { GET_MY_TASKS_LIST_API_REJECTED } from './actionType'
import { UPDATE_TCIN_LIST_API_FULFILLED } from './actionType'
import { UPDATE_TCIN_LIST_API_PENDING } from './actionType'
import { UPDATE_TCIN_LIST_API_REJECTED } from './actionType'
import { UPDATE_TCIN_LIST_SEARCH_API_FULFILLED } from './actionType'
import { UPDATE_TCIN_LIST_SEARCH_API_PENDING } from './actionType'
import { UPDATE_TCIN_LIST_SEARCH_API_REJECTED } from './actionType'
import { SET_PAGE_FILTERS } from './actionType'

const routineTCINListState = {
  tcinList: [],
  tcinLoading: false,
  facets: {},
  tcinListCount: 0,
  currentPageCount: 0,
  updateTcinLoading: '',
  tcinListStatusCounts: {},
  mytaskStatusCounts: {},
  myTaskTcinList: [],
  myTaskFacets: {},
  myTaskTcinLoading: false,
  myTaskTcinListCount: 0,
  myTaskCurPageCount: 0,
}
export default function routineTCINList(
  state = routineTCINListState,
  action = {}
) {
  const { payload = {} } = action
  const { data: responseData = {}, pageFilters = {} } = payload
  const {
    search_response = [],
    filters: facets = {},
    total_count = 0,
    response_count = 0,
    status_counts = {},
  } = responseData
  switch (action.type) {
    case SET_PAGE_FILTERS:
      return {
        ...state,
        pageFilters: pageFilters,
      }
    case GET_ROUTINE_TCIN_LIST_API_FULFILLED:
      return {
        ...state,
        tcinList: search_response,
        facets: Object.assign({}, facets, state.facets),
        tcinLoading: false,
        tcinListCount: total_count,
        tcinListStatusCounts: status_counts,
        currentPageCount: response_count,
      }
    case GET_ROUTINE_TCIN_LIST_API_PENDING:
      return {
        ...state,
        tcinLoading: true,
      }
    case GET_ROUTINE_TCIN_LIST_API_REJECTED:
      return {
        ...state,
        tcinLoading: false,
      }
    case GET_MY_TASKS_LIST_API_FULFILLED:
      return {
        ...state,
        myTaskTcinList: search_response,
        myTaskFacets: Object.assign({}, facets, state.myTaskFacets),
        myTaskTcinLoading: false,
        myTaskTcinListCount: total_count,
        myTaskCurPageCount: response_count,
        mytaskStatusCounts: status_counts,
      }
    case GET_MY_TASKS_LIST_API_PENDING:
      return {
        ...state,
        myTaskTcinLoading: true,
      }
    case GET_MY_TASKS_LIST_API_REJECTED:
      return {
        ...state,
        myTaskTcinLoading: false,
      }
    case UPDATE_TCIN_LIST_API_FULFILLED:
      return {
        ...state,
        updateTcinLoading: 'fulfilled',
      }
    case UPDATE_TCIN_LIST_API_PENDING:
      return {
        ...state,
        updateTcinLoading: 'pending',
      }
    case UPDATE_TCIN_LIST_API_REJECTED:
      return {
        ...state,
        updateTcinLoading: 'rejected',
      }
    case UPDATE_TCIN_LIST_SEARCH_API_FULFILLED:
      return {
        ...state,
        updateTcinSearchLoading: 'fulfilled',
      }
    case UPDATE_TCIN_LIST_SEARCH_API_PENDING:
      return {
        ...state,
        updateTcinSearchLoading: 'pending',
      }
    case UPDATE_TCIN_LIST_SEARCH_API_REJECTED:
      return {
        ...state,
        updateTcinSearchLoading: 'rejected',
      }
    default:
      return state
  }
}
