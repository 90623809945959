import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Button, IconButton, Chip } from '@material-ui/core'
import { formatDate } from '../../helpers/dateHelper'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import Drawer from '@material-ui/core/Drawer'
import { setBreadcrumbs } from '../../store/Layout/actionCreator'
import { useParams, useLocation } from 'react-router-dom'
import { orderBy } from 'lodash'
import EditProject from './EditProject'
import axios from 'axios'
import apiconfig from '../../apiConfig'
import CustomTable from '../../components/CustomTable'
import {
  customizeColumns,
  tcinHeaders,
  generateDefaultColumns,
} from './constants'
import TableToolbar from './TableToolbar'
import TabData from './TabData'
import EditIcon from '@material-ui/icons/Edit'
import cloneDeep from 'lodash/cloneDeep'
import apiConfig from '../../apiConfig'
import { appEnv } from '../../apiConfig'
import { setProjectInfo } from '../../store/Dashboard/actionCreator'
import { toSafeData } from '../../containers/TcinInfo/mapper'
import { withAuth } from '@praxis/component-auth'
import { producerADGroup } from '../../constants/common'
import CloseIcon from '@material-ui/icons/Close'

const { projectsApi, getUserCustomColumns } = apiconfig

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-grid',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    display: 'flex',
    padding: '16px 24px',
    alignItems: 'center',
    gap: 16,
  },
  buttons: {
    height: '36px',
    minWidth: '110px',
  },
  editButton: {
    color: '#FFFFFF',
    backgroundColor: '#366CD9',
    '&:hover': {
      backgroundColor: '#1976d2',
    },
  },
  tagsButton: {
    padding: '0 !important',
    color: '#366CD9',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    '&:hover': {
      outline: 'none',
      background: 'none !important',
    },
  },
  ownerDetails: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#333333',
  },
  itemName: {
    color: '#666666',
  },
  itemDetails: {
    color: '#333333',
    fontWeight: '500',
  },
  drawerHeader: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '23px',
    flex: '1',
    color: '#333333',
    margin: '0',
  },
  closeIcon: {
    padding: '0 !important',
    marginRight: '-9px !important',
  },
  attrDiv: {
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
  },
  attrHead: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 12,
    lineHeight: '14.06px',
  },
  attrValue: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: '18.75px',
  },
  drawerHeaderDivider: {
    marginTop: '24px',
    width: `calc(100% + 24px)`,
    marginLeft: '0px',
    '&:before': {
      content: "''",
      width: '24px',
      height: '1px',
      display: 'block',
      position: 'absolute',
      background: 'rgba(0, 0, 0, 0.12)',
      marginLeft: '-24px',
    },
  },
  drawerContainer: {
    padding: '40px 0',
  },
  imgInfo: {
    width: 'fit-content',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#EDEDED',
    color: theme.palette.text.secondary,
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
  drawer: {
    width: '80%',
    maxWidth: '1200px',
    padding: 24,
  },
  tabDrawer: {
    minWidth: 1100,
    width: '75%',
    overflowY: 'unset',
  },
}))

const ProjectDetail = React.memo((props) => {
  let { project_id } = useParams()
  const { state: { totalTcin = '' } = {} } = useLocation()
  const dispatch = useDispatch()

  const projectDataReducer = useSelector(
    (state) => state.dashboardReducer.projectDataReducer
  )

  const tcinInfo = useSelector((state) => {
    const {
      dashboardReducer: { projectDataReducer: { tcin_info = [] } = {} } = {},
    } = state
    return tcin_info
  })

  const classes = useStyles()
  const [projectData, setProjectData] = React.useState(projectDataReducer)
  const [loading, setLoading] = React.useState(true)
  const [projectLoad, setProjectLoad] = React.useState(true)
  const [openEdit, setOpenEdit] = React.useState(false)
  const [columns, setColumns] = React.useState([])
  const [tabData, setTabData] = React.useState(false)
  const [tabIndex, setTabIndex] = React.useState({
    category: 'item',
    rowIndex: 0,
  })
  const [tabContent, setTabContent] = React.useState([])
  const [userCustomizedColumns, setUserCustomizedColumns] = React.useState()
  const [viewType, setViewType] = React.useState('Userbased')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(totalTcin || 50)
  const [openDrawer, setOpenDrawer] = React.useState('')
  const [totalCount, setTotalCount] = React.useState(0)
  const [isEditDisabled, setIsEditDisabled] = React.useState(true)

  const { accessToken = '' } = props
  const { userId = '' } = props
  const { memberOf: userADGroups = [] } = props

  const getProjectInfo = (page = 0, numberOfRows = rowsPerPage) => {
    setProjectLoad(true)
    let url = `${projectsApi}/${project_id}`
    axios
      .get(url, {
        params: {
          key: apiConfig.apiKey,
          page: page,
          size: numberOfRows,
        },
        headers: { Authorization: accessToken },
      })
      .then((res) => {
        setProjectLoad(false)
        const { data = {} } = res
        const { archived: isArchived = true } = data
        dispatch(setProjectInfo(data))
        setProjectData(data)
        let name = props.customPath.replace(
          ':project_name',
          toSafeData(data, 'project_name', '-')
        )
        setColumns(tcinHeaders(toSafeData(data, 'max_alt_count', 0)))
        setPage(toSafeData(data, 'tcin_pagination_details.page', 0))
        setRowsPerPage(numberOfRows)
        setTotalCount(
          toSafeData(data, 'tcin_pagination_details.total_count', 0)
        )
        dispatch(setBreadcrumbs(name, props.pathname))
        setIsEditDisabled(
          !(
            !isArchived &&
            userADGroups.includes(producerADGroup.toUpperCase()) &&
            (userId?.toLowerCase() ===
              data.created_by?.login_id?.toLowerCase() ||
              data.users
                ?.map((user) => user.login_id.toLowerCase())
                .indexOf(userId.toLowerCase()) > -1)
          )
        )
      })
      .catch((e) => {
        setProjectLoad(false)
      })
  }

  const getUserViewData = () => {
    axios
      .get(
        `${getUserCustomColumns}?user_id=${userId.toUpperCase()}&view=project`,
        {
          params: {
            key: apiConfig.apiKey,
          },
          headers: { Authorization: accessToken },
        }
      )
      .then((res) => {
        res.data && res.data.column_list !== null
          ? applyUserView(res.data.column_list)
          : handleNoUserView()
        setLoading(false)
      })
      .catch((error) => {
        console.error(
          'There is an error while fetching the custom columns list',
          error
        )
      })
  }

  const applyUserView = (userColumnList) => {
    let userCustomizedColumns = cloneDeep(customizeColumns)
    userColumnList.forEach((userColumn) => {
      userCustomizedColumns[userColumn].selected = true
    })
    setUserCustomizedColumns({ ...userCustomizedColumns })
  }

  const handleNoUserView = () => {
    setUserCustomizedColumns(generateDefaultColumns())
  }

  const updateUserView = (newUserView) => {
    const url = `${getUserCustomColumns}?view=project`
    const body = {
      user_id: userId.toUpperCase(),
      column_list: Array.from(getNewColumns(newUserView)),
    }
    axios
      .post(url, body, {
        params: {
          key: apiConfig.apiKey,
        },
        headers: { Authorization: accessToken },
      })
      .then((res) => {
        applyUserView(res.data.project_columns.column_list)
      })
      .catch((error) => {
        console.error(
          'There is an error while saving the custom columns list',
          error
        )
      })
  }

  const getNewColumns = (userView) => {
    const columnList = new Set()
    for (const [key, value] of Object.entries(userView)) {
      value.selected && columnList.add(key)
    }
    return columnList
  }

  const renderDrawer = (drawer) => {
    switch (drawer) {
      case 'Edit Project':
        return (
          <>
            <Divider className={classes.drawerHeaderDivider} />
            <EditProject
              projectData={projectData}
              id={project_id}
              closeDialog={handleEditClose}
              isEditDisabled={isEditDisabled}
            />
          </>
        )
      case 'Project Info':
        return (
          <>
            <Divider className={classes.drawerHeaderDivider} />
            <Grid container className={classes.drawerContainer}>
              <Grid
                wrap={'nowrap'}
                container
                justify={'space-between'}
                alignItems={'center'}
              >
                <Typography variant="body1" className={classes.ownerDetails}>
                  {`${projectData.project_name} created by ${
                    projectData.created_by && projectData.created_by.first_name
                  } ${
                    projectData.created_by && projectData.created_by.last_name
                  }  on ${formatDate(projectData.created_date)}`}
                </Typography>
                <div>
                  {userADGroups?.includes(producerADGroup.toUpperCase()) &
                  !apiConfig.isPol ? (
                    <React.Fragment>
                      {userId?.toLowerCase() ===
                      projectData.created_by?.login_id?.toLowerCase() ? (
                        <Button
                          className={classes.buttons}
                          variant="outlined"
                          onClick={() =>
                            makeProjectsArchive(!projectData.archived)
                          }
                          startIcon={<DeleteOutlineIcon />}
                          style={{ marginRight: '24px' }}
                          disabled={
                            userId?.toLowerCase() !==
                            projectData.created_by?.login_id?.toLowerCase()
                          }
                        >
                          {projectData.archived ? 'Undo Archive' : 'Archive'}
                        </Button>
                      ) : (
                        <></>
                      )}

                      {!isEditDisabled && (
                        <Button
                          variant="contained"
                          className={`${classes.buttons} ${classes.editButton}`}
                          onClick={() => setOpenDrawer('Edit Project')}
                          disabled={isEditDisabled}
                          startIcon={<EditIcon />}
                        >
                          Edit
                        </Button>
                      )}
                    </React.Fragment>
                  ) : (
                    <></>
                  )}
                </div>
              </Grid>
              <Grid
                wrap={'nowrap'}
                style={{ marginTop: '40px' }}
                container
                justify={'space-between'}
                alignItems={'center'}
              >
                <Grid item lg={4}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemName}`}
                  >
                    Project Name:
                  </Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemDetails}`}
                  >
                    {projectData?.project_name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                wrap={'nowrap'}
                style={{ marginTop: '40px' }}
                container
                justify={'space-between'}
                alignItems={'center'}
              >
                <Grid item lg={4}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemName}`}
                  >
                    PID:
                  </Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemDetails}`}
                  >
                    {projectData?.sap_pid || 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                wrap={'nowrap'}
                style={{ marginTop: '40px' }}
                container
                justify={'space-between'}
                alignItems={'center'}
              >
                <Grid item lg={4}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemName}`}
                  >
                    Project Type:
                  </Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemDetails}`}
                  >
                    {projectData?.project_type || 'N/A'}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                wrap={'nowrap'}
                style={{ marginTop: '40px' }}
                container
                justify={'space-between'}
                alignItems={'center'}
              >
                <Grid item lg={4}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemName}`}
                  >
                    Estimated #shots per TCIN:
                  </Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemDetails}`}
                  >
                    {projectData?.shot_count || 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                wrap={'nowrap'}
                style={{ marginTop: '40px' }}
                container
                justify={'space-between'}
                alignItems={'center'}
              >
                <Grid item lg={4}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemName}`}
                  >
                    Estimated Completion Date:
                  </Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemDetails}`}
                  >
                    {projectData?.due_date
                      ? formatDate(projectData?.due_date)
                      : 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                wrap={'nowrap'}
                style={{ marginTop: '40px' }}
                container
                justify={'space-between'}
                alignItems={'center'}
              >
                <Grid item lg={4}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemName}`}
                  >
                    Owners:
                  </Typography>
                </Grid>
                <Grid item lg={8}>
                  <div className={classes.ownerDetails}>
                    {projectData?.users
                      ? projectData?.users.map((option) => {
                          return (
                            <Chip
                              style={{ marginRight: '8px' }}
                              label={option.display_name}
                            />
                          )
                        })
                      : 'N/A'}
                  </div>
                </Grid>
              </Grid>
              <Grid
                wrap={'nowrap'}
                style={{ marginTop: '40px' }}
                container
                justify={'space-between'}
                alignItems={'center'}
              >
                <Grid item lg={4}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemName}`}
                  >
                    Followers:
                  </Typography>
                </Grid>
                <Grid item lg={8}>
                  <div className={classes.ownerDetails}>
                    {projectData?.viewers
                      ? projectData?.viewers.map((option) => {
                          return (
                            <Chip
                              style={{ marginRight: '8px' }}
                              label={option.display_name}
                            />
                          )
                        })
                      : 'N/A'}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </>
        )

      default:
        break
    }
  }

  const makeProjectsArchive = (makeArchive) => {
    const url = `${projectsApi}/archives`
    const body = {
      project_ids: [project_id],
      archive: makeArchive,
    }
    axios
      .post(url, body, {
        params: {
          key: apiConfig.apiKey,
        },
        headers: {
          Authorization: accessToken,
          ...(appEnv === 'dev' && { 'x-tgt-lanId': userId }),
        },
      })
      .then((response) => {
        setLoading(true)
        getProjectInfo()
      })
      .catch((error) => {
        //console.log(error)
      })
  }

  React.useEffect(() => {
    setTabContent(projectData.tcin_info)
    setProjectLoad(false)
  }, [projectData.tcin_info])

  React.useEffect(() => {
    setProjectLoad(true)
    getProjectInfo()
    userId && getUserViewData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pathname, userId])

  const handleEditClose = (isSaved) => {
    isSaved && getProjectInfo()
    setOpenDrawer('')
  }

  const handleCloseTabData = () => {
    setTabData(false)
  }
  const handleOpenTabData = (e, index, category) => {
    setTabData(true)
    setTabIndex((state) => ({ ...state, category: category, rowIndex: index }))
  }

  const onOrderChange = (data, orderByName, order) => {
    setTabContent(orderBy(data, orderByName, order))
  }

  return (
    !loading && (
      <div className={classes.root}>
        <div className={classes.header}>
          <Grid container alignItems="center" className={classes.imgInfo}>
            <div className={classes.attrDiv}>
              <Typography variant="subtitle1" className={classes.attrHead}>
                #TCINs
              </Typography>
              <Typography variant="button" className={classes.attrValue}>
                {(projectData.project_stats || {}).tcin_count}
              </Typography>
            </div>
            <Divider orientation="vertical" flexItem />
            <div className={classes.attrDiv}>
              <Typography variant="subtitle1" className={classes.attrHead}>
                Estimated #Images
              </Typography>
              <Typography variant="button" className={classes.attrValue}>
                {(projectData.project_stats || {}).estimated_images}
              </Typography>
            </div>
            <Divider orientation="vertical" flexItem />
            <div className={classes.attrDiv}>
              <Typography variant="subtitle1" className={classes.attrHead}>
                Shoot Complete
              </Typography>
              <Typography variant="button" className={classes.attrValue}>
                {(projectData.project_stats || {}).shoot_complete}
              </Typography>
            </div>
            <Divider orientation="vertical" flexItem />
            <div className={classes.attrDiv}>
              <Typography variant="subtitle1" className={classes.attrHead}>
                In Imaging
              </Typography>
              <Typography variant="button" className={classes.attrValue}>
                {(projectData.project_stats || {}).imaging}
              </Typography>
            </div>
            <Divider orientation="vertical" flexItem />
            <div className={classes.attrDiv}>
              <Typography variant="subtitle1" className={classes.attrHead}>
                Sent to Pipeline
              </Typography>
              <Typography variant="button" className={classes.attrValue}>
                {(projectData.project_stats || {}).pipeline}
              </Typography>
            </div>
          </Grid>
          <div style={{ marginLeft: 'auto' }}>
            <Button
              variant="text"
              className={classes.tagsButton}
              onClick={() => {
                setOpenDrawer('Project Info')
              }}
            >
              View Project Info
            </Button>
          </div>

          <Drawer
            classes={{
              paper: classes.drawer,
            }}
            anchor="right"
            open={openDrawer.length}
            onClose={() =>
              openDrawer === 'Edit Project'
                ? handleEditClose()
                : setOpenDrawer('')
            }
          >
            <div
              style={{
                height: '100%',
              }}
            >
              <Grid
                wrap={'nowrap'}
                container
                justify={'space-between'}
                alignItems={'center'}
              >
                <h4 className={classes.drawerHeader}>{openDrawer}</h4>
                <IconButton
                  aria-label="close"
                  onClick={() => setOpenDrawer('')}
                  className={classes.closeIcon}
                >
                  <CloseIcon style={{ fontSize: 24 }} />
                </IconButton>{' '}
              </Grid>
              {renderDrawer(openDrawer)}
            </div>
          </Drawer>
        </div>
        <CustomTable
          uniqueIdName={'tcin'}
          isLoading={projectLoad}
          page={page}
          rowsPerPage={rowsPerPage}
          totalCount={totalCount}
          columns={columns}
          checkBox
          customizeColumns={
            viewType === 'Userbased'
              ? userCustomizedColumns
              : generateDefaultColumns()
          }
          userColumns={userCustomizedColumns}
          isArchived={
            projectData.archived ||
            userId?.toLowerCase() !==
              projectData.created_by?.login_id?.toLowerCase()
          }
          rows={tcinInfo}
          moreInfoHandler={handleOpenTabData}
          onRowOrderChange={onOrderChange}
          Toolbar={
            <TableToolbar
              isRemoveDisabled={isEditDisabled}
              setTabContent={setTabContent}
              getProjectInfo={getProjectInfo}
            />
          }
          getProjectInfo={getProjectInfo}
          projectId={projectDataReducer.project_id}
          isProducer={userADGroups?.includes(producerADGroup.toUpperCase())}
          viewType={viewType}
          setViewType={setViewType}
          updateUserView={updateUserView}
        />
        <Drawer
          classes={{
            paper: classes.tabDrawer,
          }}
          anchor="right"
          open={tabData}
          onClose={handleCloseTabData}
        >
          <TabData
            closeDialog={handleCloseTabData}
            tabIndex={tabIndex}
            tcinData={tabContent}
          />
        </Drawer>
      </div>
    )
  )
})

const mapAuthToProps = (auth) => {
  return {
    accessToken: auth.session?.userInfo?.accessToken,
    userId: auth.session?.userInfo?.lanId,
    memberOf: auth.session?.userInfo?.memberOf,
  }
}

export default withAuth(mapAuthToProps)(React.memo(ProjectDetail))
