import { SHOW_NOTIFICATION } from './actionType'

export function showNotification(
  isShown,
  message,
  variant = 'info',
  duration = 0,
  isCopy = false,
  copyText = ''
) {
  return {
    type: SHOW_NOTIFICATION,
    payload: {
      isShown,
      message,
      variant,
      duration,
      isCopy,
      copyText,
    },
  }
}
