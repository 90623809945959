import React from 'react'
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Button,
  Link,
  IconButton,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import { get } from 'lodash'
import { projectHeaders } from './constants'
import { Link as RouterLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { DashboardContext } from './index'
import { formatDate } from '../../helpers/dateHelper'
import { orderBy } from 'lodash'
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined'
import { isSafari } from '../../helpers/filter'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  tableHeadRoot: {
    '& .MuiTableCell-head': {
      padding: '16px !important',
      fontSize: '13px',
      '&:first-child': {
        paddingLeft: '25px !important',
      },
    },
  },
  tableBodyRoot: {
    '& .MuiTableCell-body': {
      padding: '16px !important',
      fontSize: '13px',
      '&:first-child': {
        paddingLeft: '25px !important',
      },
    },
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  activeProject: {
    fontWeight: 500,
  },
  archivedProject: {
    fontWeight: 500,
  },
}))

const CustomTableHead = React.memo((props) => {
  const classes = useStyles()
  const { dState, dMethods } = React.useContext(DashboardContext)

  const sortHandler = (event, property) => {
    const isAsc = dState.orderBy === property && dState.order === 'asc'
    dMethods.orderHandler(isAsc ? 'desc' : 'asc')
    dMethods.orderByHandler(property)
  }
  return (
    <TableHead>
      <TableRow className={classes.tableHeadRoot}>
        {/* <TableCell>
          <IconButton
            style={{
              padding: '0',
              marginLeft: props.isMyProjects ? '0' : '10px',
            }}
          >
            <StarBorderOutlinedIcon
              style={{ verticalAlign: 'middle', color: '#B6B6B6' }}
            />
          </IconButton>
        </TableCell> */}
        {projectHeaders.map((headCell) => (
          <TableCell
            style={{ left: 0, paddingTop: 0 }}
            key={headCell.data}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={
              dState.orderBy === headCell.data ? dState.order : false
            }
          >
            <TableSortLabel
              active={dState.orderBy === headCell.data}
              direction={
                dState.orderBy === headCell.data ? dState.order : 'asc'
              }
              onClick={(e) => sortHandler(e, headCell.data)}
            >
              {headCell.label}
              {dState.orderBy === headCell.data ? (
                <span className={classes.visuallyHidden}>
                  {dState.order === 'desc'
                    ? 'sorted descending'
                    : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
})

const CustomTableBody = React.memo((props) => {
  const loading = useSelector((state) => state.dashboardReducer.loading)
  const classes = useStyles()
  const { page, rowsPerPage, searchText } = props
  const { dState, dMethods } = React.useContext(DashboardContext)

  const getSortedList = () => {
    if (dState.orderBy === 'project_id') {
      return dState.order === 'desc'
        ? dState.projects.sort((a, b) =>
            b.project_id.localeCompare(a.project_id, undefined, {
              numeric: true,
            })
          )
        : dState.projects.sort((a, b) =>
            a.project_id.localeCompare(b.project_id, undefined, {
              numeric: true,
            })
          )
    } else {
      return orderBy(dState.projects, dState.orderBy, dState.order)
    }
  }

  return (
    <TableBody>
      {!loading ? (
        dState.projects.length > 0 ? (
          getSortedList()
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => (
              <TableRow key={row.project_id} className={classes.tableBodyRoot}>
                {/* <TableCell>
                  <IconButton
                    style={{
                      padding: '0',
                      marginLeft: props.isMyProjects ? '0' : '10px',
                    }}
                  >
                    <StarBorderOutlinedIcon
                      style={{
                        verticalAlign: 'middle',
                        color: '#B6B6B6',
                      }}
                    />
                  </IconButton>
                </TableCell> */}
                {projectHeaders.map((cell) => {
                  return (
                    <TableCell
                      style={{ left: 0 }}
                      key={row.project_id + '-' + cell.id}
                      className={
                        cell.id === 'project_name' &&
                        (row.archived
                          ? classes.archivedProject
                          : classes.activeProject)
                      }
                    >
                      {cell.type === 'date' ? (
                        formatDate(get(row, cell.data))
                      ) : cell.id === 'project_name' ? (
                        <Link
                          color={row.archived ? 'textSecondary' : 'initial'}
                          component={RouterLink}
                          to={'/dashboard/' + row.project_id}
                          state={{
                            totalTcin: row?.project_stats?.tcin_count || 50,
                          }}
                        >
                          {get(row, cell.data)}
                        </Link>
                      ) : (
                        get(row, cell.data)
                      )}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))
        ) : (
          <TableRow>
            <TableCell colSpan={24} align="center">
              No Projects
            </TableCell>
          </TableRow>
        )
      ) : (
        [...Array(10)].map((k, i) => (
          <TableRow key={i}>
            <TableCell key={i} style={{ left: 0, paddingTop: 0 }}>
              <Skeleton />
            </TableCell>
            {projectHeaders.map((cell) => {
              return (
                <TableCell key={i + '-' + cell.id}>
                  <Skeleton />
                </TableCell>
              )
            })}
          </TableRow>
        ))
      )}
    </TableBody>
  )
})

const ProjectTable = React.memo((props) => {
  return (
    <TableContainer
      style={{
        height: isSafari() ? 'calc(100vh - 280px)' : 'calc(100vh - 260px)',
      }}
    >
      <Table stickyHeader>
        <CustomTableHead isMyProjects={props.isMyProjects} />
        <CustomTableBody
          page={props.page}
          rowsPerPage={props.rowsPerPage}
          searchText={props.searchText}
          isMyProjects={props.isMyProjects}
        />
      </Table>
    </TableContainer>
  )
})

export default ProjectTable
