import {
  TOGGLE_DOWNLOAD_MANAGER,
  SET_INPROGRESS_DOWNLOADS,
  SET_SUCCESS_DOWNLOADS,
  SET_FILES_FOR_DOWNLOADS,
  SET_FAILED_DOWNLOADS,
  CLEAR_FAILED_DOWNLOADS,
  RETRY_FAILED_DOWNLOADS,
  CLEAR_FILE_FOR_DOWNLOADS,
  SET_DOWNLOAD_FLAG,
} from './actionType'

export const uploadState = {
  inProgress: {},
  filesForDownloads: {},
  successFull: {},
  error: {},
  showDownloadManager: false,
  isFileSet: false,
}

export default function downloads(state = uploadState, action = {}) {
  switch (action.type) {
    case TOGGLE_DOWNLOAD_MANAGER:
      return {
        ...state,
        showDownloadManager: !state.showDownloadManager,
      }
    case SET_DOWNLOAD_FLAG:
      return {
        ...state,
        isFileSet: false,
      }
    case CLEAR_FAILED_DOWNLOADS: {
      const errorForDownloadsClone = { ...state.error }
      action.payload.forEach((dat, key) => {
        delete errorForDownloadsClone[dat]
      })
      return {
        ...state,
        error: errorForDownloadsClone,
      }
    }
    case CLEAR_FILE_FOR_DOWNLOADS: {
      const filesForDownloadsClone = { ...state.filesForDownloads }
      delete filesForDownloadsClone[action.payload]

      return {
        ...state,
        filesForDownloadsClone: filesForDownloadsClone,
      }
    }
    case RETRY_FAILED_DOWNLOADS: {
      const errorForDownloadsClone = { ...state.error }
      const downloadRetryFiles = {}
      action.payload.forEach((dat, key) => {
        downloadRetryFiles[dat] = errorForDownloadsClone[dat]
        delete errorForDownloadsClone[dat]
      })
      return {
        ...state,
        error: errorForDownloadsClone,
        isFileSet: true,
        filesForDownloads: {
          ...state.filesForDownloads,
          ...downloadRetryFiles,
        },
      }
    }

    case SET_INPROGRESS_DOWNLOADS: {
      const filesForDownloadsClone = { ...state.filesForDownloads }
      delete filesForDownloadsClone[action.payload.id]
      return {
        ...state,
        filesForDownloads: filesForDownloadsClone,
        inProgress: {
          ...state.inProgress,
          [action.payload.id]: action.payload,
        },
      }
    }

    case SET_FILES_FOR_DOWNLOADS:
      return {
        ...state,
        isFileSet: true,
        showDownloadManager: true,
        filesForDownloads: { ...state.filesForDownloads, ...action.payload },
      }
    case SET_SUCCESS_DOWNLOADS: {
      const inProgessFilesClone = { ...state.inProgress }
      delete inProgessFilesClone[action.payload.id]
      return {
        ...state,
        inProgress: inProgessFilesClone,
        successFull: {
          ...state.successFull,
          [action.payload.id]: action.payload,
        },
      }
    }
    case SET_FAILED_DOWNLOADS: {
      const inProgessFilesClone = { ...state.inProgress }
      delete inProgessFilesClone[action.payload.id]
      return {
        ...state,
        inProgress: inProgessFilesClone,
        error: {
          ...state.error,
          [action.payload.id]: action.payload,
        },
      }
    }
    default:
      return state
  }
}
