import React from 'react'
import { TableCell, TableRow } from '@mui/material'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  padding_left_right_null: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
  iconWithDesc: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function UploadSuccessTable({ successFiles }) {
  const classes = useStyles()
  return (
    <>
      {Object.keys(successFiles).map((key, i) => (
        <TableRow key={successFiles[key].name + i}>
          <TableCell
            className={classes.padding_left_right_null}
            align="left"
            padding="none"
            component="th"
            scope="row"
          >
            {successFiles[key].name}
          </TableCell>
          <TableCell className={classes.padding_left_right_null} align="left">
            <span className={classes.iconWithDesc}>
              <CheckCircleOutlinedIcon
                style={{ color: '#00A324', marginRight: '5px' }}
              />
              {successFiles[key].uploadStatus}
            </span>
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}
