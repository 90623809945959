import { cloneDeep, isEmpty } from 'lodash'
import { imageDownloadHost } from '../constants/common'

let inProgressDownloadCount = 0
let remainingAssets = []

export const handleDownloadJpegs = async ({
  filesList = [],
  progressNotificationCallback = () => {},
  errorNotificationCallback = () => {},
  checkDownloadProgress = () => {},
  downloadFinished = () => {},
} = {}) => {
  if (!isEmpty(filesList)) {
    remainingAssets = cloneDeep(filesList)
    progressNotificationCallback()
  }

  if (remainingAssets.length > 0 && inProgressDownloadCount < 10) {
    for (let i = 0; i < remainingAssets.length; i++) {
      const downloadAssetDetails = remainingAssets[i]
      inProgressDownloadCount++
      let imgUrl =
        downloadAssetDetails?.support_image_url ||
        downloadAssetDetails?.originalImageUrl ||
        downloadAssetDetails?.image_url

      if (imgUrl) {
        const { pathname = '' } = new URL(imgUrl)
        downloadAssetNew(
          `${imageDownloadHost}${pathname}?${Date.now()}`,
          downloadAssetDetails.support_image_name ||
            downloadAssetDetails.fileName ||
            downloadAssetDetails.file_name,
          errorNotificationCallback,
          checkDownloadProgress,
          downloadFinished
        )
        remainingAssets.splice(i, 1)
        if (inProgressDownloadCount > 10) return true
      }
    }
  } else {
    downloadFinished()
  }
}

export const downloadAssetNew = async (
  filePath = '',
  fileName = '',
  errorNotificationCallback = () => {},
  checkDownloadProgress = () => {},
  downloadFinished = () => {}
) => {
  let blobResponse
  try {
    const response = await downloadBulkFileAPICall(filePath, fileName)
    if (response.ok) {
      blobResponse = await response.blob()
    } else {
      inProgressDownloadCount = inProgressDownloadCount - 1
      errorNotificationCallback(fileName)
    }
  } catch (e) {
    inProgressDownloadCount = inProgressDownloadCount - 1
    handleDownloadJpegs({ filesList: [], downloadFinished })
    errorNotificationCallback(fileName)
  }
  if (blobResponse) {
    downloadImageFromUrl(blobResponse, fileName, () => {
      inProgressDownloadCount = inProgressDownloadCount - 1
      checkDownloadProgress(fileName)
      handleDownloadJpegs({ filesList: [], downloadFinished })
    })
  } else {
    handleDownloadJpegs({ filesList: [], downloadFinished })
  }
}

export const getAmzDate = (dateStr) => {
  var chars = [':', '-']
  for (var i = 0; i < chars.length; i++) {
    while (dateStr.indexOf(chars[i]) !== -1) {
      dateStr = dateStr.replace(chars[i], '')
    }
  }
  dateStr = dateStr.split('.')[0] + 'Z'
  return dateStr
}

export const downloadBulkFileAPICall = (
  downloadFilePath = '',
  fileName = ''
) => {
  var amzDate = getAmzDate(new Date().toISOString())

  return fetch(downloadFilePath, {
    method: 'GET',
    headers: { 'X-Amz-Date': amzDate },
  })
}

export const downloadImageFromUrl = (
  response = {},
  downloadFileName = '',
  callback = () => {}
) => {
  const { data = {}, headers: { type = '' } = {} } = response
  const downloadUrl = window.URL.createObjectURL(response)
  const downloadLink = document.createElement('a')
  downloadLink.href = downloadUrl
  downloadLink.setAttribute('download', downloadFileName)
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
  URL.revokeObjectURL(downloadUrl)
  callback()
}

export const downloadPreviewImage = async (filePath = '', fileName = '') => {
  try {
    const image = await fetch(filePath)
    const file = fileName || filePath.split('/').pop()
    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)
    const link = document.createElement('a')
    link.href = imageURL
    link.download = file
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch {
    alert(
      'One or more JPEG files might not be accessible for this tcin. Please contact Photobridge support.'
    )
  }
}
