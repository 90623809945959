import { GET_ALL_STYLEGUIDES, DELETE_STYLEGUIDES } from './actionType'

const getStylesGuidesState = {
  allStyleGuides: [],
  Response: '',
}

export default function styleGuideReducer(
  state = getStylesGuidesState,
  action
) {
  switch (action.type) {
    case GET_ALL_STYLEGUIDES:
      return {
        ...state,
        allStyleGuides: [...action.data],
      }
    case DELETE_STYLEGUIDES:
      return {
        ...state,
      }
    default:
      return state
  }
}
