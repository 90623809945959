export default class TreeNode {
  constructor(data) {
    this.info = data
    this.filter = false
    this.expanded = false // true
    this.tcin = data === 'root' ? 'root' : data?.tcin || 'NA'
    this.childrenData = [] // map [1,2,3,4] // Actual Data
    this.parentsData = [] // map []
    this.children = [] // [1, 2] /Partial
    this.parents = [] // []
    this.init(data)
  }

  init(data) {
    let info = data?.item_info
    if (info !== undefined) {
      if (info?.child_details) {
        this.childrenData.push(...info.child_details)
      }

      if (info?.parent_tcins !== '') {
        this.parentsData.push(...info.parent_tcins.split(','))
      }
    }
  }

  // replace(parent, child){
  //   let i = this.children.indexOf(child.tcin)
  //   this.children[i] = parent.tcin
  //   parent.addParent(this)

  // }

  removeChild(node) {
    let newchildren = this.children.filter((o) => o !== node.tcin)
    this.children = newchildren
  }

  addChild(node) {
    if (!this.children.includes(node.tcin)) {
      this.children.push(node.tcin)
      node.addParent(this)
    }
  }

  removeParent(node) {
    let newparents = this.parents.filter((o) => o !== node.tcin)
    this.parents = newparents
  }

  addParent(node) {
    if (!this.parents.includes(node.tcin)) {
      this.parents.push(node.tcin)
      node.addChild(this)
    }
  }
}
