export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS'
export const GET_SUMMARY_SUCCESS = 'GET_SUMMARY_SUCCESS'
export const GET_TCIN_STYLEGUIDES_SUCCESS = 'GET_TCIN_STYLEGUIDES_SUCCESS'
export const GET_ALL_STYLEGUIDES_SUCCESS = 'GET_ALL_STYLEGUIDES_SUCCESS'
export const ADD_STYLEGUIDE_SUCCESS = 'ADD_STYLEGUIDE_SUCCESS'
export const GET_TCIN_IMAGES_SUCCESS = 'GET_TCIN_IMAGES_SUCCESS'

export const GET_TCIN_HISTORY = 'GET_TCIN_HISTORY'
export const GET_TCIN_HISTORY_FULFILLED = 'GET_TCIN_HISTORY_FULFILLED'
export const GET_TCIN_HISTORY_PENDING = 'GET_TCIN_HISTORY_PENDING'
export const GET_TCIN_HISTORY_REJECTED = 'GET_TCIN_HISTORY_REJECTED'

export const PREVIEW_API_CALL = 'PREVIEW_API_CALL'
export const PREVIEW_API_CALL_FULFILLED = 'PREVIEW_API_CALL_FULFILLED'
export const PREVIEW_API_CALL_PENDING = 'PREVIEW_API_CALL_PENDING'
export const PREVIEW_API_CALL_REJECTED = 'PREVIEW_API_CALL_REJECTED'

export const UPDATE_IMAGE_DETAILS = 'UPDATE_IMAGE_DETAILS'
export const UPDATE_IMAGE_DETAILS_FULFILLED = 'UPDATE_IMAGE_DETAILS_FULFILLED'
export const UPDATE_IMAGE_DETAILS_PENDING = 'UPDATE_IMAGE_DETAILS_PENDING'
export const UPDATE_IMAGE_DETAILS_REJECTED = 'UPDATE_IMAGE_DETAILS_REJECTED'
