import {
  SUBMIT_SUPPORT_REQUEST,
  SUPPORT_REQUEST_ERROR,
  ADD_ATTACHMENT_JIRA,
} from './actionType'

const supportResultState = {
  support: '',
  status: '',
  attachmentResponse: '',
}

export default function supportReducer(state = supportResultState, action) {
  switch (action.type) {
    case SUBMIT_SUPPORT_REQUEST:
      return {
        ...state,
        status: 'success',
        support: action.data,
      }
    case SUPPORT_REQUEST_ERROR:
      return {
        ...state,
        status: 'Error',
      }
    case ADD_ATTACHMENT_JIRA:
      return {
        ...state,
        attachmentResponse: { ...action.data },
      }
    default:
      return state
  }
}
