import { SET_BREADCRUMBS, APPEND_BREADCRUMBS } from './actionTypes'

export const setBreadcrumbs = (customPath, pathname) => {
  return {
    type: SET_BREADCRUMBS,
    payload: { customPath, pathname },
  }
}

export const appendBreadcrumbs = (obj) => {
  return {
    type: APPEND_BREADCRUMBS,
    payload: obj,
  }
}
