import { SHOW_NOTIFICATION } from './actionType'

export const initialState = {
  isShown: false,
  message: '',
  duration: '5000',
  variant: '',
  isCopy: false,
  copyText: '',
}

export default function notificationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_NOTIFICATION: {
      const { payload } = action
      const { isShown, message, duration, variant, isCopy, copyText } = payload
      return {
        ...state,
        isShown,
        message,
        duration,
        variant,
        isCopy,
        copyText,
      }
    }
    default:
      return state
  }
}
