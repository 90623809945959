import { cloneDeep } from 'lodash'

export const createFilterData = (data, oldFilters) => {
  let filters
  if (oldFilters) {
    filters = cloneDeep(oldFilters)
  } else {
    filters = {
      class: {
        label: 'Class',
        isApplied: false,
        userSelected: [],
        values: {},
      },
      department: {
        label: 'Department',
        isApplied: false,
        userSelected: [],
        values: {},
      },
      brand: {
        label: 'Brand',
        isApplied: false,
        userSelected: [],
        values: {},
      },
      photoStudio: {
        label: 'Photo Studio',
        isApplied: false,
        userSelected: [],
        values: {},
      },
      photographer: {
        label: 'Photographer',
        isApplied: false,
        userSelected: [],
        values: {},
      },
      launchDate: {
        label: 'Launch Date',
        isApplied: false,
        userSelected: [],
        values: {},
      },
      photoShotDate: {
        label: 'Photo Shot Date',
        isApplied: false,
        userSelected: [],
        values: {},
      },
      photoSourceDate: {
        label: 'Photo Source Date',
        isApplied: false,
        userSelected: [],
        values: {},
      },
    }
  }

  data.forEach((o) => {
    let department = filters['department']['values']
    let classInfo = filters['class']['values']
    let brand = filters['brand']['values']
    let photoStudio = filters['photoStudio']['values']
    let photographer = filters['photographer']['values']
    let launchDate = filters['launchDate']['values']
    let photoShotDate = filters['photoShotDate']['values']
    let photoSourceDate = filters['photoSourceDate']['values']

    //item.department
    let depKey = o.item_info?.department_name
      ? `${o.item_info?.department_id} - ${o.item_info?.department_name}`
      : 'Not Applicable'
    department[depKey]
      ? department[depKey].push(o.tcin)
      : (department[depKey] = [o.tcin])

    //item.class
    let classKey = o.item_info?.class_name
      ? `${o.item_info?.class_id} - ${o.item_info?.class_name}`
      : 'Not Applicable'
    classInfo[classKey]
      ? classInfo[classKey].push(o.tcin)
      : (classInfo[classKey] = [o.tcin])

    //item.brand
    let brandKey = o.item_info?.brands
      ? `${o.item_info?.brands}`
      : 'Not Applicable'
    brand[brandKey]
      ? brand[brandKey].push(o.tcin)
      : (brand[brandKey] = [o.tcin])

    // main.photoStudio
    let photoStudioKey =
      o.image_information?.main?.metadata?.photo_studio || 'Not applicable'
    photoStudio[photoStudioKey]
      ? photoStudio[photoStudioKey].push(o.tcin)
      : (photoStudio[photoStudioKey] = [o.tcin])

    // main.photographer
    let photographerKey =
      o.image_information?.main?.metadata?.photographer || 'Not applicable'
    photographer[photographerKey]
      ? photographer[photographerKey].push(o.tcin)
      : (photographer[photographerKey] = [o.tcin])

    let launchDateKey = o.item_info?.launch_date_time || 'Not applicable'
    launchDate[launchDateKey]
      ? launchDate[launchDateKey].push(o.tcin)
      : (launchDate[launchDateKey] = [o.tcin])

    // main.photoShotDate
    let photoShotDateKey =
      o.image_information?.main?.metadata?.image_created_dt || 'Not applicable'
    photoShotDate[photoShotDateKey]
      ? photoShotDate[photoShotDateKey].push(o.tcin)
      : (photoShotDate[photoShotDateKey] = [o.tcin])

    let photoSourceDateKey =
      o.image_information?.main?.metadata?.date_ingested || 'Not applicable'
    photoSourceDate[photoSourceDateKey]
      ? photoSourceDate[photoSourceDateKey].push(o.tcin)
      : (photoSourceDate[photoSourceDateKey] = [o.tcin])

    o.image_information?.alt?.forEach((p) => {
      // alt.photoStudio
      let photoStudioAltKey = p?.metadata?.photo_studio || 'Not applicable'
      photoStudio[photoStudioAltKey]
        ? photoStudio[photoStudioAltKey].push(o.tcin)
        : (photoStudio[photoStudioAltKey] = [o.tcin])

      // alt.photographer
      let photographerAltKey = p?.metadata?.photographer || 'Not applicable'
      photographer[photographerAltKey]
        ? photographer[photographerAltKey].push(o.tcin)
        : (photographer[photographerAltKey] = [o.tcin])

      // alt.photoShotDate
      let photoShotDateAltKey =
        p?.metadata?.image_created_dt || 'Not applicable'
      photoShotDate[photoShotDateAltKey]
        ? photoShotDate[photoShotDateAltKey].push(o.tcin)
        : (photoShotDate[photoShotDateAltKey] = [o.tcin])

      let photoSourceDateAltKey = p?.metadata?.date_ingested || 'Not applicable'
      photoSourceDate[photoSourceDateAltKey]
        ? photoSourceDate[photoSourceDateAltKey].push(o.tcin)
        : (photoSourceDate[photoSourceDateAltKey] = [o.tcin])
    })
    //TODO: if null values required, also populate when alt images are null
  })

  return filters
}
export const isDeepEmpty = (obj) => {
  for (let key in obj) {
    if (obj[key] instanceof Object === true) {
      if (isDeepEmpty(obj[key]) === false) return false
    } else {
      if (obj[key].length !== 0) return false
    }
  }
  return true
}
export const isSafari = () => {
  const platformExpression = /Mac|iPhone|iPod|iPad/i
  const rejectedExpression = /Chrome|Android|CriOS|FxiOS|EdgiOS/i
  const expectedExpression = /Safari/i

  const agent = navigator.userAgent
  if (rejectedExpression.test(agent)) {
    return false
  }
  return platformExpression.test(agent) && expectedExpression.test(agent)
}
export const createWorkFlowFilterData = (data = {}, oldFilters) => {
  let filters = {}
  if (oldFilters) {
    filters = cloneDeep(oldFilters)
  } else {
    filters = {
      assignees: {
        label: 'Assignees',
        isApplied: false,
        userSelected: [],
        values: [],
      },
      qa_assignees: {
        label: 'QA Assignees',
        isApplied: false,
        userSelected: [],
        values: [],
      },
      class_names: {
        label: 'Class Names',
        isApplied: false,
        userSelected: [],
        values: [],
      },
      photo_studios: {
        label: 'Studios',
        isApplied: false,
        userSelected: [],
        values: [],
      },
      department_names: {
        label: 'Department Names',
        isApplied: false,
        userSelected: [],
        values: [],
      },
      launch_dates: {
        label: 'Launch Date',
        isApplied: false,
        userSelected: [],
        values: [],
      },
      photographers: {
        label: 'Photographers',
        isApplied: false,
        userSelected: [],
        values: [],
      },
      projects: {
        label: 'Projects',
        isApplied: false,
        userSelected: [],
        values: [],
      },
      tag_names: {
        label: 'Tag Names',
        isApplied: false,
        userSelected: [],
        values: [],
      },
      tbays: {
        label: 'Tbays',
        isApplied: false,
        userSelected: [],
        values: [],
      },
    }
  }

  Object.keys(data).forEach((key) => {
    if (filters[key]) {
      if ((data[key] && data[key].length) || key === 'launch_dates') {
        filters[key]['values'] = data[key]
      } else {
        delete filters[key]
      }
    }
  })
  return filters
}
