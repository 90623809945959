import ErrorDownloadstable from './ErrorDownloadstable'
import InprogessDownloadsTable from './InprogessDownloadsTable'
import SuccessfulDownloadsTable from './SuccessfulDownloadsTable'
import DownloadManger from './DownloadManger'

DownloadManger.ErrorDownloadstable = ErrorDownloadstable
DownloadManger.InprogessDownloadsTable = InprogessDownloadsTable
DownloadManger.SuccessfulDownloadsTable = SuccessfulDownloadsTable

export default DownloadManger
