import {
  TableHead,
  TableRow,
  Checkbox,
  TableSortLabel,
  TableCell,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { CustomTableContext } from './index'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { findIndex, unionBy } from 'lodash'

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  sortIconRoot: {
    '&:focus': {
      color: 'unset',
    },
  },
  sortIcon: {
    opacity: 1,
  },
  pinned: {
    left: 74,
    zIndex: 5,
    position: 'sticky',
  },
  checkboxPinned: {
    left: 0,
    zIndex: 5,
    position: 'sticky',
  },
  moreInfoPinned: {
    right: 0,
    zIndex: 5,
    position: 'sticky',
  },
}))

export default function CustomTableHead(props) {
  const { tState, tMethods } = React.useContext(CustomTableContext)
  const {
    uniqueIdName = '',
    tableName = '',
    tabName = '',
    uploadsInProgress = {},
  } = props
  const classes = useStyles()
  const { selected = [], data = [], workflowSelected = {} } = tState
  const selection = workflowSelected[tabName] || []

  const onSelectAllClick = (event) => {
    let newSelected = []
    if (event.target.checked) {
      if (tableName === 'workflow') {
        const filteredData = data.filter(
          (i) => i?.imageStatus !== 'Archived' && !uploadsInProgress[i.fileName]
        )
        newSelected = unionBy([...selection, ...filteredData], uniqueIdName)
        tMethods.workFlowCheckboxHandler(newSelected, tabName)
      } else {
        newSelected = unionBy([...selected, ...data], uniqueIdName)
        tMethods.checkboxHandler(newSelected)
      }
    } else {
      if (tableName === 'workflow') {
        newSelected = selection.filter(
          (i) =>
            findIndex(data, (obj) => {
              return obj[uniqueIdName] === i[uniqueIdName]
            }) === -1
        )
        tMethods.workFlowCheckboxHandler(newSelected, tabName)
      } else {
        newSelected = selected.filter(
          (i) =>
            findIndex(data, (obj) => {
              return obj[uniqueIdName] === i[uniqueIdName]
            }) === -1
        )
        tMethods.checkboxHandler(newSelected)
      }
    }
  }
  const sortHandler = (event, property) => {
    const isAsc = tState.orderBy === property && tState.order === 'asc'
    tMethods.orderHandler(isAsc ? 'desc' : 'asc')
    tMethods.orderByHandler(property)
  }
  const isAllSelected =
    tableName === 'workflow'
      ? data.length > 0 &&
        data.every(
          (elem) =>
            findIndex(selection, (obj) => {
              return obj[uniqueIdName] === elem[uniqueIdName]
            }) !== -1
        )
      : data.length > 0 &&
        data.every(
          (elem) =>
            findIndex(selected, (obj) => {
              return obj[uniqueIdName] === elem[uniqueIdName]
            }) !== -1
        )

  return (
    <TableHead>
      <TableRow>
        {props.checkBox && (
          <TableCell className={classes.checkboxPinned}>
            <Checkbox
              indeterminate={
                tableName === 'workflow'
                  ? (tState.workflowSelected[tabName] || []).length > 0 &&
                    !isAllSelected
                  : tState.selected.length > 0 && !isAllSelected
              }
              checked={isAllSelected}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all tcins' }}
            />
          </TableCell>
        )}
        {(tState.colMap || []).map((col, i) => {
          const orderByProperty =
            !col?.sort_disabled && (col.sort_name || col.orderBy || col.field)
          return (
            (typeof col.showColumn === 'function'
              ? col.showColumn(tState.customizeCol)
              : tState.customizeCol[col.id]?.selected) && (
              <TableCell
                key={i}
                className={col.pinned && classes.pinned}
                style={{ left: 0, paddingTop: 0 }}
              >
                {orderByProperty ? (
                  <TableSortLabel
                    active={tState.orderBy === orderByProperty}
                    direction={
                      tState.orderBy === orderByProperty ? tState.order : 'asc'
                    }
                    onClick={(e) => sortHandler(e, orderByProperty)}
                    classes={{
                      root: classes.sortIconRoot,
                      icon: classes.sortIcon,
                    }}
                    IconComponent={
                      tState.orderBy !== orderByProperty
                        ? UnfoldMoreIcon
                        : ArrowDownwardIcon
                    }
                  >
                    {col.headerName}
                    {tState.orderBy === orderByProperty ? (
                      <span className={classes.visuallyHidden}>
                        {tState.order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  col.headerName
                )}
              </TableCell>
            )
          )
        })}
        <TableCell className={classes.moreInfoPinned}></TableCell>
      </TableRow>
    </TableHead>
  )
}
