import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Routes, Route } from 'react-router-dom'
import { withAuth } from '@praxis/component-auth'
import { Grid, withStyles, Typography } from '@material-ui/core'
import Header from '../components/Header'
import SideNav from '../components/SideNav'
import navRoutes from '../routes'
import { setBreadcrumbs } from '../store/Layout/actionCreator'
import { bindActionCreators } from 'redux'
import HomeIcon from '@material-ui/icons/Home'
import Cookies from 'js-cookie'
import apiConfig from '../apiConfig'
import List from '@mui/material/List'
import NavigationItems from './listItems'
import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import Notification from '../components/Notification'
import UnAuthorized from '../containers/UnAuthorized'
import { intersection } from 'lodash'
import { ProtectedElement } from '@praxis/component-auth'
import withRouter from '../customHoc/WithRouter'

const drawerWidth = 240

const openedMixin = (theme) => ({
  backgroundColor: 'white',
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme) => ({
  backgroundColor: 'red',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const mainDiv = {
  margin: '1rem',
  padding: '0 1rem',
  display: 'flex',
  flexGrow: '0',
  width: 'auto',
  height: 'auto',
  minHeight: '500px',
}
const mainContainer = {
  width: '100%',
  height: '100%',
}

const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,
})

const getDomainName = (hostName) => {
  return hostName.substring(
    hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1
  )
}

const ExpandableDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

class Main extends Component {
  constructor(props) {
    super(props)
    this.state = {
      curPageIcon: <HomeIcon />,
      curPageName: 'Home',
      open: false,
    }
  }
  componentDidMount() {
    const { auth = {} } = this.props
    const { isAuthenticated, login = () => {} } = auth
    const { session } = auth
    const { hasExpired } = session || {}
    if (hasExpired || !isAuthenticated()) {
      login({})
    } else {
      this.authChecks()
    }
  }

  componentDidUpdate() {
    this.authChecks()
  }

  authChecks = () => {
    const {
      auth: { login = () => {} },
      saveUser = () => {},
    } = this.props
    const isSessionActive = this.isSessionActive()
    if (!isSessionActive) {
      login({})
    } else {
      saveUser()
    }
  }

  isSessionActive = () => {
    const { auth } = this.props
    const { session = {} } = auth
    const { userInfo = {} } = session || {}
    const { lanId = '' } = userInfo || {}
    const domain = getDomainName(window.location.hostname)
    if (domain.endsWith('partnersonline.com')) {
      const ssoSessionInfo = Cookies.get('SSOSESSIONINFO')
      let logOutUrl = '/'
      if (ssoSessionInfo) {
        const ssoSessionObj = JSON.parse(window.atob(ssoSessionInfo))
        if (
          ssoSessionObj.user &&
          ssoSessionObj.login_status &&
          lanId &&
          ssoSessionObj.user === lanId
        ) {
          return true
        }
      } else {
        logOutUrl = apiConfig.auth.logoutUrl + '?TARGET=' + window.location.href
      }
      localStorage.removeItem('access_token')
      localStorage.removeItem('id_token')
      setTimeout(() => {
        window.location.href = logOutUrl
      }, 3000)
      return false
    }
    return true
  }

  toggleSideNav = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    this.toggleDrawer()
  }

  getDisplayUserName = () => {
    const { auth = {}, classes = {} } = this.props
    const { isAuthorized = () => {}, session = {} } = auth
    const { userInfo = {} } = session || {}
    const { fullName: userFullName = '', company: userCompany = '' } =
      userInfo || {}
    const domain = getDomainName(window.location.hostname)
    let returnStr = userFullName
    if (
      isAuthorized(apiConfig.adGroups) &&
      domain.endsWith('partnersonline.com')
    ) {
      returnStr = (
        <div className={classes.displayName}>
          <div>{userFullName}</div>
          <Typography variant="subtitle2">{userCompany}</Typography>
        </div>
      )
    }
    return returnStr
  }

  handleClick = (data) => (event) => {
    this.setState({ curPageName: data, open: false })
  }

  toggleDrawer = () => {
    this.setState({ open: !this.state.open })
  }

  render() {
    const { auth, location = {} } = this.props
    const { pathname = '' } = location
    const { isAuthorized, logout } = auth
    const { curPageName = 'Home' } = this.state
    const isAdvancedPreview = pathname === '/advancedPreview'
    return isAuthorized() ? (
      <>
        <Notification />
        <Grid container>
          {!isAdvancedPreview ? (
            <>
              {' '}
              <Grid item>
                <ExpandableDrawer
                  variant="permanent"
                  sx={{
                    '& .MuiDrawer-paper': {
                      backgroundColor: '#cc0000',
                      boxSizing: 'border-box',
                      height: '-webkit-fill-available',
                    },
                  }}
                >
                  <List component="nav">
                    {
                      <NavigationItems
                        handleClickItem={this.handleClick}
                        currentPage={curPageName}
                        open={this.state.open}
                      />
                    }
                  </List>
                </ExpandableDrawer>
              </Grid>
              <Grid item>
                {this.state.open && (
                  <SideNav
                    sideNav={this.state.open}
                    toggleSideNav={this.toggleDrawer}
                  />
                )}
              </Grid>
              <Grid item>
                {isAuthorized() && (
                  <Header
                    curPageName={curPageName}
                    isAuthorized={isAuthorized}
                    logout={logout}
                    fullName={this.getDisplayUserName()}
                  />
                )}
              </Grid>
            </>
          ) : (
            <></>
          )}
          <Grid item style={{ width: '100%' }}>
            <div
              {...(!isAdvancedPreview && {
                style: { paddingLeft: 64, paddingTop: 70 },
              })}
            >
              <main style={mainContainer}>
                <Routes>
                  {navRoutes
                    .filter((r) => r.ShouldInclude)
                    .map((prop, key) => {
                      return (
                        <Route
                          key={key}
                          exact={prop.exact}
                          path={prop.path + '*'}
                          element={
                            <ProtectedElement
                              allowed={prop.adGroups}
                              unauthorizedRoute="/unauthorized"
                            >
                              <prop.component
                                style={{ indicatorColor: 'primary' }}
                                customPath={`/${prop.displayName}`}
                                path={prop.path}
                              />
                            </ProtectedElement>
                          }
                        />
                      )
                    })}
                </Routes>
              </main>
            </div>
          </Grid>
        </Grid>
      </>
    ) : (
      ''
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setBreadcrumbs,
    },
    dispatch
  )
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withAuth()(withStyles(styles)(Main)))
)
