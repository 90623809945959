import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import { Drawer, Badge } from '@material-ui/core'
import TabData from './TabData'
import DataTable from './DataTable'
import Filters from './Filters'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import axios from 'axios'
import apiConfig from '../../apiConfig'
import { Typography } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import FilterListIcon from '@material-ui/icons/FilterList'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { compact } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { setSortedTcinData } from '../../store/TcinInfo/actionCreator'
import { setBreadcrumbs } from '../../store/Layout/actionCreator'
import { useLocation } from 'react-router-dom'
import { withAuth } from '@praxis/component-auth'
import { isSafari } from '../../helpers/filter'

const tcinPhotoTheme = createMuiTheme({})
const { getTcinInfo } = apiConfig

const useStyles = makeStyles((theme) => ({
  searchInput: {
    textOverflow: 'ellipsis',
  },
  searchPaper: {
    display: 'inline-flex',
    width: 'calc(100vw - 65px)',
    height: 80,
    alignItems: 'start',
    left: 1,
    flexDirection: 'column',
    position: 'sticky',
    paddingBlockStart: isSafari() ? '31px' : '23px',
    paddingBlockEnd: isSafari() ? '20px' : '10px',
    marginTop: isSafari() ? '12px' : '2px',
    '& > :first-child': {
      marginRight: 10,
    },
  },
  searchBar: {
    margin: '5px 20px 5px 30px',
    width: '35%',
  },
  drawer: {
    minWidth: 1100,
    width: '75%',
    overflowY: 'unset',
  },
  filterDrawer: {
    width: '540px',
    padding: 24,
  },
  landingPage: {
    display: 'flex',
    height: '40%',
    width: 'calc(100vw - 65px)',
    margin: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > :nth-child(n)': {
      margin: '6px 0',
    },
    '& > :last-child': {
      marginTop: 36,
    },
  },
  landingPageIcons: {
    display: 'flex',
    width: '30%',
    justifyContent: 'space-evenly',
    '& > *': {
      fontSize: 50,
    },
  },
  progress: {
    display: 'flex',
    height: '45%',
    '& > :nth-child(n)': {
      margin: 'auto',
    },
  },
  filtersSelected: {
    position: 'absolute',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
  },
}))

const TcinInfo = (props) => {
  const { accessToken = '', studio = '' } = props
  const classes = useStyles()
  const [openDialog, setOpenDialog] = React.useState(false)
  const [openFilters, setOpenFilters] = React.useState(false)
  const [tabIndex, setTabIndex] = React.useState({
    category: 'item',
    rowIndex: 0,
  })
  const [searchValue, setSearchValue] = React.useState('')
  const [tcinsData, setTcinsData] = React.useState([])
  const [showContent, setShowContent] = React.useState('blank')
  const [showProgress, setShowProgress] = React.useState(false)
  const [maxAltImageCount, setMaxAltImageCount] = React.useState(0)
  const [errorType, setErrorType] = React.useState('none')
  const [errorsList, setErrorsList] = React.useState([])
  const [errorsCopied, setErrorsCopied] = React.useState(false)
  const [distinctTcinCount, setDistinctTcinCount] = React.useState(0)
  const isFilterEnabled = useSelector(
    (state) => state.tcinPhotoInfoReducer.treeList.length > 0
  )

  const { isPol = false } = apiConfig

  const dispatch = useDispatch()
  const { pathname } = useLocation()

  React.useEffect(() => {
    dispatch(setBreadcrumbs(props.customPath, pathname))
    setErrorType('none')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getTcinData = (tcins) => {
    let request = {
      id_list: tcins,
    }

    let url = isPol
      ? `${getTcinInfo}?studio=${
          studio.indexOf(',') > -1
            ? studio.substring(0, studio.indexOf(','))
            : studio
        }`
      : getTcinInfo

    axios
      .post(url, request, {
        params: {
          key: apiConfig.apiKey,
        },
        headers: { Authorization: accessToken },
      })
      .then((res) => {
        setMaxAltImageCount(res.data.max_alt_count || 0)
        dispatch(setSortedTcinData(res.data.tcin_info))
        // setShowProgress(false)
        if (res.data.tcin_info.length > 0) {
          setShowContent('table')
        } else {
          setShowContent('blank')
        }
        if (res.data.errors.length > 0) {
          setErrorType('invalid')
          setErrorsList(res.data.errors)
        } else {
          setErrorType('success')
        }
      })
      .catch((e) => {
        setErrorType('error')
        setShowContent('blank')
      })
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }
  const handleDialogOpen = (e, index, category) => {
    setOpenDialog(true)
    setTabIndex((state) => ({ ...state, category: category, rowIndex: index }))
  }

  const handleFilterClose = () => {
    setOpenFilters(false)
  }

  const handleFilterOpen = (e, index, category) => {
    setOpenDialog(true)
  }

  const searchTcin = (event) => {
    let tcinsList = event.target.value.split(/[^\d\w-]+/g)
    setSearchValue(tcinsList.join(', ').trim())
    setErrorType('none')
  }

  const searchKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleSearch = () => {
    setShowContent('progress')
    var list = [...new Set(compact(searchValue.split(/, |,/)))]
    setDistinctTcinCount(list.length)
    getTcinData(list)
  }

  const copyToClipboard = () => {
    setErrorsCopied(true)
  }

  const mainContent = () => {
    switch (showContent) {
      case 'blank':
        return (
          <div style={{ marginTop: 150, textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom>
              WELCOME to Photobridge
            </Typography>
          </div>
        )
      case 'progress':
        return (
          <div className={classes.progress}>
            <CircularProgress variant="indeterminate" />
          </div>
        )
      case 'table':
        return (
          <DataTable
            // tcinsData={tcinsData}
            handleDialogOpen={handleDialogOpen}
            altImageCount={maxAltImageCount}
          />
        )
      default:
        return <div />
    }
  }

  const helperText = () => {
    switch (errorType) {
      case 'none':
        return (
          <Typography
            style={{ marginLeft: 48 }}
            // style={{ marginLeft: 48, fontSize: '0.855rem' }}
            variant="caption"
          >
            You can type or copy and paste any number of values from 1 up to
            2000
          </Typography>
        )
      case 'success':
        return (
          <Typography style={{ marginLeft: 48 }} variant="caption">
            You entered {distinctTcinCount} distinct values
          </Typography>
        )
      case 'invalid':
        return (
          <Typography style={{ marginLeft: 48 }} variant="caption">
            You entered {distinctTcinCount} distinct values. {errorsList.length}{' '}
            values entered are invalid. You can copy the invalid values to a
            clipboard
            <CopyToClipboard
              text={errorsList.join('\n')}
              onClick={copyToClipboard}
            >
              <Button
                color="primary"
                startIcon={<FileCopyOutlinedIcon />}
                style={{
                  marginLeft: 2,
                  padding: '0px 8px',
                  letterSpacing: 'normal',
                  float: 'right',
                  alignItems: 'flex-start',
                }}
              >
                COPY
              </Button>
            </CopyToClipboard>
          </Typography>
        )
      case 'error':
        return (
          <Typography
            style={{ color: 'red', marginLeft: 48 }}
            variant="caption"
          >
            Error While Searching for TCINs. Please try again. If issue
            persists, please contact support.
          </Typography>
        )
      default:
        return (
          <Typography style={{ marginLeft: 48 }} variant="caption">
            You entered {distinctTcinCount} distinct values
          </Typography>
        )
    }
  }

  const filterCount = useSelector((state) => {
    const {
      tcinPhotoInfoReducer: { filters: filterData = {} },
    } = state
    return Object.keys(filterData || {}).filter((key) => {
      const filter = filterData[key] || {}
      const { userSelected = [] } = filter
      return userSelected.length > 0
    }).length
  })

  return (
    <ThemeProvider theme={tcinPhotoTheme}>
      <Paper className={classes.searchPaper} elevation={3} square>
        <div
          style={{
            width: '100%',
            display: 'inline-flex',
            alignItems: 'start',
            marginTop: isSafari() ? '21px' : '0px',
          }}
        >
          <TextField
            type="search"
            id="search-tcin"
            autoFocus
            label="Enter TCIN or DPCI values"
            placeholder="Eg: 12345678,123-45-6789"
            variant="outlined"
            size="small"
            value={searchValue}
            onChange={searchTcin}
            // onPaste={searchTcin}
            onKeyPress={searchKeyPress}
            InputProps={{
              classes: { input: classes.searchInput },
            }}
            className={classes.searchBar}
          />

          <div className={classes.button}>
            <Button
              variant="contained"
              disabled={searchValue.length === 0}
              color="primary"
              className={classes.button}
              // style={{ marginTop: 6, padding: '5px 16px' }}
              startIcon={<SearchIcon />}
              onClick={handleSearch}
            >
              Search
            </Button>
            <Badge badgeContent={filterCount} color="secondary">
              <Button
                variant="outlined"
                disabled={!isFilterEnabled}
                startIcon={<FilterListIcon />}
                className={classes.button}
                onClick={() => setOpenFilters(true)}
              >
                <span className={classes.filtersSelected}></span>
                Filters
              </Button>
            </Badge>
          </div>

          <Drawer
            classes={{
              paper: classes.filterDrawer,
            }}
            anchor="right"
            open={openFilters}
            onClose={handleFilterClose}
          >
            <Filters closeDialog={handleFilterClose} />
          </Drawer>
        </div>
        {helperText()}
      </Paper>
      {mainContent()}

      <Drawer
        classes={{
          paper: classes.drawer,
        }}
        anchor="right"
        open={openDialog}
        onClose={handleDialogClose}
      >
        <TabData closeDialog={handleDialogClose} tabIndex={tabIndex} />
      </Drawer>
    </ThemeProvider>
  )
}

const mapAuthToProps = (auth) => {
  return {
    accessToken: auth.session?.userInfo?.accessToken,
    studio: auth.session?.userInfo?.company,
  }
}

export default withAuth(mapAuthToProps)(TcinInfo)
