import React from 'react'
import {
  Button,
  Divider,
  Drawer,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import Dropzone from 'react-dropzone'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import { toggleUploadManager } from '../../store/uploads/actionCreator'
import { useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    padding: '0 !important',
    marginRight: '-9px !important',
  },
  drawerHeader: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '23px',
    margin: 0,
    flex: '1',
    color: '#333333',
  },
  inputRoot: {
    width: '100%',
  },
  filterDrawer: {
    height: 'calc(100% - 48px) !important',
    width: '60%',
    maxWidth: '1000px',
    padding: 24,
  },
  uploadDesc: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dropzoneContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  uploadButton: {
    color: '#366CD9',
    cursor: 'pointer',
    marginLeft: '4px',
  },
  drawerHeaderDivider: {
    marginTop: '24px !important',
    width: `calc(100% + 48px)`,
    marginLeft: ' -24px !important',
    '&:before': {
      content: "''",
      width: '24px',
      height: '1px',
      display: 'block',
      position: 'absolute',
      background: 'rgba(0, 0, 0, 0.12)',
      marginLeft: '-24px',
    },
  },
  dropzone: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    border: '1px solid #C4C4C4',
    backgroundColor: '#F9F9F9;',
    borderRadius: '4px',
    color: '#bdbdbd',
    outline: 'none',
  },
  stausButton: {
    height: '40px',
    width: '120px',
    fontSize: '14px',
    fontWeight: '500',
  },
  inlineButton: {
    width: '80px !important',
  },
  primaryButton: {
    backgroundColor: '#366CD9',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: 'rgb(17, 82, 147) !important',
      color: '#ffffff !important',
    },
    '&:focus': {
      backgroundColor: 'rgb(17, 82, 147) !important',
      color: '#ffffff !important',
    },
  },
  formRoot: {
    width: '100% !important',
    paddingLeft: '40px !important',
  },
  tagsLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    color: '#333333',
    marginTop: '24px',
    display: 'block',
  },
  padding_left_right_null: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
  iconWithDesc: {
    display: 'flex',
    alignItems: 'center',
  },
  uploadTableColumn: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#666666',
  },
  uploadTableBody: {
    '& th, td': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#333333',
    },
  },
  uploadAction: {
    marginTop: '12px',
    marginBottom: '24px',
  },
  actionsDrawer: {
    // width: 'auto',
    backgroundColor: '#366CD9 !important',
    color: '#FFFFFF !important',
    padding: '10px 32px 10px 32px',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'row !important',
    left: ' 39.3% !important',
  },
  footerButton: {
    color: '#FFFFFF !important',
    fontSize: '16px !important',
    marginRight: '32px !important',
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    textTransform: 'capitalize !important',
  },
}))

export default function UploadManger({
  isDropzoneActive = false,
  checkIsUploadEnable = false,
  onDragEnter = () => {},
  onDragLeave = () => {},
  onDrop = () => {},
  successTable,
  failedTable,
  uploadProgressTable,
  droppedFilesIngestion,
  droppedFilesWorkflow,
  newImageFilesTable,
  droppedFiles = [],
  uploadsInProgress = {},
  failedFiles = {},
  successFiles = {},
  onDiscard = () => {},
  onUpload = () => {},
  page = '',
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const dropzoneRef = React.useRef(null)
  const showUploadManager = useSelector((state) => {
    const { uploadsReducer: { showUploadManager = false } = {} } = state
    return showUploadManager
  })

  const openSystemDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open()
    }
  }
  return (
    <Drawer
      classes={{
        paper: classes.filterDrawer,
      }}
      ModalProps={{
        keepMounted: true,
      }}
      anchor="right"
      variant="temporary"
      open={showUploadManager}
      onClose={() => dispatch(toggleUploadManager())}
    >
      <div
        style={{
          height: '100%',
        }}
      >
        <Grid
          wrap={'nowrap'}
          container
          justify={'space-between'}
          alignItems={'center'}
        >
          <h4 className={classes.drawerHeader}>Upload Manager</h4>
          <IconButton
            aria-label="close"
            onClick={() => dispatch(toggleUploadManager())}
            className={classes.closeIcon}
          >
            <CloseIcon style={{ fontSize: 24 }} />
          </IconButton>{' '}
        </Grid>
        <Divider className={classes.drawerHeaderDivider} />
        <div
          style={{
            flexGrow: 1,
            margin: '16px 0',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            paddingTop: 24,
          }}
        >
          <Dropzone
            ref={dropzoneRef}
            noClick
            onDrop={onDrop}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            noKeyboard
            accept={'.tif , .tiff'}
          >
            {({ getRootProps, getInputProps }) => (
              <div className={classes.dropzoneContainer}>
                <div
                  {...getRootProps({
                    className: `${classes.dropzone}`,
                  })}
                >
                  <input {...getInputProps()} />
                  {isDropzoneActive ? (
                    <p>Drop here to upload</p>
                  ) : (
                    <p className={classes.uploadDesc}>
                      <CloudUploadOutlinedIcon
                        style={{ marginRight: '4px' }}
                        fontSize="24"
                      />
                      To upload drag files here or{' '}
                      <span
                        onClick={openSystemDialog}
                        className={classes.uploadButton}
                      >
                        SELECT FILES
                      </span>
                    </p>
                  )}
                </div>
              </div>
            )}
          </Dropzone>

          <TableContainer style={{ minHeight: '180px' }}>
            {page === 'workflow' ? (
              <>
                <Table sx={{ width: '100%' }} aria-label="simple table">
                  <TableHead style={{ background: '#EAF5F9' }}>
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        align="left"
                        className={`${classes.padding_left_right_null} ${classes.uploadTableColumn}`}
                        style={{ width: '30%' }}
                      >
                        Images for Upload ({Object.keys(droppedFiles).length})
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{droppedFilesWorkflow}</TableBody>
                </Table>
                {newImageFilesTable}
              </>
            ) : (
              <Table sx={{ width: '100%' }} aria-label="simple table">
                <TableHead style={{ background: '#EAF5F9' }}>
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      align="left"
                      className={`${classes.padding_left_right_null} ${classes.uploadTableColumn}`}
                      //   style={{ width: '30%' }}
                    >
                      Images for Upload ({Object.keys(droppedFiles).length})
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{droppedFilesIngestion}</TableBody>
              </Table>
            )}
          </TableContainer>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
            className={classes.uploadAction}
          >
            <Button
              variant="outlined"
              className={classes.stausButton}
              onClick={onDiscard}
            >
              Discard
            </Button>
            <Button
              variant="contained"
              className={`${classes.stausButton} ${classes.primaryButton}`}
              disabled={!checkIsUploadEnable}
              onClick={onUpload}
            >
              Upload
            </Button>
          </Stack>
        </div>
        <div>
          {Object.keys(uploadsInProgress).length > 0 ||
          Object.keys(successFiles).length > 0 ||
          Object.keys(failedFiles).length > 0 ? (
            <>
              <FormLabel className={classes.tagsLabel}>
                {Object.keys(uploadsInProgress).length +
                  Object.keys(successFiles).length}{' '}
                Uploaded Images
              </FormLabel>
              <TableContainer sx={{ marginBottom: '25px' }}>
                <Table sx={{ width: '100%' }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        className={`${classes.padding_left_right_null} ${classes.uploadTableColumn}`}
                        style={{ width: '30%' }}
                      >
                        Image Name
                      </TableCell>
                      <TableCell
                        align="left"
                        className={`${classes.padding_left_right_null} ${classes.uploadTableColumn}`}
                        style={{ width: '50%' }}
                      >
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.uploadTableBody}>
                    {successTable}
                    {failedTable}
                    {uploadProgressTable}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : null}
        </div>
      </div>
    </Drawer>
  )
}
