import React from 'react'
import { Button, Divider, Drawer, Grid, IconButton, Stack } from '@mui/material'
import CloseIcon from '@material-ui/icons/Close'
import GetAppIcon from '@material-ui/icons/GetApp'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import SuccessfulDownloadsTable from './SuccessfulDownloadsTable'
import InprogressDownloadsTable from './InprogessDownloadsTable'
import ErrorDownloadstable from './ErrorDownloadstable'
import {
  setInprogressDownloads,
  toggleDownloadManager,
  setErrorDownloads,
  setSuccessDownloads,
  clearErrorDownloads,
  clearFileToBeDownload,
  setDownloadFlag,
  retryErrorDownloads,
} from '../../store/Downloads/actionCreator'
import { useRef } from 'react'
import {
  downloadBulkFileAPICall,
  downloadImageFromUrl,
} from '../../helpers/imageDownloadHelper'
import { imageDownloadHost } from '../../constants/common'

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    padding: '0 !important',
    marginRight: '-9px !important',
  },
  drawerHeader: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '23px',
    margin: 0,
    flex: '1',
    color: '#333333',
  },
  iconWithDesc: {
    display: 'flex',
    alignItems: 'center',
  },
  accordianSummaryRoot: {
    padding: '0 24px !important',
    margin: 0,
  },
  accordianSummaryContent: {
    '& tbody tr td:first-child, thead tr th:first-child': {
      padding: '0 24px !important',
    },
  },
  expandedIcon: {
    transform: 'rotate(180deg) !important',
  },
  inputRoot: {
    width: '100%',
  },
  filterDrawer: {
    height: 'calc(100% - 48px) !important',
    width: '40%',
    maxWidth: '1000px',
    padding: 24,
  },

  nonExpandedIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  drawerHeaderDivider: {
    marginTop: '24px !important',
    width: `calc(100% + 48px)`,
    marginLeft: ' -24px !important',
    '&:before': {
      content: "''",
      width: '24px',
      height: '1px',
      display: 'block',
      position: 'absolute',
      background: 'rgba(0, 0, 0, 0.12)',
      marginLeft: '-24px',
    },
  },
  buttons: {
    display: 'flex',
    gap: 24,
  },
  retryButton: {
    '&:disabled': {
      cursor: 'not-allowed !important',
      color: 'rgba(0, 0, 0, 0.26) !important',
      border: '1px solid rgba(0, 0, 0, 0.12) !important',
    },
  },
  discardButton: {
    color: '#666666 !important',
    border: '1px solid #C4C4C4 !important',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
      textDecoration: 'none',
    },
  },
  stausButton: {
    height: '40px',
    width: '120px',
    fontSize: '14px !important',
    fontWeight: '500',
  },
  primaryButton: {
    backgroundColor: '#366CD9 !important',
    color: '#ffffff !important',
    '&:hover': {
      backgroundColor: 'rgb(17, 82, 147) !important',
      color: '#ffffff !important',
    },
    '&:focus': {
      backgroundColor: 'rgb(17, 82, 147) !important',
      color: '#ffffff !important',
    },
  },
}))

export default function DownloadManger() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const inProgressCount = useRef(0)
  const allFilesForDownloads = useRef({})
  const [errorFileSelection, setErrorFileSelection] = React.useState([])

  const [
    showDownloadManager,
    inProgressDownloads,
    errorDownloads,
    filesForDownloads,
    successFullDownloads,
    isFileSetForDownloads,
  ] = useSelector((state) => {
    const {
      downloadsReducer: {
        showDownloadManager = false,
        inProgress = {},
        error = {},
        filesForDownloads = {},
        successFull = {},
        isFileSet = false,
      } = {},
    } = state
    return [
      showDownloadManager,
      inProgress,
      error,
      filesForDownloads,
      successFull,
      isFileSet,
    ]
  })
  allFilesForDownloads.current = filesForDownloads

  React.useEffect(() => {
    isFileSetForDownloads && handleDownloadJpegs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFileSetForDownloads])

  const filesForDownloadsArray = Object.keys(allFilesForDownloads.current)

  const onSelectAllClick = (event) => {
    if (event.target.checked) {
      const selectionKeys = Object.keys(errorDownloads)
      setErrorFileSelection(selectionKeys)
    } else {
      setErrorFileSelection([])
    }
  }

  const onErrorDownloadSelect = (isChecked, id) => {
    let newSelected = [...errorFileSelection]
    if (isChecked) {
      newSelected.push(id)
      setErrorFileSelection(newSelected)
    } else {
      const selectedRow = newSelected.filter((e) => e !== id)
      setErrorFileSelection(selectedRow)
    }
  }

  const handleDownloadJpegs = async () => {
    if (filesForDownloadsArray.length > 0 && inProgressCount.current < 10) {
      dispatch(setDownloadFlag())
      filesForDownloadsArray.forEach((dat, key) => {
        const downloadAssetDetails = allFilesForDownloads.current[dat]
        let imgUrl =
          downloadAssetDetails?.support_image_url ||
          downloadAssetDetails?.originalImageUrl ||
          downloadAssetDetails?.image_url ||
          downloadAssetDetails?.url
        if (imgUrl && !inProgressDownloads[dat]) {
          const { pathname = '' } = new URL(imgUrl)
          inProgressCount.current = inProgressCount.current + 1
          downloadAssetNew(
            `${imageDownloadHost}${pathname}?${Date.now()}`,
            downloadAssetDetails.support_image_name ||
              downloadAssetDetails.fileName ||
              downloadAssetDetails.file_name,
            () => {
              //   inProgressCount.current = inProgressCount.current - 1
              dispatch(
                setErrorDownloads({
                  ...downloadAssetDetails,
                  id: dat,
                  timeStamp: Date.now(),
                })
              )
            },
            { downloadAssetDetails, id: dat }
          )
          dispatch(
            setInprogressDownloads({
              ...downloadAssetDetails,
              id: dat,
              timeStamp: Date.now(),
            })
          )
          if (inProgressCount.current > 10) return true
        } else {
          dispatch(clearFileToBeDownload(dat))
        }
      })
    }
  }

  const downloadAssetNew = async (
    filePath = '',
    fileName = '',
    errorNotificationCallback = () => {},
    fileDetails = {}
  ) => {
    let blobResponse
    try {
      const response = await downloadBulkFileAPICall(filePath, fileName)
      inProgressCount.current = inProgressCount.current - 1
      if (response.ok) {
        blobResponse = await response.blob()
      } else {
        errorNotificationCallback()
      }
    } catch (e) {
      inProgressCount.current = inProgressCount.current - 1
      handleDownloadJpegs()
      errorNotificationCallback()
    }
    if (blobResponse) {
      downloadImageFromUrl(blobResponse, fileName, () => {
        dispatch(
          setSuccessDownloads({
            ...fileDetails,
            timeStamp: Date.now(),
          })
        )
        handleDownloadJpegs()
      })
    } else {
      //   inProgressCount.current = inProgressCount.current - 1
      handleDownloadJpegs()
      //   errorNotificationCallback()
    }
  }

  const [expandedSection, setExpandedSection] = React.useState({
    errorTable: true,
    successTable: true,
    progressTable: true,
  })

  return (
    <Drawer
      classes={{
        paper: classes.filterDrawer,
      }}
      ModalProps={{
        keepMounted: true,
      }}
      anchor="right"
      variant="temporary"
      open={showDownloadManager}
      onClose={() => dispatch(toggleDownloadManager())}
    >
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid
          wrap={'nowrap'}
          container
          justify={'space-between'}
          alignItems={'center'}
        >
          <h4 className={classes.drawerHeader}>Download Manager</h4>
          <IconButton
            aria-label="close"
            onClick={() => dispatch(toggleDownloadManager())}
            className={classes.closeIcon}
          >
            <CloseIcon style={{ fontSize: 24 }} />
          </IconButton>{' '}
        </Grid>
        <Divider className={classes.drawerHeaderDivider} />
        <div
          style={{
            height: '100%',
            flexGrow: 1,
            margin: '0 -24px',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ErrorDownloadstable
            filesSelected={errorFileSelection}
            onSelectAll={onSelectAllClick}
            onSelect={(isChecked, id) => {
              onErrorDownloadSelect(isChecked, id)
            }}
            errorFiles={errorDownloads}
            classes={classes}
            isExpanded={expandedSection.errorTable}
            setExpanded={() =>
              setExpandedSection({
                ...expandedSection,
                errorTable: !expandedSection.errorTable,
              })
            }
          />
          <InprogressDownloadsTable
            inProgressFiles={inProgressDownloads}
            classes={classes}
            isExpanded={expandedSection.progressTable}
            setExpanded={() =>
              setExpandedSection({
                ...expandedSection,
                progressTable: !expandedSection.progressTable,
              })
            }
          />
          <SuccessfulDownloadsTable
            successfulFiles={successFullDownloads}
            classes={classes}
            isExpanded={expandedSection.successTable}
            setExpanded={() =>
              setExpandedSection({
                ...expandedSection,
                successTable: !expandedSection.successTable,
              })
            }
          />
        </div>
        <div>
          <div className={classes.buttons}>
            <Stack direction="row" spacing={2}>
              <Button
                disabled={!errorFileSelection.length}
                variant="outlined"
                className={classes.discardButton}
                startIcon={<CloseIcon />}
                onClick={() => {
                  setErrorFileSelection([])
                  dispatch(clearErrorDownloads(errorFileSelection))
                }}
              >
                Discard
              </Button>
              <Button
                disabled={!errorFileSelection.length}
                className={classes.retryButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  setErrorFileSelection([])
                  dispatch(retryErrorDownloads(errorFileSelection))
                }}
                startIcon={<GetAppIcon />}
              >
                Retry
              </Button>
            </Stack>
          </div>
        </div>
      </div>
    </Drawer>
  )
}
