import {
  ENLARGE_IMAGE,
  SET_FILTER_DATA,
  GROUPING_TREE_DATA,
  SELECTED_TCIN_DATA,
  SELECTED_FILTER_TCINS,
  DATA_FILTERED,
  TREE_LIST_DATA,
  USER_FILTERS,
} from './actionTypes'
import { cloneDeep, compact, uniq } from 'lodash'
import { createFilterData } from '../../helpers/filter'
import TreeNode from '../../containers/TcinInfo/TreeNode'
import apiConfig from '../../apiConfig'
import axios from 'axios'
import { useSelector } from 'react-redux'
const {
  getAllProjectsApi,
  editProjectApi,
  deleteProjectsApi,
  projectpdfreport,
  generateShotSheets,
  generateContactSheets,
  generateContactSheetsFinal,
  generateContactSheetsStores,
  generateCompactShotSheets,
} = apiConfig

export const setSortedTcinData = (data, appendingData = false) => {
  return (dispatch, getState) => {
    const state = getState().tcinPhotoInfoReducer
    let newFilters
    // let newData
    // let treeData
    if (appendingData) {
      newFilters = createFilterData(data, state.filters)
      dispatch(updateUserFilters(newFilters))
      dispatch(appendToTree(data))
    } else {
      newFilters = createFilterData(data)
      dispatch(createTree(data))
    }

    // let list = []
    // convertTreeToList(treeData, list)
    dispatch(setFilters(newFilters))
    dispatch(setSelected([]))
    // dispatch(setTcinData(list))
    // dispatch(setTreeData(treeData))
    // dispatch(setTreeList(data))
  }
}

export const createTree = (data) => async (dispatch, getState) => {
  let newRoot = new TreeNode('root')
  let newMap = new Map()

  data.forEach((o) => {
    let child = new TreeNode(o)
    newRoot.addChild(child)
    newMap.set(child.tcin, child)
  })

  let newTree = {
    treeNode: newRoot,
    treeMap: newMap,
  }

  dispatch(setTreeData(newTree))
  let newList = []
  createRowList(newTree.treeNode, newTree.treeMap, 0, newList)
  dispatch(setTreeList(newList))
}

export const appendToTree = (data) => async (dispatch, getState) => {
  let treeData = cloneDeep(getState().tcinPhotoInfoReducer.tree)
  let selected = cloneDeep(getState().tcinPhotoInfoReducer.selected)
  let newRoot = treeData.treeNode
  let newMap = treeData.treeMap
  selected.forEach((o) => {
    let oNode = o

    oNode?.parentsData?.forEach((parent) => {
      if (!oNode.parents.some((t) => t === parent)) {
        let parentTcin = data.find((o) => o.tcin === parent)
        if (parentTcin) {
          let foundParent = newMap.get(parent)

          if (foundParent === undefined) {
            let newParent = new TreeNode(parentTcin)
            newRoot.addChild(newParent)
            newParent.addChild(oNode)
            newMap.set(newParent.tcin, newParent)
            newMap.set(oNode.tcin, oNode)
          } else {
            foundParent.addChild(oNode)
            newMap.set(foundParent.tcin, foundParent)
            newMap.set(oNode.tcin, oNode)
          }
          newRoot.removeChild(oNode)
        }
      }
    })

    oNode?.childrenData?.forEach((child) => {
      if (!oNode.children.some((t) => t === child)) {
        let childNode = data.find((o) => o.tcin === child)
        if (childNode) {
          let foundChild = newMap.get(child)
          if (foundChild === undefined) {
            let newChild = new TreeNode(childNode)
            oNode.addChild(newChild)
            newMap.set(newChild.tcin, newChild)
            newMap.set(oNode.tcin, oNode)
          } else {
            oNode.addChild(foundChild)
            newMap.set(oNode.tcin, oNode)
            newMap.set(foundChild.tcin, foundChild)
            if (foundChild.parents.some((t) => t === 'root')) {
              newRoot.removeChild(foundChild)
            }
          }
        }
      }
    })
  })
  newMap.set(newRoot.tcin, newRoot)
  let newTree = {
    treeNode: newRoot,
    treeMap: newMap,
  }
  dispatch(setTreeData(newTree))
  let newList = []
  createRowList(newTree.treeNode, newTree.treeMap, 0, newList)
  dispatch(setTreeList(newList))
}

const createRowList = (tree, treeMap, level = 0, list) => {
  if (tree.children.length !== 0) {
    tree.children.forEach((tcin, i) => {
      let node = treeMap.get(tcin)
      let obj = {
        level: level,
        node: node,
      }
      list.push(obj)
      createRowList(node, treeMap, level + 1, list)
    })
  }
}

const updateUserFilters = (globalFilter) => async (dispatch, getState) => {
  // let newFilterData = cloneDeep(filterData)
  let filterWithTcins = {}
  Object.entries(getState().tcinPhotoInfoReducer.userFilters).forEach(
    ([key, value]) => {
      // newFilterData[key].userSelected = value
      if (value.length > 0) {
        filterWithTcins[key] = []
        Object.entries(globalFilter[key].values).forEach(([k, v]) => {
          if (value.includes(k)) {
            filterWithTcins[key].push(...v)
          }
        })
      }
    }
  )
  let tcins = Object.values(filterWithTcins)
  let filtered = compact(
    uniq(
      tcins.reduce(
        (previousValue, currentValue) =>
          currentValue.filter((c) => previousValue.includes(c)),
        tcins[0] ?? []
      )
    )
  )
  dispatch(
    setFilteredTCINList([
      ...getState().tcinPhotoInfoReducer.filteredTCINS,
      ...filtered,
    ])
  )
}

export const generatePDFAPI =
  (tcins, accessToken, progressCallBack = 0) =>
  async (dispatch, getState) => {
    const url = `${generateShotSheets}`
    axios
      .post(
        url,
        { tcins: tcins },
        {
          params: {
            key: apiConfig.apiKey,
          },
          headers: { Authorization: accessToken },
          responseType: 'blob',
          onDownloadProgress(progressEvent) {
            let length = progressEvent.lengthComputable
              ? progressEvent.total
              : progressEvent.target.getResponseHeader('Content-Length')
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / length
            )
            progressCallBack(percentCompleted)
          },
        },
        {}
      )
      .then((response) => {
        var file = new Blob([response.data], {
          type: response.headers['content-type'],
        })
        var fileURL = URL.createObjectURL(file)
        window.open(fileURL)
      })
      .catch((error) => {})
  }

export const generateContactSheetPDFAPI =
  (tcins, accessToken, progressCallBack = 0) =>
  async (dispatch, getState) => {
    const url = `${generateContactSheets}`
    axios
      .post(
        url,
        { tcins: tcins },
        {
          params: {
            key: apiConfig.apiKey,
          },
          headers: { Authorization: accessToken },
          responseType: 'blob',
          onDownloadProgress(progressEvent) {
            let length = progressEvent.lengthComputable
              ? progressEvent.total
              : progressEvent.target.getResponseHeader('Content-Length')
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / length
            )
            progressCallBack(percentCompleted)
          },
        },
        {}
      )
      .then((response) => {
        var file = new Blob([response.data], {
          type: response.headers['content-type'],
        })
        var fileURL = URL.createObjectURL(file)
        window.open(fileURL)
      })
      .catch((error) => {})
  }
export const generateContactSheetFinalPDFAPI =
  (tcins, accessToken, progressCallBack = 0, dpci = false) =>
  async (dispatch, getState) => {
    const url = `${generateContactSheetsFinal}`
    axios
      .post(
        url,
        { tcins: tcins, dpci: dpci },
        {
          params: {
            key: apiConfig.apiKey,
          },
          headers: { Authorization: accessToken },
          responseType: 'blob',
          onDownloadProgress(progressEvent) {
            let length = progressEvent.lengthComputable
              ? progressEvent.total
              : progressEvent.target.getResponseHeader('Content-Length')
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / length
            )
            progressCallBack(percentCompleted)
          },
        },
        {}
      )
      .then((response) => {
        var file = new Blob([response.data], {
          type: response.headers['content-type'],
        })
        var fileURL = URL.createObjectURL(file)
        window.open(fileURL)
      })
      .catch((error) => {})
  }

export const generateContactSheetStoresPDFAPI =
  (dpci, accessToken, progressCallBack = 0) =>
  async (dispatch, getState) => {
    const url = `${generateContactSheetsStores}`
    axios
      .post(
        url,
        { dpci: dpci },
        {
          params: {
            key: apiConfig.apiKey,
          },
          headers: { Authorization: accessToken },
          responseType: 'blob',
          onDownloadProgress(progressEvent) {
            let length = progressEvent.lengthComputable
              ? progressEvent.total
              : progressEvent.target.getResponseHeader('Content-Length')
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / length
            )
            progressCallBack(percentCompleted)
          },
        },
        {}
      )
      .then((response) => {
        var file = new Blob([response.data], {
          type: response.headers['content-type'],
        })
        var fileURL = URL.createObjectURL(file)
        window.open(fileURL)
      })
      .catch((error) => {})
  }

export const generateCompactShotSheetPDFAPI =
  (tcins, accessToken, progressCallBack = 0) =>
  async (dispatch, getState) => {
    const url = `${generateCompactShotSheets}`
    axios
      .post(
        url,
        { tcins: tcins },
        {
          params: {
            key: apiConfig.apiKey,
          },
          headers: { Authorization: accessToken },
          responseType: 'blob',
          onDownloadProgress(progressEvent) {
            let length = progressEvent.lengthComputable
              ? progressEvent.total
              : progressEvent.target.getResponseHeader('Content-Length')
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / length
            )
            progressCallBack(percentCompleted)
          },
        },
        {}
      )
      .then((response) => {
        var file = new Blob([response.data], {
          type: response.headers['content-type'],
        })
        var fileURL = URL.createObjectURL(file)
        window.open(fileURL)
      })
      .catch((error) => {})
  }

export function setFilters(data) {
  return {
    type: SET_FILTER_DATA,
    payload: data,
  }
}

function setTreeData(data) {
  return {
    type: GROUPING_TREE_DATA,
    payload: data,
  }
}

export function setTreeList(data) {
  return {
    type: TREE_LIST_DATA,
    payload: data,
  }
}

export function setFilteredTCINList(data) {
  return {
    type: SELECTED_FILTER_TCINS,
    payload: data,
  }
}

export function setSelected(data) {
  return {
    type: SELECTED_TCIN_DATA,
    payload: data,
  }
}
export function setDataFiltered(data) {
  return {
    type: DATA_FILTERED,
    payload: data,
  }
}
export function setUserFilters(data) {
  return {
    type: USER_FILTERS,
    payload: data,
  }
}

export function openZoomedImage(
  title,
  imageSrc,
  tableName = '',
  fileName = '',
  imageUrl = '',
  originalVersion = '',
  imageStatus = '',
  imageVersion = '',
  uploadedVersion = ''
) {
  let data = {
    open: true,
    title: title,
    src: imageSrc,
    fileName: fileName,
    imageUrl: imageUrl,
    tableName: tableName,
    originalVersion,
    imageStatus,
    imageVersion,
    uploadedVersion,
  }

  return {
    type: ENLARGE_IMAGE,
    payload: data,
  }
}

export function closeZoomedImage() {
  let data = {
    open: false,
    title: '',
    src: '',
    fileName: '',
    imageUrl: '',
  }
  return {
    type: ENLARGE_IMAGE,
    payload: data,
  }
}
