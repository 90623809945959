import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Grid,
  Button,
  TextField,
  Typography,
  FormControl,
  Divider,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { convertShortDate } from '../../helpers/dateHelper'
import axios from 'axios'
import { setBreadcrumbs } from '../../store/Layout/actionCreator'
import { useNavigate } from 'react-router-dom'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { Alert, AlertTitle } from '@material-ui/lab'
import { uniq, compact } from 'lodash'
import apiConfig, { appEnv } from '../../apiConfig'
import { withAuth } from '@praxis/component-auth'
import { producerADGroup } from '../../constants/common'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const { projectsApi, projectUsersApi } = apiConfig
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControl-root': {
      width: '80%',
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(-1),
    },
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  buttons: {
    marginLeft: theme.spacing(3),
    marginTop: '16px',
  },
  validateButton: {
    marginLeft: theme.spacing(2),
    color: '#366CD9',
    borderColor: '#366CD9',
  },

  alert: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  ownerDetails: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#333333',
  },
  inputFindProject: {
    transform: 'translate(14px, 13px) scale(1)',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#666666',
  },
  dropDownInput: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#666666',
  },
  adornedEnd: {
    paddingRight: 'unset',
    '& >button': {
      padding: '0',
      marginRight: '-5px',
    },
  },
  divider: {
    width: '100%',
    borderTop: '1px solid #DDDDDD',
    marginTop: '30px',
  },
  ccButton: {
    width: '114px',
    height: '36px',
    marginRight: '24px',
  },
  alertMessage: {
    width: '100%',
  },
  submitButton: {
    backgroundColor: '#366CD9',
    color: '#ffffff',
    '&:disabled': {
      color: '#000000',
    },
    '&:hover': {
      color: '#000000',
    },
  },
}))

const initialValues = {
  id: 0,
  name: '',
  pid: '',
  shotCount: '',
  dueDate: null,
  projectType: null,
  owners: [],
  viewers: [],
  tcins: '',
}

export const convertToEventParameters = (name, value) => ({
  target: {
    name,
    value,
  },
})

export const useForm = (initialValues, validateOnChange = false, validate) => {
  const [values, setValues] = React.useState(initialValues)
  const [errors, setErrors] = React.useState({})

  const handleInputChange = (event, newValue, fieldName) => {
    const name = event.target.name || fieldName
    const value = event.target.value || newValue
    if (name === 'tcins') {
      let tcinsList = value ? uniq(value.split(/[^\d\w]+/g)) : []
      setValues({ ...values, [name]: tcinsList.join(', ').trim() })
    } else {
      setValues({
        ...values,
        [name]: value,
      })
    }
    if (validateOnChange) {
      validate({ [name]: value })
    }
  }

  const resetForm = () => {
    setValues(initialValues)
    setErrors({})
  }

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  }
}

const CreateProject = (props) => {
  const { accessToken = '', lanId = '', fullName = '', memberOf = [] } = props
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(setBreadcrumbs(props.customPath, props.pathname))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pathname])

  const classes = useStyles()
  const theme = useTheme()

  let navigate = useNavigate()

  const validate = (fieldValues = values) => {
    let messages = { ...errors }
    if ('name' in fieldValues) {
      messages.name =
        fieldValues.name && fieldValues.name.trim() !== ''
          ? projects.includes(fieldValues.name.trim())
            ? 'The project name is already taken. Try with a Different One'
            : ''
          : 'Name cannot be empty'
    }
    if ('shotCount' in fieldValues) {
      messages.shotCount =
        fieldValues.shotCount && fieldValues.shotCount.trim() !== ''
          ? !isNaN(fieldValues.shotCount.trim()) &&
            fieldValues.shotCount.trim() > 0
            ? ''
            : 'Shot count should be 1 or more'
          : ''
    }
    setErrors({ ...messages })

    if (fieldValues === values) {
      return Object.values(messages).every((x) => x === '')
    }
  }

  const [invalidTcins, setInvalidTcins] = React.useState([])
  const [duplicateTcins, setDuplicateTcins] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const validateTcins = () => {
    if (values.tcins !== '') {
      let requestBody = {
        tcin_list:
          values.tcins !== ''
            ? [...new Set(compact(values.tcins.split(/, |,/)))]
            : [],
      }
      axios
        .post(`${projectsApi}/tcin_validations`, requestBody, {
          params: {
            key: apiConfig.apiKey,
          },
          headers: { Authorization: accessToken },
        })
        .then(
          (response) => {
            setInvalidTcins(response.data.invalid_tcinlist)
            setDuplicateTcins(response.data.duplicate_tcinlist)
            setLoading(false)
          },
          (error) => {
            window.alert(error)
          }
        )
    } else {
      setLoading(false)
    }
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate)

  React.useEffect(() => {
    const getProjects = async () => {
      const response = await fetch(
        projectsApi + `/project_names?key=${apiConfig.apiKey}`,
        {
          headers: { Authorization: accessToken },
        }
      )
      const body = await response.json()
      setProjects(Object.values(body))
    }
    const getOwners = async () => {
      const response = await fetch(
        projectUsersApi +
          '/ad_groups?ad_groups=APP-PTB-Producer,APP-PTB-ADMIN' +
          `?key=${apiConfig.apiKey}`,
        {
          headers: { Authorization: accessToken },
        }
      )
      const body = await response.json()
      setOwners(body)
    }
    getProjects()
    getOwners()

    const getViewers = async () => {
      const response = await fetch(
        projectUsersApi +
          '/ad_groups?ad_groups=APP-PTB-GeneralUser,APP-PTB-Photographer,APP-PTB-ReToucher,APP-PTB-ReTouching-QA,APP-PTB-ReTouching-Lead,APP-PTB-ArtDirectors,APP-PTB-Producer,APP-PTB-ADMIN' +
          `?key=${apiConfig.apiKey}`,
        {
          headers: { Authorization: accessToken },
        }
      )
      const body = await response.json()
      setViewers(body)
    }
    getProjects()
    getViewers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    validateTcins()
    if (validate()) {
      const requestBody = {
        project_name: values.name,
        sap_pid: values.pid,
        project_type: values.projectType,
        due_date: values.dueDate,
        shot_count: values.shotCount || null,
        users: owners.filter((u) =>
          values.owners.includes(u.first_name + ' ' + u.last_name)
        ),
        viewers: viewers.filter((u) =>
          values.viewers.includes(u.first_name + ' ' + u.last_name)
        ),
        tcins: values.tcins !== '' ? formatTcinArray(values.tcins) : [],
      }
      axios
        .post(projectsApi, requestBody, {
          params: {
            key: apiConfig.apiKey,
          },
          headers: {
            Authorization: accessToken,
            ...(appEnv === 'dev' && {
              'x-tgt-lanId': lanId,
            }),
          },
        })
        .then(
          (response) => {
            resetForm()
            navigate('/dashboard/' + response.data.project_id, {
              state: {
                totalTcin: 50,
              },
            })
          },
          (error) => {
            window.alert(error)
          }
        )
    }
  }

  const formatTcinArray = (tcins) => {
    const trimmedTcins = tcins.split(',').map((tcin) => tcin.trim())
    return trimmedTcins.filter((tcin) => tcin.length)
  }

  const [projects, setProjects] = React.useState([])
  const [owners, setOwners] = React.useState([])
  const [viewers, setViewers] = React.useState([])
  Object.entries(duplicateTcins).forEach(([key, value]) => {})

  const removeTcins = (tcins) => {
    setValues({
      ...values,
      tcins: values['tcins']
        .split(', ')
        .filter((t) => !tcins.includes(t))
        .join(', '),
    })
    setInvalidTcins(invalidTcins.filter((i) => !tcins.includes(i)))
    setDuplicateTcins(
      Object.keys(duplicateTcins)
        .filter((key) => !tcins.includes(key))
        .reduce((obj, key) => {
          obj[key] = duplicateTcins[key]
          return obj
        }, {})
    )
  }

  const onFocusIn = (e) => {
    setLoading(true)
  }

  const onFocusOut = (e) => {
    if (values.tcins === '') {
      setLoading(false)
    }
  }

  return (
    <form className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={6} lg={5}>
          <Grid container style={{ margin: 0, padding: 0 }}>
            <Typography
              variant="body1"
              style={{ margin: '40px 0 0 24px' }}
              className={classes.ownerDetails}
            >
              Being created by {fullName || 'you'} on{' '}
              {convertShortDate({ format: 'MMM DD YYYY' })}
            </Typography>

            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              style={{ marginTop: theme.spacing(5) }}
            >
              <TextField
                style={{
                  maxWidth: '360px',
                  marginBottom: 0,
                }}
                InputProps={{ style: { maxHeight: '40px' } }}
                InputLabelProps={{
                  classes: {
                    outlined: classes.inputFindProject,
                  },
                }}
                {...(errors.name && { error: true, helperText: errors.name })}
                variant="outlined"
                label="Project Name"
                value={values.name}
                onChange={handleInputChange}
                name="name"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              style={{ marginTop: theme.spacing(5) }}
            >
              <TextField
                style={{
                  maxWidth: '220px',
                  marginBottom: 0,
                }}
                InputProps={{ style: { maxHeight: '40px' } }}
                InputLabelProps={{
                  classes: {
                    outlined: classes.inputFindProject,
                  },
                }}
                variant="outlined"
                label="PID"
                value={values.pid}
                onChange={handleInputChange}
                name="pid"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              style={{ marginTop: theme.spacing(5) }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={{
                    maxWidth: '220px',
                    marginBottom: 0,
                  }}
                  InputProps={{
                    style: { maxHeight: '40px' },
                  }}
                  InputAdornmentProps={{
                    classes: { root: classes.adornedEnd },
                  }}
                  InputLabelProps={{
                    classes: {
                      outlined: classes.inputFindProject,
                    },
                  }}
                  {...(errors.dueDate && {
                    error: true,
                    helperText: 'Choose a date',
                  })}
                  label="Estimated Completion Date"
                  autoOk
                  format="MM/dd/yyyy"
                  variant="inline"
                  inputVariant="outlined"
                  onChange={(date) =>
                    handleInputChange(convertToEventParameters('dueDate', date))
                  }
                  value={values.dueDate}
                  name="dueDate"
                  disablePast
                ></KeyboardDatePicker>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              style={{ marginTop: theme.spacing(5) }}
            >
              {' '}
              <TextField
                {...(errors.shotCount && {
                  error: true,
                  helperText: errors.shotCount,
                })}
                style={{
                  maxWidth: '360px',
                  marginBottom: 0,
                }}
                InputProps={{ style: { maxHeight: '40px' } }}
                InputLabelProps={{
                  classes: {
                    outlined: classes.inputFindProject,
                  },
                }}
                name="shotCount"
                variant="outlined"
                label="Estimated #shots per TCIN"
                value={values.shotCount}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              style={{ marginTop: theme.spacing(5) }}
            >
              <Autocomplete
                defaultValue={values.owners}
                limitTags={2}
                multiple
                onChange={(e, newValue) =>
                  handleInputChange(e, newValue, 'owners')
                }
                options={owners.map((u) => u.first_name + ' ' + u.last_name)}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(option) => (
                  <Typography
                    style={{
                      fontWeight:
                        values.owners.indexOf(option) > -1 ? 500 : 400,
                    }}
                  >
                    {option}
                  </Typography>
                )}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{
                      maxWidth: '480px',
                      marginBottom: 0,
                    }}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                      classes: {
                        outlined: classes.dropDownInput,
                      },
                    }}
                    variant="outlined"
                    label="Add Owners"
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              style={{ marginTop: theme.spacing(5) }}
            >
              <Autocomplete
                defaultValue={values.viewers}
                limitTags={2}
                multiple
                onChange={(e, newValue) =>
                  handleInputChange(e, newValue, 'viewers')
                }
                options={viewers.map((u) => u.first_name + ' ' + u.last_name)}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                size="small"
                renderOption={(option) => (
                  <Typography
                    style={{
                      fontWeight:
                        values.viewers.indexOf(option) > -1 ? 500 : 400,
                    }}
                  >
                    {option}
                  </Typography>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{
                      maxWidth: '480px',
                      marginBottom: 0,
                    }}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                      classes: {
                        outlined: classes.dropDownInput,
                      },
                    }}
                    variant="outlined"
                    label="Add Followers"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: theme.spacing(6) }}
          item
          xs={12}
          sm={12}
          lg={7}
        >
          <Grid
            item
            xs={12}
            sm={12}
            lg={12}
            style={{ marginTop: theme.spacing(4) }}
          >
            <Typography
              variant="body1"
              style={{ margin: '40px 0 0 24px mx-2' }}
              value={values.projectType}
            >
              Project Type :
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="projectType"
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    value="special"
                    control={<Radio />}
                    label="Special"
                  />
                  <FormControlLabel
                    value="item"
                    control={<Radio />}
                    label="Item"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
            </Typography>
          </Grid>
          <Grid
            container
            alignItems={'center'}
            style={{ marginTop: theme.spacing(3) }}
          >
            <TextField
              variant="outlined"
              style={{
                maxWidth: '480px',
                margin: 0,
              }}
              InputProps={{ style: { maxHeight: '40px' } }}
              InputLabelProps={{
                classes: {
                  outlined: classes.inputFindProject,
                },
              }}
              label="Add TCINs"
              value={values.tcins}
              onChange={handleInputChange}
              name="tcins"
              onBlur={onFocusOut}
              onFocus={onFocusIn}
            />

            <Button
              onClick={(e) => validateTcins()}
              className={classes.validateButton}
              variant="outlined"
            >
              Validate
            </Button>
          </Grid>

          {invalidTcins.length > 0 && (
            <Alert
              severity="error"
              classes={{
                message: classes.alertMessage,
              }}
              style={{ width: '57%', marginTop: '12px' }}
            >
              <AlertTitle>Error - Invalid TCIN</AlertTitle>
              <div className={classes.alert}>
                <Typography
                  variant="body1"
                  style={{ marginLeft: theme.spacing(3) }}
                >
                  You have entered {invalidTcins.length} invalid Tcins.
                </Typography>
                <CopyToClipboard text={invalidTcins.join('\n')}>
                  <Button color="primary" startIcon={<FileCopyOutlinedIcon />}>
                    COPY
                  </Button>
                </CopyToClipboard>
                <IconButton
                  onClick={() => removeTcins(invalidTcins)}
                  aria-label="delete"
                  className={classes.margin}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            </Alert>
          )}
          {Object.keys(duplicateTcins).length > 0 && (
            <Alert
              severity="warning"
              classes={{
                message: classes.alertMessage,
              }}
              style={{ width: '75%', marginLeft: 22 }}
            >
              <AlertTitle>Warning - TCIN in multiple projects</AlertTitle>
              <div style={{ height: 400, overflow: 'auto' }}>
                {Object.keys(duplicateTcins).map((t) =>
                  duplicateTcins[t].map((value) => (
                    <div className={classes.alert}>
                      <Typography
                        variant="body1"
                        style={{ marginLeft: theme.spacing(3) }}
                      >
                        {t} is with {value.project_name} project
                      </Typography>
                      <IconButton
                        onClick={() => removeTcins([t])}
                        aria-label="delete"
                        className={classes.margin}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </div>
                  ))
                )}
              </div>
            </Alert>
          )}
        </Grid>
        <Divider className={classes.divider} />
        <div className={classes.buttons}>
          <Link to="/dashboard" style={{ textDecoration: 'none' }}>
            <Button
              className={classes.ccButton}
              variant="outlined"
              onClick={resetForm}
              size="medium"
            >
              Cancel
            </Button>
          </Link>
          <Button
            variant="contained"
            className={classes.submitButton}
            disabled={
              !(
                memberOf?.includes(producerADGroup.toUpperCase()) &&
                !apiConfig.isPol
              ) ||
              loading ||
              invalidTcins.length > 0 ||
              errors.name ||
              errors.shotDate
            }
            size="medium"
            onClick={handleSubmit}
          >
            Create
          </Button>
        </div>
      </Grid>
    </form>
  )
}

const mapAuthToProps = (auth) => {
  return {
    accessToken: auth.session?.userInfo?.accessToken,
    lanId: auth.session?.userInfo?.lanId,
    fullName: auth.session?.userInfo?.fullName,
    memberOf: auth.session?.userInfo?.memberOf,
  }
}

export default withAuth(mapAuthToProps)(CreateProject)
