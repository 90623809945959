/*
👋 “Hey” from the Praxis team
Praxis ships with support for modern browsers, but you may have users or external vendors that require IE11.
If that’s the case - uncomment the below imports.
Learn more about our the default browser support in the README.

import '@praxis/react-app-polyfill/ie11'
import '@praxis/react-app-polyfill/stable'
*/

import React from 'react'
import ReactDOM from 'react-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import 'typeface-roboto'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import muiTheme from './config/ThemeConfig'
import App from './App'
import configureStore from './store/configureStore'
import './styles/index.css'

const store = configureStore()
const rootEl = document.getElementById('root')

ReactDOM.render(
  <MuiThemeProvider theme={muiTheme}>
    <Provider store={store}>
      <App />
    </Provider>
  </MuiThemeProvider>,
  rootEl
)
if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    ReactDOM.render(
      <MuiThemeProvider theme={muiTheme}>
        <Provider store={store}>
          <NextApp />
        </Provider>
      </MuiThemeProvider>,
      rootEl
    )
  })
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
