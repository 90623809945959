export const columnMap = [
  {
    groupId: 'filename',
    custColName: 'Image File Name',
    custColType: 'string',
    selected: true,
    colId: 'filename',
    colName: 'Image File Name',
    colOrder: 0,
    sortable: true,
    data: 'filename',
    dataType: 'single',
    sortName: 'filename',
    dataField: 'file_name',
  },
  {
    groupId: 'status',
    custColName: 'Status',
    custColType: 'status',
    selected: true,
    colId: 'status',
    colName: 'Status',
    colOrder: 1,
    sortable: true,
    data: 'status',
    dataType: 'single',
    sortName: 'status',
    dataField: 'status',
  },
  {
    groupId: 'image',
    custColName: 'Image',
    custColType: 'image',
    selected: true,
    colId: 'image',
    colName: 'Image',
    colOrder: 2,
    sortable: false,
    data: 'thumbnail_url',
    dataType: 'image',
    dataField: 'thumbnail_url',
  },
  {
    groupId: 'tcin',
    custColName: 'TCIN',
    custColType: 'string',
    selected: true,
    colId: 'tcin',
    colName: 'TCIN',
    colOrder: 3,
    sortable: true,
    data: 'tcin',
    dataType: 'single',
    sortName: 'filename',
    dataField: 'file_name',
  },
  {
    groupId: 'position',
    custColName: 'Main/Alt Position',
    custColType: 'alt_position',
    selected: true,
    colId: 'position',
    colName: 'Main/Alt Position',
    colOrder: 4,
    sortable: true,
    data: 'position',
    dataType: 'single',
    sortName: 'alt_position',
    dataField: 'position',
  },
  {
    groupId: 'ingest_datetime',
    custColName: 'Ingest Date-Time',
    custColType: 'date',
    selected: true,
    colId: 'ingest_datetime',
    colName: 'Ingest Date-Time',
    colOrder: 5,
    sortable: true,
    data: 'ingest_datetime',
    dataType: 'single',
    sortName: 'dateIngested',
    dataField: 'date_ingested',
  },
  {
    groupId: 'version',
    custColName: 'Version',
    custColType: 'string',
    selected: true,
    colId: 'version',
    colName: 'Version',
    colOrder: 6,
    sortable: true,
    data: 'version',
    dataType: 'single',
    sortName: 'studio',
    dataField: 'version',
  },
  // {
  //   groupId: 'pid',
  //   custColName: 'PID',
  //   custColType: 'checkbox',
  //   selected: true,
  //   colId: 'pid',
  //   colName: 'PID',
  //   colOrder: 6,
  //   sortable: true,
  //   data: 'pid',
  //   dataType: 'single',
  // },
  {
    groupId: 'studio',
    custColName: 'Studio',
    custColType: 'string',
    selected: false,
    colId: 'studio',
    colName: `Studio`,
    colOrder: 7,
    sortable: true,
    data: 'studio',
    dataType: 'single',
    sortName: 'studio',
    dataField: 'studio',
  },
  {
    groupId: 'tbay',
    custColName: 'Tbay',
    custColType: 'string',
    selected: false,
    colId: 'tbay',
    colName: 'Tbay',
    colOrder: 8,
    sortable: true,
    data: 'tbay',
    dataType: 'single',
    sortName: 'tbay',
    dataField: 'tbay',
  },
  {
    groupId: 'shot_date',
    custColName: 'Photo Shot Date',
    custColType: 'date',
    selected: false,
    colId: 'shot_date',
    colName: 'Photo Shot Date',
    colOrder: 9,
    sortable: true,
    data: 'image_created_date',
    dataType: 'date',
    sortName: 'createDt',
    dataField: 'image_created_date',
  },
  {
    groupId: 'photographer',
    custColName: 'Photographer',
    custColType: 'string',
    selected: false,
    colId: 'photographer',
    colName: 'Photographer',
    colOrder: 10,
    sortable: true,
    data: 'artist',
    dataType: 'single',
    sortName: 'artist',
    dataField: 'artist',
  },
  {
    groupId: 'file_format',
    custColName: 'File Format',
    custColType: 'string',
    selected: false,
    colId: 'file_format',
    colName: 'File Format',
    colOrder: 11,
    sortable: true,
    data: 'file_format',
    dataType: 'single',
    dataField: 'file_extension',
  },
  {
    groupId: 'subject_codes',
    custColName: 'Multi TCIN',
    custColType: 'chip',
    selected: false,
    colId: 'subject_codes',
    colName: 'Multi TCIN',
    colOrder: 12,
    sortable: true,
    data: 'subject_codes',
    dataType: 'single',
    dataField: 'subject_codes',
  },
  {
    groupId: 'drm_id',
    custColName: 'DRM-ID',
    custColType: 'string',
    selected: false,
    colId: 'drm_id',
    colName: 'DRM-ID',
    colOrder: 13,
    sortable: true,
    data: 'drm_id',
    dataType: 'single',
    sortName: 'drmId',
    dataField: 'drm_id',
  },
  {
    groupId: 'bit_depth',
    custColName: 'Bit Depth',
    custColType: 'string',
    selected: false,
    colId: 'bit_depth',
    colName: 'Bit Depth',
    colOrder: 14,
    sortable: true,
    data: 'bit_depth',
    dataType: 'single',
    sortName: 'bitDepth',
    dataField: 'bit_depth',
  },
  {
    groupId: 'lzw_compression',
    custColName: 'LZW Compression',
    custColType: 'string',
    selected: false,
    colId: 'lzw_compression',
    colName: 'LZW Compression',
    colOrder: 15,
    sortable: true,
    data: 'lzw_compression',
    dataType: 'single',
    sortName: 'compression',
    dataField: 'compression',
  },
  {
    groupId: 'file_size',
    custColName: 'File Size',
    custColType: 'string',
    selected: false,
    colId: 'file_size',
    colName: 'File Size',
    colOrder: 16,
    sortable: true,
    data: 'file_size',
    dataType: 'single',
    sortName: 'fileSize',
    dataField: 'file_size',
  },
]

export default columnMap

export const allStudiosList = [
  'Winter Street',
  'Royalston',
  'Periscope',
  'Schawk',
  'Splashlight',
  'IndustrialColor',
]
