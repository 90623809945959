import * as React from 'react'
import AppLogo from '../images/PhotoBridge_Logo_white-on-red.svg'
import { ListItemAvatar } from '@material-ui/core'
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from '@praxis/component-auth'
import navRoutes from '../routes/index'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import apiConfig from '../apiConfig'

const getPageName = (pathName) => {
  let pos1 = pathName.indexOf('/')
  var pos2 = pathName.indexOf('/', pos1 + 1)
  return pathName.substring(0, pos2 < 0 ? pathName.length : pos2)
}

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#cc0000',
    fontSize: theme.typography.pxToRem(9),
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#cc0000',
    fontSize: theme.typography.pxToRem(18),
  },
}))

const ListItems = (props) => {
  const { pathname: pathName = '' } = useLocation()
  const auth = useAuth()
  const { session: { userInfo: { memberOf = [] } = {} } = {} } = auth
  const currentPage = getPageName(pathName)
  return (
    <Stack direction="column" spacing={2}>
      <ListItem>
        <ListItemAvatar>
          <AppLogo title="Application Logo" />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary="Photobridge"
          sx={{
            opacity: props.open ? 1 : 0,
            color: '#ffffff',
            fontWeight: 600,
            fontSize: 18,
          }}
        />
      </ListItem>
      {navRoutes
        .filter((r) => r.sideNav)
        .map((route, key) =>
          apiConfig.isPol ? (
            <BootstrapTooltip arrow title={route.displayName} placement="right">
              <ListItemButton
                key={key}
                onClick={props.handleClickItem(route.name)}
                selected={currentPage === route.path}
                component={Link}
                to={route.path}
                sx={{ '&.Mui-selected': { borderLeft: '5px solid #ffffff' } }}
              >
                <ListItemIcon>
                  <route.icon sx={{ color: '#ffffff' }} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary="TCIN Image Info"
                  sx={{
                    opacity: props.open ? 1 : 0,
                    color: '#ffffff',
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                />
              </ListItemButton>
            </BootstrapTooltip>
          ) : route.allowedAdGroups.some(
              (r) => memberOf.indexOf(r.toUpperCase()) >= 0
            ) ? (
            <BootstrapTooltip arrow title={route.displayName} placement="right">
              <ListItemButton
                key={key}
                onClick={props.handleClickItem(route.name)}
                selected={currentPage === route.path}
                component={Link}
                to={route.path}
                sx={{ '&.Mui-selected': { borderLeft: '5px solid #ffffff' } }}
              >
                <ListItemIcon>
                  <route.icon sx={{ color: '#ffffff' }} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary="TCIN Image Info"
                  sx={{
                    opacity: props.open ? 1 : 0,
                    color: '#ffffff',
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                />
              </ListItemButton>
            </BootstrapTooltip>
          ) : (
            <></>
          )
        )}
    </Stack>
  )
}

export default ListItems
