import { camelCase } from 'lodash'

export const getUpdatedFailedFiles = (failedFiles = {}, fileName = '') => {
  const updatedFailedFiles = { ...failedFiles }
  if (updatedFailedFiles[fileName]) {
    delete updatedFailedFiles[fileName]
  }
  return {
    updatedFailedFiles,
    updatedFailedFilesCount: Object.keys(updatedFailedFiles).length,
  }
}

export const formatPaginationQueryParams = (pagination) => {
  let params = ''
  params += `page=${pagination.page}&size=${pagination.size}`
  if (pagination.sort) {
    // spring paging reqires camelcase field name for sorting
    params += `&sort=${camelCase(pagination.sort.field)},${
      pagination.sort.direction
    }`
  }
  return params
}
