import {
  SET_USER_AUTH_INFO,
  SET_USER_AUTH_INFO_PENDING,
  SET_USER_AUTH_INFO_FULFILLED,
  SET_USER_AUTH_INFO_REJECTED,
} from './actionTypes'

export const getLoginUserAdGroupList = (
  memberOf = [],
  adGroupFuncList = {}
) => {
  const { user_roles = [] } = adGroupFuncList
  return (
    (user_roles.length && user_roles.filter((e) => memberOf.indexOf(e) > -1)) ||
    []
  )
}
export const getAdGroupType = (loginUserAdGroupList) => {
  var appLevelADGroups = [
    'Prep-Stylist',
    'Producer',
    'Photographer',
    'Photographer-QC',
    'ReTouching-Lead',
    'ReToucher',
    'ReTouching-QA',
  ]
  let isUserExists = false
  let currentAdGroup = ''
  for (let key = 0; key < appLevelADGroups.length; key++) {
    let appAdGroup = appLevelADGroups[key]
    var regex = new RegExp('-' + appAdGroup + '$')
    isUserExists = loginUserAdGroupList.some(
      // obj => obj.indexOf(appAdGroup) !== -1
      // eslint-disable-next-line no-loop-func
      (obj) => regex.test(obj) === true
    )

    // if ((/-appAdGroup]$/).test(value)) {
    if (isUserExists) {
      currentAdGroup = appAdGroup
      break
    }
  }
  return currentAdGroup
}
export const isSuperAdmin = (loginUserAdGroupList) =>
  loginUserAdGroupList.some((obj) => obj.indexOf('Producer') !== -1)
export const getLoginUserAdGroupData = (
  loginUserAdGroupList = [],
  adGroupFuncList = {}
) => {
  const { actions_map = [] } = adGroupFuncList
  var filteredList = {}
  loginUserAdGroupList.forEach((e) => {
    filteredList[e] = actions_map[e]
  })
  return filteredList
}
export const formatUserInfo = (info = {}, adGroupFuncList = {}) => {
  const { memberOf = [] } = info
  const loginUserAdGroupList = getLoginUserAdGroupList(
    memberOf,
    adGroupFuncList
  )
  const loginUserAdGroupData = getLoginUserAdGroupData(
    loginUserAdGroupList,
    adGroupFuncList
  )
  const currentAdGroup = getAdGroupType(loginUserAdGroupList)
  const isAdGroupSuperAdmin = isSuperAdmin(loginUserAdGroupList)
  return {
    ...info,
    accessibleModules: [],
    loginUserAdGroupList: loginUserAdGroupList,
    loginUserAdGroupData: loginUserAdGroupData,
    isProvisionedUser: false,
    isAdGroupSuperAdmin: isAdGroupSuperAdmin,
    currentAdGroup: currentAdGroup,
  }
}
export const initialState = {
  ...formatUserInfo(),
  isLoading: false,
}
const reducer = (state = initialState, { type, payload = {} } = {}) => {
  const { data: authData = {}, userInfo = {} } = payload
  switch (type) {
    case SET_USER_AUTH_INFO:
      return {
        ...state,
        ...formatUserInfo(userInfo, authData),
        isLoading: false,
      }
    case SET_USER_AUTH_INFO_FULFILLED:
      return {
        ...state,
        ...formatUserInfo(userInfo, authData),
        isLoading: false,
      }
    case SET_USER_AUTH_INFO_PENDING:
      return {
        ...state,
        isLoading: true,
      }
    case SET_USER_AUTH_INFO_REJECTED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default reducer
