import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Button, Typography } from '@material-ui/core'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Checkbox from '@material-ui/core/Checkbox'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from '@material-ui/core'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import SaveIcon from '@material-ui/icons/Save'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: 10,
  },
  formGroup: {
    marginBottom: 15,
    marginTop: 7,
  },
  labelRoot: {
    height: 30,
  },
  label: {
    fontSize: 14,
    color: 'black !important',
  },
  buttonGroup: {
    marginLeft: 'auto',
  },
}))

export default function CustomColumn(props) {
  const {
    columnMap,
    handleColumnSelection,
    onRestoreDefaultMap,
    onPopupClose,
    onSwitchToUserView,
    onSaveAndApplyUserView,
    view,
    isSaveDisabled,
    isApplyDisabled,
    onUpdateView,
    onDiscardChanges,
  } = props
  const [custMap, setCustMap] = React.useState([])
  const [viewType, setViewType] = React.useState(view)
  const classes = useStyles()
  const handleViewTypeChange = (event) => {
    setViewType(event.target.value)
  }

  const handleSaveAndApply = () => {
    onSaveAndApplyUserView()
    onPopupClose(null)
  }

  const handleApply = () => {
    onUpdateView()
    onPopupClose(null)
  }

  React.useEffect(() => {
    setCustMap(columnMap.filter((o) => o.custColName !== undefined))
  }, [columnMap])

  return (
    <React.Fragment>
      <div className={classes.header}>
        <Typography variant="h6">Customize Columns</Typography>
        <IconButton
          onClick={onDiscardChanges}
          style={{
            paddingRight: 10,
            position: 'relative',
            alignSelf: 'flex-end',
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Item</FormLabel>
            <FormGroup classes={{ root: classes.formGroup }}>
              {custMap.map((col, i) => {
                return (
                  col.category === 'item' && (
                    <FormControlLabel
                      key={i}
                      classes={{
                        root: classes.labelRoot,
                        label: classes.label,
                        disabled: classes.labelDisabled,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          checked={col.selected}
                          onChange={handleColumnSelection}
                          name={col.groupId}
                          disabled={
                            viewType === 'Default' ||
                            col.groupId === 'tcin' ||
                            col.groupId === 'relationship_type'
                          }
                        />
                      }
                      label={col.custColName}
                    />
                  )
                )
              })}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Sample</FormLabel>
            <FormGroup classes={{ root: classes.formGroup }}>
              {custMap.map((col, i) => {
                return (
                  col.category === 'sample' && (
                    <FormControlLabel
                      key={i}
                      classes={{
                        root: classes.labelRoot,
                        label: classes.label,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          checked={col.selected}
                          onChange={handleColumnSelection}
                          name={col.groupId}
                          disabled={viewType === 'Default'}
                        />
                      }
                      label={col.custColName}
                    />
                  )
                )
              })}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Photo</FormLabel>
            <FormGroup classes={{ root: classes.formGroup }}>
              <Typography
                variant="body2"
                style={{ color: 'rgba(0, 0, 0, 0.54)', marginTop: 2 }}
              >
                Photo info
              </Typography>
              {custMap.map((col, i) => {
                return (
                  col.category === 'photo' &&
                  col.custColGroup !== 'each' && (
                    <FormControlLabel
                      key={i}
                      classes={{
                        root: classes.labelRoot,
                        label: classes.label,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          checked={col.selected}
                          onChange={handleColumnSelection}
                          name={col.groupId}
                          disabled={viewType === 'Default'}
                        />
                      }
                      label={col.custColName}
                    />
                  )
                )
              })}
              <Typography
                variant="body2"
                style={{ color: 'rgba(0, 0, 0, 0.54)', marginTop: 2 }}
              >
                Photo info for each Image
              </Typography>
              {custMap.map((col, i) => {
                return (
                  col.category === 'photo' &&
                  col.custColGroup === 'each' && (
                    <FormControlLabel
                      key={i}
                      classes={{
                        root: classes.labelRoot,
                        label: classes.label,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          checked={col.selected}
                          onChange={handleColumnSelection}
                          name={col.groupId}
                          disabled={viewType === 'Default'}
                        />
                      }
                      label={col.custColName}
                    />
                  )
                )
              })}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl component="fieldset">
            <FormLabel component="legend">&nbsp;</FormLabel>
            <FormGroup classes={{ root: classes.formGroup }}>
              <Typography
                variant="body2"
                style={{ color: 'rgba(0, 0, 0, 0.54)', marginTop: 2 }}
              >
                Options
              </Typography>
              {custMap.map((col, i) => {
                return (
                  col.category === 'options' && (
                    <FormControlLabel
                      key={i}
                      classes={{
                        root: classes.labelRoot,
                        label: classes.label,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          checked={col.selected}
                          onChange={handleColumnSelection}
                          name={col.groupId}
                          disabled={viewType === 'Default'}
                        />
                      }
                      label={col.custColName}
                    />
                  )
                )
              })}
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <FormControl component="fieldset">
            <RadioGroup
              row
              name="ViewType"
              value={viewType}
              onChange={handleViewTypeChange}
            >
              <FormControlLabel
                value="Default"
                control={<Radio />}
                label="Default View"
                onClick={onRestoreDefaultMap}
              />
              <FormControlLabel
                value="Userbased"
                control={<Radio />}
                label="My Custom View"
                onClick={onSwitchToUserView}
              />
              {viewType === 'Userbased' && (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSaveAndApply}
                  size="small"
                  startIcon={<SaveIcon />}
                  disabled={isSaveDisabled}
                >
                  Save and Apply
                </Button>
              )}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item className={classes.buttonGroup}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApply}
            disabled={isApplyDisabled}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
