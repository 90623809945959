import { createStore, applyMiddleware } from 'redux'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import reduxThunk from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'
import createDebounce from 'redux-debounced'

import rootReducer from './index'

export const middleware = [reduxThunk, promiseMiddleware, createDebounce()]

if (process.env.NODE_ENV !== 'production') {
  middleware.push(reduxImmutableStateInvariant())
}
const composeEnhancers = composeWithDevTools({ trace: true })

export default function configureStore() {
  return createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware))
  )
}
