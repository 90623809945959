import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { setBreadcrumbs } from '../store/Layout/actionCreator'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    left: '0',
    top: '0',
    width: '100%',
  },
  content: {
    boxSizing: 'border-box',
    margin: '0 auto',
    padding: '20px 269px',
  },
  unAuthContent: {
    textAlign: 'center',
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '34px',
    margin: '0',
    color: '#CC0000',
  },
  subheading: {
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '1.5',
    margin: '1.8em 10px 2em',
  },
}))

const getDomainName = (hostName) => {
  return hostName.substring(
    hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1
  )
}

const returnInternalUnauthorized = () => (
  <div>
    <h1>Please follow below instructions to get Access</h1>
    <div>
      <h2>
        1. Login to{' '}
        <a
          href="https://myaccess.target.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          myAccess
        </a>
        <br />
        2. Search for the right access based on your role and request access{' '}
        <br />
      </h2>
      <h3>
        Below is the list of roles and their respective entitlements: <br />{' '}
        <br />
        APP-PTB-GeneralUser &nbsp; : &nbsp; Partners who support the Photo
        Studio
        <br />
        APP-PTB-Producer &nbsp;: &nbsp; Studio Producers
        <br />
        APP-PTB-Photographer &nbsp; : &nbsp; Photographers
        <br />
        APP-PTB-ReToucher &nbsp; : &nbsp; TII Imaging Team - Retouchers
        <br />
        APP-PTB-ReTouching-QA &nbsp; : &nbsp; Retoucher for QA
        <br />
        APP-PTB-ReTouching-Lead &nbsp; : &nbsp; * MPLS Imaging Team &nbsp; * TII
        Imaging Team - Leads &nbsp; * TII Imaging Team - QA Leads
        <br />
        APP-PTB-ArtDirectors &nbsp; : &nbsp; Art Directors
        <br />
      </h3>
      <h2>
        Thanks!
        <br />
        Photobridge Support
      </h2>
    </div>
  </div>
)

const returnPOLUnauthorized = () => (
  <div>
    <h1>Please follow below instructions to get Access</h1>
    <div>
      <h2>
        You will need to go to{' '}
        <a
          href="https://www.partnersonline.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          POL
        </a>{' '}
        in order to set up access to the Photobridge tool.
        <br />
        Here are some links that might be helpful: <br />
        <br />
        <a
          href="https://www.partnersonline.com/page/library/VMM/applications/Application%20Training%20Document/95"
          target="_blank"
          rel="noreferrer noopener"
        >
          Add/Edit a Contact
        </a>{' '}
        – If the contact needs POL access, see step 9. <br />
        <a
          href="https://www.partnersonline.com/page/library/VMM/applications/Application%20Training%20Document/123"
          target="_blank"
          rel="noreferrer noopener"
        >
          Manage access to Applications
        </a>{' '}
        – These are the steps for adding an application (role) to a contact.{' '}
        <br />
        <a
          href="https://www.partnersonline.com/page/library/VMM/applications/Application%20Training%20Document/517"
          target="_blank"
          rel="noreferrer noopener"
        >
          Manage Functional Responsibilities
        </a>{' '}
        – This is how the admin can add Sample Management to the contact. <br />
        <br />
      </h2>
      <h2>
        Thanks!
        <br />
        Photobridge Support
      </h2>
    </div>
  </div>
)

const returnDiv = () => <div>hello</div>

export default function UnAuthorized(props) {
  const classes = useStyles()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(setBreadcrumbs(props.customPath, pathname))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.unAuthContent}>
          <div className={classes.heading}>Unauthorized</div>
          <div className={classes.subheading}>
            You are not authorized to view this page
          </div>
        </div>
        <div>
          <div>
            {getDomainName(window.location.hostname).endsWith(
              'partnersonline.com'
            )
              ? returnPOLUnauthorized()
              : returnInternalUnauthorized()}
          </div>
        </div>
      </div>
    </div>
  )
}
