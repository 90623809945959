import { Button, MenuItem, Select, Typography, Chip } from '@material-ui/core'
import React from 'react'
import Paper from '@material-ui/core/Paper'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import {
  withStyles,
  makeStyles,
  useTheme,
  ThemeProvider,
} from '@material-ui/core/styles'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
// import Checkbox from '@material-ui/core/Checkbox'
import { useDispatch, useSelector } from 'react-redux'
import { uniq, compact, cloneDeep, mapValues, pickBy, set } from 'lodash'
import {
  setFilteredTCINList,
  setFilters,
  setUserFilters,
} from '../../store/TcinInfo/actionCreator'
import 'date-fns'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { formatDateTime } from '../../helpers/dateHelper'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import pink from '@material-ui/core/colors/pink'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { createMuiTheme, createStyles } from '@material-ui/core'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: 'space-between',
  },
  dateInput: {
    width: 224,
  },
  subtitle: {
    fontWeight: 500,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: 24,
    marginBottom: 48,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  content: {
    flexGrow: 1,
    margin: '16px 0',
    overflowY: 'auto',
    // boxShadow: 'inset 0px 11px 8px -10px #ccc, inset 0px -11px 8px -10px #ccc',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    paddingTop: 24,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
    padding: '8px 24px',
  },
  // paper: {
  //   marginTop: theme.spacing(3),
  //   marginBottom: theme.spacing(3),
  //   padding: theme.spacing(2),
  //   [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
  //     marginTop: theme.spacing(3),
  //     marginBottom: theme.spacing(6),
  //     padding: theme.spacing(3),
  //   },
  //   width: 486,
  // },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const filtersTheme = createMuiTheme({
  overrides: {
    MuiAutocomplete: createStyles({
      root: {
        width: 480,
        padding: '8px 24px',
      },
    }),
    MuiTypography: createStyles({
      body1: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        fontFamily: 'Roboto',
      },
    }),
  },
})

export default function Filters(props) {
  const filterData = useSelector((state) => state.tcinPhotoInfoReducer.filters)

  const user_filters = useSelector(
    (state) => state.tcinPhotoInfoReducer.userFilters
  )

  const [addFilterDropDown, setAddFilterDropDown] = React.useState(false)

  const filteredTCINS = useSelector(
    (state) => state.tcinPhotoInfoReducer.filteredTCINS
  )
  const dispatch = useDispatch()

  const [filter, setFilter] = React.useState(
    mapValues(filterData, (o) => o.userSelected)
  )

  const classes = useStyles()

  const [selectedFilters, setSelectedFilters] = React.useState(
    Object.keys(filterData).filter(
      (key) => filterData[key].userSelected.length > 0
    )
  )

  const treeList = useSelector((state) => state.tcinPhotoInfoReducer.treeList)

  const chooseFilter = (event, newValue) => {
    setSelectedFilters(() => compact(uniq(newValue)))
    Object.entries(filter).forEach(([key, value]) => {
      if (!newValue.includes(key)) {
        setFilter((filter) => set(filter, key, []))
      }
    })
  }

  const handleChange = (e, newValue, s) => {
    let subFilterSelected = cloneDeep(filter)
    if (subFilterSelected[s]) {
      subFilterSelected[s] = newValue
    } else {
      subFilterSelected[s] = newValue
    }
    setFilter(subFilterSelected)
  }

  const applyFilters = () => {
    let newFilterData = cloneDeep(filterData)
    let filtered = cloneDeep(filteredTCINS)
    let filterWithTcins = {}
    Object.entries(filter).forEach(([key, value]) => {
      newFilterData[key].userSelected = value
      if (value.length > 0) {
        filterWithTcins[key] = []
        Object.entries(filterData[key].values).forEach(([k, v]) => {
          if (value.includes(k)) {
            filterWithTcins[key].push(...v)
          }
        })
      }
    })
    let tcins = Object.values(filterWithTcins)
    filtered = compact(
      uniq(
        tcins.reduce(
          (previousValue, currentValue) =>
            currentValue.filter((c) => previousValue.includes(c)),
          tcins[0] ?? []
        )
      )
    )
    dispatch(setFilteredTCINList(filtered))
    dispatch(setFilters(newFilterData))
    dispatch(setUserFilters(filter))
    props.closeDialog(null)
  }

  const removeFilters = () => {
    let newFilterData = cloneDeep(filterData)
    let subFilterSelected = cloneDeep(filter)
    Object.entries(newFilterData || {}).forEach(([key]) => {
      if ((newFilterData[key] || {}).userSelected) {
        newFilterData[key].userSelected = []
      }
    })
    Object.entries(subFilterSelected || {}).forEach(([key]) => {
      if (subFilterSelected[key]) {
        subFilterSelected[key] = []
      }
    })
    setFilter(subFilterSelected)
    setSelectedFilters([])
    dispatch(setFilters(newFilterData))
    dispatch(setUserFilters(subFilterSelected))
    dispatch(setFilteredTCINList(treeList.map((t) => t.node.tcin)))
    props.closeDialog(null)
  }

  const handleStartDateChange = (inputDate, filterKey) => {
    inputDate.setHours(0, 0, 0, 0)
    let subFilterSelected = cloneDeep(filter)
    subFilterSelected[filterKey] = compact(
      uniq([
        formatDateTime(inputDate, 'YYYY-MM-DD[T]HH:mm:ss.sss'),
        ...Object.keys(filterData[filterKey].values).filter(
          (date) => new Date(date) >= inputDate
        ),
      ])
    )
    setFilter(subFilterSelected)
  }

  const handleEndDateChange = (inputDate, filterKey) => {
    inputDate.setHours(0, 0, 0, 0)
    let nextDay = new Date(inputDate)
    let startDate = filter[filterKey]
      .filter((date) => !isNaN(Date.parse(date)))
      .map((date) => new Date(date))
      .sort((a, b) => a - b)[0]
    nextDay.setDate(nextDay.getDate() + 1)
    let subFilterSelected = cloneDeep(filter)
    subFilterSelected[filterKey] = compact(
      uniq([
        formatDateTime(startDate, 'YYYY-MM-DD[T]HH:mm:ss.sss'),
        ...Object.keys(filterData[filterKey].values).filter(
          (date) => new Date(date) < nextDay && new Date(date) >= startDate
        ),
        formatDateTime(inputDate, 'YYYY-MM-DD[T]HH:mm:ss.sss'),
      ])
    )
    setFilter(subFilterSelected)
  }

  const isFiltersApplied = Object.values(filter).some((x) => x.length)

  return (
    // <Paper className={classes.paper}>
    <ThemeProvider theme={filtersTheme}>
      <React.Fragment>
        <div>
          <div className={classes.header}>
            <Typography
              component="h1"
              variant="h6"
              align="left"
              // style={{ marginLeft: '10px' }}
            >
              Filters ({selectedFilters.length})
            </Typography>
            <IconButton
              size="small"
              disableFocusRipple
              onClick={() => props.closeDialog(null)}
              // classes={{ MuiIconButtonsizeSmall: { padding: '0px' } }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div>
            {/* <FormControl className={classes.formControl} key="Choose-Filter"> */}
            <Autocomplete
              multiple
              disableCloseOnSelect
              id="Choose-filter"
              onChange={(e, newValue) => chooseFilter(e, newValue)}
              options={Object.keys(filterData)}
              getOptionLabel={(option) => option}
              open={addFilterDropDown}
              onOpen={() => {
                setAddFilterDropDown(!addFilterDropDown)
              }}
              onClose={() => {
                setAddFilterDropDown(!addFilterDropDown)
              }}
              value={selectedFilters}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={filterData[option].label}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selectedFilters.indexOf(option) > -1}
                  />
                  {filterData[option].label}
                </React.Fragment>
              )}
              // style={{ width: 500 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Add Filters"
                  placeholder="Select"
                />
              )}
            />
            {/* </FormControl> */}
          </div>
          {/* <Divider /> */}
        </div>
        <div className={classes.content}>
          {selectedFilters.map((s) => {
            return s === 'photoShotDate' ||
              s === 'photoSourceDate' ||
              s === 'launchDate' ? (
              <div key={s} style={{ padding: '8px 24px' }}>
                <Typography>{filterData[s].label}</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    style={{ gap: 32 }}
                  >
                    {/* <ThemeProvider theme={materialTheme}> */}
                    <KeyboardDatePicker
                      animateYearScrolling
                      className={classes.dateInput}
                      autoOk
                      disableToolbar
                      // disableFuture
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id={s + '-start'}
                      key={s + '-start'}
                      label={'From'}
                      value={
                        filter[s]
                          .filter((date) => !isNaN(Date.parse(date)))
                          .map((date) => new Date(date))
                          .sort((a, b) => a - b)[0] || null
                      }
                      onChange={(e) => e && handleStartDateChange(e, s)}
                      minDate={
                        Object.keys(filterData[s].values)
                          .filter((ts) => !isNaN(Date.parse(ts)))
                          .map((ts) => new Date(ts))
                          .sort((a, b) => a - b)[0]
                      }
                      maxDate={
                        Object.keys(filterData[s].values)
                          .filter((ts) => !isNaN(Date.parse(ts)))
                          .map((ts) => new Date(ts))
                          .sort((a, b) => b - a)[0]
                      }
                    />
                    {/* </ThemeProvider> */}
                    {/* <ThemeProvider theme={materialTheme}> */}
                    <KeyboardDatePicker
                      className={classes.dateInput}
                      autoOk
                      disabled={!filter[s].length > 0}
                      disableToolbar
                      inputVariant="outlined"
                      // disableFuture
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id={s + '-end'}
                      key={s + '-end'}
                      label={'To'}
                      value={
                        filter[s]
                          .filter((ts) => !isNaN(Date.parse(ts)))
                          .map((ts) => new Date(ts))
                          .sort((a, b) => b - a)[0] || null
                      }
                      onChange={(e) => e && handleEndDateChange(e, s)}
                      minDate={
                        filter[s]
                          .filter((ts) => !isNaN(Date.parse(ts)))
                          .map((ts) => new Date(ts))
                          .sort((a, b) => a - b)[0]
                      }
                      maxDate={
                        Object.keys(filterData[s].values)
                          .filter((ts) => !isNaN(Date.parse(ts)))
                          .map((ts) => new Date(ts))
                          .sort((a, b) => b - a)[0]
                      }
                    />
                    {/* </ThemeProvider> */}
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>
            ) : (
              <div key={s}>
                {/* <FormControl className={classes.formControl} key={s}> */}
                <Autocomplete
                  id={s}
                  limitTags={2}
                  onChange={(e, newValue) => handleChange(e, newValue, s)}
                  multiple
                  disableCloseOnSelect
                  options={Object.keys(filterData[s].values)}
                  getOptionLabel={(option) => option}
                  value={filter[s]}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        id={s}
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={filter[s].indexOf(option) > -1}
                        sx={{
                          '&.Mui-checked': {
                            color: '#CC0000',
                          },
                        }}
                      />
                      {option}
                    </React.Fragment>
                  )}
                  // style={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={filterData[s].label}
                      placeholder="more"
                    />
                  )}
                />
                {/* </FormControl> */}
              </div>
            )
          })}
        </div>
        <div className={classes.buttons}>
          <Button
            // style={{ width: 114 }}
            disabled={!isFiltersApplied || addFilterDropDown}
            onClick={removeFilters}
            variant="outlined"
          >
            Clear & Apply
          </Button>

          <Button
            // style={{ width: 114 }}
            disabled={!isFiltersApplied || addFilterDropDown}
            variant="contained"
            color="primary"
            onClick={applyFilters}
          >
            Apply
          </Button>
        </div>
      </React.Fragment>
    </ThemeProvider>
    // </Paper>
  )
}
