import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider } from '@praxis/component-auth'
import Main from './layout'
import apiConfig from './apiConfig'

// Replace with your app’s unique Identity 2.0 client id
// see http://go/oauth to create or manage client ids
// const CLIENT_ID = 'ecgi_npe_im'

/* `App` composes our top-most app code, "Main", within two wrapping components.
  [1] 🗝️ the `AuthProvider` is our interface into Praxis Authentication.
      By default Identity 2.0 flows are full page redirects.
      The `AuthProvider` establishes an `AuthContext` which `Main` accesses via `useAuth`
  [2] 🛣️ the Router maintains where we are in the single-page javascript app
      Routes are defined in `Main`, with the `Switch` ensuring only `*Route` is rendered at a time
*/
const App = () => {
  return (
    <AuthProvider {...apiConfig.auth}>
      <Router>
        <Main />
      </Router>
    </AuthProvider>
  )
}
export default App
