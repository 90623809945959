import { GET_REPORT_RESULTS } from './actionType'

const reportResultState = {
  report: {},
}

export default function reportReducer(state = reportResultState, action) {
  switch (action.type) {
    case GET_REPORT_RESULTS:
      return {
        ...state,
        report: { ...action.data },
      }
    default:
      return state
  }
}
