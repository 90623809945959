import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { formatDate, toSafeData } from './mapper'

export default function ItemData(props) {
  const classes = useStyles()
  const { data } = props
  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} aria-label="simple table" align="center">
        <TableBody>
          <TableRow>
            <TableCell className={classes.tableCell}>
              Item Description
            </TableCell>
            <TableCell className={classes.tableCellValues}>
              {toSafeData(data, 'item_info.product_description', '-')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>Launch Date</TableCell>
            <TableCell className={classes.tableCellValues}>
              <span style={{ whiteSpace: 'nowrap' }}>
                {formatDate(
                  toSafeData(data, 'item_info.launch_date_time', '-')
                )}
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>Lifecycle State</TableCell>
            <TableCell className={classes.tableCellValues}>
              {toSafeData(data, 'item_info.item_state', '-')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>DPCI</TableCell>
            <TableCell className={classes.tableCellValues}>
              {toSafeData(data, 'dpci', '-')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>
              Class ID/Class Name
            </TableCell>
            <TableCell className={classes.tableCellValues}>
              {toSafeData(data, 'item_info.class_id', '') +
                ' - ' +
                toSafeData(data, 'item_info.class_name', '')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>
              Department ID/Department Name
            </TableCell>
            <TableCell className={classes.tableCellValues}>
              {toSafeData(data, 'item_info.department_id', '') +
                ' - ' +
                toSafeData(data, 'item_info.department_name', '')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>
              UPC/Barcode Number
            </TableCell>
            <TableCell className={classes.tableCellValues}>
              {toSafeData(data, 'item_info.barcode_number', '-')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>Brand</TableCell>
            <TableCell className={classes.tableCellValues}>
              {toSafeData(data, 'item_info.brands', '-')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>
              Vendor Name/Vendor ID
            </TableCell>
            <TableCell className={classes.tableCellValues}>
              {toSafeData(data, 'item_info.vendor_id', '-')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>
              Manufacturer Style Number
            </TableCell>
            <TableCell className={classes.tableCellValues}>
              {toSafeData(data, 'item_info.manufacturer_style', '-')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>Size</TableCell>
            <TableCell className={classes.tableCellValues}>
              {toSafeData(data, 'item_info.size', '-')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>Color</TableCell>
            <TableCell className={classes.tableCellValues}>
              {toSafeData(data, 'item_info.color', '-')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>
              Relationship Type
            </TableCell>
            <TableCell className={classes.tableCellValues}>
              {toSafeData(data, 'item_info.relationship', '-')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>Parent TCIN</TableCell>
            <TableCell className={classes.tableCellValues}>
              {toSafeData(data, 'item_info.parent_tcins', '-')}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'fit-content',
  },
  table: {
    width: '98%',
    whiteSpace: 'nowrap',
  },
  tableCell: {
    align: 'left',
    fontsize: '0.875rem',
    fontWeight: 500,
    width: '25%',
  },
  tableCellValues: {
    textAlign: 'left',
  },
})
