import apiConfig, { appEnv } from '../../apiConfig'
import axios from 'axios'
import { workflowRowMapper } from './workflowMappers'

const {
  getUserCustomColumns,
  getDownloadImageApi,
  generateSupportContactSheets,
  uploads: { uploadStatusUrl = '' } = {},
} = apiConfig

export const generateRetouchContactSheet = async (accessToken, fileList) => {
  const url = `${generateSupportContactSheets}`
  return axios.post(
    url,
    { file_version_infos: fileList },
    {
      params: {
        key: apiConfig.apiKey,
      },
      headers: { Authorization: accessToken },
      responseType: 'blob',
    },
    {}
  )
}

export const updateWorkflowCustomColumns = async (
  accessToken,
  lanId,
  view,
  columns
) => {
  const newColumnList = []
  for (const [key, value] of Object.entries(columns)) {
    if (value.selected) {
      newColumnList.push(key)
    }
  }
  const body = {
    user_id: lanId.toUpperCase(),
    column_list: newColumnList,
  }
  try {
    const response = await axios.post(getUserCustomColumns, body, {
      params: {
        view: view,
        key: apiConfig.apiKey,
      },
      headers: { Authorization: accessToken },
    })
    return response.data[`workflow_${view.toLowerCase()}_columns`].column_list
  } catch (err) {
    console.error('There is an error while saving the custom columns list', err)
  }
}

export const getCustomColumns = async (lanId, view) => {
  try {
    const response = await axios.get(getUserCustomColumns, {
      params: {
        user_id: lanId,
        view: view,
      },
    })
    return response.data
  } catch (err) {
    console.error(
      'There is an error while getting the custom columns list',
      err
    )
  }
}

export const downloadImage = async (url) => {
  const body = {
    image_url: url,
  }
  try {
    const response = await fetch(getDownloadImageApi, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
    return response.blob()
    // const response = await axios.post(getDownloadImageApi, body, {responseType: 'blob'})
    // return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getWorkflowRows = (workflowImageData = {}) => {
  const workflowRows = workflowRowMapper(workflowImageData?.search_response)
  return workflowRows
}

export const getUploadStatus = (
  accessToken = '',
  userId = '',
  uploadIds = []
) => {
  return axios.post(
    uploadStatusUrl,
    { upload_ids: uploadIds },
    {
      params: {
        key: apiConfig.apiKey,
      },
      headers: {
        ...(appEnv === 'dev' && { 'x-tgt-lanId': userId }),
        Authorization: accessToken,
      },
    }
  )
}
