import {
  SUBMIT_SUPPORT_REQUEST,
  SUPPORT_REQUEST_ERROR,
  ADD_ATTACHMENT_JIRA,
} from './actionType'
import axios from 'axios'
import apiConfig from '../../apiConfig'

const {
  createJiraRequestApi = '',
  attachJiraRequestApi = '',
  projectkey = '',
} = apiConfig

function createJiraTicketSuccess(data) {
  return {
    type: SUBMIT_SUPPORT_REQUEST,
    data,
  }
}

function attachToJiraTicket(data) {
  return {
    type: ADD_ATTACHMENT_JIRA,
    data,
  }
}

function jiraRequestError() {
  return {
    type: SUPPORT_REQUEST_ERROR,
  }
}

export const getSupportAPI =
  (summary, description, issuetype, priority, email, onSuccess = () => {}) =>
  async (dispatch) => {
    const getSupportURL = `${createJiraRequestApi}`
    axios
      .post(getSupportURL, {
        projectkey: projectkey,
        summary: summary,
        description: description,
        issuetype: issuetype,
        priority: priority,
        email: email,
      })
      .then((response) => {
        dispatch(createJiraTicketSuccess(response.data))
        onSuccess()
      })
      .catch((error) => {
        dispatch(jiraRequestError())
      })
  }

export const attachmentSupportAPI = (image, support) => async (dispatch) => {
  const attachmentURL = `${attachJiraRequestApi}/${support}`
  var formData = new FormData()
  formData.append('file', image.file)
  axios
    .post(attachmentURL, formData)
    .then((response) => {
      dispatch(attachToJiraTicket(response.data))
    })
    .catch((error) => {
      //console.log(error)
    })
}
