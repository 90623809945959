import { Paper, Table, TableContainer } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import CustomTableHead from './CustomTableHead'
import CustomTableBody from './CustomTableBody'
import CustomTableToolbar from './CustomTableToolbar'

const useStyles = makeStyles(() => ({
  root: {
    margin: '0 4px',
  },
  container: {
    border: '1px solid #B6B6B6',
    width: 'auto',
    height: 'calc(100vh - 275px)',
  },
}))

export const CustomTableContext = React.createContext({
  tState: {},
  tMethods: {},
})

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHECKBOX_HANDLER':
      return { ...state, selected: action.payload }
    case 'WORKFLOW_CHECKBOX_HANDLER': {
      const { tab = '', selection = [] } = action.payload
      return {
        ...state,
        workflowSelected: { ...state.workflowSelected, [tab]: selection },
      }
    }
    case 'ORDER_BY_HANDLER':
      return { ...state, orderBy: action.payload }
    case 'ORDER_HANDLER':
      return { ...state, order: action.payload }
    case 'COLUMNS_HANDLER':
      return { ...state, colMap: action.payload }
    case 'DATA_HANDLER':
      return { ...state, data: action.payload }
    case 'ORIGINAL_DATA_HANDLER':
      return { ...state, originalData: action.payload }
    case 'CUSTOMIZE_COLUMN_HANDLER':
      return { ...state, customizeCol: action.payload }
    case 'PAGE_HANDLER':
      return { ...state, page: action.payload }
    case 'ROWS_PER_PAGE_HANDLER':
      return { ...state, rowsPerPage: action.payload }
    default:
      return state
  }
}

const CustomTable = React.forwardRef((props, ref) => {
  const {
    columns,
    rows,
    customizeColumns,
    onSelectionChange = () => {},
    onRowOrderChange = () => {},
    tableName = '',
    tabName = '',
    page = 0,
    rowsPerPage = 50,
    ...otherProps
  } = props
  const classes = useStyles()
  let initState = React.useMemo(
    () => ({
      selected: [],
      workflowSelected: {},
      orderBy: '',
      order: '',
      colMap: [],
      data: [],
      originalData: [],
      customizeCol: [],
      page: 0,
      rowsPerPage: 50,
    }),
    []
  )

  const [state, dispatch] = React.useReducer(reducer, initState)

  const methods = React.useMemo(() => {
    return {
      checkboxHandler: (data) => {
        dispatch({
          type: 'CHECKBOX_HANDLER',
          payload: data,
        })
        onSelectionChange(data)
      },
      workFlowCheckboxHandler: (data, tabName) => {
        dispatch({
          type: 'WORKFLOW_CHECKBOX_HANDLER',
          payload: { tab: tabName, selection: data },
        })
        onSelectionChange(data, tabName)
      },
      orderByHandler: (col) =>
        dispatch({
          type: 'ORDER_BY_HANDLER',
          payload: col,
        }),

      orderHandler: (dir) =>
        dispatch({
          type: 'ORDER_HANDLER',
          payload: dir,
        }),

      colMapHandler: (data) =>
        dispatch({
          type: 'COLUMNS_HANDLER',
          payload: data,
        }),

      dataHandler: (data) => {
        dispatch({
          type: 'DATA_HANDLER',
          payload: data,
        })
      },

      originalDataHandler: (data) =>
        dispatch({
          type: 'ORIGINAL_DATA_HANDLER',
          payload: data,
        }),

      customizeColHandler: (data) =>
        dispatch({
          type: 'CUSTOMIZE_COLUMN_HANDLER',
          payload: data,
        }),

      pageHandler: (data) =>
        dispatch({
          type: 'PAGE_HANDLER',
          payload: data,
        }),
      rowsPerPageHandler: (data) =>
        dispatch({
          type: 'ROWS_PER_PAGE_HANDLER',
          payload: data,
        }),
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useImperativeHandle(ref, () => ({
    setSelect(data, tab = tabName) {
      methods.workFlowCheckboxHandler(data, tab)
    },
    setOrder(orderBy, order) {
      methods.orderByHandler(orderBy)
      methods.orderHandler(order)
    },
  }))

  React.useEffect(() => {
    methods.colMapHandler(columns || [])
    methods.dataHandler(rows || [])
    methods.originalDataHandler(rows || [])
    methods.customizeColHandler(customizeColumns || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, props.viewType, customizeColumns, rows])

  React.useEffect(() => {
    if (tableName === 'workflow') {
      // methods.workFlowCheckboxHandler([], tabName)
    } else {
      methods.checkboxHandler([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    onSelectionChange(state.selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, state)

  React.useEffect(() => {
    methods.pageHandler(page)
    methods.rowsPerPageHandler(rowsPerPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page])

  React.useEffect(() => {
    if (state.orderBy || state.order) {
      if (tableName === 'workflow') {
        onRowOrderChange(state.orderBy, state.order)
      } else {
        onRowOrderChange(state.data || [], state.orderBy, state.order)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.order, state.orderBy])

  return (
    <CustomTableContext.Provider value={{ tState: state, tMethods: methods }}>
      {props.Toolbar && <CustomTableToolbar {...otherProps} />}
      <TableContainer
        {...(props.style && { style: props.style })}
        className={classes.container}
      >
        <Table stickyHeader aria-label="sticky table">
          <CustomTableHead {...{ ...otherProps, tableName, tabName }} />
          <CustomTableBody {...{ ...otherProps, tableName, tabName }} />
        </Table>
      </TableContainer>
    </CustomTableContext.Provider>
  )
})

export default CustomTable
