import React from 'react'
import {
  Accordion,
  AccordionSummary,
  TableCell,
  TableRow,
  AccordionDetails,
  Table,
  TableHead,
  TableBody,
} from '@mui/material'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Typography } from '@material-ui/core'
import { formatDateTime } from '../../helpers/dateHelper'

export default function SuccessfulDownloadsTable({
  successfulFiles = {},
  isExpanded = false,
  setExpanded = () => {},
  classes = {},
}) {
  return (
    <Accordion
      elevation={0}
      square
      expanded={isExpanded}
      onChange={setExpanded}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        classes={{
          root: classes.accordianSummaryRoot,
        }}
        id="panel1a-header"
      >
        <Typography className={classes.heading}>Success</Typography>

        <ExpandMoreIcon
          className={`${classes.nonExpandedIcon} ${
            isExpanded ? classes.expandedIcon : ''
          }`}
          style={{ marginLeft: '5px' }}
        />
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: classes.accordianSummaryContent,
        }}
        style={{ padding: 0 }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Image Name</TableCell>
              <TableCell>Date - Time</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(successfulFiles).map((key, i) => {
              return (
                <TableRow>
                  <TableCell>{key}</TableCell>
                  <TableCell>
                    {formatDateTime(
                      successfulFiles[key].timeStamp,
                      'MMM D, YYYY - h:mm'
                    )}
                  </TableCell>
                  <TableCell>
                    <span className={classes.iconWithDesc}>
                      <CheckCircleOutlinedIcon
                        style={{ color: '#00A324', marginRight: '5px' }}
                      />
                      {successfulFiles[key].status}
                    </span>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}
