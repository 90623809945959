import { SET_BREADCRUMBS, APPEND_BREADCRUMBS } from './actionTypes'

const initialState = {
  breadcrumbs: [],
}

const prepareBreadcrumbs = (state, { customPath, pathname }) => {
  const customArr = customPath.split('/').splice(1)
  const currentArr = pathname.split('/').splice(1)
  if (customArr.length === currentArr.length) {
    const result = customArr.map((o, i) => {
      return {
        name: o,
        link: '/' + currentArr.slice(0, i + 1).join('/'),
      }
    })

    return { ...state, breadcrumbs: result }
  }
  return state
}

export default function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case SET_BREADCRUMBS:
      return prepareBreadcrumbs(state, action.payload)
    case APPEND_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: [...state.breadcrumbs, action.payload],
      }
    default:
      return state
  }
}
