import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const ConfirmationDailog = (props) => {
  const {
    open = false,
    alertMessage = 'Are you sure?',
    message = '',
    cancelText = 'Cancel',
    agreeText = 'Confirm',
    onClose = () => {},
    onConfirm = () => {},
  } = props
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{alertMessage}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {cancelText}
        </Button>
        <Button
          variant="outlined"
          onClick={onConfirm}
          style={{
            backgroundColor: '#366CD9',
            color: '#ffffff',
          }}
          autoFocus
        >
          {agreeText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDailog
