import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { formatDate, toSafeData, prefixSampleId } from './mapper'
import { isArrayLike } from 'lodash'

export default function SampleData(props) {
  const classes = useStyles()
  const { data } = props
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          className={classes.table}
          aria-label="simple table"
          align="center"
        >
          <TableHead className={classes.header}>
            <TableRow className={classes.header}>
              <TableCell className={classes.header}>Sample ID</TableCell>
              <TableCell className={classes.header} align="left">
                Sample Status
              </TableCell>
              <TableCell className={classes.header} align="left">
                Sample Received Date
              </TableCell>
              <TableCell className={classes.header} align="left">
                Sample Ship To Location
              </TableCell>
              <TableCell className={classes.header} align="left">
                Sample Current Location
              </TableCell>
              <TableCell className={classes.header} align="left">
                Sample Project
              </TableCell>
            </TableRow>
          </TableHead>
          {toSafeData(data, 'sample_info', null) &&
          isArrayLike(data.sample_info) &&
          data.sample_info.length ? (
            <TableBody>
              {data.sample_info.map((row) => (
                <TableRow key={row.pegasus_id}>
                  <TableCell
                    className={classes.tableBody}
                    component="th"
                    scope="row"
                  >
                    {prefixSampleId(toSafeData(row, 'pegasus_id', '-'))}
                  </TableCell>
                  <TableCell className={classes.tableBody} align="left">
                    {toSafeData(row, 'sample_status', '-')}
                  </TableCell>
                  <TableCell className={classes.tableBody} align="left">
                    <span style={{ whiteSpace: 'nowrap' }}>
                      {formatDate(toSafeData(row, 'received_date', '-'))}
                    </span>
                  </TableCell>
                  <TableCell className={classes.tableBody} align="left">
                    {toSafeData(row, 'ship_to_loc', '-')}
                  </TableCell>
                  <TableCell className={classes.tableBody} align="left">
                    {toSafeData(row, 'location', '-')}
                  </TableCell>
                  <TableCell className={classes.tableBody} align="left">
                    {toSafeData(row, 'projects', '-')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            ''
          )}
        </Table>
      </TableContainer>
    </Paper>
  )
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  table: {
    width: '98%',
  },
  tableBody: {
    verticalAlign: 'top',
    textAlign: 'left',
  },
  header: {
    verticalAlign: 'top',
    textAlign: 'left',
  },
  container: {
    height: 'calc(100vh - 168px)',
    maxHeight: 'fit-content',
  },
  noSamples: {
    align: 'center',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '75vh',
    color: 'gray',
    fontSize: '25px',
  },
})
