import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import TablePagination from '@material-ui/core/TablePagination'
import Container from '@material-ui/core/Container'
import PaginationActions from '../TcinInfo/PaginationActions'
import ProjectTable from './ProjectTable'
import { Link } from 'react-router-dom'
import {
  getAllProjectsAPI,
  setIsMyProjects,
  setIsIncludeArchived,
  setProjectSearchText,
} from '../../store/Dashboard/actionCreator'
import { useDispatch, useSelector } from 'react-redux'
import { DashboardContext } from './index'
import apiConfig from '../../apiConfig'
import { withAuth } from '@praxis/component-auth'
import { producerADGroup } from '../../constants/common'
import { isSafari } from '../../helpers/filter'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '10px 5px',
  },
  topMenu: {
    marginTop: isSafari() ? '57px' : '16px',
    display: 'flex',
    alignItems: 'center',
    gap: 56,
  },
  tableMenu: {
    padding: 25,
  },
  projectHeading: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#333333',
  },
  checkedSwitch: {
    color: '#366CD9 !important',
  },
  paginationToolbar: {
    marginLeft: 'auto',
  },
  tableActionMenu: {
    display: 'flex',
    alignItems: 'center',
    gap: 56,
  },
  inputFindProject: {
    transform: 'translate(14px, 13px) scale(1)',
  },
  inputProps: {
    height: 40,
    padding: '0 8px',
  },
  inputWidth: {
    width: 423,
  },
  createProjButton: {
    marginLeft: 'auto',
    height: 36,
    width: 150,
    textDecoration: 'none',
  },
  switch_track: {
    backgroundColor: '#B6B6B6',
  },
  switch_base: {
    color: '#B6B6B6',
    '&.Mui-checked': {
      color: '#366CD9',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#366CD9',
    },
  },
  switch_primary: {
    '&.Mui-checked': {
      color: '#366CD9',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#366CD9',
    },
  },
}))

const ProjectList = (props) => {
  const { accessToken = '', company = '', memberOf = [], lanId = '' } = props
  const { dState, dMethods } = React.useContext(DashboardContext)
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isIncludedArchived, isMyProjects, searchQuery } = useSelector(
    ({
      dashboardReducer: {
        isIncludedArchived = false,
        isMyProjects = false,
        searchQuery = '',
      },
    }) => {
      return { isIncludedArchived, isMyProjects, searchQuery }
    }
  )
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(50)

  React.useEffect(() => {
    dispatch(getAllProjectsAPI(accessToken, lanId, company))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMyProjects, isIncludedArchived])

  const tableData = useSelector((state) => state.dashboardReducer.projects)
  React.useEffect(() => {
    let result = tableData.filter(
      (o) =>
        o.project_id?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        o.project_name?.toLowerCase().includes(searchQuery?.toLowerCase())
    )
    dMethods.projectsHandler(result)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, searchQuery])

  const handleIsIncludeArchived = (event) => {
    dispatch(setIsIncludeArchived(event.target.checked))
  }

  const handleProjectChange = (event) => {
    dispatch(setIsMyProjects(event.target.checked))
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const onChangeSearch = (e) => {
    dispatch(setProjectSearchText(e.target.value))
  }

  return (
    <div>
      <Container maxWidth={false} className={classes.topMenu}>
        <TextField
          label="Find Project"
          id="find-project"
          InputLabelProps={{
            classes: {
              outlined: classes.inputFindProject,
            },
          }}
          onChange={onChangeSearch}
          value={searchQuery}
          InputProps={{
            classes: {
              inputAdornedEnd: classes.inputProps,
              adornedEnd: classes.inputWidth,
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="find_project" edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
        <Typography component="div">
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>All Projects</Grid>
            <Grid item>
              <Switch
                classes={{
                  track: classes.switch_track,
                  switchBase: classes.switch_base,
                  colorPrimary: classes.switch_primary,
                }}
                checked={isMyProjects}
                onChange={handleProjectChange}
                name="allProjects"
                color="primary"
              />
            </Grid>
            <Grid item>My Projects</Grid>
          </Grid>
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={isIncludedArchived}
              classes={{
                checked: classes.checkedSwitch,
              }}
              onChange={handleIsIncludeArchived}
              name="isIncludeArchived"
              color="primary"
            />
          }
          label="Include Archived"
        />
        {memberOf?.includes(producerADGroup.toUpperCase()) &&
          !apiConfig.isPol && (
            <Link to={'/dashboard/create'} className={classes.createProjButton}>
              <Button variant="contained" color="primary">
                Create Project
              </Button>
            </Link>
          )}
      </Container>
      <Container maxWidth={false} className={classes.tableMenu}>
        <div className={classes.tableActionMenu}>
          <Typography variant="h6" className={classes.projectHeading}>
            Showing {dState.projects.length} Projects
          </Typography>
          <TablePagination
            classes={{
              root: classes.paginationToolbar,
            }}
            component="div"
            rowsPerPageOptions={[
              50,
              100,
              200,
              { label: 'All', value: dState.projects.length },
            ]}
            colSpan={3}
            count={dState.projects.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
        </div>
      </Container>
      <ProjectTable
        page={page}
        rowsPerPage={rowsPerPage}
        searchText={searchQuery}
        isMyProjects={isMyProjects}
      />
    </div>
  )
}

const mapAuthToProps = (auth) => {
  return {
    accessToken: auth.session?.userInfo?.accessToken,
    company: auth.session?.userInfo?.company,
    memberOf: auth.session?.userInfo?.memberOf,
    lanId: auth.session?.userInfo?.lanId,
  }
}

export default withAuth(mapAuthToProps)(React.memo(ProjectList))
