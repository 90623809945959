import {
  TOGGLE_UPLOAD_MANAGER,
  TOGGLE_PROJECT,
  UPLOAD_INITIATED,
  UPLOAD_START_CALL,
  UPLOAD_START_CALL_SUCCESS,
  UPLOAD_START_CALL_FAIL,
  CHUNK,
  CHUNK_SUCCESS,
  CHUNK_FAIL,
  UPLOAD_END_CALL,
  UPLOAD_END_CALL_FAIL,
  UPLOAD_END_CALL_SUCCESS,
  UPLOAD_JOB_STATUS_CALL,
  UPLOAD_JOB_STATUS_CALL_SUCCESS,
  UPLOAD_JOB_STATUS_CALL_FAIL,
} from './actionType'

// ============= Beginning of upload start Call ===============

export function uploadStartCallInit(
  projectUUID,
  fileName,
  tcin,
  taskId,
  shotType,
  shotTypeCount
) {
  return {
    type: UPLOAD_START_CALL,
    projectUUID,
    fileName,
    tcin,
    taskId,
    shot_type: shotType,
    shot_type_count: shotTypeCount,
  }
}

export function uploadStartCallSuccess(
  projectUUID,
  fileName,
  shot_type,
  shot_type_count
) {
  return {
    type: UPLOAD_START_CALL_SUCCESS,
    projectUUID,
    fileName,
    shot_type,
    shot_type_count,
  }
}

export function uploadStartCallFail(
  projectUUID,
  fileName,
  shot_type,
  shot_type_count
) {
  return {
    type: UPLOAD_START_CALL_FAIL,
    projectUUID,
    fileName,
    shot_type,
    shot_type_count,
  }
}
// ============= End of upload start Call ===============

// ============= Beginning of chunk Call ===============

export function chunkInit(projectUUID, fileName, shot_type, shot_type_count) {
  return {
    type: CHUNK,
    projectUUID,
    fileName,
    shot_type,
    shot_type_count,
  }
}

export function chunkSuccess(
  projectUUID,
  fileName,
  completedChunks,
  totalChunks,
  shot_type,
  shot_type_count
) {
  return {
    type: CHUNK_SUCCESS,
    projectUUID,
    fileName,
    completedChunks,
    totalChunks,
    shot_type,
    shot_type_count,
  }
}

export function chunkFail(projectUUID, fileName, shot_type, shot_type_count) {
  return {
    type: CHUNK_FAIL,
    projectUUID,
    fileName,
    shot_type,
    shot_type_count,
  }
}
// ============= End of chunk Call ===============

// ============= Beginning of chunk end call ===============

export function chunkEndCallInit(
  projectUUID,
  fileName,
  shot_type,
  shot_type_count
) {
  return {
    type: UPLOAD_END_CALL,
    projectUUID,
    fileName,
    shot_type,
    shot_type_count,
  }
}

export function chunkEndCallSuccess(
  projectUUID,
  fileName,
  shot_type,
  shot_type_count
) {
  return {
    type: UPLOAD_END_CALL_SUCCESS,
    projectUUID,
    fileName,
    shot_type,
    shot_type_count,
  }
}

export function chunkEndCallFail(
  projectUUID,
  fileName,
  shot_type,
  shot_type_count
) {
  return {
    type: UPLOAD_END_CALL_FAIL,
    projectUUID,
    fileName,
    shot_type,
    shot_type_count,
  }
}
// ============= End of chunk end call ===============

// ============= Beginning of upload job status call ===============

export function uploadJobStatusInit(projectUUID, fileName) {
  return {
    type: UPLOAD_JOB_STATUS_CALL,
    projectUUID,
    fileName,
  }
}

export function uploadJobStatusSuccess(
  projectUUID,
  fileName,
  vaultUploadStatus,
  uploadedVersion,
  shot_type,
  shot_type_count
) {
  return {
    type: UPLOAD_JOB_STATUS_CALL_SUCCESS,
    projectUUID,
    fileName,
    uploadedVersion,
    vaultUploadStatus,
    shot_type,
    shot_type_count,
  }
}

export function uploadJobStatusFail(
  projectUUID,
  fileName,
  shot_type,
  shot_type_count
) {
  return {
    type: UPLOAD_JOB_STATUS_CALL_FAIL,
    projectUUID,
    fileName,
    shot_type,
    shot_type_count,
  }
}
// ============= end of upload job status call ===============

export function initUpload(files, project, shot_type, shot_type_count) {
  return {
    type: UPLOAD_INITIATED,
    files,
    project,
  }
}

export function toggleProject(projectUUID) {
  return {
    type: TOGGLE_PROJECT,
    projectUUID,
  }
}

export function toggleUploadManager() {
  return {
    type: TOGGLE_UPLOAD_MANAGER,
  }
}
