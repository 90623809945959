import React from 'react'
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import { fileValidationTexts } from '../../constants/common'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'
import { DeleteForever } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  padding_left_right_null: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
  iconWithDesc: {
    display: 'flex',
    alignItems: 'center',
  },
  uploadTableColumn: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#666666',
  },
  filter: {
    minWidth: '160px !important',
  },
}))

export default function NewImageFilesTable({
  newImageFiles,
  onNewDelete,
  handleStudioChange,
}) {
  const classes = useStyles()

  return (
    <>
      {newImageFiles.length ? (
        <Table sx={{ width: '100%' }} aria-label="simple table">
          <TableHead style={{ background: '#EAF5F9' }}>
            <TableRow>
              <TableCell
                align="left"
                className={`${classes.padding_left_right_null} ${classes.uploadTableColumn}`}
                // style={{ width: '30%' }}
              >
                New Images for Ingestion ({newImageFiles.length})
              </TableCell>
              <TableCell
                align="left"
                className={`${classes.padding_left_right_null} ${classes.uploadTableColumn}`}
                // style={{ width: '30%' }}
              ></TableCell>
              <TableCell
                colSpan={2}
                align="left"
                className={`${classes.padding_left_right_null} ${classes.uploadTableColumn}`}
                // style={{ width: '30%' }}
              >
                Studio Name
              </TableCell>
              <TableCell
                colSpan={2}
                align="left"
                className={`${classes.padding_left_right_null} ${classes.uploadTableColumn}`}
                // style={{ width: '10%' }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newImageFiles.length ? (
              newImageFiles.map((row, i) => (
                <TableRow key={row.name}>
                  <TableCell
                    className={classes.padding_left_right_null}
                    align="left"
                    padding="none"
                    component="th"
                    scope="row"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    className={classes.padding_left_right_null}
                    align="left"
                  >
                    <span className={classes.iconWithDesc}>
                      {row.validationText === 'Validating' ? (
                        <></>
                      ) : row.validationText === fileValidationTexts.new ? (
                        <CheckCircleOutlinedIcon
                          style={{
                            color: '#00A324',
                            marginRight: '5px',
                          }}
                        />
                      ) : (
                        <ReportProblemOutlinedIcon
                          style={{
                            color: '#BD6839',
                            marginRight: '5px',
                          }}
                        />
                      )}
                      {row.validationText}
                    </span>
                  </TableCell>
                  <TableCell
                    className={classes.padding_left_right_null}
                    align="left"
                  >
                    <FormControl variant="outlined" className={classes.filter}>
                      <InputLabel id="studio-filter-label">Studio</InputLabel>
                      <Select
                        label="Studio"
                        value={row.studio || ''}
                        onChange={(e) => {
                          handleStudioChange(row.name, e.target.value)
                        }}
                        labelId="studio-filter-label"
                      >
                        <MenuItem value={'winterst'}>Winter Street</MenuItem>
                        <MenuItem value={'industrialcolor'}>
                          Industrial Color
                        </MenuItem>
                        <MenuItem value={'periscopehk'}>Periscope HK</MenuItem>
                        <MenuItem value={'periscope'}>Periscope</MenuItem>
                        <MenuItem value={'royalston'}>Royalston</MenuItem>
                        <MenuItem value={'schawk'}>Schawk</MenuItem>
                        <MenuItem value={'schawkasia'}>Schawk Asia</MenuItem>
                        <MenuItem value={'splashlight'}>Splashlight</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell
                    className={classes.padding_left_right_null}
                    align="left"
                  >
                    <IconButton
                      aria-label="delete"
                      disabled={row.validationText === 'Validating'}
                      onClick={() => onNewDelete(row)}
                    >
                      <DeleteForever />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No Selected Image
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      ) : (
        <></>
      )}
    </>
  )
}
