import DateFnsUtils from '@date-io/date-fns'
import { isEmpty, toString } from 'lodash'
import { convertUTCtoLocal } from '../../helpers/dateHelper'

const dateUtils = new DateFnsUtils()

export const workflowRowMapper = (imageDetails = []) => {
  const muiRows = imageDetails.map((imageDetail) => {
    const {
      workflow_info = {},
      item_info = {},
      project_infos = [],
    } = imageDetail
    return {
      id: workflow_info?.file_name.split('.')[0],
      fileName: workflow_info?.file_name,
      originalVersion: workflow_info?.version,
      originalImage: workflow_info?.original_image,
      isMultiTcin: workflow_info?.multi_tcin,
      isOriginal: workflow_info?.original,
      version: workflow_info?.uploaded_version
        ? workflow_info?.uploaded_version
        : workflow_info?.version,
      uploaded_version: workflow_info?.uploaded_version,
      originalImageUrl: workflow_info?.image_url,
      previewImage: workflow_info?.preview_image_url,
      zoomImage: workflow_info?.zoom_url,
      ingestionDate: convertUTCtoLocal(workflow_info?.ingested_date),
      photoStudio: workflow_info?.photo_studio,
      sentToPipeline: workflow_info?.sent_to_pipeline,
      photoShotDate: convertUTCtoLocal(workflow_info?.image_created_date),
      tbay: workflow_info?.tbay,
      fileSize: workflow_info?.file_size,
      photographer: workflow_info?.photographer,
      stylist: workflow_info?.stylist,
      altPosition: parseInt(workflow_info.alt_position)
        ? `${parseInt(workflow_info.alt_position)}`
        : workflow_info.alt_position,
      size: item_info?.size,
      projects:
        project_infos &&
        project_infos
          .reduce((filteredProjectNames, project) => {
            if (project.project_name) {
              filteredProjectNames.push(project.project_name)
            }
            return filteredProjectNames
          }, [])
          .join(', '),
      imageStatus: workflow_info?.image_status,
      tags: workflow_info?.tags?.map((tag) => {
        return {
          tagId: tag.tag_id,
          tagName: tag.tag_name,
          tagDesc: tag.tag_description,
        }
      }),
      notes: workflow_info?.notes,
      qaNotes: workflow_info?.qa_notes,
      subjectCodes: workflow_info?.subject_codes,
      assignee: workflow_info?.assignee?.display_name,
      qaAssignee: workflow_info?.qa_assignee?.display_name,
      wip: workflow_info?.wip,
      tcin: imageDetail.tcin,
      relationshipType: item_info?.relationship,
      dpci: imageDetail.dpci,
      description: item_info?.product_description,
      pipelineObj: workflow_info?.pipeline || {},
      sentToPipelineBy: workflow_info?.sent_to_pipeline_by || {},
      launchDate: convertUTCtoLocal(item_info?.launch_date_time),
      lastUpdatedDate: convertUTCtoLocal(workflow_info?.wf_updated_date),
      parentTcin: item_info?.parent_tcins,
      class: `${
        isEmpty(toString(item_info?.class_id)) ? '' : item_info?.class_id
      } - ${
        isEmpty(toString(item_info?.class_name)) ? '' : item_info?.class_name
      }`,
      department: `${
        isEmpty(toString(item_info?.department_id))
          ? ''
          : item_info?.department_id
      } - ${
        isEmpty(toString(item_info?.department_name))
          ? ''
          : item_info?.department_name
      }`,
      barcode: item_info?.barcode_number,
      vendor: item_info?.vendor_id,
      manufacturerStyle: item_info?.manufacturer_style,
      color: item_info?.color,
      brands: item_info?.brands,
      lifecycle: item_info?.item_state,
    }
  })

  return muiRows
}

export const workflowCsvMapper = (rows) => {
  let data = [...rows].map((row) => {
    let { sourceImage, tags, subjectCodes = '', ...restOfTheRow } = row
    let tcinAndPropsData = subjectCodes?.split(/(?:,|;)+/).reduce(
      (acc, dat) => {
        if (dat.length) {
          if (dat.toLowerCase().includes('_p')) {
            acc['props'].push(dat)
          } else {
            acc['multi'].push(dat)
          }
        }
        return acc
      },
      { props: [], multi: [] }
    )
    return {
      ...restOfTheRow,
      imageStatus: restOfTheRow.imageStatus.toString(),
      tcin_props: tcinAndPropsData?.props?.join(','),
      subjectCodes: tcinAndPropsData?.multi?.join(';'),
    }
  })
  return data
}
