import * as React from 'react'
import {
  IconButton,
  Card,
  CardContent,
  Chip,
  Typography,
  CardHeader,
  Popover,
} from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import { pipeLineStatusColours, pipeLineStatus } from './constants'
import CloseIcon from '@material-ui/icons/Close'
import DateFnsUtils from '@date-io/date-fns'

const dateUtils = new DateFnsUtils()

const useStyles = makeStyles({
  chipRoot: {
    '& .MuiChip-icon': {
      order: 1, // the label has a default order of 0, so this icon goes after the label
      margin: 0,
    },
  },
  cardRoot: {
    width: '400px',
    minHeight: '220px',
  },
  headerIcon: {
    height: '24px',
    marginLeft: '5px',
  },
  cardTitleClass: {
    display: 'flex !important',
    alignItems: 'center',
    fontSize: '18px !important',
    color: '#333',
    fontWeight: '500 !important',
  },
  cardTitleText: {
    fontSize: '16px !important',
    fontWeight: '500 !important',
  },
})

export default function SentToPipelineFlyout({ data = {} }) {
  const {
    imageStatus = '',
    pipelineObj = {},
    sentToPipeline = '',
    sentToPipelineBy = {},
  } = data
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const lowerCaseParam = imageStatus.toLowerCase()

  const handleTooltipClose = () => {
    setAnchorEl(null)
  }

  const handleTooltipToggle = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const clickable = pipeLineStatus.includes(lowerCaseParam)
  const open = Boolean(anchorEl)
  const icon = pipeLineStatusColours[lowerCaseParam]?.icon
    ? pipeLineStatusColours[lowerCaseParam].icon
    : null

  return (
    <>
      <SimpleCard
        onCardClose={handleTooltipClose}
        isOpen={open}
        anchor={anchorEl}
        icon={icon}
        sentToPipeLineData={{
          ...pipelineObj,
          sentToPipelineDate: sentToPipeline,
          sentToPipelineBy,
        }}
        title={imageStatus}
      />
      <Chip
        label={imageStatus}
        {...(icon && {
          icon: pipeLineStatusColours[lowerCaseParam].icon,
        })}
        {...(clickable && {
          clickable: true,
        })}
        {...(clickable && {
          onClick: handleTooltipToggle,
        })}
        size="small"
        classes={{
          root: classes.chipRoot,
        }}
        style={{
          padding: '13px',
          border: `2px solid ${
            pipeLineStatusColours[lowerCaseParam]?.color || '#999999'
          }`,
          background: 'transparent',
        }}
      />
    </>
  )
}

export const SimpleCard = ({
  title = '',
  onCardClose = () => {},
  isOpen = false,
  anchor = null,
  icon = null,
  sentToPipeLineData = {},
}) => {
  const classes = useStyles()
  const {
    message = '',
    sentToPipelineDate = '',
    sentToPipelineBy: { display_name = '' } = {},
  } = sentToPipeLineData

  return (
    <Popover
      open={isOpen}
      onClose={onCardClose}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Card className={classes.cardRoot}>
        <CardHeader
          classes={{
            title: classes.cardTitleClass,
          }}
          action={
            <IconButton aria-label="close" onClick={onCardClose}>
              <CloseIcon />
            </IconButton>
          }
          title={
            <>
              {title}
              {icon ? (
                <span className={classes.headerIcon}>{icon}</span>
              ) : (
                <></>
              )}
            </>
          }
        />
        <CardContent>
          <Typography
            variant="body2"
            component="p"
            style={{ marginBottom: '16px' }}
          >
            <span className={classes.cardTitleText}>Sent To Pipeline</span>
            <br />
            <span>
              On{' '}
              <b>
                {dateUtils.format(
                  new Date(sentToPipelineDate),
                  'MMM dd, y hh:mm'
                )}
              </b>{' '}
              {display_name ? (
                <>
                  by <b>{display_name}</b>
                </>
              ) : (
                <></>
              )}
            </span>
          </Typography>
          {message ? (
            <Typography variant="body2" component="p">
              <span className={classes.cardTitleText}>Pipeline Message</span>
              <br />
              <span>{message}</span>
            </Typography>
          ) : (
            <></>
          )}
        </CardContent>
      </Card>
    </Popover>
  )
}
