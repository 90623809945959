import { combineReducers } from 'redux'
import authReducer from './Auth/reducer'
import uploadsReducer from './uploads/reducer'
import routineTCINListReducer from './RouteinTcin/reducer'
import tcinDetailReducer from './TcinDetails/reducer'
import reportReducer from './Reports/reducer'
import supportReducer from './Support/reducer'
import styleGuideReducer from './StyleGuides/reducer'
import dashboardReducer from './Dashboard/reducer'
import tcinPhotoInfoReducer from './TcinInfo/reducer'
import layoutReducer from './Layout/reducer'
import workflowReducer from './Workflow/reducer'
import notificationReducer from './Notification/reducer'
import downloadsReducer from './Downloads/reducer'

const rootReducer = combineReducers({
  authReducer,
  uploadsReducer,
  routineTCINListReducer,
  tcinDetailReducer,
  reportReducer,
  supportReducer,
  styleGuideReducer,
  dashboardReducer,
  tcinPhotoInfoReducer,
  layoutReducer,
  workflowReducer,
  notificationReducer,
  downloadsReducer,
})

export default rootReducer
