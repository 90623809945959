import Moment from 'moment-timezone'
import { camelCase } from 'lodash'
// import apiConfig from '../../../../config/apiConfig'

const apiConfig = {
  externalUser: false,
}
const getChunkNumbers = (totalNumberOfChunks = 0) => {
  let chunkNumbers = []
  for (let chunkNumber = 0; chunkNumber < totalNumberOfChunks; chunkNumber++) {
    chunkNumbers.push(chunkNumber)
  }
  return chunkNumbers
}

const getCurrentUserLocation = () => {
  let currentLocation = Moment.tz.guess() === 'Asia/Calcutta' ? 'India' : 'MPLS'
  let userType = apiConfig.externalUser ? 'EXTERNAL' : 'INTERNAL'
  return userType + ' - ' + currentLocation
}
export const formatPaginationQueryParams = (pagination) => {
  let params = ''
  params += `page=${pagination.page}&size=${pagination.size}`
  if (pagination.sort) {
    // spring paging reqires camelcase field name for sorting
    params += `&sort=${camelCase(pagination.sort.field)},${
      pagination.sort.direction
    }`
  }
  return params
}

export { getCurrentUserLocation, getChunkNumbers }
