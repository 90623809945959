import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import { Grid, Button, TextField, Typography, Link } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { isEmpty } from 'lodash'
import {
  getSupportAPI,
  attachmentSupportAPI,
} from '../../store/Support/actionCreator'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import {
  supportIssueTypeList,
  supportpriorityList,
  supportSuccessMessage,
  supportErrorMessage,
} from '../../constants/common'

import { setBreadcrumbs } from '../../store/Layout/actionCreator'
import { useLocation } from 'react-router-dom'
import apiConfig from '../../apiConfig'
import { withAuth } from '@praxis/component-auth'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '1200px',
    width: 'auto',
    padding: '15px 15px 5px 15px',
  },
  notchedOutlineNew: {
    borderWidth: '1px !important',
    borderColor: '#666 !important',
  },
  searchInput: {
    margin: 'unset',
    color: '#666',
    fontSize: '14px',
  },
  searchInputLabel: {
    color: '#666 !important',
    fontSize: '14px',
    fontWeight: 'initial',
  },
  changeToSelect: {
    color: '#666!important',
    fontSize: '14px',
    fontWeight: 'initial',
  },
  fileUploadContainer: {
    width: '45%',
  },
  formContainer: {
    width: '35%',
  },
  labelmandatory: {
    fontSize: '12px',
    color: 'Red',
  },
  largeTextFiled: {
    width: '415px',
  },
  smallTextField: {
    width: '200px',
  },
  displayFiled: {
    display: 'none',
  },
  imgStyle: {
    fontSize: '14px',
    color: 'Black',
  },
}))

const Support = (props) => {
  const { email = '' } = props
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(setBreadcrumbs(props.customPath, pathname))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const classes = useStyles()
  const { support, status } = useSelector(
    ({ supportReducer: { support = '', status = '' } }) => {
      return { support, status }
    }
  )
  const [issueType, setIssueType] = React.useState('')
  const [priority, setPriority] = React.useState('')
  const [summary, setSummary] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [image, setImage] = React.useState({ file: '' })
  const [isSubmitted, setIsSubmitted] = React.useState(true)
  const [imageName, setImageName] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [userMessage, setUserMessage] = React.useState('')
  const [isSubmitClicked, setSubmitClicked] = React.useState(false)

  const handleIssueChange = (event) => {
    setIssueType(event.target.value)
  }
  const handlePriorityChange = (event) => {
    setPriority(event.target.value)
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
  }

  const handleSummaryChange = (event) => {
    setSummary(event.target.value)
  }

  const handleChange = (e) => {
    setImageName(e.target.files[0].name)
    e.target.files.length
      ? setImage({ file: e.target.files[0] })
      : setImage({ file: '' })
  }

  const handleClose = () => {
    setOpen(false)
    setSubmitClicked(false)
  }

  const handleSubmit = (event) => {
    setSubmitClicked(true)
    dispatch(getSupportAPI(summary, description, issueType, priority, email))
  }

  function clearData() {
    setDescription('')
    setSummary('')
    setImageName('')
    setUserMessage(supportSuccessMessage)
    setOpen(true)
    setIsSubmitted(false)
    setSubmitClicked(false)
  }
  useEffect(() => {
    if (isSubmitClicked) {
      if (status === 'success' && !isEmpty(imageName)) {
        dispatch(attachmentSupportAPI(image, support))
        clearData()
      } else if (status === 'success') {
        clearData()
      } else if (status === 'Error') {
        setUserMessage(supportErrorMessage)
        setOpen(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitClicked, support])

  const handleSubmitButton = (event) => {
    if (
      isEmpty(description) ||
      isEmpty(summary) ||
      isEmpty(issueType) ||
      isEmpty(priority)
    ) {
      setIsSubmitted(true)
    } else {
      setIsSubmitted(false)
    }
  }
  return (
    <div>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            justify="center"
            spacing={10}
            alignItems="center"
            direction="column"
            style={{ paddingTop: 100, paddingBottom: 40 }}
          >
            <Grid item style={{ maxWidth: 600, marginBottom: 30 }}>
              <Grid
                container
                justify="center"
                spacing={10}
                alignItems="center"
                direction="column"
              >
                <Typography variant="h6" align="center">
                  Please use the following documents to assist with any
                  questions you have in using Photobridge:
                </Typography>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href={apiConfig.userGuide}
                  variant="h5"
                  align="center"
                >
                  Photobridge User Guide
                </Link>
              </Grid>
            </Grid>
            <div style={{ margin: 30 }}>
              <Grid item style={{ maxWidth: 600, marginBottom: 30 }}>
                <Grid
                  container
                  justify="center"
                  spacing={10}
                  alignItems="center"
                  direction="column"
                >
                  <Typography variant="h6" align="center">
                    If you still have questions about Photobridge, please reach
                    out to the Photobridge team by contacting the following
                    email address:
                  </Typography>
                  <Link
                    href={`mailto:${apiConfig.supportEmailGroup}`}
                    variant="h5"
                    align="center"
                  >
                    {apiConfig.supportEmailGroup}
                  </Link>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        {!apiConfig.isPol ? (
          <Grid item xs={12}>
            <Grid container justify="center" spacing={2} alignItems="center">
              <h3>You can also raise an Issue by submitting below</h3>
              <Grid container justify="center">
                <label name="mandatory" className={classes.labelmandatory}>
                  * Fields are mandatory
                </label>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="center">
                  <Grid item>
                    <TextField
                      id="summary"
                      label="Summary"
                      variant="outlined"
                      size="medium"
                      required={true}
                      value={summary}
                      className={classes.largeTextFiled}
                      onChange={handleSummaryChange}
                      onMouseOut={handleSubmitButton}
                      InputLabelProps={{
                        className: classes.searchInputLabel,
                      }}
                      InputProps={{
                        classes: {
                          input: classes.searchInput,
                          notchedOutline: classes.notchedOutlineNew,
                          focused: classes.notchedOutlineNew,
                          adornedEnd: classes.adornedEnd,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="center" spacing={2}>
                  <Grid item>
                    <TextField
                      select
                      id="IssueType"
                      label="Issue Type"
                      name="IssueType"
                      onChange={handleIssueChange}
                      className={classes.smallTextField}
                      onMouseOut={handleSubmitButton}
                      variant="outlined"
                      required={true}
                      value={issueType}
                      InputLabelProps={{
                        className: classes.searchInputLabel,
                      }}
                      InputProps={{
                        classes: {
                          input: classes.searchInput,
                          notchedOutline: classes.notchedOutlineNew,
                          focused: classes.notchedOutlineNew,
                          adornedEnd: classes.adornedEnd,
                        },
                      }}
                    >
                      {supportIssueTypeList.map((option, i) => (
                        <MenuItem key={option.value + i} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item>
                    <TextField
                      select
                      id="Priority"
                      label="Priority"
                      name="Priority"
                      value={priority}
                      onChange={handlePriorityChange}
                      className={classes.smallTextField}
                      onMouseOut={handleSubmitButton}
                      variant="outlined"
                      required={true}
                      InputLabelProps={{
                        className: classes.searchInputLabel,
                      }}
                      InputProps={{
                        classes: {
                          input: classes.searchInput,
                          notchedOutline: classes.notchedOutlineNew,
                          focused: classes.notchedOutlineNew,
                          adornedEnd: classes.adornedEnd,
                        },
                      }}
                    >
                      {supportpriorityList.map((option, i) => (
                        <MenuItem key={option.value + i} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify={'center'} spacing={2}>
                  <Grid item>
                    <TextField
                      id="outlined-multiline-static"
                      label="Description"
                      multiline
                      rows={5}
                      variant="outlined"
                      size="medium"
                      className={classes.largeTextFiled}
                      value={description}
                      onChange={handleDescriptionChange}
                      required={true}
                      onMouseOut={handleSubmitButton}
                      InputLabelProps={{
                        className: classes.searchInputLabel,
                      }}
                      InputProps={{
                        classes: {
                          input: classes.searchInput,
                          notchedOutline: classes.notchedOutlineNew,
                          focused: classes.notchedOutlineNew,
                          adornedEnd: classes.adornedEnd,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify={'center'}>
                  <Grid item>
                    <div className={classes.fileUploadContainer}>
                      <input
                        className={classes.displayFiled}
                        id="contained-button-file"
                        type="file"
                        onChange={handleChange}
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          variant="outlined"
                          color="secondary"
                          component="span"
                          className={classes.largeTextFiled}
                        >
                          Attachment <CloudUploadIcon />
                        </Button>
                      </label>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justify="center">
                <label name="mandatory" className={classes.imgStyle}>
                  {imageName}
                </label>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify={'center'} spacing={2}>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleSubmit}
                      disabled={isSubmitted}
                    >
                      Submit Request
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container justify="center" spacing={2} alignItems="center">
            <h3>
              To raise an issue, Please raise a ticket using{' '}
              <Link
                target="_blank"
                rel="noreferrer"
                href="https://www.partnersonline.com/page/help"
                align="center"
              >
                service-now
              </Link>
            </h3>
          </Grid>
        )}

        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ marginTop: 20 }}
        >
          <Typography variant="h6" align="center">
            Thanks, Photobridge Product Team
          </Typography>
        </Grid>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {userMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              ok
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </div>
  )
}

const mapAuthToProps = (auth) => {
  return {
    email: auth.session?.userInfo?.email,
  }
}

export default withAuth(mapAuthToProps)(Support)
