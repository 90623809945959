import React from 'react'
import { TableCell, TableRow, IconButton } from '@mui/material'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'
import { fileValidationTexts } from '../../constants/common'
import { DeleteForever } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  padding_left_right_null: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
  iconWithDesc: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function DroppedFilesTableWorkflow({
  droppedFiles = {},
  onExistingDelete = () => {},
}) {
  const classes = useStyles()
  return (
    <>
      {Object.keys(droppedFiles).length ? (
        Object.keys(droppedFiles).map((row, i) => (
          <TableRow key={row.name}>
            <TableCell
              className={classes.padding_left_right_null}
              align="left"
              padding="none"
              component="th"
              scope="row"
            >
              {row}
            </TableCell>
            <TableCell className={classes.padding_left_right_null} align="left">
              <span className={classes.iconWithDesc}>
                {droppedFiles[row] === 'Validating' ? (
                  <></>
                ) : droppedFiles[row] === fileValidationTexts.valid ? (
                  <CheckCircleOutlinedIcon
                    style={{
                      color: '#00A324',
                      marginRight: '5px',
                    }}
                  />
                ) : (
                  <ReportProblemOutlinedIcon
                    style={{
                      color: '#BD6839',
                      marginRight: '5px',
                    }}
                  />
                )}
                {droppedFiles[row]}
              </span>
            </TableCell>
            <TableCell
              className={classes.padding_left_right_null}
              align="left"
            ></TableCell>
            <TableCell className={classes.padding_left_right_null} align="left">
              <IconButton
                aria-label="delete"
                disabled={droppedFiles[row] === 'Validating'}
                onClick={() => onExistingDelete(row)}
              >
                <DeleteForever />
              </IconButton>
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={3} align="center">
            No Selected Image
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
