export const TOGGLE_DOWNLOAD_MANAGER = 'TOGGLE_DOWNLOAD_MANAGER'

export const SET_FAILED_DOWNLOADS = 'SET_FAILED_DOWNLOADS'
export const CLEAR_FAILED_DOWNLOADS = 'CLEAR_FAILED_DOWNLOADS'
export const SET_SUCCESS_DOWNLOADS = 'SET_SUCCESS_DOWNLOADS'
export const SET_INPROGRESS_DOWNLOADS = 'SET_INPROGRESS_DOWNLOADS'
export const SET_FILES_FOR_DOWNLOADS = 'SET_FILES_FOR_DOWNLOADS'
export const CLEAR_FILE_FOR_DOWNLOADS = 'CLEAR_FILE_FOR_DOWNLOADS'
export const RETRY_FAILED_DOWNLOADS = 'RETRY_FAILED_DOWNLOADS'
export const SET_DOWNLOAD_FLAG = 'SET_DOWNLOAD_FLAG'
