import * as React from 'react'
import {
  IconButton,
  Chip,
  ClickAwayListener,
  Typography,
  Button,
} from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import StickyNote2Icon from '@mui/icons-material/StickyNote2'
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined'

// const HtmlTooltip = styled(({ ...props }) => (
//     <Tooltip {...props} />
//   ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//       backgroundColor: '#f5f5f9',
//       color: 'rgba(0, 0, 0, 0.87)',
//       maxWidth: 220,
//       fontSize: theme.typography.pxToRem(12),
//       border: '1px solid #dadde9',
//     },
//   }));

export const NotesIconFlyout = ({ notes }) => {
  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipToggle = () => {
    setOpen(!open)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        onClose={handleTooltipClose}
        open={open}
        placement="right-start"
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          <React.Fragment>
            <Typography>{notes}</Typography>
          </React.Fragment>
        }
      >
        <IconButton onClick={handleTooltipToggle}>
          <StickyNote2Icon color="primary" />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  )
}

export const NotesChipFlyout = ({ index, tag, notes }) => {
  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipToggle = () => {
    setOpen(!open)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        onClose={handleTooltipClose}
        open={open}
        placement="right-start"
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          <React.Fragment>
            <Typography color="inherit" variant="body1">
              {tag}
            </Typography>
            <Typography variant="body2">{notes}</Typography>
          </React.Fragment>
        }
        sx={{
          [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'white',
            border: '1px solid #dadde9',
          },
        }}
      >
        <Chip
          key={index}
          label={tag}
          icon={<StickyNote2OutlinedIcon sx={{ '&&': { color: '#1976d2' } }} />}
          onClick={handleTooltipToggle}
          size="small"
          style={{ marginRight: 7, marginBottom: 7 }}
        />
      </Tooltip>
    </ClickAwayListener>

    //     <ClickAwayListener onClickAway={handleTooltipClose}>
    //     <div>
    //       <Tooltip
    //         PopperProps={{
    //           disablePortal: true,
    //         }}
    //         onClose={handleTooltipClose}
    //         open={open}
    //         disableFocusListener
    //         disableHoverListener
    //         disableTouchListener
    //         title="Add"
    //       >
    //         <Button onClick={handleTooltipOpen}>Click</Button>
    //       </Tooltip>
    //     </div>
    //   </ClickAwayListener>
  )
}
