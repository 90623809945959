import CreateProject from './CreateProject'
import ProjectList from './ProjectList'
import ProjectDetail from './ProjectDetail'
import EditProject from './EditProject'

const projectRoutes = [
  {
    key: 'list',
    path: '/',
    component: ProjectList,
  },
  {
    key: 'create',
    name: 'Create Project',
    path: '/create',
    component: CreateProject,
  },
  {
    key: 'details',
    name: ':project_name',
    path: '/:project_id',
    component: ProjectDetail,
  },
  {
    key: 'edit',
    name: 'Edit project',
    path: '/:project_id/edit',
    component: EditProject,
  },
]

export default projectRoutes
