import React from 'react'
import { useLocation, Routes, Route } from 'react-router-dom'
import childRoutes from './childRoutes'
import { useDispatch } from 'react-redux'
import { setBreadcrumbs } from '../../store/Layout/actionCreator'
import ProjectList from './ProjectList'
import { ProtectedElement } from '@praxis/component-auth'

export const DashboardContext = React.createContext({
  dState: {},
  dMethods: {},
})

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHECKBOX_HANDLER':
      return { ...state, selected: action.payload }
    case 'ORDER_BY_HANDLER':
      return { ...state, orderBy: action.payload }
    case 'ORDER_HANDLER':
      return { ...state, order: action.payload }
    case 'PROJECTS_HANDLER':
      return { ...state, projects: action.payload }
    default:
      return state
  }
}
export default function Dashboard(props) {
  let { pathname } = useLocation()

  const rDispatch = useDispatch()
  React.useEffect(() => {
    rDispatch(setBreadcrumbs(props.customPath, pathname))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  let initState = React.useMemo(
    () => ({
      selected: [],
      orderBy: '',
      order: '',
      projects: [],
    }),
    []
  )

  const [state, dispatch] = React.useReducer(reducer, initState)

  const methods = React.useMemo(
    () => ({
      checkboxHandler: (id) =>
        dispatch({
          type: 'CHECKBOX_HANDLER',
          payload: id,
        }),

      orderByHandler: (col) =>
        dispatch({
          type: 'ORDER_BY_HANDLER',
          payload: col,
        }),

      orderHandler: (dir) =>
        dispatch({
          type: 'ORDER_HANDLER',
          payload: dir,
        }),

      projectsHandler: (data) =>
        dispatch({
          type: 'PROJECTS_HANDLER',
          payload: data,
        }),
    }),
    []
  )

  return (
    <DashboardContext.Provider value={{ dState: state, dMethods: methods }}>
      <Routes>
        <Route path="/">
          <Route index element={<ProjectList />} />
          {childRoutes.map((page, key) => {
            return (
              <Route
                exact={true}
                path={`${page.path}`}
                element={
                  <ProtectedElement>
                    <page.component
                      style={{ indicatorColor: 'primary' }}
                      parentCrumb={{ name: 'Projects', link: props.customPath }}
                      customPath={
                        props.customPath + (page.name ? '/' + page.name : '')
                      }
                      pathname={pathname}
                      path={`${props.path}${page.path}`}
                    />
                  </ProtectedElement>
                }
                key={key}
              />
            )
          })}
        </Route>
      </Routes>
    </DashboardContext.Provider>
  )
}
