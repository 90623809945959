import React from 'react'
import classNames from 'classnames'
import { Button, Snackbar, SnackbarContent } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { showNotification } from '../../store/Notification/actionCreator'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'

import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'
import { useDispatch, useSelector } from 'react-redux'

const variantIcon = {
  success: CheckCircleOutlinedIcon,
  error: ErrorOutlineOutlinedIcon,
  info: InfoOutlinedIcon,
}

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: '#4CAF50 !important',
  },
  error: {
    backgroundColor: '#CC0000 !important',
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  icon: {
    fontSize: 24,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(2),
  },
  message: {
    fontSize: 15,
    display: 'flex',
    alignItems: 'center',
  },
}))

const Notification = () => {
  const classes = useStyles()
  const notificationState = useSelector((state) => state.notificationReducer)
  const dispatch = useDispatch()
  const {
    isShown: notificationIsShown = '',
    message = '',
    duration = '',
    variant = '',
    isCopy = false,
    copyText = '',
  } = notificationState
  const direction = { vertical: 'top', horizontal: 'right' }
  const Icon = variantIcon[variant]

  const handleRequestClose = (event, reason) => {
    const { variant = '' } = notificationState
    if (reason === 'clickaway' && variant !== 'error') {
      return
    }
    dispatch(showNotification(false, '', variant))
  }

  const action = (
    <>
      {isCopy && (
        <CopyToClipboard text={copyText}>
          <Button
            style={{
              padding: '6px 3px',
              fontFamily: 'Roboto',
              fontWeight: '400',
              fontSize: '14px',
              color: '#FFFFFF',
            }}
            startIcon={<FileCopyOutlinedIcon />}
          >
            Copy
          </Button>
        </CopyToClipboard>
      )}
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        className={classes.close}
        onClick={handleRequestClose}
        size="medium"
      >
        <CloseOutlinedIcon className={classes.icon} />
      </IconButton>
    </>
  )

  return (
    <Snackbar
      anchorOrigin={direction}
      id="globalSnackbar"
      open={notificationIsShown}
      onClose={handleRequestClose}
      autoHideDuration={duration}
    >
      <SnackbarContent
        className={classNames(classes[variant])}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            <span>{message}</span>
          </span>
        }
        action={action}
      />
    </Snackbar>
  )
}

export default Notification
