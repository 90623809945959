import { modalStyles } from '../containers/TcinInfo/DataTable'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { withStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import { makeStyles } from '@material-ui/core/styles'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import defaultThumbnail from '../images/defaultThumbnail.jpg'
import { useRef } from 'react'
import {
  Button,
  Skeleton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Drawer,
  Table,
  Chip,
  Typography,
  CircularProgress,
  IconButton,
  Checkbox,
  List,
  ListItemText,
  ListSubheader,
  ListItem,
  Stack,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import apiConfig from '../apiConfig'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import DownloadIcon from '@mui/icons-material/Download'
import { imageDownloadHost } from '../constants/common'
import Dialog from '@material-ui/core/Dialog'
import ConfirmationDailog from './ConfirmationDailog'
import { openZoomedImage } from '../store/TcinInfo/actionCreator'
import { pipeLineStatus } from '../containers/Workflow/constants'
import { showNotification } from '../store/Notification/actionCreator'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import {
  handleDownloadJpegs,
  downloadImageFromUrl,
  downloadBulkFileAPICall,
} from '../helpers/imageDownloadHelper'
import { cloneDeep } from 'lodash'
import DateFnsUtils from '@date-io/date-fns'
import { convertUTCtoLocal } from '../helpers/dateHelper'

const dateUtils = new DateFnsUtils()

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: '#366CD9',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: 'rgb(17, 82, 147) !important',
      color: '#ffffff !important',
    },
    '&:focus': {
      backgroundColor: 'rgb(17, 82, 147) !important',
      color: '#ffffff !important',
    },
  },
  outlined: {
    border: '1px solid rgb(17, 82, 147) !important',
    background: 'transparent !important',
    color: 'rgb(17, 82, 147) !important',
    '&:hover': {
      backgroundColor: '#366CD9 !important',
      color: '#ffffff !important',
    },
    '&:focus': {
      backgroundColor: '#366CD9 !important',
      color: '#ffffff !important',
    },
  },
  userDetails: {
    color: '#366CD9',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
  },
}))

const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          {Array.isArray(expandComponent) ? (
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : (
            <></>
          )}
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && <> {expandComponent}</>}
    </>
  )
}

const ImageDialog = ({
  open,
  src,
  isLoading = false,
  handleClose,
  title = '',
  onImageArchive = () => {},
  tableName = '',
  imageStatus = '',
  uploadedVersion = '',
  version = '',
}) => {
  const [images, setImages] = useState([])
  const [versions, setVersions] = useState({})
  const [versionsLoading, setVersionsLoading] = useState(false)
  const dispatch = useDispatch()
  const [confirmationDailog, setConfirmationDailog] = useState(false)
  const [zoomPreviewLoading, setZoomPreviewLoading] = useState(false)
  const [selection, setSelection] = useState([])
  const fileName = useSelector(
    (state) => state.tcinPhotoInfoReducer.enlargeImage.fileName
  )
  const originalVersion = useSelector(
    (state) => state.tcinPhotoInfoReducer.enlargeImage.originalVersion
  )
  const imageUrl = useSelector(
    (state) => state.tcinPhotoInfoReducer.enlargeImage.imageUrl
  )
  const newClass = useStyles()
  const classes = modalStyles()
  const rootRef = useRef(null)
  // commenting this line for now to test the resolution in prod
  // const isRetinaDisplay = useMediaQuery('(-webkit-min-device-pixel-ratio: 2)')
  src += '?scl=1'

  const isArchived = imageStatus === 'Archived'
  const DialogTitle = withStyles(classes)((props) => {
    const { children, onClose } = props
    return (
      <MuiDialogTitle
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        disableTypography
        className={classes.root}
      >
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose}>
            <CancelIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  })

  const openImage = async (src) => {
    if (tableName === 'workflow') {
      setZoomPreviewLoading(true)
      try {
        const image = await downloadBulkFileAPICall(
          `${src}?${Date.now()} `,
          fileName
        )
        const imageBlog = await image.blob()
        var imageURL = await URL.createObjectURL(
          imageBlog.slice(0, imageBlog.size, 'image/jpeg')
        )
        window.open(imageURL, '_blank')
        setZoomPreviewLoading(false)
      } catch (e) {
        console.log(e)
      }
    } else {
      window.open(src, '_blank')
    }
  }

  const handleToggle = (isChecked, imageDetails) => () => {
    let newSelected = cloneDeep(selection)
    if (isChecked) {
      newSelected.push(imageDetails)
    } else {
      const selectedRow = newSelected.findIndex(
        (e) => e.support_image_name === imageDetails.support_image_name
      )
      newSelected.splice(selectedRow, 1)
    }

    setSelection(newSelected)
  }

  const errorFileNotification = (fileName) => {
    dispatch(
      showNotification(
        true,
        `Error while downloading ${fileName}`,
        'error',
        1000
      )
    )
  }

  const downloadAsset = async (imgUrl = '') => {
    dispatch(showNotification(true, `Download In Progress`, 'info', 500))

    if (imgUrl) {
      setTimeout(() => {
        dispatch(showNotification(false, '', 'info'))
      }, 1000)
      const { pathname = '' } = new URL(imgUrl)
      let blobResponse
      try {
        const response = await downloadBulkFileAPICall(
          `${imageDownloadHost}${pathname}?${Date.now()}`,
          fileName
        )
        if (response.ok) {
          blobResponse = await response.blob()
        } else {
          errorFileNotification(fileName)
        }
      } catch (e) {
        errorFileNotification(fileName)
      }
      if (blobResponse) {
        downloadImageFromUrl(blobResponse, fileName, () => {})
      }
    } else {
      errorFileNotification(fileName)
    }
  }

  useEffect(() => {
    if (tableName === 'workflow') {
      const { getSupportImagesApi = '', getImageVersionsApi = '' } = apiConfig
      setVersionsLoading(true)
      axios
        .post(
          `${getSupportImagesApi}`,
          {
            image_details: [
              {
                file_name: fileName,
                version: originalVersion,
                uploaded_version: uploadedVersion,
              },
            ],
          },
          {
            params: {
              key: apiConfig.apiKey,
            },
          }
        )
        .then((res) => {
          const { primary_and_support_image_details = [] } = res.data
          const { primary_image_url = '', support_images = [] } =
            primary_and_support_image_details[0] || {}
          setImages(support_images)
        })
        .catch((e) => {
          dispatch(
            showNotification(
              true,
              'Error while getting support images',
              'error',
              1000
            )
          )
        })
      axios
        .get(`${getImageVersionsApi}`, {
          params: { image_name: fileName, key: apiConfig.apiKey },
        })
        .then((res) => {
          setVersionsLoading(false)
          const { image_details = [], image_name = '' } = res.data
          let imageVersionList = image_details.reduce((acc, dat) => {
            let version = dat.version.replace(/^\D+/g, '')
            if (Math.floor(version) < Number(version) + 1) {
              if (acc[`v${Math.floor(version)}`]) {
                acc[`v${Math.floor(version)}`].subVersion.push(dat)
              } else {
                acc[`v${Math.floor(version)}`] = { ...dat, subVersion: [] }
              }
            }
            return acc
          }, {})
          setVersions({
            imageName: image_name,
            imageVersions: imageVersionList,
          })
        })
        .catch((e) => {
          setVersionsLoading(false)
          dispatch(
            showNotification(
              true,
              'Error while getting versions',
              'error',
              1000
            )
          )
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const versionChangeHandler = (data) => {
    const {
      image_url = '',
      image_preview_url = '',
      image_zoom_url = '',
      version = '',
      uploaded_version = '',
    } = data
    const { imageName = '' } = versions
    dispatch(
      openZoomedImage(
        `${imageName || 'Unknown'} | ${version}`,
        image_zoom_url,
        tableName,
        imageName,
        image_url,
        originalVersion,
        imageStatus,
        version,
        uploaded_version
      )
    )
  }

  const generateExpandableRows = (data) => {
    let rowsArr = []
    if (data.image_history_status_list.length > 1) {
      data.image_history_status_list.forEach((da, ke) => {
        if (ke > 0) {
          rowsArr.push(
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell align="left"></TableCell>
              <TableCell align="left">
                {convertUTCtoLocal(da?.timestamp)}
              </TableCell>
              <TableCell align="left">
                <>
                  <span className={newClass.userDetails}>
                    {da?.user?.display_name}
                  </span>{' '}
                  {da?.message || ''}
                </>
              </TableCell>
            </TableRow>
          )
        } else {
          return <></>
        }
      })
    }
    if (data.subVersion.length) {
      data?.subVersion.forEach((k, i) => {
        const isVersionFilled = version === k.version
        rowsArr.push(
          <>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell align="left">
                <Chip
                  classes={{
                    filled: newClass.chip,
                    outlined: newClass.outlined,
                  }}
                  clickable
                  label={k.version}
                  color="primary"
                  variant={isVersionFilled ? 'filled' : 'outlined'}
                  onClick={() => versionChangeHandler(k)}
                />
              </TableCell>
              <TableCell align="left">
                {convertUTCtoLocal(k?.image_history_status_list[0]?.timestamp)}
              </TableCell>
              <TableCell align="left">
                <>
                  <span className={newClass.userDetails}>
                    {k?.image_history_status_list[0]?.user.display_name || ''}
                  </span>{' '}
                  {k?.image_history_status_list[0].message || ''}
                </>
              </TableCell>
            </TableRow>
            {k?.image_history_status_list.map((da, ke) => {
              if (ke > 0) {
                return (
                  <TableRow>
                    <TableCell padding="checkbox" />
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">
                      {convertUTCtoLocal(
                        k?.image_history_status_list[0]?.timestamp
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <>
                        <span className={newClass.userDetails}>
                          {da?.user?.display_name || ''}
                        </span>{' '}
                        {da?.message || ''}
                      </>
                    </TableCell>
                  </TableRow>
                )
              } else {
                return <></>
              }
            })}
          </>
        )
      })
    }
    return rowsArr
  }

  const versionsWithHistory = versions?.imageVersions

  return (
    <div className={classes.root} ref={rootRef}>
      <ConfirmationDailog
        open={confirmationDailog}
        alertMessage="Are you sure?"
        message={`Selected file will be ${
          isArchived ? 'unarchived' : 'archived'
        }`}
        onConfirm={() => {
          setConfirmationDailog(false)
          onImageArchive(isArchived)
        }}
        onClose={() => setConfirmationDailog(false)}
      />
      {tableName === 'workflow' ? (
        <Drawer
          aria-labelledby="customized-dialog-title"
          open={open}
          anchor="right"
          onClose={handleClose}
          className={classes.modal}
          style={{ width: '80% !important' }}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {title.includes('.tif') ? 'File' : 'TCIN'} {title}
          </DialogTitle>

          <DialogContent dividers style={{ padding: 16 }}>
            <div style={{ display: 'flex' }}>
              <div style={{ padding: '0 16px 16px 0' }}>
                <img
                  src={src}
                  className={classes.imgStyle}
                  alt="full"
                  style={{
                    height: '450px',
                    width: '450px',
                    cursor: zoomPreviewLoading ? 'progress' : 'zoom-in',
                  }}
                  disabled={zoomPreviewLoading}
                  onClick={() => {
                    openImage(src)
                  }}
                  onError={(e) => {
                    e.target.src = defaultThumbnail
                  }}
                />

                <Stack
                  direction="row"
                  justifyContent={'space-between'}
                  style={{ paddingTop: '16px' }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => downloadAsset(imageUrl)}
                    startIcon={<DownloadIcon />}
                  >
                    Download Image
                  </Button>
                  {tableName === 'workflow' &&
                  !pipeLineStatus.includes(imageStatus) ? (
                    <Button
                      variant="outlined"
                      onClick={() => setConfirmationDailog(true)}
                      disabled={isLoading}
                      startIcon={
                        isArchived ? (
                          <UnarchiveOutlinedIcon />
                        ) : (
                          <ArchiveOutlinedIcon />
                        )
                      }
                    >
                      {isLoading ? (
                        <CircularProgress size={14} />
                      ) : isArchived ? (
                        'Unarchive'
                      ) : (
                        'Archive'
                      )}
                    </Button>
                  ) : (
                    <></>
                  )}
                </Stack>
              </div>
              <TableContainer
                style={{
                  borderLeft: '1px solid',
                  margin: '-16px 0 0',
                  maxHeight: '652px',
                }}
              >
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left">Versions</TableCell>
                      <TableCell align="left">Date, Time</TableCell>
                      <TableCell align="left">History</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!versionsLoading ? (
                      versionsWithHistory &&
                      Object.keys(versionsWithHistory).length > 0 ? (
                        Object.keys(versionsWithHistory).map((key, i) => {
                          const isFilled =
                            version === versionsWithHistory[key]?.version
                          return (
                            <ExpandableTableRow
                              key={versionsWithHistory[key]?.version}
                              expandComponent={
                                generateExpandableRows(versionsWithHistory[key])
                                  .length ? (
                                  generateExpandableRows(
                                    versionsWithHistory[key]
                                  )
                                ) : (
                                  <></>
                                )
                              }
                            >
                              <TableCell align="left">
                                <Chip
                                  classes={{
                                    filled: newClass.chip,
                                    outlined: newClass.outlined,
                                  }}
                                  clickable
                                  label={versionsWithHistory[key]?.version}
                                  color="primary"
                                  variant={isFilled ? 'filled' : 'outlined'}
                                  onClick={() =>
                                    versionChangeHandler(
                                      versionsWithHistory[key]
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell align="left">
                                {convertUTCtoLocal(
                                  versionsWithHistory[key]
                                    ?.image_history_status_list[0]?.timestamp
                                )}
                              </TableCell>
                              <TableCell align="left">
                                <>
                                  <span className={newClass.userDetails}>
                                    {versionsWithHistory[key]
                                      ?.image_history_status_list[0].user
                                      ?.display_name || ''}
                                  </span>{' '}
                                  {versionsWithHistory[key]
                                    ?.image_history_status_list[0].message ||
                                    ''}
                                </>
                              </TableCell>
                            </ExpandableTableRow>
                          )
                        })
                      ) : (
                        <TableRow height="40px">
                          <TableCell colSpan={4} align="center">
                            No Data found
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      [...Array(3)].map((k, i) => (
                        <TableRow key={i}>
                          <TableCell key={i} style={{ left: 0 }}>
                            <Skeleton />
                          </TableCell>
                          <TableCell key={i} style={{ left: 0 }}>
                            <Skeleton />
                          </TableCell>
                          <TableCell key={i} style={{ left: 0 }}>
                            <Skeleton />
                          </TableCell>
                          <TableCell key={i} style={{ left: 0 }}>
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            {images.length > 0 && (
              <div>
                <List
                  subheader={
                    <ListSubheader
                      sx={{
                        color: '#333333',
                        fontSize: '16px',
                        paddingLeft: 0,
                      }}
                    >
                      Support Images
                    </ListSubheader>
                  }
                  sx={{ width: '100%', maxWidth: 360, mb: 4 }}
                >
                  {images.map((image) => {
                    let {
                      support_image_name = '',
                      support_preview_image_url = '',
                      support_image_url = '',
                      support_zoom_image_url = '',
                    } = image
                    const isChecked =
                      (selection || []).findIndex(
                        (e) => e.support_image_name === image.support_image_name
                      ) >= 0
                    return (
                      <ListItem
                        sx={{
                          height: 72,
                        }}
                        key={support_image_name}
                        disablePadding
                      >
                        <Checkbox
                          edge="start"
                          onChange={handleToggle(!isChecked, image)}
                          checked={isChecked}
                          inputProps={{ 'aria-labelledby': support_image_name }}
                        />
                        <img
                          src={support_preview_image_url}
                          alt={support_image_name}
                          onError={(e) => {
                            e.target.src = defaultThumbnail
                          }}
                          style={{
                            height: 72,
                            cursor: zoomPreviewLoading ? 'progress' : 'pointer',
                          }}
                          disabled={zoomPreviewLoading}
                          onClick={() => {
                            openImage(support_zoom_image_url)
                          }}
                        />
                        <ListItemText
                          sx={{ textAlign: 'center' }}
                          id={support_image_name}
                          primary={
                            <Typography style={{ color: '#333333' }}>
                              {support_image_name}
                            </Typography>
                          }
                        />
                      </ListItem>
                    )
                  })}
                </List>

                <Button
                  variant="outlined"
                  onClick={() => {
                    handleDownloadJpegs({
                      filesList: selection,
                      progressNotificationCallback: () => {
                        dispatch(
                          showNotification(
                            true,
                            `Download In Progress`,
                            'info',
                            500
                          )
                        )
                      },
                      errorNotificationCallback: (fileName) => {
                        errorFileNotification(fileName)
                      },
                    })
                  }}
                  startIcon={<DownloadIcon />}
                  disabled={selection.length === 0}
                >
                  Download Support Images{' '}
                </Button>
              </div>
            )}
          </DialogContent>
        </Drawer>
      ) : (
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={open}
          onClose={handleClose}
          className={classes.modal}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {title.includes('.tif') ? 'File' : 'TCIN'} {title}
          </DialogTitle>
          <DialogContent dividers>
            <img
              src={src}
              className={classes.imgStyle}
              alt="full"
              style={{
                height: 564,
                width: 564,
                cursor: zoomPreviewLoading ? 'progress' : 'zoom-in',
              }}
              disabled={zoomPreviewLoading}
              onClick={() => {
                openImage(src)
              }}
              onError={(e) => {
                e.target.src = defaultThumbnail
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}

export default ImageDialog
