export const GET_ROUTINE_TCIN_LIST = 'GET_ROUTINE_TCIN_LIST'
export const GET_ROUTINE_TCIN_LIST_API = 'GET_ROUTINE_TCIN_LIST_API'
export const GET_ROUTINE_TCIN_LIST_API_FULFILLED =
  'GET_ROUTINE_TCIN_LIST_API_FULFILLED'
export const GET_ROUTINE_TCIN_LIST_API_PENDING =
  'GET_ROUTINE_TCIN_LIST_API_PENDING'
export const GET_ROUTINE_TCIN_LIST_API_REJECTED =
  'GET_ROUTINE_TCIN_LIST_API_REJECTED'

export const GET_MY_TASKS_LIST_API = 'GET_MY_TASKS_LIST_API'
export const GET_MY_TASKS_LIST_API_FULFILLED = 'GET_MY_TASKS_LIST_API_FULFILLED'
export const GET_MY_TASKS_LIST_API_PENDING = 'GET_MY_TASKS_LIST_API_PENDING'
export const GET_MY_TASKS_LIST_API_REJECTED = 'GET_MY_TASKS_LIST_API_REJECTED'

export const UPDATE_TCIN_LIST_API = 'UPDATE_TCIN_LIST_API'
export const UPDATE_TCIN_LIST_API_FULFILLED = 'UPDATE_TCIN_LIST_API_FULFILLED'
export const UPDATE_TCIN_LIST_API_PENDING = 'UPDATE_TCIN_LIST_API_PENDING'
export const UPDATE_TCIN_LIST_API_REJECTED = 'UPDATE_TCIN_LIST_API_REJECTED'

export const UPDATE_TCIN_LIST_SEARCH_API = 'UPDATE_TCIN_LIST_SEARCH_API'
export const UPDATE_TCIN_LIST_SEARCH_API_FULFILLED =
  'UPDATE_TCIN_LIST_SEARCH_API_FULFILLED'
export const UPDATE_TCIN_LIST_SEARCH_API_PENDING =
  'UPDATE_TCIN_LIST_SEARCH_API_PENDING'
export const UPDATE_TCIN_LIST_SEARCH_API_REJECTED =
  'UPDATE_TCIN_LIST_SEARCH_API_REJECTED'

export const SET_PAGE_FILTERS = 'SET_PAGE_FILTERS'
