import {
  Paper,
  Typography,
  Button,
  IconButton,
  Popover,
  TablePagination,
  Drawer,
  TextField,
} from '@material-ui/core'
import React from 'react'
import {
  RemoveCircleOutlineOutlined as RemoveCircleOutlineOutlinedIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSelector, useDispatch } from 'react-redux'

import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import GetAppIcon from '@material-ui/icons/GetApp'
import { makeStyles } from '@material-ui/core/styles'
import CustomizeColumn from './CustomizeColumn'
import CustomPaginationActions from '../../components/CustomTable/CustomPaginationActions'
import Filters from '../TcinInfo/Filters'
import {
  generatePDFAPI,
  generateContactSheetPDFAPI,
  generateContactSheetFinalPDFAPI,
  generateCompactShotSheetPDFAPI,
} from '../../store/TcinInfo/actionCreator'
import axios from 'axios'
import apiConfig, { appEnv } from '../../apiConfig'
import { CSVLink } from 'react-csv'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { formatCSVData } from '../TcinInfo/mapper'
import { isEmpty } from 'lodash'
import { uniq, compact } from 'lodash'
import { withAuth } from '@praxis/component-auth'
import ConfirmationDailog from '../../components/ConfirmationDailog'

const { projectsApi } = apiConfig

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    width: '100%',
  },
  actionMenu: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  topMenu: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginTop: 8,
  },
  paginationToolbar: {
    marginLeft: 'auto',
  },
  inputProps: {
    height: 40,
    padding: '0 8px',
  },
  inputWidth: {
    width: 423,
  },
  inputLabel: {
    transform: 'translate(14px, 13px) scale(1)',
  },
  popover: {
    width: 896,
    padding: '20px 30px',
  },
}))

const TableToolbar = (props) => {
  const {
    selected,
    rows,
    originalRows,
    setRows,
    userColumns,
    customizeColumns,
    setCustomizeColumns,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    projectId,
    getProjectInfo,
    columns,
    isProducer,
    viewType,
    setViewType,
    updateUserView,
    totalCount,
    accessToken = '',
    lanId = '',
    isRemoveDisabled = true,
  } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const [customColumnAnchor, setCustomColumnAnchor] = React.useState(null)
  const [openFilters, setOpenFilters] = React.useState(false)
  const [progressSheet, setProgressSheet] = React.useState(0)
  const [downloadAnchorEl, setDownloadAnchorEl] = React.useState(null)
  const [downloadSheetAnchorEl, setDownloadSheetAnchorEl] = React.useState(null)
  const [search, setSearch] = React.useState('')
  const [csvData, setCsvData] = React.useState({
    headers: [],
    data: false,
  })
  const csvBtn = React.useRef()
  const [openConfirmation, setOpenConfirmation] = React.useState(false)

  const altImageCount = useSelector((state) => {
    const {
      dashboardReducer: { projectDataReducer: { max_alt_count = 0 } = {} } = {},
    } = state
    return max_alt_count
  })

  const onPageChangeClick = (event, page = Number(0)) => {
    getProjectInfo(page, rowsPerPage)
    handleChangePage(event, page)
  }

  const onRowsPerPageChangeClick = (event) => {
    getProjectInfo(page, event.target.value)
    handleChangeRowsPerPage(event, event.target.value)
  }

  React.useEffect(() => {
    if (
      !isEmpty(csvData.data) &&
      csvBtn.current !== null &&
      csvBtn.current.link !== null
    ) {
      setTimeout(() => {
        csvBtn.current !== null && csvBtn.current.link.click()
        setCsvData({ ...csvData, data: false })
      })
    }
  }, [csvData])

  const handleFilterClose = () => {
    setOpenFilters(false)
  }
  const handleDownloadShotSheets = () => {
    closeDownloadSheet()
    setProgressSheet(1)
    dispatch(
      generatePDFAPI(
        selected.map((r) => r.tcin),
        accessToken,
        downloadCallBack
      )
    )
  }

  const handleDownloadContactSheets = () => {
    closeDownloadSheet()
    setProgressSheet(1)
    dispatch(
      generateContactSheetPDFAPI(
        selected.map((r) => r.tcin),
        accessToken,
        downloadCallBack
      )
    )
  }

  const handleDownloadContactSheetsFinal = () => {
    closeDownloadSheet()
    setProgressSheet(1)
    dispatch(
      generateContactSheetFinalPDFAPI(
        selected.map((r) => r.tcin),
        accessToken,
        downloadCallBack
      )
    )
  }

  const handleDownloadCompactShotSheets = () => {
    closeDownloadSheet()
    setProgressSheet(1)
    dispatch(
      generateCompactShotSheetPDFAPI(
        selected.map((r) => r.tcin),
        accessToken,
        downloadCallBack
      )
    )
  }

  const closeDownload = () => {
    setDownloadAnchorEl(null)
  }

  const closeDownloadSheet = () => {
    setDownloadSheetAnchorEl(null)
  }

  const downloadCallBack = (downlistPercentages = '0') => {
    if (downlistPercentages === 100) {
      setProgressSheet(0)
    } else {
      setProgressSheet(downlistPercentages)
    }
  }

  const removeTcinsFromProject = () => {
    let url = `${projectsApi}/update_tcins/`
    let data = {
      tcins: selected.map((o) => o.tcin),
    }
    axios
      .post(url, data, {
        params: {
          key: apiConfig.apiKey,
          project_id: projectId,
        },
        headers: {
          Authorization: accessToken,
          ...(appEnv === 'dev' && { 'x-tgt-lanId': lanId }),
        },
      })
      .then((res) => {
        getProjectInfo()
      })
  }

  const onCloseCustomizeWindow = () => {
    setCustomColumnAnchor(null)
  }

  const onClickDownload = (event, value) => {
    closeDownload()
    let csvHeaders = []
    if (event.currentTarget.dataset.item === 'all') {
      csvHeaders = columns
        .filter((o) => {
          return o.id !== undefined && o.csvDownload !== false
        })
        .map((obj) => {
          return {
            key: obj.id,
            label: obj.headerName,
          }
        })
    } else {
      csvHeaders = columns
        .filter((col) => {
          return (
            (typeof col.showColumn === 'function' ||
            typeof col.showNestedRow === 'function'
              ? col.showColumn(customizeColumns) ||
                col.showNestedRow(customizeColumns)
              : customizeColumns[col.id].selected) && col.csvDownload !== false
          )
        })
        .map((obj) => {
          return {
            key: obj.id,
            label: obj.headerName,
          }
        })
    }
    setCsvData({
      headers: csvHeaders,
      data: formatCSVData(selected, altImageCount, 'csv', 'projectInfo'),
    })
  }

  const openDownload = (event) => {
    setDownloadAnchorEl(event.currentTarget)
  }

  const openDownloadSheet = (event) => {
    setDownloadSheetAnchorEl(event.currentTarget)
  }

  const onChangeSearch = (e) => {
    let tcinsList = e.target.value
      ? uniq(e.target.value.split(/[^\d\w-]+/g))
      : []
    let searchedRows = originalRows.filter(
      (o) =>
        tcinsList.length === 0 ||
        compact(tcinsList).some((t) => o.tcin?.includes(t)) ||
        compact(tcinsList).some((t) => o.dpci?.includes(t))
    )
    setSearch(tcinsList.join(', ').trim())
    setRows(searchedRows)
    props.setTabContent(searchedRows)
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <div className={classes.topMenu}>
        <Typography variant="h6" style={{ fontWeight: 400 }}>
          Showing {rows.length} TCINs of {totalCount}
        </Typography>
        <TextField
          type="search"
          autoFocus
          label="Find TCINs or DPCIs in this page"
          id="find-project"
          className={classes.paginationToolbar}
          InputLabelProps={{
            classes: {
              outlined: classes.inputLabel,
            },
          }}
          onChange={onChangeSearch}
          value={search}
          InputProps={{
            classes: {
              inputAdornedEnd: classes.inputProps,
              adornedEnd: classes.inputWidth,
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="find_project" edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
        {/* <Badge badgeContent={5} color="secondary">
          <Button
            variant="outlined"
            // disabled={!isFilterEnabled}
            startIcon={<FilterListIcon />}
            className={classes.button}
            onClick={() => setOpenFilters(true)}
          >
            <span className={classes.filtersSelected}></span>
            Filters
          </Button>
        </Badge> */}
        <ConfirmationDailog
          open={openConfirmation}
          alertMessage="Are you sure?"
          message={`${selected.length} selected tcins will be removed from this project`}
          cancelText="Cancel"
          agreeText="Confirm"
          onConfirm={() => {
            removeTcinsFromProject()
            setOpenConfirmation(false)
          }}
          onClose={() => setOpenConfirmation(false)}
        />
        <Drawer
          classes={{
            paper: classes.filterDrawer,
          }}
          anchor="right"
          open={openFilters}
          onClose={handleFilterClose}
        >
          <Filters closeDialog={handleFilterClose} />
        </Drawer>
      </div>
      <div className={classes.actionMenu}>
        <Typography variant="body1" style={{ fontWeight: 400 }}>
          {selected.length} Selected
        </Typography>
        {isProducer && !apiConfig.isPol && (
          <Button
            disabled={!(selected.length > 0 && !isRemoveDisabled)}
            onClick={() => {
              setOpenConfirmation(true)
            }}
            startIcon={<RemoveCircleOutlineOutlinedIcon />}
          >
            Remove From Project
          </Button>
        )}

        {/* <Button startIcon={<GetAppIcon />}>Data</Button> */}
        <Button
          variant="outlined"
          startIcon={<GetAppIcon />}
          onClick={openDownload}
          disabled={selected.length === 0}
        >
          Data
        </Button>
        {csvData.data ? (
          <CSVLink
            ref={csvBtn}
            asyncOnClick
            filename={'tcinImageInfo.csv'}
            data={csvData.data}
            headers={csvData.headers}
          />
        ) : undefined}
        <Menu
          id="simple-menu"
          anchorEl={downloadAnchorEl}
          keepMounted
          open={Boolean(downloadAnchorEl)}
          onClose={closeDownload}
        >
          <MenuItem data-item="all" onClick={onClickDownload}>
            Download All Columns
          </MenuItem>
          <MenuItem data-item="visible" onClick={onClickDownload}>
            Download Visible Columns
          </MenuItem>
        </Menu>
        <Button
          variant="outlined"
          startIcon={<GetAppIcon />}
          onClick={openDownloadSheet}
          disabled={selected.length === 0 || progressSheet !== 0}
        >
          Sheets
          {progressSheet !== 0 && (
            <CircularProgress
              style={{
                marginLeft: 8,
              }}
              thickness={6}
              size={20}
              variant="determinate"
              value={progressSheet}
            />
          )}
        </Button>
        {csvData.data ? (
          <CSVLink
            ref={csvBtn}
            asyncOnClick
            filename={'tcinImageInfo.csv'}
            data={csvData.data}
            headers={csvData.headers}
          />
        ) : undefined}
        <Menu
          id="simple-menu"
          anchorEl={downloadSheetAnchorEl}
          keepMounted
          open={Boolean(downloadSheetAnchorEl)}
          onClose={closeDownloadSheet}
        >
          <MenuItem data-item="shot-sheet" onClick={handleDownloadShotSheets}>
            Shot Sheets
          </MenuItem>
          <MenuItem
            data-item="contact-sheet"
            onClick={handleDownloadCompactShotSheets}
          >
            Compact Shot Sheets
          </MenuItem>
          <MenuItem
            data-item="contact-sheet"
            onClick={handleDownloadContactSheets}
          >
            Contact Sheets Source
          </MenuItem>

          <MenuItem
            data-item="contact-sheet"
            onClick={handleDownloadContactSheetsFinal}
          >
            Contact Sheets Final
          </MenuItem>
        </Menu>

        <TablePagination
          classes={{
            root: classes.paginationToolbar,
          }}
          component="div"
          rowsPerPageOptions={[
            50,
            100,
            200,
            { label: 'All', value: totalCount },
          ]}
          colSpan={3}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onPageChangeClick}
          onChangeRowsPerPage={onRowsPerPageChangeClick}
          ActionsComponent={CustomPaginationActions}
        />
        <IconButton
          variant="outlined"
          onClick={(e) => setCustomColumnAnchor(e.currentTarget)}
        >
          <SettingsIcon />
        </IconButton>
        <Popover
          open={Boolean(customColumnAnchor)}
          anchorEl={customColumnAnchor}
          onClose={onCloseCustomizeWindow}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          classes={{
            paper: classes.popover,
          }}
        >
          <CustomizeColumn
            userColumns={userColumns}
            customColumns={customizeColumns}
            setCustomColumns={setCustomizeColumns}
            onDiscardChanges={onCloseCustomizeWindow}
            onPopupClose={setCustomColumnAnchor}
            viewType={viewType}
            setViewType={setViewType}
            updateUserView={updateUserView}
          />
        </Popover>
      </div>
    </Paper>
  )
}

const mapAuthToProps = (auth) => {
  return {
    accessToken: auth.session?.userInfo?.accessToken,
    lanId: auth.session?.userInfo?.lanId,
  }
}

export default withAuth(mapAuthToProps)(TableToolbar)
