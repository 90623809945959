import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  barRoot: {
    height: 10,
    borderRadius: 5,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
})

export default function LinearDeterminate({ progress = 0 }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <LinearProgress
        classes={{
          root: classes.barRoot,
          bar: classes.bar,
        }}
        variant="determinate"
        value={progress}
      />
    </div>
  )
}
